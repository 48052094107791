import React, { useEffect, useState, useMemo, useContext } from "react";
import TopicStateProvider from "contexts/TopicStateProvider";
import { shouldLog } from "utils/shouldLog";
import UITopicContainer from "./UITopicContainer";
import GlobalContext from "contexts/context";
import "ui/UISS.css";
import { isMobileTablet } from "hooks/helper";

const isEqual = require("react-fast-compare");

const UITopicFrame = (props) => {
  let key
  let s
  const { globalState } = useContext(GlobalContext);

  // if (props.type !== "here") {
  //   key =
  //     "UITopicFrame_" +
  //     (props.location?.state?.dialog?.mdialog ||
  //       props.location?.state?.subscription?.mtopic ||
  //       props.location?.state?.topic?.mtopic);
  //   s = props.location.state;
  // }
  if (isMobileTablet()) {
    key =
      "UITopicFrame_" +
      (props.location?.state?.dialog?.mdialog ||
        props.location?.state?.subscription?.mtopic ||
        props.location?.state?.topic?.mtopic);
    s = props.location.state;
    // key =
    //   "UITopicFrame_" +
    //   (props.dialog?.mdialog ||
    //     props.subscription?.mtopic ||
    //     props.topic?.mtopic);
    // s = props.state
  }

  const [frame, setFrame] = useState({
    intHeight: window.innerHeight,
    uiHeight: window.innerHeight,
    intWidth: window.innerWidth,
    uiWidth: window.innerWidth
  });

  useEffect(() => {
    const handleResize = () => {
      shouldLog() && console.log("[UITopicFrame] handleResize");
      setFrame({
        intHeight: window.innerHeight,
        uiHeight: window.innerHeight,
        intWidth: window.innerWidth,
        uiWidth: window.innerWidth
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styleWidth = {
    height: frame.uiHeight,
    // Adding media query..
    "@media (maxWidth: 40rem)": {
      height: frame.uiHeight,
      width: frame.uiWidth
    }
  };

  const topicContainer = useMemo(() => {
    return (
      // <TopicStateProvider>
        <UITopicContainer
          showback={true}
          metadata={s}
          key={key}
          type={props.type ? props.type : ""}

        />
      // </TopicStateProvider>
    );
  }, [key, s]);

  let content = (
    <div>
    <div style={{ flex: "1" }}>
      <div
        className="UI"
        style={{
          height: frame.intHeight,
        }}
      >
          {isMobileTablet() &&
            <div
              className="UI-frame"
              style={globalState.isLoggedIn
                ? {
                  ...styleWidth
                }
                : { display: "none" }}
            >
              {topicContainer}
            </div>
          }
      </div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicFrame, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
