import React from "react";
import useWebsocketState from "connectivity/useWebsocketState";
import WebsocketContext from "connectivity/contextWebsocket";

const WebsocketStateProvider = ({ children }) => {
  const stateWebsocket = {};

  return (
    <WebsocketContext.Provider value={useWebsocketState(stateWebsocket)}>
      {children}
    </WebsocketContext.Provider>
  );
};

export default WebsocketStateProvider;
