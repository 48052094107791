import React from "react";
import useFocusState from "contexts/useFocusState";
import FocusContext from "contexts/contextFocus";

const FocusStateProvider = ({ children }) => {
  return (
    <FocusContext.Provider value={useFocusState({})}>
      {children}
    </FocusContext.Provider>
  );
};

export default FocusStateProvider;
