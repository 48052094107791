import React, { useContext, useState, useEffect, useRef } from "react";
import {
  WorldTextInput,
  convHrsMins,
  DispTopicHdr
} from "../utils/UtilsUniversal";
import useDebounce from "hooks/debounce";
import { workerGet } from "workers/interfaceRest";
import GlobalContext from "contexts/context";
import TopicsContext from "contexts/contextTopics";
import "ui/UI.css"
import "./modals.css"
import { isMobileTablet } from "hooks/helper";

const UIInvitePersonas = (props) => {
  const sortJoin = (A, B, Delim) => {
    return A < B ? A + Delim + B : B + Delim + A;
  };
  const [minvitees, setMinvitees] = useState([]); //list of selected personas
  const [thisPersonaList, setThisPersonaList] = useState([]); //initial list of searched matches
  const [useSearch, setSearch] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const increment = useRef(null);
  const tick = useRef();
  const debouncedSearchTerm = useDebounce(useSearch, 500);
  const { globalState } = useContext(GlobalContext);
  const { topicsState } = useContext(TopicsContext);
  const [rolesChanged, setRolesChanged] = useState({});
  const [rolesReturned, setRolesReturned] = useState({});
  const refLast = useRef();
  const [scrollToBtm, setScrollToBtm] = useState(false);
  const [screenWidth, setScreenWidth] = useState();
  const [hdrContainerHgt, setHdrContainerHgt] = useState();
  useEffect(() => {
    if (!screenWidth || screenWidth === undefined)
      setScreenWidth(window.innerWidth);
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (increment.current) {
      increment.current = !increment.current;
      return;
    }

    if (isActive) {
      tick.current = setInterval(() => {
        // <-- set tick ref current value
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current); // <-- access tick ref current value
    }

    return () => clearInterval(tick.current); // <-- clear on unmount!
  }, [isActive]);

  useEffect(() => {
    const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
    const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
    if (debouncedSearchTerm === "") {
      setIsActive(false);
      setThisPersonaList([]);
    }
    let isMounted = true;
    globalState?.jwt &&
      debouncedSearchTerm &&
      debouncedSearchTerm !== "" &&
      workerGet(urlPersona, keyPersona, {
        type: "searchpersona",
        value: debouncedSearchTerm,
        token: globalState?.jwt,
        version: "v1.6.0"
      })
        .then((result) => {
          let newResults = result.list.filter(
            (item) => !minvitees.some((el) => el.mpersona === item.mpersona)
          );
          isMounted && setThisPersonaList(newResults || []);
          clearInterval(increment.current);
          setIsActive(false);
          setTimer(0);
        })
        .catch((err) => {
          console.log("CATCH", err);
        });

    return () => {
      isMounted = false;
    };
  }, [debouncedSearchTerm, globalState.version]);

  const roles = {
    owner: [
      // { label: "default", role: "default" },
      { label: "owner", role: "owner" },
      { label: "admin", role: "admin" },
      { label: "design", role: "designer" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" }
      // { label: "receive", role: "rx" }
    ],
    admin: [
      // { label: "default", role: "default" },
      { label: "admin", role: "admin" },
      { label: "design", role: "designer" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" }
      // { label: "receive", role: "rx" }
    ],
    designer: [
      // { label: "default", role: "default" },
      { label: "design", role: "designer" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" }
      // { label: "receive", role: "rx" }
    ],
    tx: [
      // { label: "default", role: "default" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" }
      // { label: "receive", role: "rx" }
    ],
    whisper: [
      // { label: "default", role: "default" },
      { label: "whisper", role: "whisper" }
      // { label: "receive", role: "rx" }
    ],
    rx: [
      // { label: "default", role: "default" },
      // { label: "receive", role: "rx" }
    ]
  };

  // const noBorderStyle = {
  //   borderBottom: "none",
  //   cursor: "pointer"
  // };

  const invRoles = props.topic?.roles?.includes("owner")
    ? roles["owner"]
    : props.topic?.roles?.includes("admin")
    ? roles["admin"]
    : props.topic?.roles?.includes("designer")
    ? roles["designer"]
    : props.topic?.roles?.includes("tx")
    ? roles["tx"]
    : props.topic?.roles?.includes("whisper")
    ? roles["whisper"]
    : props.topic?.roles?.includes("rx")
    ? roles["rx"]
    : [];

  // const checkDefaultChecked = (B, r) => {
  //   for (let rc = 0; rc < minvitees?.length; rc++) {
  //     if (minvitees.length > 0 && B === minvitees[rc].mpersona)
  //       for (let rl = 0; rl < minvitees[rc].roles?.length; rl++)
  //         if (minvitees[rc].roles[rl] === r.role) {
  //           return true;
  //         }
  //   }
  //   return false;
  // };

  // const checkChecked = (B, r) => {
  //   for (let rc = 0; rc < props.newInvites?.length; rc++) {
  //     if (props.newInvites.length > 0 && B === props.newInvites[rc].mpersona)
  //       for (let rl = 0; rl < props.newInvites[rc].roles?.length; rl++)
  //         if (props.newInvites[rc].roles[rl] === r.role) {
  //           return true;
  //         }
  //   }
  //   return false;
  // };

  const addDefaults = (persona, mpersona, currentRoles) => {
    let index = minvitees.findIndex((x) => x.mpersona === mpersona);
    let defaultRoles = currentRoles || [];
    let newRoles = [
      ...defaultRoles,
      ...currentRoles.filter(
        (r) => !defaultRoles.includes(r)
        // &&
        // !(r === "tx" && defaultRoles.includes("whisper")) &&
        // !(r === "whisper" && defaultRoles.includes("tx"))
      )
    ];
    let ThisMpersona = {
      persona: persona,
      mpersona: mpersona,
      bestow: newRoles,
      revoke: []
    };
    if (index > 0) {
      const newList = [...minvitees];
      newList[index] = ThisMpersona;
      setMinvitees(newList);
    } else {
      let OtherMpersonas = minvitees.filter((m) => m.mpersona !== mpersona);
      setMinvitees(
        newRoles.length === 0
          ? [...OtherMpersonas]
          : [ThisMpersona, ...OtherMpersonas]
      );
    }
  };

  const toggle = (persona, Bmpersona, role) => {
    editRoles(persona, false, Bmpersona, role);
  };

  const editRoles = (persona, mustAdd, mpersona, role) => {
    let index = minvitees.findIndex((x) => x.mpersona === mpersona);
    // let OtherMpersonas = minvitees?.filter((m) => m.mpersona !== mpersona);
    let ThisMpersona = minvitees?.filter((m) => m.mpersona === mpersona)[0];
    let currentRoles = ThisMpersona ? ThisMpersona.bestow : [];
    {
      let newRoles;
      if (mustAdd) {
        newRoles = [role, ...currentRoles].filter(
          (r) =>
            !(
              (r === "whisper" && role === "tx") ||
              (r === "tx" && role === "whisper")
            )
        );
        // if (role === "tx") toggle(persona, mpersona, "whisper");
        // if (role === "whisper") toggle(persona, mpersona, "tx");
      } else {
        newRoles = currentRoles.filter((r) => r !== role);
      }
      ThisMpersona = {
        persona: persona,
        mpersona: mpersona,
        bestow: newRoles,
        revoke: []
      };
      const newList = [...minvitees];
      newList[index] = ThisMpersona;
      setMinvitees(newList);
    }
  };

  const checkRoles = (r, mpersona) => {
    let index = minvitees.findIndex((x) => x.mpersona === mpersona);
    return minvitees[index].bestow.some((el) => el === r.role);
  };

  const inheritance = (role, mpersona) => {
    //need to check if role is checked ie.minvitees[index].roles.some((el) => el === role)
    //if it is checked-> if first item in arr (owner) return false
    //if not first (else) check prev role for checked
    //if that is chcked return True else return false
    let rolesArr = Object.keys(roles);
    let index = minvitees.findIndex((x) => x.mpersona === mpersona);
    let indexRoles = rolesArr.findIndex((x) => x === role);
    if (minvitees[index].bestow.some((el) => el === role)) {
      if (indexRoles === 0) return false;
      else if (indexRoles > 0) {
        if (
          minvitees[index].bestow.some(
            (el) => el === rolesArr[indexRoles - 1]
          ) ||
          (role === "whisper" &&
            minvitees[index].bestow.some(
              (el) => el === rolesArr[indexRoles - 2]
            ))
        )
          return true;
        else return false;
      } else return false;
    } else {
      if (
        role === "tx" &&
        minvitees[index].bestow.some((el) => el === rolesArr[indexRoles - 1])
      )
        return true;
      else if (
        role === "whisper" &&
        minvitees[index].bestow.some((el) => el === rolesArr[indexRoles - 1])
      )
        return true;
      else return false;
    }
  };

  const handleRolesChange = () => {
    //this just to get rid of warning onClick used in place of onChange
  };

  const boxes = (persona, Bmpersona) => {
    // persona here is an object containing persona,mpersona,last_seen
    return (
      <div className="UI-options-new">
        {invRoles.map((r) => (
          <div key={Bmpersona + "_" + r.role} id={Bmpersona + "_" + r.role}>
            <input
              className="checkbox"
              style={{ alignContent: "center" }}
              type="checkbox"
              key={Bmpersona + "|" + r.role}
              id={Bmpersona + "|" + r.role}
              disabled={inheritance(r.role, Bmpersona)}
              checked={checkRoles(r, persona.mpersona)}
              onClick={() => {
                if (document.getElementById(Bmpersona + "|" + r.role).checked) {
                  // if (r.role === "tx") {
                  //   //instead of setting 2 checked vars set 1 and let it contain "tx","whisper", or "undefined" or summin like that
                  //   //call the var sndWhspChk
                  //   //it needs to be linked to the persona.... do we make an array of sndWhspChk
                  //   //let's make an array of objects---[{mpersona:XXX,sndWhsp:XXX},{...},...]
                  //   //so now with a storage spot how do we consume the data?
                  //   sendWhispArr(persona.mpersona, "tx")
                  // }
                  // else if (r.role === "whisper") {
                  //   sendWhispArr(persona.mpersona, "whisper")
                  // }
                  editRoles(persona.persona, true, Bmpersona, r.role);
                  setRolesChanged({ persona: persona, newRole: r.role });
                } else {
                  if (
                    props.topic?.props?.defaultroles.some((el) => el === r.role)
                  )
                    editRoles(persona.persona, false, Bmpersona, r.role);
                  else setRolesReturned({ persona: persona });
                }
              }}
              onChange={handleRolesChange}
            ></input>
            <label style={{ fontSize: "0.9rem", maxWidth: "fit-content" }}>
              {r.label}
            </label>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    //steps
    //need to look at all minvitees (is there a way to know which changed?)
    //get highest level of Roles - levels: 1 owner, 2 admin, 3 designer,
    //4 tx, 5 whisper(either tx or whisper), 6 rx(does not display)
    //check if all below are greyed and checked
    if (minvitees?.length > 0) {
      // let index = sndWhspChk.findIndex(x => x.mpersona === rolesChanged.persona.mpersona);
      //it needs to be linked to the persona.... do we make an array of sndWhspChk
      //let's make an array of objects---[{mpersona:XXX,sndWhsp:XXX},{
      let defRoles;
      switch (rolesChanged.newRole) {
        case "owner":
          defRoles = ["owner", "admin", "designer", "tx", "whisper", "rx"];
          break;
        case "admin":
          defRoles = ["admin", "designer", "tx", "whisper", "rx"];
          break;
        case "designer":
          defRoles = ["designer", "tx", "whisper", "rx"];
          break;
        case "tx":
          defRoles = ["tx", "whisper", "rx"];
          break;
        case "whisper":
          defRoles = ["whisper", "rx"];
          break;
        default:
          defRoles = { undefined };
          break;
      }
      addDefaults(
        rolesChanged.persona?.persona,
        rolesChanged.persona?.mpersona,
        defRoles
      );
    }
  }, [rolesChanged]);

  //to add/edit an array
  //ADD eg:setThisPersonaList(thisPersonaList => [...thisPersonaList, tmpPersList]);
  //EDIT find index, copy to tmpArr, chnage item, return to orig arr
  //eg if (index > 0) {
  //   const newList = [...minvitees];
  //   newList[index] = ThisMpersona;
  //   setMinvitees(newList);
  // }

  // const sendWhispArr = (mpersona, sendOrWhisp) => {
  //   let index = sndWhspChk.findIndex(x => x.mpersona === mpersona);
  //   if (index >= 0) {
  //     const newData = [...sndWhspChk]
  //     newData[index] = { mpersona: mpersona, sndWhsp: sendOrWhisp }
  //     setSndWhspChk(newData)
  //   }
  //   else setSndWhspChk([...sndWhspChk, { mpersona: mpersona, sndWhsp: sendOrWhisp }])
  // }

  useEffect(() => {
    if (minvitees?.length > 0) {
      addDefaults(
        rolesReturned.persona?.persona,
        rolesReturned.persona?.mpersona,
        props.topic?.props?.defaultroles
      );
    }
    // if (props.topic?.props?.defaultroles?.length > 0 && minvitees?.length > 0) {
    //   if (props.topic?.props?.defaultroles.some(el => el === "tx")) {
    //     sendWhispArr(rolesReturned.persona?.mpersona, "tx")
    //   }
    //   else if (props.topic?.props?.defaultroles.some(el => el === "whisper")) {
    //     sendWhispArr(rolesReturned.persona?.mpersona, "whisper")
    //   }
    // }
  }, [rolesReturned]);

  const handleOnChange = (e) => {
    setSearch(e);
    setIsActive(true);
  };

  // Steps
  //Display personas, with checkboxes, that match search criteria
  //Allow selection via checkboxes
  //if selected transfer (remove from current display) to top section
  //add possible roles with checkboxes at new position
  //

  const getCreator = (mtopic) => {
    let topicMatches = topicsState.topics?.filter((t) => t.mtopic === mtopic);
    return {
      creator: topicMatches[0]?.mcreator,
      creatorpersona: topicMatches[0]?.persona
    };
  };

  const getCreationDate = (mp) => {
    let topicMatches = topicsState.topics?.filter(
      (t) => t.mtopic === mp && t.mpersona === props.topic?.mpersona
    );
    return topicMatches[0]?.props?.creationdate;
  };

  const handleOnChangePersList = (persona, index) => {
    if (props.topic?.props?.defaultroles?.length > 0) {
      addDefaults(persona, persona.mpersona, props.topic?.props?.defaultroles);
      // if (props.topic?.props?.defaultroles.some(el => el === "tx")) {
      //   sendWhispArr(persona?.persona?.mpersona, "tx")
      // }
      // else if (props.topic?.props?.defaultroles.some(el => el === "whisper")) {
      //   sendWhispArr(persona?.mpersona, "whisper")
      // }
    }
    setThisPersonaList(
      thisPersonaList.filter(
        (mp) => mp.mpersona !== thisPersonaList[index].mpersona
      )
    );
    setScrollToBtm(!scrollToBtm);
  };

  const handleOnChangeSelList = (item) => {
    let listIndex = minvitees.findIndex((x) => x.mpersona === item.mpersona);

    //remove selected from thisPersonaList
    //place selected in top section
    //add roles checkboxes to selected in top section
    setMinvitees(
      minvitees.filter((mp) => mp.mpersona !== minvitees[listIndex].mpersona)
    );
    let tmpPersList = minvitees[listIndex].persona;
    setThisPersonaList((thisPersonaList) => [...thisPersonaList, tmpPersList]);
  };

  useEffect(() => {
    refLast?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, [scrollToBtm]);

  useEffect(() => {
    if (props.onInvited) props.onInvited(minvitees);
  }, [minvitees]);

  let content = (
    <div className="UI-login-container">
      <div
        id="header_container"
        style={{
          fontStyle: "italic",
          marginBottom: "0.25rem"
        }}
      >
        <DispTopicHdr
          topicName={props.topic?.topic || props.topic?.dialog}
          displayName={props.topic?.props?.topic_display_name}
          topicID={props.topic?.mtopic}
          time={
            props.topic?.mtopic
              ? convHrsMins(getCreationDate(props.topic.mtopic))
              : "-"
          }
          creator={
            props.topic?.mtopic ? getCreator(props.topic?.mtopic).creator : "-"
          }
        />
      </div>
      {minvitees && minvitees.length > 0 && (
        <div className="modal-label-new" style={{ marginBottom: "0.5rem" }}>
          Selected personas:
          <div
            className="flex-container"
            style={{
              overflow: "auto",
              // padding:"0.25rem 0rem 0.25rem 0rem",
              maxHeight: `${
                props.theMaxHgtBody -
                250 -
                Math.min(thisPersonaList?.length * 20, 5 * 20)
              }px`,
              fontWeight: "normal",
              paddingRight: "1rem"
            }}
            // className="UI-persona-search-list-item-name--flex"
            // className="ui-invite-grid"
          >
            {
              // minvitees && minvitees.length > 0
              // ?
              [...minvitees].reverse().map((item, index) => (
                <div
                  key={"sp_" + item.mpersona}
                  id={"sp_" + item.mpersona}
                  ref={index === minvitees.length - 1 ? refLast : {}}
                  style={{
                    borderBottom:
                      "1px solid var(--main_screen_topic_separator_line_color)"
                  }}
                >
                  <div
                    className="flex-row"
                    // className="ui-invite-grid"
                  >
                    <div
                      className="flex-cell"
                      style={{
                        fontSize: isMobileTablet() ? "1rem" : "1.1rem",
                        marginRight: "1rem",
                        maxWidth: isMobileTablet()
                          ? "50vw"
                          : item.persona?.persona?.length > 40
                          ? "300px"
                          : "fit-content"
                        // cursor: props.roles?.length > 0 && "auto"
                      }}
                    >
                      <label
                        // className="flex-cell"
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        }}
                        // style={{
                        //     display: "flex",
                        //     flexDirection: "row",
                        //     alignItems: "flex-start",
                        //     fontWeight: "bold",
                        //     fontSize: "1rem"
                        //   }}
                      >
                        <input
                          className="UI-checkbox-large"
                          type="checkbox"
                          checked={
                            true //if unchecked the entry disappears from this list
                            // invitees.some(el => el === thisPersonaList[index].mpersona)
                          }
                          onChange={() => {
                            handleOnChangeSelList(item);
                            //remove selected from top selected list
                            //place in bottom section
                          }}
                        />
                        {item.persona?.persona}
                      </label>
                    </div>
                    <div
                      className="flex-cell"
                      style={{
                        fontSize: "0.9rem",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                      }}
                    >
                      {item.persona?.last_seen}
                    </div>
                  </div>
                  {/* <div className="UI-options-new"> */}
                  {invRoles.length > 0 && boxes(item, item.mpersona)}
                  {/* </div> */}
                </div>
              ))
              // : ""
            }
          </div>
        </div>
      )}
      {/* <div style={{ marginTop: "1rem" }}>
        Tap on names to invite
      </div> */}
      <WorldTextInput
        universalStyle={{ display: "flex", flexDirection: "column" }}
        identity="invitesearch"
        // ref={inviteBoxRef}
        focus={true}
        // labelStyle={{
        //   fontWeight: "normal",
        // }}
        type="text"
        // title={tagTooltip}
        callback={(e) => handleOnChange(e)}
        // label={"Tap on names to invite"}
        // required={true}
        placeholder={"Search for..."}
        description={thisPersonaList?.length > 0 ? "Select from list" : ""}
        minRows={1}
        maxRows={1}
        // minStrLen={3}
        // maxStrLen={10}
        // active={worldTestTA?.length > 0 ? "true" : "false"}
        value={useSearch}
        // regex={/^[\w\ ]+$/i}
      />
      {isActive && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Loading...</span>
          {timer}
          <div className="spinner"></div>
        </div>
      )}
      <div
      // className="UI-topic-list-container-search">
      >
        <div
          className="flex-row"
          // className="grid-row"
          // className="ui-invite-grid"
        >
          <header>Persona</header>
          <header style={{ marginRight: "2rem" }}>Last online</header>
        </div>
        {/* <div
          className="ui-invite-grid"
          style={{
            fontWeight: "bold",
            fontSize: "0.9rem"
            // marginLeft: "1.5rem"
          }}
        >
          <label>Persona</label>
          <label>Last online</label>
        </div> */}
        <div
          className="flex-container"
          // className="grid-container"
          style={{
            overflow: "auto",
            // padding:"0.25rem 0rem 0.25rem 0rem",
            maxHeight: `${
              props.theMaxHgtBody -
              250 -
              Math.min(minvitees?.length * 40, 5 * 40)
            }px`,
            paddingRight: "1rem"
          }}
        >
          {thisPersonaList === undefined || thisPersonaList.length === 0 ? (
            <p>No Results</p>
          ) : (
            thisPersonaList.map((p, index) => (
              <div
                key={"d_" + p.mpersona}
                id={"d_" + p.mpersona}
                style={{
                  borderBottom:
                    "1px solid var(--main_screen_topic_separator_line_color)"
                }}
              >
                <div
                // style={{
                //   overflowY: "auto",
                //   overflowX: "hidden",
                //   maxHeight: `${props.theMaxHgtBody - 55}px`,
                //   paddingRight: "1rem"
                // }}
                >
                  <div
                    className="flex-row"
                    // className="grid-row"

                    // style={
                    //   isMobileTablet()
                    //     ? {
                    //         gridTemplateColumns:
                    //           "minmax(42vw, 1fr) minmax(min-content, max-content)",
                    //         gap: "0.5rem"
                    //       }
                    //     : p.persona.length > (0.4 * screenWidth) / 10
                    //     ? {
                    //         gridTemplateColumns:
                    //           "minmax(42vw, 1fr) minmax(min-content, max-content)"
                    //       }
                    //     : {
                    //         gridTemplateColumns:
                    //           "minmax(max-content, 1fr) minmax(min-content, max-content)"
                    //       }
                    // }
                    // className="ui-invite-grid"
                  >
                    <div
                      className="flex-cell"
                      // className="grid-cell"
                      style={{
                        display: "flex",
                        fontSize: isMobileTablet() ? "1rem" : "1.1rem",
                        marginRight: isMobileTablet() ? "0" : "1rem",
                        maxWidth: isMobileTablet()
                          ? "45vw"
                          : p.persona?.length > 40
                          ? "300px"
                          : "fit-content",
                        // textOverflow: "ellipsis",
                        // whiteSpace: "nowrap",
                        // overflow: "hidden",
                        cursor: props.roles?.length > 0 && "auto"
                      }}
                    >
                      <label
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden"
                        }}
                      >
                        <input
                          className="checkbox"
                          type="checkbox"
                          checked={minvitees.some(
                            (el) => el === thisPersonaList[index].mpersona
                          )}
                          onChange={() => {
                            //remove selected from thisPersonaList
                            //place selected in top section
                            //add roles checkboxes to selected in top section
                            handleOnChangePersList(p, index);
                          }}
                        />
                        {p.persona}
                      </label>
                    </div>
                    <div
                      className="flex-cell"
                      style={{
                        fontSize: "0.9rem",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      }}
                      // className="grid-cell"
                    >
                      {p.last_seen}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto"
          }}
        >
        </div> */}
      </div>
    </div>
  );

  return content;
};

export default React.memo(UIInvitePersonas, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
