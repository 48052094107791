import React from "react";
import {
  GrDocument,
  GrDocumentCsv,
  GrDocumentExcel,
  GrDocumentPdf,
  GrDocumentPpt,
  GrDocumentSound,
  GrDocumentText,
  GrDocumentVideo,
  GrDocumentWindows,
  GrDocumentWord,
  GrDocumentZip,
} from "react-icons/gr";
import { FaJava, FaRegFileImage } from "react-icons/fa";

function GetFileExt(props) {
  const getExt = (fName) => {
    let startExt = fName.indexOf(".");
    let extension =
      startExt > 0 ? fName.substr(startExt + 1, fName.length) : "";
    return extension;
  };

  let extension = getExt(props.fName).toLowerCase();
  // let startExt = props.fName.indexOf(".")
  // let extension = startExt > 0 ? props.fName.substr(startExt + 1, props.fName.length)
  //     : ""
  // console.log("extension=", extension)
  let control = () => {
    switch (extension) {
      case "mp3":
      case "mpa":
      case "wav":
      case "wma":
      case "wpl":
        return <GrDocumentSound />;
      case "zip":
        return <GrDocumentZip />;
      case "csv":
        return <GrDocumentCsv />;
      case "exe":
        return <GrDocumentWindows />;
      case "gif":
      case "png":
      case "bmp":
      case "jpeg":
      case "jpg":
      case "ps":
      case "psd":
      case "svg":
      case "xcf":
      case "tif":
        return <FaRegFileImage />;
      case "ppt":
      case "pptx":
        return <GrDocumentPpt />;
      case "java":
      case "php":
        return <FaJava />;
      case "xls":
      case "xlsm":
      case "xlsx":
        return <GrDocumentExcel />;
      case "m4v":
      case "mov":
      case "mp4":
      case "mpg":
      case "mpeg":
      case "wmv":
        return <GrDocumentVideo />;
      case "doc":
      case "docx":
        return <GrDocumentWord />;
      case "pdf":
        return <GrDocumentPdf />;
      case "txt":
      case "dat":
        return <GrDocumentText />;
      default:
        return <GrDocument />;
    }
  };
  return control();
}

export default GetFileExt;
