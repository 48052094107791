import React from "react";
import useDialogsState from "contexts/useDialogsState";
import DialogsContext from "contexts/contextDialogs";

const DialogsStateProvider = ({ children }) => {
  return (
    <DialogsContext.Provider value={useDialogsState({})}>
      {children}
    </DialogsContext.Provider>
  );
};

export default DialogsStateProvider;
