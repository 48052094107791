import React from "react";
import useServiceMessageState from "contexts/useServiceMessageState";
import ServiceMessageContext from "contexts/contextServiceMessage";

const ServiceMessageStateProvider = ({ children }) => {
  const stateSvcMsg = {
    modal: undefined,
    response: [],
  };

  return (
    <ServiceMessageContext.Provider value={useServiceMessageState(stateSvcMsg)}>
      {children}
    </ServiceMessageContext.Provider>
  );
};

export default ServiceMessageStateProvider;
