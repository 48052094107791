import { useReducer } from "react";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  shouldLog() && console.log("SET subs", state, action);
  switch (action.type) {
    case "SET_SUBSCRIPTIONS":
      if (isEqual(state.subscriptions, action.values.subscriptions))
        return state;
      else
        return {
          ...state,
          subscriptions: action.values.subscriptions
        };
    default: {
      return state;
    }
  }
};

const useSubscriptionsState = (state) => {
  const [subscriptionsState, subscriptionsDispatch] = useReducer(
    reducer,
    state
  );
  return { subscriptionsState, subscriptionsDispatch };
};

export default useSubscriptionsState;
