import { useReducer } from "react";
import isEqual from "react-fast-compare";

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_WAIT_ID":
      state.wait = state.wait || {};
      if (state?.wait[action.values.id] === action.values.value) return state;
      else {
        let wait = { ...(state.wait, {}) };
        wait[action.values.id] = action.values.value;
        return { ...state, wait: wait };
      }
    case "SET_RESPONSE":
      if (isEqual(state.response, action.values.response)) return state;
      else
        return {
          ...state,
          response: action.values.response
        };
    case "SET_MODAL":
      if (isEqual(state.modal, action.values.modal)) return state;
      else
        return {
          ...state,
          modal: action.values.modal
        };
    default: {
      return state;
    }
  }
};

const useServiceMessageState = (state) => {
  const [svcMsgState, svcMsgDispatch] = useReducer(reducer, state);
  return { svcMsgState, svcMsgDispatch };
};

export default useServiceMessageState;
