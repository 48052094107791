import React from "react";
import useLocationState from "geospatial/useLocationState";
import LocationContext from "geospatial/contextLocation";

const LocationStateProvider = ({ children }) => {
  const stateLocation = {
    latitude: 0,
    longitude: 0,
    accuracy: 21000000, // 21,000km
    timestamp: Date.now(),
    useLocation: localStorage.getItem("useLocation") ?? "true",
  };

  return (
    <LocationContext.Provider value={useLocationState(stateLocation)}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationStateProvider;
