import React from "react";
import useCountState from "contexts/useCountState";
import CountContext from "contexts/contextCount";

const CountStateProvider = ({ children }) => {
  return (
    <CountContext.Provider value={useCountState({ count: {} })}>
      {children}
    </CountContext.Provider>
  );
};

export default CountStateProvider;
