import React from "react";
import useGlobalState from "contexts/useGlobalState";
import GlobalContext from "contexts/context";
import { version } from "version.js";

const GlobalStateProvider = ({ children }) => {
  const stateGlobal = {
    isLoggedIn: false,
    localKey: null,
    version: version
  };

  return (
    <GlobalContext.Provider value={useGlobalState(stateGlobal)}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalStateProvider;
