import {
  AiOutlineMessage,
  AiOutlineQuestionCircle,
  AiOutlineSchedule,
  AiOutlineTool
} from "react-icons/ai";
import { BiAngry, BiTachometer } from "react-icons/bi";
import { BsPiggyBank } from "react-icons/bs";
import { CgDanger, CgFileDocument, CgProfile } from "react-icons/cg";
import { FaTeamspeak } from "react-icons/fa";
import { GiEarthAfricaEurope } from "react-icons/gi";
import { GoReport } from "react-icons/go";
import { IoIosGlobe } from "react-icons/io";
import { IoCardOutline, IoEnterOutline, IoExitOutline } from "react-icons/io5";
import { MdOutlineCheckCircleOutline, MdOutlineGroups } from "react-icons/md";
import { RiCoinsLine, RiInformationLine } from "react-icons/ri";
import { TiContacts } from "react-icons/ti";
import BSBoxWorld from "./icons/BsBoxWorld";
import GrDocTimeWorld from "./icons/GrDocTimeWorld";
import GrWorkshopWorld from "./icons/GrWorkshopWorld";

export const buttonTypes = [
  {
    title: "Services",
    icon: <AiOutlineTool />
  },
  {
    title: "Info",
    icon: <RiInformationLine />
  },
  {
    title: "Account",
    icon: <RiCoinsLine />
  },
  {
    title: "Report",
    icon: <GoReport />
  },
  {
    title: "Finance",
    icon: <BsPiggyBank />
  },
  {
    title: "Portal",
    icon: <IoIosGlobe />
  },
  {
    title: "Meters",
    icon: <BiTachometer />
  },
  {
    title: "Docs",
    icon: <CgFileDocument />
  },
  {
    title: "Meetings",
    icon: <AiOutlineSchedule />
  },
  {
    title: "Visitors",
    icon: <MdOutlineGroups />
  },
  {
    title: "Directory",
    icon: <TiContacts />
  },
  {
    title: "Profile",
    icon: <CgProfile />
  },
  {
    title: "Panic",
    icon: <CgDanger />
  },
  {
    title: "Exit",
    icon: <IoExitOutline size={20} />
  },
  {
    title: "Enter",
    icon: <IoEnterOutline />
  },
  {
    title: "Help",
    icon: <AiOutlineQuestionCircle />
  },
  {
    title: "Chat",
    icon: <AiOutlineMessage />
  },
  {
    title: "Complaint",
    icon: <BiAngry />
  },
  {
    title: "Ask",
    icon: <FaTeamspeak />
  },
  {
    title: "Approvals",
    icon: <MdOutlineCheckCircleOutline />
  },
  {
    title: "WORLD",
    icon: <GiEarthAfricaEurope />
  },
  {
    title: "Timesheet",
    icon: <GrDocTimeWorld strokecolor="white" fillcolor="white" />
    //  GrDocumentTime classname="icon" size={40} />
  },
  {
    title: "Work Order",
    icon: <GrWorkshopWorld strokecolor="white" fillcolor="white" />
  },
  {
    title: "Expense",
    icon: <IoCardOutline />
  },
  {
    title: "Material",
    icon: (
      <BSBoxWorld strokecolor="white" fillcolor="white" strokewidth="0.25" />
    )
  }
];
