import React from "react";
import ClickOutsideDetector from "utils/ClickOutsideDetector";

const UIPersonaFlag = (props) => {
  const [show, setShow] = React.useState(false);

  let mpersona = props.mpersona;
  let c1 = "#" + mpersona.substring(0, 6);
  let c2 = "#" + mpersona.substring(6, 12);
  let c3 = "#" + mpersona.substring(12, 18);
  let c4 = "#" + mpersona.substring(18, 24);
  let c5 = "#" + mpersona.substring(24, 30);
  let c6 = "#" + mpersona.substring(30, 32) + mpersona.substring(0, 4);

  const flagLeft = {
    // position: "absolute",
    // top: "50px",
    // left: "0px",
    // margin: "0px",
    // padding: "0px",

    display: "grid",
    gridTemplateColumns: "7px 7px 7px",
    gridTemplateRows: "7px 7px",
    gridGap: "0px",
    backgroundColor: "#fff",
    // color: "#444",
    width: "21px",
    padding: "1px",
    border: "1px solid #000",
  }

  const flagRight = {
    // position: "absolute",
    // top: "50px",
    // right: "0px",
    // margin: "0px",
    // padding: "0px",
    display: "grid",
    gridTemplateColumns: "7px 7px 7px",
    gridTemplateRows: "7px 7px",
    gridGap: "0px",
    backgroundColor: "#fff",
    // color: "#444",
    width: "21px",
    padding: "1px",
    border: "1px solid #000",
  }

  let flag = (
    <div
      style={props.left_right === "left" ? flagLeft : flagRight}

    >
      <div
        style={{
          backgroundColor: c1,
          color: c1,
          borderRadius: "0px",
        }}
      ></div>
      <div
        style={{
          backgroundColor: c2,
          color: c2,
          borderRadius: "0px",
        }}
      ></div>
      <div
        style={{
          backgroundColor: c3,
          color: c3,
          borderRadius: "0px",
        }}
      ></div>
      <div
        style={{
          backgroundColor: c4,
          color: c4,
          borderRadius: "0px",
        }}
      ></div>
      <div
        style={{
          backgroundColor: c5,
          color: c5,
          borderRadius: "0px",
        }}
      ></div>
      <div
        style={{
          backgroundColor: c6,
          color: c6,
          borderRadius: "0px",
        }}
      ></div>
    </div>
  );

  const flagStyleLeft = {
    textAlign: "left",
    // marginLeft: "20px",
    position: "absolute",
    padding: "0px",
    backgroundColor: "var(--receive_bubble_text_color)",
    color: "var(--receive_bubble_background_color)",
    border: "1px solid var(--receive_bubble_border_color)",
    borderRadius: "var(--border_radius_small)",
    paddingLeft: "4px",
    paddingRight: "4px",
    transform: "translate(0px, 0px)"
  }
  const flagStyleRight = {
    textAlign: "right",
    margin: "0px",
    padding: "0px",
    position: "absolute",
    backgroundColor: "var(--send_bubble_text_color)",
    color: "var(--send_bubble_background_color)",
    border: "1px solid var(--send_bubble_border_color)",
    borderRadius: "var(--border_radius_small)",
    paddingLeft: "4px",
    paddingRight: "4px",
    transform: "translate(0px, 0px)"
  }

  let hint = (
    <div
      style={
        props.left_right === "left" ? flagStyleLeft : flagStyleRight}
    // style={flagStyleRight}
    >
      {props.mpersona}
    </div >
  );

  let content = (
    <div>
      <ClickOutsideDetector
        caller="UIPersonaFlag"
        listen
        onClickOutside={() => {
          setShow(false)
        }}
        onClick={() => { }}
      >
        <span
          // className="name"
          // style={
          // props.left_right === "left" ? flagStyleLeft : flagStyleRight
          // backgroundColor: "black",
          // border: "1px solid #000",
          // position: "absolute",
          // top: "50px",

          // }
          onClick={() => setShow(true)}
        >
          {props.showFlagOnly ? flag : show ? hint : flag}
        </span>
      </ClickOutsideDetector>
    </div>
  );

  return content;
};

export default UIPersonaFlag;
