import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import TopicsContext from "contexts/contextTopics";
// import ClickOutsideDetector from "utils/ClickOutsideDetector";
import { isMobileTablet, randomString } from "hooks/helper";
import Modals from "./Modals";
import "./modals.css";
import "ui/UI.css";
import { useHistory } from "react-router-dom";
import PersonasContext from "contexts/contextPersonas";
// import { ImInfo } from 'react-icons/im'
import { ShowOptionSwitchiBtn } from "./Forms/TextInput";
import { imageVersions } from "utils/image";
import TopicContext from "contexts/contextTopic";
// import { FilledInput } from "@material-ui/core";
import { TextField, makeStyles, InputBase } from "@material-ui/core";
import plusLogo from "./icons/plusonly.png";
// import { AttributionControl } from "react-leaflet";
import {
  digestCalc,
  storeDescriptor,
  getDescriptor,
  storeImageB64
  // getImage64,
  // storeImage
} from "data/descriptors";
import { convHrsMins } from "../utils/UtilsUniversal";
import { WorldTextInput } from "utils/UtilsUniversal";
import { dex_action } from "data/dexUtils";

const UICreateTopicModal = (props) => {
  const { globalState } = useContext(GlobalContext);
  const [defaults, setDefaults] = useState([]);
  const { personasState } = useContext(PersonasContext);
  const { databaseState } = useContext(DatabaseContext);
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(false);
  let history = useHistory();
  const [changeHgt, setChangeHgt] = useState(false);
  const [scrnHgt, setScrnHgt] = useState(0);
  const [submitOK, setSubmitOK] = useState(false);
  const [checkedPP, setCheckedPP] = useState(false);
  const [descriptor, setDescriptor] = useState(undefined);
  const { topicState, topicDispatch } = useContext(TopicContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const inputFile = useRef(null);
  const [histChecked, setHistChecked] = useState(false);
  const [suppressChecked, setSuppressChecked] = useState(false);
  const [wasUnchecked, setWasUnchecked] = useState(false);
  const [hiddenChecked, setHiddenChecked] = useState(false);
  const [privateChecked, setPrivateChecked] = useState(true);
  const [publicChecked, setPublicChecked] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [newThumbnail, setNewThumbnail] = useState(null);
  const [topicName, setTopicName] = useState("");
  const [topicDisplayName, setTopicDisplayName] = useState("");
  const [topicDescription, setTopicDescription] = useState("");
  const [tagVal, setTagVal] = useState("");
  const [validTagVal, setValidTagVal] = useState(false);
  const [tagValChanged, setTagValChanged] = useState(false);
  const [paramTopicType, setParamTopicType] = useState();
  const [topicType, setTopicType] = useState("chat");
  const [mute, setMute] = useState(false);
  const [headerSize, setHeaderSize] = useState("medium");
  const [about, setAbout] = useState("");
  const [focusVal, setFocusVal] = useState(true);
  //MUI formatting
  // const useStyles = makeStyles((theme) => ({
  //   inputBase: {
  //     minWidth: "240px",
  //     padding: "0.5rem",
  //     border: "0.06rem solid var(--modal_border_color)",
  //     borderRadius: "var(--border_radius_small)"
  //   }
  // }));
  // const classes = useStyles();

  const maxStringLength = 40;

  useEffect(() => {
    if (props.topic?.topic) setTopicName(props.topic?.topic);
    if (props.topic?.props?.topic_display_name)
      setTopicDisplayName(props.topic?.props?.topic_display_name);
    if (props.tag || props.topic?.props?.tag)
      setTagVal(props.tag || props.topic?.props?.tag);

    // if (props.topic?.props?.topictype)
    //   setTopicType(props.topic?.props?.topictype)
    // else setTopicType("chat")
    if (
      props.topic?.props?.defaultroles?.includes("rx") &&
      props.topic?.props?.defaultroles?.includes("tx")
    )
      setTopicType("Chat");
    else if (
      props.topic?.props?.defaultroles?.includes("rx") &&
      props.topic?.props?.defaultroles?.includes("whisper")
    )
      setTopicType("Whisper");
    else if (props.topic?.props?.defaultroles?.includes("rx"))
      setTopicType("Broadcast");
    if (props.topic?.props?.visibility) {
      if (props.topic?.props?.visibility?.toLowerCase() === "private") {
        setPrivateChecked(true);
        setPublicChecked(false);
      }
      if (props.topic?.props?.visibility?.toLowerCase() === "public") {
        setPrivateChecked(false);
        setPublicChecked(true);
      }
    } else setPrivateChecked(true);
    if (props.origHeaderSize) {
      setHeaderSize(props.origHeaderSize);
    } else setHeaderSize("medium");
    if (props.origAbout) setAbout(props.origAbout);
    if (props.origDescription) setAbout(props.origDescription);
    if (props.origLogo) setNewImage(props.origLogo);
    // if (props.timeFormatHMS) setTimeFormatHMS(props.timeFormatHMS)
    // else setTimeFormatHMS("HH:mm")
    let topicMatches = topicsState.topics?.filter(
      (t) => t.mtopic === props.mtopic && t.mpersona === props.topic?.mpersona
    );
    if (topicMatches?.length > 0) {
      setTopicDescription(topicMatches[0]?.props?.description);
      if (topicMatches[0]?.props?.history === "true") {
        setHistChecked(true);
      } else {
        setHistChecked(false);
      }
      if (topicMatches[0]?.props?.visibility === "private")
        setPrivateChecked(true);
      else {
        setPrivateChecked(false);
      }
      if (topicMatches[0]?.props?.visibility === "public")
        setPublicChecked(true);
      else setPublicChecked(false);
      if (topicMatches[0]?.props?.visibility === "hidden") {
        setPublicChecked(true);
        setHiddenChecked(true);
      } else setHiddenChecked(false);
      if (topicMatches[0]?.props?.suppress === "true") setSuppressChecked(true);
      else setSuppressChecked(false);
      if (topicMatches[0]?.props?.topictype)
        setParamTopicType(topicMatches[0]?.props?.topictype);
      if (topicMatches[0]?.props?.defaultmute === "true") setCheckedPP(true);
      else setCheckedPP(false);
    }
  }, []);

  const handleChangeMute = (newValue) => {
    setMute(newValue);
  };
  const onChangeMute = () => {
    setCheckedPP(!checkedPP);
  };

  useEffect(() => {
    if (props.mtopic)
      try {
        if (
          topicsState?.logos[
            props.mtopic.split("_").length < 3
              ? props.mtopic
              : `${props.mtopic}_${props.mpersona}`
          ]
        ) {
          topicDispatch({
            type: "SET_LOGO_IMAGE",
            values: {
              logo_image:
                topicsState.logos[
                  props.mtopic.split("_").length < 3
                    ? props.mtopic
                    : `${props.mtopic}_${props.mpersona}`
                ]
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    return () => {};
  }, [props.mtopic, topicDispatch, topicsState?.logos, newImage]);

  useEffect(() => {
    let isMounted = true;
    if (topicsState.topics && props.mtopic && props.mtopic.startsWith("t_")) {
      let topicList = topicsState.topics.filter(
        (top) => top.mtopic === props.mtopic
      );
      // if (props.message?.parameters?.descriptor) {
      if (topicList[0]?.props?.descriptor?.digest) {
        getDescriptor(
          databaseState.dexUser,
          "topic_descriptor",
          props.mtopic,
          topicList[0]?.props?.descriptor.digest
        ).then((d) => {
          if (d.mtopic) {
            if (isMounted) {
              if (d.logo?.thumbdigest) {
              }
              if (!d) {
              } else {
                isMounted && setDescriptor(d);
              }
            }
          }
        });
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseState.dexUser]);

  useEffect(() => {
    function handleResize() {
      // if (window.innerWidth < 1024) {
      setScrnHgt(window.innerHeight * 0.9);
      setChangeHgt(true);
      // }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let identifier = undefined;

  const timestamp = () => {
    let d = new Date();
    let ts_sender =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      " " +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2) +
      ":" +
      ("0" + d.getSeconds()).slice(-2) +
      "." +
      ("0" + d.getMilliseconds()).slice(-3);
    return ts_sender;
  };

  const clear = () => {
    defaults.forEach(
      (r) => (document.getElementById("cb_" + r).checked = false)
    );
    setDefaults([]);
  };

  const writeDescriptor = useMemo(
    () => async () => // timeFormatHMS
    {
      const onFinaliseDescriptor = async (j) => {
        // must include a JWT >>> topic server provides the JWT which provides permission to
        delete j.digest;
        let descriptorDigest = await digestCalc(JSON.stringify(j));
        j.digest = descriptorDigest;
        storeDescriptor(databaseState.dexUser, "upload_topic_descriptor", j);
      };
      if (true) {
        let mpersona = props.topic?.mpersona;
        let stagedImgDigest, stagedThumbDigest;
        let logo = descriptor?.logo || {};
        if (props.mtopic && topicState?.logo?.img64) {
          stagedImgDigest = await digestCalc(topicState.logo.img64)
            .then(async (digest) => {
              let result = await storeImageB64(
                databaseState.dexUser,
                "upload_share_image",
                topicState.logo.img64,
                {
                  scope: props.mtopic
                }
              );
              return result && digest;
            })
            .catch((e) => {
              return false;
            });
          if (stagedImgDigest) {
            logo.imgdigest = stagedImgDigest;
            logo.imgpath = `image/${props.mtopic}/${stagedImgDigest}.json`;
          }
        }
        if (props.mtopic && topicState?.logo?.thumb64) {
          stagedThumbDigest = await digestCalc(topicState.logo.thumb64)
            .then(async (digest) => {
              let result = await storeImageB64(
                databaseState.dexUser,
                "upload_share_image",
                topicState.logo.thumb64,
                {
                  scope: props.mtopic
                }
              );
              return result && digest;
            })
            .catch((e) => {
              return false;
            });
          if (stagedThumbDigest) {
            logo.thumbdigest = stagedThumbDigest;
            logo.thumbpath = `image/${props.mtopic}/${stagedThumbDigest}.json`;
          }
        }
        topicDispatch({
          type: "SET_LOGO_IMAGE",
          values: { logo_image: topicState?.logo?.thumb64 }
        });
        topicsDispatch({
          type: "SET_LOGO",
          values: { mtopic: props.mtopic, logo: topicState?.logo }
        });
        let j = {
          type: "topic descriptor", // required
          mpersona: mpersona, // required
          mtopic: props.mtopic, // required
          // date: timestamp(), // test only
          headerSize: headerSize,
          description: about,
          topicType: topicType,
          // timeFormatHMS: timeFormatHMS,
          theme: {},
          wallpaper: {},
          logo: logo || {}
        };
        onFinaliseDescriptor(j);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      databaseState.dbMaster,
      descriptor?.logo,
      // origDescription,
      props.mtopic,
      about,
      headerSize,
      newImage,
      newThumbnail,
      topicDispatch,
      topicState?.logo,
      topicsDispatch
    ]
  );

  const onCreateTopic = () => {
    let trimTopicName = topicName.trim();
    let trimTopicDisplayName = topicDisplayName.trim();
    let trimTagVal = tagVal.trim();
    if (!props.mtopic) {
      let j = {
        type: "w.t.create",
        topic: trimTopicName,
        topic_display_name: trimTopicDisplayName,
        mpersona: globalState.persona?.mpersona
          ? globalState.persona.mpersona
          : personasState.personas
          ? personasState.personas[0]?.mpersona
          : undefined,
        identifier: identifier,
        defaultroles: defaults.map((r) => r),
        description: topicDescription,
        tag: trimTagVal,
        version: globalState.version,
        ts_sender: timestamp(),
        smid: randomString(8)
      };
      // j.topictype = topicType;
      j.history = histChecked?.toString();
      j.hidden = hiddenChecked?.toString();
      j.suppress = suppressChecked?.toString();
      if (paramTopicType) j.topictype = paramTopicType;
      else j.topictype = "";
      // j.defaultmute = checkedPP?.toString()
      if (privateChecked) j.visibility = "private";
      else if (publicChecked && hiddenChecked) j.visibility = "hidden";
      else if (publicChecked) j.visibility = "public";
      globalState.logging && console.log("CREATE TOPIC", j);
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
    } else {
      let newProps = {};
      // defaultmute, defaultroles, suppress, visibility, history,
      // description, tag, topic_display_name, networkcountryiso, topictype
      newProps.defaultmute = checkedPP?.toString();
      if (topicType.toLowerCase() === "chat")
        newProps.defaultroles = ["rx", "tx"];
      else if (topicType.toLowerCase() === "whisper")
        newProps.defaultroles = ["rx", "whisper"];
      else if (topicType.toLowerCase() === "broadcast")
        newProps.defaultroles = ["rx"];
      newProps.suppress = suppressChecked?.toString();
      if (paramTopicType) newProps.topictype = paramTopicType;
      else newProps.topictype = "";

      if (hiddenChecked) newProps.visibility = "hidden";
      else if (publicChecked) newProps.visibility = "public";
      else if (privateChecked) newProps.visibility = "private";
      if (histChecked) newProps.history = "true";
      else newProps.history = "false";
      // if (topicDisplayName)
      newProps.topic_display_name = topicDisplayName;
      newProps.description = topicDescription;
      newProps.tag = trimTagVal;
      let m = {
        type: "w.t.edit",
        smid: randomString(8),
        mtopic: props.mtopic,
        mpersona: props.topic?.mpersona,
        version: globalState.version
      };
      // if (topicDisplayName) m.topic_display_name = topicDisplayName
      if (trimTopicName) m.topic = trimTopicName;
      if (Object.keys(newProps).length > 0) m.props = newProps;
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: m
          }
        });
      writeDescriptor();
      // , timeFormatHMS)
    }
    props.onClose();
  };

  const defaultOptions = [
    { label: "owner", role: "owner" },
    { label: "admin", role: "admin" },
    { label: "designer", role: "designer" },
    { label: "send", role: "tx" },
    { label: "whisper", role: "whisper" },
    { label: "receive", role: "rx" }
  ];

  const editDefaults = (mustAdd, defaultOption) => {
    let newDefaults = mustAdd
      ? [defaultOption, ...defaults]
      : defaults.filter((r) => r !== defaultOption);
    setDefaults([...newDefaults]);
  };

  const toggle = (role) => {
    editDefaults(false, role);
    document.getElementById("cb_" + role) &&
      (document.getElementById("cb_" + role).checked = false);
  };

  const boxes = defaultOptions.map((option) => (
    <div key={"div_" + option.role} id={"div_" + option.role}>
      <input
        className="checkbox"
        type="checkbox"
        key={"cb_" + option.role}
        id={"cb_" + option.role}
        onClick={() => {
          if (document.getElementById("cb_" + option.role).checked) {
            if (option.role === "tx") toggle("whisper");
            if (option.role === "whisper") toggle("tx");
            editDefaults(true, option.role);
          } else {
            editDefaults(false, option.role);
          }
        }}
      ></input>
      <label>{option.label}</label>
      <br></br>
    </div>
  ));

  // const showHistory = () => {
  //   setCbHistory(
  //     document.querySelector('input[name="visibility"]:checked') &&
  //       document.querySelector('input[name="visibility"]:checked').value ===
  //       "private" ? (
  //       <div
  //         key={"div_history"}
  //         id={"div_history"}
  //         style={{
  //           marginLeft: "16px",
  //           display: "flex",
  //           justifyContent: "left",
  //           alignItems: "flex-start",
  //         }}
  //       >
  //         <input
  //           type="checkbox"
  //           key={"cb_history"}
  //           id={"cb_history"}
  //             onClick={() => { }}
  //         ></input>
  //         <label>Allow history</label>
  //         <br></br>
  //       </div>
  //     ) : (
  //       <div></div>
  //     )
  //   );
  // };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (window.location?.href?.includes("menu")) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick, selectedPersona]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  const handleNewTopicName = (e) => {
    setTopicName(e);
  };

  const handleNewTopicType = (e) => {
    setParamTopicType(e);
  };

  const handleNewTopicDisplayName = (e) => {
    setTopicDisplayName(e);
  };

  const handleNewDescription = (e) => {
    setTopicDescription(e);
  };

  const handleNewTag = (e) => {
    // if (validTagVal) {
    setTagVal(e);
    setTagValChanged(true);
    // }
  };

  const handleKeyPressTagVal = (event) => {
    var RegEx = /^[\w\ ]+$/i;
    if (tagVal?.length !== 0 || event.charCode !== 32) {
      if (tagVal?.length < 12) {
        setValidTagVal(RegEx.test(event.key));
      } else {
        setValidTagVal(false);
        alert("too long - max 12 characters"); /*modify*/
      }
    } else setValidTagVal(false);
  };

  const handleKeyUp = (event) => {
    let chCode =
      event.target?.value[event.target?.value?.length - 1]?.charCodeAt(0);
    if (tagVal?.length < 12) {
      if (
        !chCode === 32 &&
        !(chCode >= 48 && chCode <= 57) &&
        !(chCode >= 65 && chCode <= 90) &&
        !(chCode >= 97 && chCode <= 122)
      )
        setTagVal(
          event.target?.value?.substring(0, event.target?.value?.length - 1)
        );
    } else {
      setValidTagVal(false);
      alert("too long - max 12 characters"); /*modify*/
    }
  };

  const handleKeyDownTagVal = (event) => {
    if (event.keyCode !== 229) {
      if (event.key === "Backspace" || event.key === "Delete") {
        setValidTagVal(true);
      } else {
        var RegEx = /^[\w\ ]+$/i;
        // var RegEx1 = /^[a-z\d\-_\s]+$/i
        //problem with Android- does not return same char codes as on Laptop
        // aaarrrrrggggghhhhhh !!!!!!!!!!!!
        //if keycode=229 (Android) let the value go through, then check on UP event
        //not ideal but what else??
        if (tagVal.length !== 0 || event.key?.charCode !== 32) {
          if (tagVal.length < 12) {
            setValidTagVal(RegEx.test(event.key));
          } else {
            setValidTagVal(false);
            alert("too long - max 12 characters"); /*modify*/
          }
        } else setValidTagVal(false);
      }
    } else {
      setValidTagVal(true);
    }
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const tagTooltip =
    "no leading or trailing spaces\n" +
    "maximum 12 characters\n" +
    "A..Z a..z 0-9\n" +
    "uppercase will be converted to lowercase";

  const handleAddPersona = () => {
    globalState.logging && console.log("[UICreateTopicModal] add persona");
  };

  const onImageSelect = (event) => {
    const file = event.target.files[0];
    // Process the image into 2 sizes and then store as Avatar
    // Note: the storeAvatar also updates the user's active descriptor
    // When we implement the full carousel, this will also have to adjust the list of available images in the descriptor
    file &&
      imageVersions(event.target.files[0], [
        { key: "image", maxWidth: 500, maxHeight: 500 },
        { key: "thumbnail", maxWidth: 80, maxHeight: 80 }
      ]).then((r) => {
        setNewImage(r.image.dataURI);
        setNewThumbnail(r.thumbnail.dataURI);
        topicDispatch({
          type: "SET_LOGO",
          values: {
            logo: { img64: r.image.dataURI, thumb64: r.thumbnail.dataURI }
          }
        });
      });
  };

  const handleAbout = (newValue) => {
    setAbout(newValue);
  };

  const handleTopicType = (event) => {
    setTopicType(event.target.value);
  };

  const handlePrivate = () => {
    setPrivateChecked(true);
    setPublicChecked(false);
    setHiddenChecked(false);
  };

  const handlePublic = () => {
    setPrivateChecked(false);
    setPublicChecked(true);
    setHistChecked(false);
  };

  // const handleTimeFormatHMS = (event) => {
  //   setTimeFormatHMS(event.target.value)
  // }

  useEffect(() => {
    if (topicName?.length > 0) setSubmitOK(true);
    else setSubmitOK(false);
  }, [topicName]);

  useEffect(() => {
    if (topicType?.toLowerCase() === "chat") setDefaults(["rx", "tx"]);
    else if (topicType?.toLowerCase() === "whisper")
      setDefaults(["rx", "whisper"]);
    else if (topicType?.toLowerCase() === "broadcast") setDefaults(["rx"]);
  }, [topicType]);

  const handleSubmit = () => {
    if (submitOK) {
      onCreateTopic();
      if (history.length >= 1) history.goBack();
    }
  };

  const getCreationDate = (mp) => {
    let topicMatches = topicsState.topics?.filter(
      (t) => t.mtopic === mp && t.mpersona === props.topic?.mpersona
    );
    return topicMatches[0]?.props?.creationdate;
  };

  const handleSuppress = () => {
    if (suppressChecked) setWasUnchecked(true);
    setSuppressChecked(!suppressChecked);
  };

  useEffect(() => {
    if ((publicChecked || hiddenChecked) && !wasUnchecked) {
      if (!props.mtopic) setSuppressChecked(true);
      setWasUnchecked(false);
    } else if (privateChecked) {
      if (!props.mtopic) setSuppressChecked(false);
      setWasUnchecked(false);
    }
  }, [publicChecked, hiddenChecked, privateChecked]);

  const getCreator = (mtopic) => {
    let topicMatches = topicsState.topics?.filter((t) => t.mtopic === mtopic);
    return {
      creator: topicMatches[0]?.mcreator,
      creatorpersona: topicMatches[0]?.persona
    };
  };

  let modalCreateTopic = (
    <div id="create_topic_modal">
      <Modals
        title={props.mtopic ? "Edit Topic" : "Create Topic"}
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        style={
          changeHgt && window.innerWidth <= 1024 ? { maxHeight: scrnHgt } : {}
        }
        footer={
          <button
            className="UI-button-service"
            disabled={!submitOK}
            // style={submitOK ? {} : { opacity: "0.5" }}
            type="login"
            onClick={handleSubmit}
          >
            Submit
          </button>
        }
      >
        <div className="modal-content-section">
          {props.mtopic && (
            <div>
              <div className="modal-label-new" style={{ fontStyle: "italic" }}>
                <span>Unique topic number </span>
                <br />
                <span
                  style={{
                    display: "block",
                    fontWeight: "normal",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "75vw",
                    width: "300px"
                  }}
                >
                  {props.mtopic ? props.mtopic : "-"}
                </span>
              </div>
              <div
                className="modal-label-new"
                style={{
                  fontStyle: "italic"
                }}
              >
                <span>Topic creation date</span>
                <br />
                <span style={{ fontWeight: "normal" }}>
                  {props.mtopic
                    ? convHrsMins(getCreationDate(props.mtopic))
                    : "-"}
                </span>
                {(globalState.devMode === "true" ||
                  globalState.devMode === true) && (
                  <div>
                    <span>Topic creator</span>
                    <br />
                    <span style={{ fontWeight: "normal" }}>
                      {props.mtopic ? (
                        <div>
                          <span>{getCreator(props.mtopic).creator}</span>
                          <br />
                          {/* <span>
                            {getCreator(props.mtopic).creatorpersona}
                          </span> */}
                        </div>
                      ) : (
                        "-"
                      )}
                    </span>
                  </div>
                )}
              </div>
              <hr
                style={{
                  border: "0.001rem solid transparent",
                  // border:
                  //   "0.001rem solid  var(--main_screen_topic_separator_line_color)",
                  width: "95%",
                  marginLeft: "0"
                }}
              />
            </div>
          )}
          {/* <label className="modal-label-new required">Topic name</label> */}
          <WorldTextInput
            universalStyle={{ display: "flex", flexDirection: "column" }}
            identity="topicname"
            focus={focusVal}
            removeFocus={(val) => handleFocus(val)}
            labelStyle={{
              fontWeight: "bold"
            }}
            inpStyle={{
              maxWidth: "20rem",
              width: isMobileTablet() ? "70vw" : "60vw"
            }}
            type="text"
            // title={tagTooltip}
            callback={(e) => handleNewTopicName(e)}
            label={"Topic name"}
            required={true}
            placeholder={"Choose a name for your topic"}
            // description={"generic World text input field testing phase"}
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            maxStrLen={maxStringLength}
            // active={worldTestTA?.length > 0 ? "true" : "false"}
            value={topicName}
            // regex={/^[\w-./]+$/i}
            // regex={/^[\w\.\- ]+$/i}
          />
          {(props.topic?.topic_display_name ||
            props.topic?.props?.topic_display_name ||
            globalState.devMode === "true" ||
            globalState.devMode === true) && (
            <WorldTextInput
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="topicdisplayname"
              // focus={focusVal}
              removeFocus={(val) => handleFocus(val)}
              labelStyle={{
                fontWeight: "bold"
              }}
              inpStyle={{
                maxWidth: "20rem",
                width: isMobileTablet() ? "70vw" : "60vw"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleNewTopicDisplayName(e)}
              label={"Topic display name"}
              required={false}
              placeholder={"Choose a display name for your topic"}
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={56}
              // active={worldTestTA?.length > 0 ? "true" : "false"}
              value={topicDisplayName}
              // regex={/^[\w-./]+$/i}
              // regex={/^[\w\.\- ]+$/i}
            />
          )}
          {(globalState.devMode === "true" || globalState.devMode === true) && (
            <WorldTextInput
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="description"
              focus={false}
              labelStyle={{
                fontWeight: "bold"
              }}
              inpStyle={{
                maxWidth: "20rem",
                width: isMobileTablet() ? "70vw" : "60vw"
              }}
              type="text"
              callback={(e) => handleNewDescription(e)}
              label={"Description"}
              required={false}
              placeholder={"Describe the purpose of the Topic"}
              minRows={1}
              maxRows={1}
              maxStrLen={56}
              value={topicDescription}
            />
          )}
          {/* <label className="modal-label-new"
            title={tagTooltip}
        >Tag */}
          {/* <ImInfo
            title={tagTooltip}
            style={{
              fontSize: "1.1rem",
              marginLeft: "0.15rem"
            }}
          >
          </ImInfo> */}
          {/* </label> */}
          <WorldTextInput
            universalStyle={{ display: "flex", flexDirection: "column" }}
            identity="tagValField"
            focus={false}
            labelStyle={{
              fontWeight: "bold"
            }}
            inpStyle={{
              maxWidth: "20rem",
              width: isMobileTablet() ? "70vw" : "60vw"
            }}
            type="text"
            title={tagTooltip}
            callback={(e) => handleNewTag(e)}
            label={"Tag"}
            required={false}
            placeholder={"Blank, or up to 12 characters"}
            // description={"generic World text input field testing phase"}
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            maxStrLen={12}
            // active={worldTestTA?.length > 0 ? "true" : "false"}
            value={tagVal}
            regex={/^[\w\.\- ]+$/i}
          />
          {/* <div>
          <input
            className="modal-input-new"
            type="topic"
            placeholder="Blank, or up to 12 characters"
            name="topic_tag"
            required
            id="topic_tag"
              // onKeyPress={handleKeyPressTagVal}
              onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDownTagVal}
            value={tagVal}
            onChange={(e) => {
              handleNewTag(e)
            }}
          />
        </div> */}
          <label className="modal-label-new">Type of Topic</label>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, minmax(max-content,1fr))",
              alignItems: "center",
              justifyItems: "start"
            }}
          >
            <label>
              <input
                type="radio"
                id="chat"
                name="topic_type"
                value="Chat"
                checked={topicType.toLowerCase() === "chat"}
                onChange={handleTopicType}
              />
              <span>Chat</span>
            </label>
            <label>
              <input
                type="radio"
                id="whisper"
                name="topic_type"
                value="Whisper"
                checked={topicType.toLowerCase() === "whisper"}
                onChange={handleTopicType}
              />
              <span>Whisper</span>
            </label>
            <label>
              <input
                type="radio"
                id="broadcast"
                name="topic_type"
                value="Broadcast"
                checked={topicType.toLowerCase() === "broadcast"}
                onChange={handleTopicType}
              />
              <span>Broadcast</span>
            </label>
          </div>
          <hr
            style={{
              border: "0.001rem solid transparent",
              // border:
              //   "0.001rem solid  var(--main_screen_topic_separator_line_color)",
              width: "95%",
              marginLeft: "0"
            }}
          />
          <label className="modal-label-new">Topic visibility</label>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "max-content max-content",
              gridTemplateRows: "max-content",
              justifyItems: "start"
            }}
          >
            <label>
              <input
                type="radio"
                id="Private"
                name="topic_visibility"
                value="Private"
                checked={privateChecked}
                onChange={handlePrivate}
              />
              <span>Private</span>
            </label>
            <div
              key={"div_show_history"}
              id={"div_show_history"}
              className="modal-checkboxes"
              // ref={histRef}
              style={{ fontSize: "0.85rem" }}
            >
              <input
                className="modal-checkbox-small"
                type="checkbox"
                // ref={histChkRef}
                key={"show_history"}
                id={"show_history"}
                disabled={publicChecked}
                checked={histChecked}
                onChange={(e) => {
                  setHistChecked(e.target.checked);
                }}
              ></input>
              <label
                style={publicChecked ? { opacity: "0.5" } : { opacity: "1" }}
              >
                Show history
              </label>
            </div>

            <label>
              <input
                type="radio"
                id="Public"
                name="topic_visibility"
                value="Public"
                checked={publicChecked}
                onChange={handlePublic}
              />
              <span>Public</span>
            </label>
            <div
              key={"div_hidden"}
              id={"div_hidden"}
              // ref={hiddenRef}
              className="modal-checkboxes"
              style={{ fontSize: "0.85rem" }}
            >
              <input
                className="modal-checkbox-small"
                type="checkbox"
                // ref={hiddenChkRef}
                key={"hidden"}
                id={"hidden"}
                disabled={privateChecked}
                checked={hiddenChecked}
                onChange={(e) => {
                  setHiddenChecked(e.target.checked);
                }}
              ></input>
              <label
                style={privateChecked ? { opacity: "0.5" } : { opacity: "1" }}
              >
                Hidden
              </label>
            </div>
          </div>
          <label>
            <input
              className="checkbox"
              type="checkbox"
              key={"suppress"}
              id={"suppress"}
              checked={suppressChecked}
              onChange={handleSuppress}
            ></input>
            Suppress welcome message
          </label>
          {/* <br /> */}
          {(globalState.devMode === "true" || globalState.devMode === true) && (
            <WorldTextInput
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="topictype"
              focus={false}
              // removeFocus={(val) => handleFocus(val)}
              labelStyle={{
                fontWeight: "bold",
                marginTop: "0.5rem"
              }}
              inpStyle={{
                maxWidth: "20rem",
                width: isMobileTablet() ? "70vw" : "60vw"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleNewTopicType(e)}
              label={"Topic type"}
              required={false}
              placeholder={"Enter topic type"}
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              // maxStrLen={56}
              // active={worldTestTA?.length > 0 ? "true" : "false"}
              value={paramTopicType}
              // regex={/^[\w-./]+$/i}
              // regex={/^[\w\.\- ]+$/i}
            />
          )}
          <hr
            style={{
              border: "0.001rem solid transparent",
              // border:
              //   "0.001rem solid  var(--main_screen_topic_separator_line_color)",
              width: "95%",
              marginLeft: "0"
            }}
          />
          {props.mtopic && (
            <ShowOptionSwitchiBtn
              borderColorSwitch="var(--toggle_switch_border_color)"
              backgroundColorSwitch="var(--toggle_switch_background_color)"
              colorSelectSwitch="var(--toggle_switch_background_selected_color)"
              value="Mute Topic"
              callback={handleChangeMute}
              heightSwitch="14px"
              id="reachable_switch"
              checkedPP={checkedPP}
              onChange={onChangeMute}
              iBtn="false"
              addSwitch="true"
            />
          )}
          {props.mtopic && (
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}
            >
              <label className="modal-label-new">Header size</label>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5,1fr)",
                  alignItems: "center",
                  justifyItems: "start"
                }}
              >
                <label>
                  <input
                    type="radio"
                    id="XL"
                    name="header_size"
                    value="XL"
                    checked={headerSize.toLowerCase() === "xlarge"}
                    onChange={() => setHeaderSize("xlarge")}
                  />
                  <span>XL</span>
                </label>
                <label>
                  <input
                    type="radio"
                    id="L"
                    name="header_size"
                    value="L"
                    checked={headerSize.toLowerCase() === "large"}
                    onChange={() => setHeaderSize("large")}
                  />
                  <span>L</span>
                </label>
                <label>
                  <input
                    type="radio"
                    id="M"
                    name="header_size"
                    value="M"
                    checked={headerSize.toLowerCase() === "medium"}
                    onChange={() => setHeaderSize("medium")}
                  />
                  <span>M</span>
                </label>
                <label>
                  <input
                    type="radio"
                    id="S"
                    name="header_size"
                    value="S"
                    checked={headerSize.toLowerCase() === "small"}
                    onChange={() => setHeaderSize("small")}
                  />
                  <span>S</span>
                </label>
                <label>
                  <input
                    type="radio"
                    id="none"
                    name="visibility"
                    value="none"
                    checked={headerSize.toLowerCase() === "none"}
                    onChange={() => setHeaderSize("none")}
                  />
                  <span>None</span>
                </label>
              </div>
              <hr
                style={{
                  border: "0.001rem solid transparent",
                  // border:
                  //   "0.001rem solid  var(--main_screen_topic_separator_line_color)",
                  width: "95%",
                  marginLeft: "0"
                }}
              />
              <WorldTextInput
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="about"
                labelStyle={{
                  fontWeight: "bold"
                }}
                inpStyle={{
                  maxWidth: "20rem",
                  width: isMobileTablet() ? "70vw" : "60vw"
                }}
                type="textarea"
                title="Description will appear under header"
                callback={(e) => handleAbout(e)}
                label={"About"}
                required={false}
                placeholder={"Enter text"}
                border="0.06rem solid var(--modal_border_color)"
                border_radius="var(--border_radius_small)"
                background_color="var(--modal_background_color)"
                text_color="var(--modal_text_color)"
                hint_color="var(--send_bar_hint_color)"
                // description={"generic World text input field testing phase"}
                variant="outlined"
                minRows={1}
                maxRows={5}
                minStrLen={3}
                active={"true"}
                value={about}
              />
              {/* <InputBase
              className={classes.inputBase}
              variant="outlined"
              margin="dense"
              placeholder="enter text"
              multiline
              minRows={1}
              maxRows={5}
              onChange={handleAbout}
              value={about}
                /> */}
              {/* <hr style={{
                  border: "0.001rem solid  var(--main_screen_topic_separator_line_color)",
                  width: "95%",
                  marginLeft: "0"
                }} /> */}
              {/* <label className="modal-label-new">
                  Time format
                </label>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "max-content max-content",
                    gap: "1rem",
                    gridTemplateRows: "max-content",
                    justifyItems: "start"
                  }}>
                  <label>
                    <input
                      type="radio"
                      id="HHmm"
                      name="time_format"
                      value="HH:mm"
                      checked={timeFormatHMS === "HH:mm"}
                      onChange={handleTimeFormatHMS}
                    />
                    HH:mm
                  </label>
                  <label>
                    <input
                      type="radio"
                      id="HHmmss"
                      name="time_format"
                      value="HH:mm:ss"
                      checked={timeFormatHMS === "HH:mm:ss"}
                      onChange={handleTimeFormatHMS}
                    />
                    HH:mm:ss
                  </label>
                </div> */}
              <label className="modal-label-new">
                <hr
                  style={{
                    border: "0.001rem solid transparent",
                    // border:
                    //   "0.001rem solid  var(--main_screen_topic_separator_line_color)",
                    width: "95%",
                    marginLeft: "0"
                  }}
                />
                Logo
              </label>

              {/* {
              wallpaper ?
                <div className="item-work-container">
                  <div
                    className="theme-grid"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    Logo
                  </div>
                </div>
                : */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <input
                  style={{
                    display: "none"
                  }}
                  type="file"
                  ref={inputFile}
                  accept="image/*"
                  onChange={(event) =>
                    onImageSelect(
                      event
                      // databaseState.dbMaster,
                      // globalState.muid,
                      // globalState.persona.mpersona
                    )
                  }
                />
                <img
                  id={"image_plus_logo"}
                  className="persona-card-header-unselected"
                  src={newImage ? newImage : plusLogo}
                  alt="Logo"
                  height={40}
                  style={{
                    zIndex: "5"
                  }}
                  onClick={() => {
                    inputFile.current?.click();
                  }}
                />
              </div>
              {/* } */}
              <button
                className="UI-button"
                style={{
                  opacity: "0.5",
                  width: "fit-content"
                }}
                onClick={() => handleAddPersona}
              >
                Add Persona
              </button>
            </div>
          )}
        </div>

        {/* <div> */}
        {/* <span
            style={{
              height: "3.75rem",
            }}>
            {topicState?.logo_image && (
              <img
                src={topicState?.logo?.thumb64}
                alt="logo"
              />
            )}
          </span> */}
        {/* </div> */}

        {/* {cbHistory} */}
      </Modals>
    </div>
  );

  let content = <div>{modalCreateTopic}</div>;

  return content;
};

export default React.memo(UICreateTopicModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
