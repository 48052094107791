import React from "react";
import useSubscriptionsState from "contexts/useSubscriptionsState";
import SubscriptionsContext from "contexts/contextSubscriptions";

const SubscriptionsStateProvider = ({ children }) => {
  return (
    <SubscriptionsContext.Provider value={useSubscriptionsState({})}>
      {children}
    </SubscriptionsContext.Provider>
  );
};

export default SubscriptionsStateProvider;
