import React, { useEffect, useState } from "react";
import "./fab.css";
import Draggable from "react-draggable";
import { FaPlus } from "react-icons/fa6";
import { isMobileTablet } from "hooks/helper";

const FabButton = ({ onFabClick, ...props }) => {
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [distance, setDistance] = useState(0);
  //  const [position, setPosition] = useState({ x: 0, y: 0 });

  //  const handleStart = (event, data) => {
  //    setStartPosition({ x: data.x, y: data.y });
  //  };

  //  const handleStop = (event, data) => {
  //    setDistance(
  //      Math.sqrt(
  //        Math.pow(data.x - startPosition.x, 2) +
  //          Math.pow(data.y - startPosition.y, 2)
  //      )
  //    );
  //    setPosition({ x: data.x, y: data.y });
  //  };

  const handleFabClick = (event) => {
    if (distance < 5) {
      onFabClick(event);
    }
  };

  return (
    <div>
      {/* <Draggable
         bounds={{
           left: isMobileTablet()
             ? props?.parentObj?.left - window.innerWidth + 20
             : window.innerWidth < 580
             ? props?.parentObj?.left - 460
             : props?.parentObj?.left - 560,
           top: isMobileTablet()
             ? props?.parentObj?.top - window.innerHeight + 50
             : window.innerHeight < 300
             ? props?.parentObj?.top - 220
             : window.innerHeight < 450
             ? props?.parentObj?.top - 400
             : props?.parentObj?.top - 620,
           right: isMobileTablet()
             ? props?.parentObj?.right - window.innerWidth + 60
             : window.innerWidth < 580
             ? props?.parentObj?.right - 450
             : props?.parentObj?.right - 550,
           bottom: isMobileTablet()
             ? props?.parentObj?.bottom - window.innerHeight + 110
             : window.innerHeight < 300
             ? props?.parentObj?.bottom - 170
             : window.innerHeight < 450
             ? props?.parentObj?.bottom - 330
             : props?.parentObj?.bottom - 560
         }}
         onStart={handleStart}
         onStop={handleStop}
         defaultPosition={{ x: 0, y: 0 }}
         position={position}
         // handle=".handle"
       > */}
      <div
        className="fab"
        onClick={handleFabClick}
        style={{
          zIndex: "99",
          left: props?.parentObj?.right - 20,
          top: props?.parentObj?.bottom - 20
        }}
      >
        <div
          className="handle"
          //  onClick={handleFabClick}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center", // Center the icon
            width: "100%",
            height: "100%"
          }}
        >
          <FaPlus size={24} />
        </div>
      </div>
      {/* </Draggable> */}
    </div>
  );
};

export default FabButton;
