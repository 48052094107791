import React, { useState, useEffect } from "react";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { workerPost } from "workers/interfaceRest";
import { WorldTextInput } from "utils/UtilsUniversal";

const UIRequestPropertyModal = (props) => {
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  let history = useHistory();
  const [changeHgt, setChangeHgt] = useState(false);
  const [scrnHgt, setScrnHgt] = useState(0);
  const [focusVal, setFocusVal] = useState(true);
  const [emailInp, setEmailInp] = useState("");

  useEffect(() => {
    function handleResize() {
      setScrnHgt(window.innerHeight * 0.9);
      setChangeHgt(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmitEmail = (muid, jwt, email) => {
    // send email to lambda
    // lambda send email to user to click for validation
    // validation goes back to mailerPublic
    let url = process.env.REACT_APP_JWT_PWD_API_URL;
    let key = process.env.REACT_APP_JWT_PWD_API_KEY;
    let submitEmail = {
      "type": "submit_email",
      "url": process.env.REACT_APP_URL,
      "muid": muid,
      "jwt": jwt,
      "email": email
    };

    workerPost(url, key, submitEmail).then((urlResult) => {
      if (urlResult.result === "ok") {
        if (
          window.confirm(
            "Your email login will only function once successfully validated.\nPlease check your email for a validation link.\n"
          ) === true
        ) {
          props.onClose();
        }
      } else {
        if (
          window.confirm("That failed. You'll be prompted again later.") ===
          true
        ) {
          props.onClose();
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let regex = /^[^~,]+@[^~,]+\.[^~,]+$/;
    if (emailInp && emailInp.length > 0 && regex.test(emailInp)) {
      onSubmitEmail(props.muid, props.jwt, emailInp);
    } else {
      alert("Please enter a valid email address");
    }
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleEmailInp = (e) => {
    setEmailInp(e);
  };

  let content = (
    <div id="email_modal">
      <form onSubmit={handleSubmit}>
        <Modals
          loginModal={true}
          title="Add an email address"
          onClose={() => handleClose()}
          onClickOutside={() => handleClickOutside()}
          clickOutsideActive={true}
          style={
            changeHgt && window.innerWidth <= 1024 ? { maxHeight: scrnHgt } : {}
          }
          // mainStyle={{
          //   position: "relative",
          //   backgroundColor: "rgba(0, 0, 0, 1)"
          // }}
          styleBody={
            changeHgt && window.innerWidth <= 1024
              ? { maxHeight: scrnHgt * 0.5 }
              : {}
          }
          // buttonStyle={{ display: "none" }}
          footer={
            <button
              className="UI-button-service"
              type="submit"
              onClick={handleSubmit}
            >
              Submit
            </button>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            {
              <div>
                <p
                  style={{
                    textAlign: "left",
                    margin: "0"
                  }}
                >
                  The email address you add here will replace logging in with a
                  password
                </p>
              </div>
            }
            <WorldTextInput
              identity="email"
              focus={focusVal}
              removeFocus={(val) => handleFocus(val)}
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleEmailInp(e)}
              label="Email"
              required={true}
              placeholder="Enter your email address"
              minRows={1}
              maxRows={1}
              maxStrLen={56}
              value={emailInp}
              active="true"
              autoComplete="off"
              autoCapitalize="none"
            />
          </div>
          {/* </ClickOutsideDetector> */}
        </Modals>
      </form>
    </div>
  );
  return content;
};

export default React.memo(UIRequestPropertyModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
