import React, { useState, useEffect, useContext } from "react";
import GlobalContext from "contexts/context";
// import ClickOutsideDetector from "utils/ClickOutsideDetector";
import UIInvite from "./UIInvite";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { randomString, timestamp } from "hooks/helper";
import DatabaseContext from "data/contextDatabase";
import UIInvitePersonas from "ui/UIInvitePersonas"
import { min } from "moment";
import { dex_action } from "data/dexUtils";

const UIInviteModal = (props) => {
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(false);
  let history = useHistory();
  const [minvitees, setMinvitees] = useState();
  const [clearStatus, setClearStatus] = useState();
  const [calculatedMaxHeight, setCalculatedMaxHeight] = useState();

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (window.location?.href?.includes("menu")) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick, selectedPersona]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length > 1) history.goBack();
  };

  const handleSelectedPersona = () => {
    setSelectedPersona(true);
    props.onClose();
  };

  const onInvited = (invitees) => {
    setMinvitees(invitees);
  };

  const onClearStatus = (val) => {
    setClearStatus(val);
  };

  const invite = () => {
    if (minvitees?.length > 0) {
      let j = {
        type: "w.t.invite",
        version: globalState.version,
        // prototype: "true",
        smid: randomString(8),
        ts_sender: timestamp(),
        mtopic: props.topic.mtopic,
        mpersona: props.topic.mpersona,
        minvitees: minvitees,
        origin_sender: "registered"
      };
      globalState.logging && console.log("[UIInviteModal] invite", j);
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
    }
  };

  const handleClearInvite = () => {
    setMinvitees([]);
    setClearStatus(true);
  };

  const handleClickInvite = () => {
    invite();
    if (history.length >= 1) history.goBack();
  };

  const modalInvite = (
    <div id={"invite_" + props.topic.mtopic}>
      <Modals
        title="Invite Persona"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        switchScrollOff={true}
        theMaxHgtBody={(val) => setCalculatedMaxHeight(val)}
        footer={
          <div>
            {/* <button
              className={minvitees?.length > 0 ? "modal-button-new" : "UI-button-greyed"}
              type="button"
              onClick={() => {
                minvitees?.length > 0 &&
                  handleClearInvite()
              }
              }
            >
              Clear
            </button> */}
            <button
              className={"UI-button-service"}
              disabled={minvitees?.length <= 0}
              type="button"
              onClick={() => {
                minvitees?.length > 0 && handleClickInvite();
              }}
            >
              Invite
            </button>
          </div>
        }
      >
        <UIInvitePersonas
          topic={props.topic}
          // version={globalState.version}
          onInvited={(invitees) => onInvited(invitees)}
          theMaxHgtBody={calculatedMaxHeight}
          // onClose={() => handleSelectedPersona()}
          // newInvites={minvitees}
          // clearStatus={clearStatus}
        ></UIInvitePersonas>
        {/* <UIInvite
              topic={props.topic}
              version={globalState.version}
          onInvited={(invitees) => onInvited(invitees)}
          onClose={() => handleSelectedPersona()}
          newInvites={minvitees}
          clearStatus={clearStatus}
          onClearStatus={(val) => onClearStatus(val)}
        ></UIInvite> */}
      </Modals>
    </div>
  );

  let content = <div>{modalInvite}</div>;

  return content;
};

export default React.memo(UIInviteModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
