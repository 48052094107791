import React from "react";
import "ui/UI.css"

const DropDownIconsCard = ({ menuItems = [], setOpen }) => (
  <ul className="UI-dropdown-icons">
    {menuItems.map((item, i) => (
      <li
        className="UI-dropdown-icon"
        key={i}
        onClick={() => (setOpen(false), item.callback())}
        title={item.title}
      >
        {item.icon}
      </li>
    ))}
  </ul>
);

export default DropDownIconsCard;
