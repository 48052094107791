import React from "react";
import useDatabaseState from "data/useDatabaseState";
import DatabaseContext from "data/contextDatabase";

const DatabaseStateProvider = ({ children }) => {
  const stateDatabase = {
    dbMaster: null,
    dbMessages: null,
    dbUser: null,
    dbReady: false
  };

  return (
    <DatabaseContext.Provider value={useDatabaseState(stateDatabase)}>
      {children}
    </DatabaseContext.Provider>
  );
};

export default DatabaseStateProvider;
