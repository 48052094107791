import { useReducer } from "react";
const isEqual = require("react-fast-compare");

// Note: React calls the reducer twice, so the console log repeats
// https://github.com/facebook/react/issues/16295#issuecomment-610098654

const reducer = (state, action) => {
  state.logging && console.log("SET global", state, action);
  switch (action?.type) {
    case "SET_TOKEN":
      if (isEqual(state.token, action.values.token)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          token: action.values.token
        };
      }
    case "SET_JWT":
      if (isEqual(state.jwt, action.values.jwt)) {
        return state;
      } else {
        return {
          ...state,
          jwt: action.values.jwt
        };
      }
    case "SET_PERSONA":
      if (isEqual(state.persona, action.values.persona)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        localStorage.setItem("personaLogged", action.values?.persona?.mpersona);
        return {
          ...state,
          persona: action.values.persona
        };
      }
    case "SET_CONNECTION":
      if (state.connected === action.values.connected) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          connected: action.values.connected
        };
      }
    case "SET_AUTH":
      if (state.authenticated === action.values.authenticated) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          authenticated: action.values.authenticated
        };
      }
    case "ACCOUNTDB":
      let newACCOUNTDB = {
        ...state,
        accountDbInitiated: action.values.initiated
      };
      if (isEqual(state, newACCOUNTDB)) return state;
      // console.log("CHANGE!!!", state, action);
      return newACCOUNTDB;
    case "LOGIN":
      state.logging && console.log("LOGIN!!!", state, action);
      if (
        state.uid === action.values.uid &&
        state.password !== action.values.password &&
        state.jwtLongExpiry !== action.values.jwtLongExpiry &&
        state.muid !== action.values.muid &&
        state.cid !== action.values.cid &&
        state.isLoggedIn
      )
        return state;
      else
        return {
          ...state,
          uid: action.values.uid,
          password: action.values.password,
          jwtLongExpiry: action.values.jwtLongExpiry,
          muid: action.values.muid,
          cid: action.values.cid,
          isLoggedIn: true
        };
    case "SET_CID":
      state.logging && console.log("SET_CID!!!", state, action);
      if (state.cid === action.values.cid) return state;
      else
        return {
          ...state,
          cid: action.values.cid
        };
    case "SET_CURRENT_TOPIC":
      if (isEqual(state.displaying_topic, action.values.displaying_topic)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          displaying_topic: action.values.displaying_topic
        };
      }
    case "TOGGLE_ARCHIVE":
      // console.log("CHANGE!!!", state, action);
      return {
        ...state,
        displaying_archive: !state.displaying_archive
      };
    case "SET_UID":
      if (state.uid === action.values.uid) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          uid: action.values.uid
        };
      }
    case "SET_EMAILS":
      if (isEqual(state.emails, action.values.emails)) {
        return state;
      } else {
        return {
          ...state,
          emails: action.values.emails
        };
      }
    case "SET_PREFS":
      if (isEqual(state.prefs, action.values.prefs)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          prefs: action.values.prefs
        };
      }
    case "SET_MUID":
      if (state.muid === action.values.muid) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          muid: action.values.muid
        };
      }
    case "SET_KEY":
      if (isEqual(state.key, action.values.key)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          key: action.values.key
        };
      }

    case "LOGOUT":
      console.log("Logging out...");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return {
        ...state,
        uid: undefined,
        password: undefined,
        muid: undefined,
        isLoggedIn: false
      };
    case "SET_SHOW_SIDEBAR":
      if (isEqual(state.showSidebar, action.values.showSidebar)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          showSidebar: action.values.showSidebar
        };
      }
    case "SET_PERSONA_SCREEN":
      if (isEqual(state.showPersonaScreen, action.values.showPersonaScreen)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          showPersonaScreen: action.values.showPersonaScreen
        };
      }
    case "SET_WORLD_ID_SCREEN":
      if (isEqual(state.showWorldIDScreen, action.values.showWorldIDScreen)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          showWorldIDScreen: action.values.showWorldIDScreen
        };
      }
    case "SET_PREVENT_SENDING":
      state.logging && console.log("SET_PREVENT_SENDING", action, Date.now());
      if (state.prevent_sending === action.values.prevent_sending) {
        return state;
      } else {
        return {
          ...state,
          prevent_sending: action.values.prevent_sending
        };
      }
    case "SET_DEV_MODE":
      if (isEqual(state.devMode, action.values.devMode)) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        localStorage.setItem("devmode", action.values?.devMode);
        return {
          ...state,
          devMode: action.values.devMode
        };
      }
    case "SET_LOGGING":
      if (isEqual(state.logging, action.values.logging)) {
        return state;
      } else {
        return {
          ...state,
          logging: action.values.logging
        };
      }
    case "SET_JWTSIGNUP":
      if (isEqual(state.jwtSignup, action.values.jwtSignup)) {
        return state;
      } else {
        return {
          ...state,
          jwtSignup: action.values.jwtSignup,
          payloadSignup: action.values.payloadSignup
        };
      }
    case "SET_THEME_EDIT":
      if (
        state.themeEdit?.theme === action.values.theme &&
        state.themeEdit?.editType === action.values.editType
      )
        return state;
      else
        return {
          ...state,
          themeEdit: {
            theme: action.values.theme,
            editType: action.values.editType
          }
        };
    case "CLEAR_THEME_EDIT":
      return {
        ...state,
        themeEdit: undefined
      };
    case "setState":
      const newState = {
        ...state,
        ...action.values
      };
      if (isEqual(state, newState)) return state;
      else {
        // console.log("CHANGE!!!", state, action);
        return newState;
      }
    default: {
      console.log("Logging default...", action);
      return state;
    }
  }
};

const useGlobalState = (state) => {
  const [globalState, globalDispatch] = useReducer(reducer, state);
  return { globalState, globalDispatch };
};

export default useGlobalState;
