import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as adjunctServiceWorker from "serviceWorker/adjunctServiceWorker";
import { version } from "version.js";

localStorage.setItem("version", version);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

//UserService.initKeycloak(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

console.log("REACT_APP_URL", process.env.REACT_APP_URL);

adjunctServiceWorker.register({ file: "sw.js" });
