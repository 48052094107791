import React from "react";

const IconDelete = ({ onClick }) => (
  <button
    className="UI-button-icon UI-topic-menu"
    type="delete"
    onClick={onClick}
  ></button>
);

export default IconDelete;
