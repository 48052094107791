import React, { useState, useContext, useEffect } from "react";
import { isMobileTablet } from "hooks/helper";
import "ui/UI.css";
import PersonasContext from "contexts/contextPersonas";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";

const UIAlertModal = (props) => {
  const { personasState } = useContext(PersonasContext);
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  let history = useHistory();
console.log("tedestAAA uialertmodal props=", props);
useEffect(() => {
  window.addEventListener("popstate", props.onClose);
  if (window.location?.href?.includes("menu")) {
    window.history.replaceState({ id: 2 }, null, "modal");
  } else {
    if (!props.loginModal) {
      window.history.pushState({ id: 2 }, null, "modal");
    }
  }
  return () => {
    window.removeEventListener("popstate", props.onClose);
  };
}, [modalClose, outClick]);

const handleClose = () => {
  setModalClose(true);
  props.onClose();
};

const handleClickOutside = () => {
  setOutClick(true);
  if (history.length >= 1) history.goBack();
};

let modalShare = (
  <div id="share_modal">
    <Modals
      title={props.title}
      buttonStyle={props.secure && { display: "none" }}
      onClose={() => handleClose()}
      onClickOutside={() => handleClickOutside()}
      clickOutsideActive={!props.secure}
      switchScrollOff={true}
      footer={props.footer}
    >
      {/* <label className="modal-label-new">Information</label> */}
      <div
        style={{
          color: "var(--modal_text_color)",
          textDecoration: "none",
          textAlign: "left",
          width: "100%",
          overflow: "hidden",
          whiteSpace: "pre-line"
        }}
        id="share_modal_link_title"
      >
        {props.message}
      </div>
      {/* <h4
          style={{
            color: "var(--modal_text_color)",
            textAlign: "center",
            textDecoration: "none",
            // wordWrap: "break-word",
            backgroundColor: "#FFFFFF",
            borderRadius: "var(--border_radius_medium)",
            padding: "4px",
            marginBottom: "16px",
            wordBreak: "break-all",
            fontWeight: "inherit",
          }}
          id="share_modal_link"
        ></h4> */}
    </Modals>
  </div>
);

  let content = <div>{modalShare}</div>;

  return content;
};

export default React.memo(UIAlertModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
