import React, { useEffect, useContext, useCallback } from "react";

import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import { dex_action } from "data/dexUtils";

// import firebase from "firebase-messaging";
// import { messaging } from "push/init-fcm";

const FCM = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);

  const homeURL = process.env.REACT_APP_URL || "app.enabled.world";

  const get_browser = () => {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
        ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: "IE", version: tem[1] || "" };
    }
    if (M[1] === "Chrome") {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: "Opera", version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  };

  const isSafari = get_browser().name === "Safari";

  const wssToken = useCallback(
    (muid, cid, token) => {
      let d = new Date();
      let ts_sender =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2) +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2) +
        ":" +
        ("0" + d.getSeconds()).slice(-2) +
        "." +
        ("0" + d.getMilliseconds()).slice(-3);
      let j = {
        type: "w.user.token.update",
        muid: muid,
        cid: cid,
        fcmtoken: token,
        version: globalState.version,
        txn_id: "null",
        ts_sender: ts_sender,
        smid: "fcm_smid" // randomString(8),
      };
      globalState.logging && console.log("FCM UPDATE", j);
      // databaseState.dbMessages &&
      //   databaseDispatch({
      //     type: "UPSERT_REC",
      //     values: {
      //       jmessage: j,
      //       db: databaseState.dbMessages,
      //       collection: "send",
      //       table: "confirm_smid",
      //       rowid: j.smid,
      //       doc: j
      //     }
      //   });
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      globalDispatch({
        type: "SET_TOKEN",
        values: { token: token }
      });
    },
    [
      // databaseDispatch,
      databaseState.dexUser,
      globalDispatch,
      globalState.version
    ]
  );

  const wssTokenClear = useCallback(
    (cid, token) => {
      let d = new Date();
      let ts_sender =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2) +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2) +
        ":" +
        ("0" + d.getSeconds()).slice(-2) +
        "." +
        ("0" + d.getMilliseconds()).slice(-3);
      let j = token
        ? {
            type: "w.user.token.clear",
            cid: cid,
            fcmtoken: token,
            version: globalState.version,
            txn_id: "null",
            ts_sender: ts_sender,
            smid: "fcm_smid" // randomString(8),
          }
        : {
            type: "w.user.token.clear",
            cid: cid,
            version: globalState.version,
            txn_id: "null",
            ts_sender: ts_sender,
            smid: "fcm_smid" // randomString(8),
          };
      globalState.logging && console.log("FCM MUTE", j);

      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      globalDispatch({
        type: "SET_TOKEN",
        values: { token: undefined }
      });
    },
    [
      // databaseDispatch,
      databaseState.dexUser,
      globalDispatch,
      globalState.logging,
      globalState.version
    ]
  );

  useEffect(() => {
    const registerWorker = () => {
      if ("serviceWorker" in navigator && !isSafari) {
        // window.addEventListener("load", () => {
        // navigator.serviceWorker
        //   .register("./firebase-messaging-sw.js")
        navigator.serviceWorker
          .getRegistration(`https://${homeURL}/`)
          .then((registration) => {
            console.log(
              "FCM Registration successful, scope is:",
              registration.scope
            );
            import("push/init-fcm")
              .then(({ messaging }) => {
                messaging.onMessage((payload) => {
                  console.log(
                    "[firebase-messaging-sw.js] Received message ",
                    payload
                  );
                });
                globalState.muid &&
                  messaging
                    .getToken({ serviceWorkerRegistration: registration })
                    .then((currentToken) => {
                      if (currentToken) {
                        console.log("FCM token: ", currentToken);
                        // sendTokenToServer(currentToken);
                        wssToken(
                          globalState.muid,
                          globalState.cid,
                          currentToken
                        );
                        // updateUIForPushEnabled(currentToken);
                      } else {
                        // Show permission request.
                        console.log(
                          "No Instance ID token available. Request permission to generate one."
                        );
                        // Show permission UI.

                        console.log("Please allow FCM token");
                        // updateUIForPushPermissionRequired();
                        // setTokenSentToServer(false);
                      }
                    })
                    .catch((err) => {
                      console.log(
                        "An error occurred while retrieving token. ",
                        err
                      );
                      // showToken('Error retrieving Instance ID token. ', err);
                      // setTokenSentToServer(false);
                    });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log("Service worker registration failed, error:", err);
          });
        // });
      }
    };

    // navigator.permissions &&
    //   navigator.permissions.query({ name: "notifications" }).then((res) => {
    //     res.onchange = (e) => {
    //       // detecting if the event is a change
    //       if (e.type === "change") {
    //         // checking what the new permissionStatus state is
    //         const newState = e.target.state;
    //         if (newState === "denied") {
    //           wssTokenClear(globalState.cid, globalState.token);
    //         } else if (newState === "granted") {
    //           registerWorker();
    //         } else {
    //           console.log("Thanks for reverting things back to normal");
    //         }
    //       }
    //     };
    //     res.state === "granted" && registerWorker();
    //   });

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      registerWorker();
    } else if (
      Notification.permission !== "denied" ||
      Notification.permission === "default"
    ) {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          registerWorker();
        }
      });
    } else {
      wssTokenClear(globalState.cid, globalState.token);
    }

    // messaging
    //   .requestPermission()
    //   .then(async function () {
    //     const token = await messaging.getToken();
    //     console.log("FCM token: ", token);
    //   })
    //   .catch(function (err) {
    //     console.log("Unable to get permission to notify.", err);
    //   });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.muid, globalState.cid, wssToken, isSafari]);

  let content = <div></div>;
  return content;
};

export default React.memo(FCM, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
