import { useReducer } from "react";

const reducer = (state, action) => {
  console.log("SET location", state, action);
  switch (action.type) {
    case "SET_LOCATION":
      return {
        ...state,
        latitude: action.values.latitude,
        longitude: action.values.longitude,
        accuracy: action.values.accuracy,
        timestamp: Date.now(),
      };
    case "SET_USE_LOCATION":
      localStorage.setItem("useLocation", action.values.useLocation);
      return {
        ...state,
        useLocation: action.values.useLocation,
      };
    default: {
      // console.log("Location default...");
      return state;
    }
  }
};

const useLocationState = (state) => {
  const [locationState, locationDispatch] = useReducer(reducer, state);
  return { locationState, locationDispatch };
};

export default useLocationState;
