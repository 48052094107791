import React, { useContext, useMemo, useEffect, useState } from "react";
import { timestamp, randomString } from "hooks/helper";
import TopicsContext from "contexts/contextTopics";
import DatabaseContext from "data/contextDatabase";
import { dex_action } from "data/dexUtils";
import LiveCacheContext from "data/contextLiveCache";
import { isMobileTablet } from "hooks/helper";
import GlobalContext from "contexts/context";
import { useHistory } from "react-router-dom";
// import StyleContext from "contexts/contextStyle";
import "./modals.css";

const UIPersona = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  let history = useHistory();
  // const { styleState } = useContext(StyleContext);
  // const [fontFam, setFontFam] = useState("");
  // const [fontWeight, setFontWeight] = useState();
  // const [screenWidth, setScreenWidth] = useState();

  // useEffect(() => {
  //   if (!screenWidth || screenWidth === undefined)
  //     setScreenWidth(window.innerWidth);
  //   function handleResize() {
  //     setScreenWidth(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const sortJoin = (A, B, Delim) => {
    return A < B ? A + Delim + B : B + Delim + A;
  };

  // useEffect(() => {
  //   let fontName = styleState?.theme?.fonts?.topic_name_main_screen_font;
  //   if (fontName) {
  //     setFontFam(fontName.substring(0, fontName.length - 3));
  //     setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
  //   }
  // }, [styleState]);

  const handleAdjPan = (obj) => {
    topicsDispatch({
      type: "SET_SHOWTOPIC",
      values: { showTopic: obj }
    });
    props.onClose();
  };

  const goToTopic = useMemo(
    () => (t) => {
      // we need this info to action the rest...
      let persona = {
        mpersona: t.mpersona,
        persona: t.persona,
        tag: (
          t.tag ||
          t.props?.tag ||
          t.props?.topiccollection ||
          t.props?.visibility ||
          t.props?.topictype ||
          "undefined"
        ).toLowerCase()
      };
      let collectionState = liveCacheState?.collectionState || {};
      process.env.REACT_APP_DEBUG_THIS &&
        console.log(
          "[UISearchMyTopicsModal] topic",
          t,
          "\n\tcollection",
          collectionState,
          "\n\tpersona:",
          persona
        );
      // make sure this topic's collection will be expanded
      if (
        collectionState &&
        collectionState[persona?.tag] &&
        collectionState[persona.tag][persona.mpersona]?.open &&
        collectionState[persona.tag][persona.mpersona].open !== false
      ) {
        collectionState[persona.tag][persona.mpersona].open = false;
        liveCacheDispatch({
          type: "SET",
          values: { cache: { collectionState: collectionState } }
        });
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "user",
              doc: { key: "collectionstate", value: collectionState }
            }
          });
      }
      // and open the topic
      if (t.mpersona !== globalState.persona?.mpersona) {
        globalDispatch({
          type: "SET_PERSONA",
          values: {
            persona: persona
          }
        });
      }
      if (isMobileTablet()) {
        let serializedObj = JSON.stringify({
          mtopic: t?.mtopic,
          mpersona: t?.mpersona
        });
        history.push("/UIMessageList", { subscription: t.topic });
      } else {
        handleAdjPan({ subscription: t });
      }
    },
    [
      liveCacheState?.collectionState,
      globalState.persona?.mpersona,
      databaseState.dexUser
    ]
  );

  const createTopic = () => {
    let found = false;
    if (topicsState?.topics) {
      let topicId = `t_${
        props.mpersona < props.rpersona.mpersona
          ? props.mpersona
          : props.rpersona.mpersona
      }_${
        props.mpersona < props.rpersona.mpersona
          ? props.rpersona.mpersona
          : props.mpersona
      }`;
      for (const t of Object.keys(topicsState.topics)) {
        if (topicId === topicsState.topics[t]?.mtopic) {
          found = true;
          goToTopic(topicsState.topics[t]);
          break;
        }
      }
    }
    if (!found) {
      if (props.mpersona !== props.rpersona.mpersona) {
        let suppress = true;
        let defaultroles = ["admin", "designer", "tx", "rx"];
        let tag = "dialog";
        let topictype = "dialog";
        let visibility = "private";
        let topicHistory = true;
        let version = props.version;
        let mpersona = props.mpersona;
        let minvitees = [props.rpersona.mpersona];
        let d = {
          topic: `t_${
            props.mpersona < props.rpersona.mpersona
              ? props.mpersona
              : props.rpersona.mpersona
          }_${
            props.mpersona < props.rpersona.mpersona
              ? props.rpersona.mpersona
              : props.mpersona
          }`,
          got_voice: true,
          mtopic: sortJoin(props.mpersona, props.rpersona.mpersona, "_"),
          mpersona: props.mpersona,
          persona: props.persona,
          peer: props.rpersona.mpersona,
          peerpersona: props.rpersona.persona,
          visibility: "PRIVATE_DIALOG"
        };
        let j = {
          type: "w.t.create",
          topic: `t_${
            props.mpersona < props.rpersona.mpersona
              ? props.mpersona
              : props.rpersona.mpersona
          }_${
            props.mpersona < props.rpersona.mpersona
              ? props.rpersona.mpersona
              : props.mpersona
          }`,
          mpersona: mpersona,
          // identifier: identifier,
          defaultroles: defaultroles,
          // description: description,
          tag: tag,
          version: version,
          ts_sender: timestamp(),
          smid: randomString(8)
        };
        if (topictype) j.topictype = topictype;
        if (topicHistory) j.history = topicHistory;
        if (suppress) j.suppress = suppress;
        if (visibility) j.visibility = visibility;
        if (minvitees && minvitees.length > 0) j.minvitees = minvitees;

        // console.log("CREATE TOPIC", j);
        // clear();
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "send",
              doc: j
            }
          });
        // if (window.location?.href?.includes("modal")) {
        //   history.replace("/UIMessageList", {
        //     topic: d
        //   });
        // }
        // else history.push("/UIMessageList", {
        //   topic: d
        // });
        props.onClose();
      } else {
        alert(
          "It appears you are trying to start a conversation with yourself."
        );
      }
    }
  };

  const createDialog = () => {
    props.onClose();
    // if (window.location?.href?.includes("modal")) {
    //   history.replace("/UIMessageList", {
    //     dialog: d,
    //   });
    // }
    // history.push("/UIMessageList", {
    //   dialog: d,
    // });
  };

  const noBorderStyle = {
    borderBottom: "none",
    cursor: "pointer"
  };

  let content = (
    <div>
      <div
        style={props.noBorder ? noBorderStyle : {}}
        onClick={
          () =>
            props.mtopic
              ? props.mtopic.startsWith("t_")
                ? console.log("New topic, do nothing")
                : alert("Cannot invite to old topics")
              : createTopic() // createDialog() // createTopic()
        }
      >
        <div className="flex-row">
          <div
            className="flex-cell"
            style={{
              fontSize: isMobileTablet() ? "1rem" : "1.1rem",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginRight: isMobileTablet() ? "0.5rem" : "1rem",
              maxWidth: isMobileTablet()
                ? "40vw"
                : props.rpersona?.persona?.length > 40
                ? "300px"
                : "fit-content",
              cursor: props.roles?.length > 0 && "auto"
            }}
          >
            {props.rpersona.persona}
          </div>
          <div>
            <p
              className="flex-cell"
              style={{
                fontSize: "0.9rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textAlign: "right"
              }}
            >
              {props.rpersona.last_seen}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UIPersona, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
