import React from "react";
import usePersonasState from "contexts/usePersonasState";
import PersonasContext from "contexts/contextPersonas";

const PersonasStateProvider = ({ children }) => {
  return (
    <PersonasContext.Provider value={usePersonasState({})}>
      {children}
    </PersonasContext.Provider>
  );
};

export default PersonasStateProvider;
