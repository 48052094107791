import React from "react";
import useTopicState from "contexts/useTopicState";
import TopicContext from "contexts/contextTopic";

const TopicStateProvider = ({ children }) => {
  return (
    <TopicContext.Provider value={useTopicState({})}>
      {children}
    </TopicContext.Provider>
  );
};

export default TopicStateProvider;
