// function: for things like intials
//  get nth letter from each array element/word until newStrLen reached.
//  if the combination of start & nChars exeeds the length of a word, the padWith char will be used.
//  if there is only one word, the entire new string will be grabbed from it.
// parms:
//  str: string to process
//  newStrLen: max length of new string
//  start: start position within each word (where first start = 0)
//  nChars: number of chars from start position to grab from each word
//  padWith: pad to max length with this char, if needed.

function GrabCharsFromWords(str, newStrLen, start, nChars, padWith = " ") {
  if (str?.length === 0) return "".padEnd(newStrLen, padWith);

  let processStr = str;
  let newStr = "";
  let charPos = start;
  let maxLen = newStrLen;
  let noOfChars = nChars;
  let words = [];

  maxLen > processStr.length && (maxLen = processStr.length);
  processStr = processStr.replace(/[^a-zA-Z0-9._\- ]/g, "").trim();

  (processStr.includes(" ") && (words = processStr.split(" "))) ||
    (processStr.includes(".") && (words = processStr.split("."))) ||
    (processStr.includes("_") && (words = processStr.split("_"))) ||
    (processStr.includes("-") && (words = processStr.split("-"))) ||
    (words = processStr);

  for (let i = 0, y = 0; i < words.length && y < maxLen; i++, y++) {
    if (
      charPos < words[i].length &&
      words[i].substr(charPos, noOfChars).length < maxLen
    ) {
      newStr = newStr + words[i].substr(charPos, noOfChars);
    } else {
      newStr = newStr + padWith;
    }
  }

  newStr.length > maxLen
    ? (newStr = newStr.substring(0, maxLen))
    : (newStr = newStr.padEnd(maxLen, padWith));

  // not sure if I want this, limits functionality.
  // newStr = newStr.toUpperCase();

  return newStr;
}

export default GrabCharsFromWords;
