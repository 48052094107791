import React from "react";

const UIReportModal = (props) => {
  // console.log("we got to reportmodal!");
  let modal = (
    <div
      id="report_modal"
      className="UI UI-login-modal"
      style={{ display: "block" }}
    >
      <div className="UI-login-modal-content animate">
        <div className="UI-login-container">
          <b>testing</b>
          {/* <ClickOutsideDetector
                        listen
                        onClickOutside={() => {
                            document.getElementById("report_modal").style.display = "none";

                        }}
                        onClick={() => { }}
                    >
                        <img src={props.img}></img>
                    </ClickOutsideDetector> */}
        </div>
      </div>
    </div>
  );

  return modal;
};

export default React.memo(UIReportModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
