import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import "ui/UI.css";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import { timestamp, randomString } from "hooks/helper";
import UIMessage from "./UIMessage";

const UIVote = (props) => {
  const [useSelected, setSelected] = useState(
    props.message.parameters.selection
  );
  const { databaseState, databaseDispatch } = useContext(DatabaseContext);
  const { globalState } = useContext(GlobalContext);

  let history = useHistory();

  const sortJoin = (A, B, Delim) => {
    return A < B ? A + Delim + B : B + Delim + A;
  };

  const modalChoice = (id, choice) => {
    let popup_msg = {
      ...props.message,
      content: { ...props.message.content, ...choice.popup.content },
      parameters: {
        ...props.message.parameters,
        choices: [],
        ...choice.popup.parameters,
      },
      containerID: id,
    };
    let modal_content = (
      <div id={id} className="UI-login-modal">
        <div className="UI-message-modal animate">
          <div className="UI-imgcontainer">
            <span
              onClick={() =>
                (document.getElementById(id).style.display = "none")
              }
              className="UI-message-modal-close"
              title="Close"
            >
              &times;
            </span>
          </div>
          <UIMessage
            message={popup_msg}
            // muid={globalState.muid}
          />
        </div>
      </div>
    );
    let modal_content2 = (
      <div id={id} className="UI-login-modal">
        <div className="UI-login-modal-content animate">
          <div className="UI-imgcontainer">
            <span
              onClick={() =>
                (document.getElementById(id).style.display = "none")
              }
              className="UI-modal-close"
              title="Close"
            >
              &times;
            </span>
          </div>

          <div className="UI-login-container">
            <h2 style={{ color: "#555", textDecoration: "none" }}>
              <b>{choice.text}</b>
            </h2>
          </div>
          {choice?.popup?.parameters?.choices &&
            choice.popup.parameters.choices.map((popupChoice) => (
              <div>
                <button
                  className="UI-choice"
                  key={popupChoice.id}
                  onClick={() =>
                    longpress
                      ? {}
                      : popupChoice.popup
                      ? onModal(popupChoice)
                      : (chosen(popupChoice),
                        (document.getElementById(id).style.display = "none"))
                  }
                  onMouseDown={() => {
                    startTime = new Date().getTime();
                    longpress = false;
                    timer = setTimeout(() => {
                      longpress = true;
                      onLongPress(popupChoice);
                    }, 1000);
                    longpress = false;
                  }}
                  onMouseUp={() => {
                    clearTimeout(timer);
                  }}
                  onMouseOut={() => {
                    clearTimeout(timer);
                  }}
                  onTouchStart={() => {
                    startTime = new Date().getTime();
                    longpress = false;
                    timer = setTimeout(() => {
                      longpress = true;
                      onLongPress(popupChoice);
                    }, 1000);
                    longpress = false;
                  }}
                  onTouchEnd={() => {
                    clearTimeout(timer);
                  }}
                  onDrag={() => {
                    clearTimeout(timer);
                  }}
                  style={{
                    backgroundColor:
                      popupChoice.id === useSelected
                        ? popupChoice.selection_hex
                          ? popupChoice.selection_hex
                          : "#add036"
                        : popupChoice.colour_hex
                        ? popupChoice.colour_hex
                        : "#333333",
                    color:
                      popupChoice.id === useSelected
                        ? popupChoice.selection_text_hex
                          ? popupChoice.selection_text_hex
                          : "#333333"
                        : popupChoice.text_hex
                        ? popupChoice.text_hex
                        : "#add036",
                  }}
                >
                  {popupChoice.text}
                </button>
                <div id={"lp_" + popupChoice.id} style={{ display: "none" }}>
                  {popupChoice.long_press &&
                    popupChoice.long_press.items.map((item) => (
                      <button
                        className="UI-sub-choice"
                        style={{
                          backgroundColor: "#333333",
                          color: "#add036",
                        }}
                      >
                        {item}
                      </button>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
    return modal_content;
  };

  const createDialog = (dialogInfo) => {
    let d = {
      dialog:
        dialogInfo.topic_display_name && dialogInfo.topic_display_name !== ""
          ? dialogInfo.topic_display_name
          : dialogInfo.peerpersona,
      got_voice: true,
      mdialog:
        sortJoin(dialogInfo.mpersona, dialogInfo.peer, "_") +
        (dialogInfo.tag && dialogInfo.tag !== "" ? "/" + dialogInfo.tag : ""),
      mpersona: dialogInfo.mpersona,
      persona: dialogInfo.persona,
      peer: dialogInfo.peer,
      peerpersona: dialogInfo.peerpersona,
      visibility: "PRIVATE_DIALOG"
    };
  };

  let choices =
    props &&
    props.message &&
    props.message.parameters &&
    props.message.parameters.choices
      ? props.message.parameters.choices
      : [];

  const chosen = (choice) => {
    if (props.message?.containerID) {
      let el = document.getElementById(props.message.containerID);
      if (el) {
        el.style.display = "none";
      }
    }
    setSelected(choice.id);
    if (choice.dialog) {
      let dialogInfo = {
        persona: props.persona,
        mpersona: props.mpersona,
        peer: choice.dialog.bmpersona,
        peerpersona: choice.dialog.bpersona,
        tag: choice.dialog.tag,
        topic_display_name: choice.dialog.topic_display_name,
        content: choice.dialog.content
      };
      createDialog(dialogInfo);
    }
    let j = props.message;
    if (j.type === "w.topic.message") {
      j.persona_picture = "";
      j.mpersona = props.mpersona;
      j.persona = props.persona;
      j.name = props.persona;
      j.version = globalState.version;
      j.lat = "";
      j.lon = "";
      let d = new Date();
      j.ts_sender =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2) +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2) +
        ":" +
        ("0" + d.getSeconds()).slice(-2) +
        "." +
        ("0" + d.getMilliseconds()).slice(-3);
      let newParameters = {};
      if (choice?.response?.parameters !== undefined) {
        newParameters = { ...choice.response.parameters };
        Object.entries(choice.response.parameters).forEach(([key, value]) => {
          newParameters[key] = choice.response.parameters[key];
          if (value === "*keep*") {
            newParameters[key] = j.parameters[key];
          }
        });
      }
      //use parameters and keep the *keep* ones
      j.parameters = newParameters;
    } else if (j.type === "w.mpersona.message") {
      j.peer =
        props.message.yourmpersona === props.message.mpersona
          ? props.message.peer
          : props.message.mpersona;
      j.mpersona = props.mpersona;
      j.persona = props.persona;
      j.name = props.persona;
      j.lat = "";
      j.lon = "";
      j.persona_picture = "";
      j.version = globalState.version;
      let d = new Date();
      j.ts_sender =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2) +
        " " +
        ("0" + d.getHours()).slice(-2) +
        ":" +
        ("0" + d.getMinutes()).slice(-2) +
        ":" +
        ("0" + d.getSeconds()).slice(-2) +
        "." +
        ("0" + d.getMilliseconds()).slice(-3);
      let newParameters = {};
      if (choice.response.parameters !== undefined) {
        newParameters = { ...choice.response.parameters };
        Object.entries(choice.response.parameters).forEach(([key, value]) => {
          newParameters[key] = choice.response.parameters[key];
          if (value === "*keep*") {
            newParameters[key] = j.parameters[key];
          }
        });
      }
      //use parameters and keep the *keep* ones
      j.parameters = newParameters;
    }
    globalState.logging && console.log("Response: ", j);
    let jj = JSON.parse(JSON.stringify(j));
  };

  let startTime, endTime, longpress, timer;

  const onLongPress = (choice) => {
    let el = document.getElementById("lp_" + choice.id);
    if (el) {
      el.style.display = el.style.display == "inline" ? "none" : "inline";
    }
  };
  const onModal = (choice) => {
    let el = document.getElementById("modal_" + choice.id);
    if (el) {
      el.style.display = el.style.display == "inline" ? "none" : "inline";
    }
  };

  let content = (
    <div className="UI-choice-group">
      {choices.map((choice) => {
        // (choice.text === "12: Comments" ||
        //   choice.text === "4: Invite a Persona" ||
        //   choice.text === "70: That's cheeky" ||
        //   choice.text === "HELP: Tap for help") &&
        //   (choice.popup = {
        //     content: { body: choice.text },
        //     parameters: {
        //       allow_whisper: "false",
        //       banks: [],
        //       show_menu: "false",
        //       choices: [
        //         {
        //           text_hex: "#333333",
        //           text: "45: Chit-chat",
        //           selection_text_hex: "#000000",
        //           selection_hex: "#cccccc",
        //           id: "45",
        //           colour_hex: "#999999",
        //           response: {
        //             parameters: {
        //               side: "right",
        //               show_menu: "false",
        //               selection: "45",
        //               choices: "*keep*",
        //               allow_whisper: "false",
        //             },
        //           },
        //         },
        //         {
        //           text_hex: "#333333",
        //           text: "46: Info",
        //           selection_text_hex: "#000000",
        //           selection_hex: "#cccccc",
        //           id: "46",
        //           colour_hex: "#add036",
        //           response: {
        //             parameters: {
        //               side: "right",
        //               show_menu: "false",
        //               selection: "46",
        //               choices: "*keep*",
        //               allow_whisper: "false",
        //             },
        //           },
        //         },
        //         {
        //           text_hex: "#333333",
        //           text: "47: Fun",
        //           selection_text_hex: "#000000",
        //           selection_hex: "#cccccc",
        //           id: "47",
        //           colour_hex: "#add036",
        //           response: {
        //             parameters: {
        //               side: "right",
        //               show_menu: "false",
        //               selection: "47",
        //               choices: "*keep*",
        //               allow_whisper: "false",
        //             },
        //           },
        //         },
        //       ],
        //     },
        //   });
        return (
          <div>
            {choice.popup && modalChoice("modal_" + choice.id, choice)}
            <button
              className="UI-choice"
              key={choice.id}
              onClick={() =>
                longpress ? {} : choice.popup ? onModal(choice) : chosen(choice)
              }
              onMouseDown={() => {
                startTime = new Date().getTime();
                longpress = false;
                timer = setTimeout(() => {
                  longpress = true;
                  onLongPress(choice);
                }, 1000);
                longpress = false;
              }}
              onMouseUp={() => {
                clearTimeout(timer);
              }}
              onMouseOut={() => {
                clearTimeout(timer);
              }}
              onTouchStart={() => {
                startTime = new Date().getTime();
                longpress = false;
                timer = setTimeout(() => {
                  longpress = true;
                  onLongPress(choice);
                }, 1000);
                longpress = false;
              }}
              onTouchEnd={() => {
                clearTimeout(timer);
              }}
              onDrag={() => {
                clearTimeout(timer);
              }}
              style={{
                backgroundColor:
                  choice.id === useSelected
                    ? choice.selection_hex
                      ? choice.selection_hex
                      : "#add036"
                    : choice.colour_hex
                    ? choice.colour_hex
                    : "#333333",
                color:
                  choice.id === useSelected
                    ? choice.selection_text_hex
                      ? choice.selection_text_hex
                      : "#333333"
                    : choice.text_hex
                    ? choice.text_hex
                    : "#add036",
              }}
            >
              {choice.text}
            </button>
            <div id={"lp_" + choice.id} style={{ display: "none" }}>
              {choice.long_press &&
                choice.long_press.items.map((item) => (
                  <button
                    className="UI-sub-choice"
                    style={{
                      backgroundColor: "#333333",
                      color: "#add036",
                    }}
                  >
                    {item}
                  </button>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );

  return content;
};

export default React.memo(UIVote, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
