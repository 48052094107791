// Copied from GetInitials.js
// Splitting code out because we need the same calculation in more than one place
// WAPP-1398
function CalcStringColor(str) {
  
  let aval = str.charCodeAt(0) - 64;
  let red = 0;
  let green = 0;
  let blue = 0;
  switch (true) {
    case aval <= 4://A..D
      red = Math.round((aval - 1) * 38 + 75);
      green = 0;
      blue = 0;
      break;
    case aval <= 5://E
      red = 50;
      green = 125;
      blue = 125;
      break;
    case aval <= 9://F..I
      red = 250;
      green = Math.round((aval - 6) * 38 + 75);
      blue = 0;
      break;
    case aval <= 14://J..N
      red = 0;
      green = Math.round((aval - 10) * 38 + 75);
      blue = 0;
      break;
    case aval <= 19://O..S
      red = 0;
      green = Math.round((aval - 15) * 19 + 0);
      blue = Math.round((aval - 15) * 64 + 63);
      break;
    case aval <= 21://T..U
      red = Math.round((aval - 20) * 75 + 75);
      green = Math.round((aval - 20) * 20 + 100);
      blue = 120;
      break;
    case aval <= 22://V
      red = 120;
      green = 120;
      blue = 120;
      break;
    case aval <= 23://W
      red = 173;
      green = 208;
      blue = 54;
      break;
    case aval <= 24://X
      red = 0;
      green = 128;
      blue = 96;
      break;
    case aval <= 25://Y
      red = 0;
      green = Math.round((aval - 25) * 40 + 130);
      blue = 250;
      break;
    case aval <= 26://Z
      red = 0;
      green = 175;
      blue = 250;
      break;
    default:
      red = 0;
      green = 0;
      blue = 0;
  }
  return [red, green, blue];
}

export default CalcStringColor;
