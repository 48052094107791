import React, { useContext, useState, useEffect, useMemo } from "react";

import { Link } from "react-router-dom";

import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import LiveCacheContext from "data/contextLiveCache";
import IconDropDown from "ui/Dropdown/iconDropDown";
import { ImShare2, ImBin } from "react-icons/im";
import { isMobileTablet } from "hooks/helper";

import { IoExitOutline } from "react-icons/io5";
import { RiInboxUnarchiveLine, RiInboxArchiveLine } from "react-icons/ri";

import { AiFillStar, AiOutlineStar, AiOutlineTool, AiOutlinePushpin, AiFillPushpin } from "react-icons/ai";
import { BiSolidPurchaseTagAlt } from 'react-icons/bi'
import { GoMute, GoUnmute, } from "react-icons/go";
import { IoVolumeMute, IoVolumeHigh } from "react-icons/io5";
import { secondsSinceEpoch } from "hooks/helper";
import {
  randomString,
  timestamp,
  timestampZero
} from "hooks/helper";
import UIMessagePreview from "ui/UIMessagePreview";
import TopicsContext from "contexts/contextTopics";
import TopicContext from "contexts/contextTopic";
import GetInitials from "../utils/GetInitials";
import { useHistory } from "react-router-dom";

import {
  applyTopicDescriptors,
  getImage64,
  storeImage
} from "data/descriptors";
import { s3ToImage } from "connectivity/s3";
import StyleContext from "contexts/contextStyle";
import ServMsgPview from "./ServMsgPview";
import { useLiveQuery } from "dexie-react-hooks";
import { dex_action } from "data/dexUtils";
import { useLiveTopicCount } from "hooks/dexHooks";
import "ui/UI.css"
import LabelTag from "./icons/LabelTag";

const isEqual = require("react-fast-compare");

const UITopic = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { topicState, topicDispatch } = useContext(TopicContext);
  const { databaseState } = useContext(DatabaseContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  const { styleState } = useContext(StyleContext);
  const [previewMsg, setPreviewMsg] = useState(undefined);
  const [unreadCount, setUnreadCount] = useState(0);
  const [atPersona, setAtPersona] = useState(false);
  const [servData, setServData] = useState();
  const [servType, setServType] = useState();

  const [colArr, setColArr] = useState([]);
  let history = useHistory();
  const [imagePres, setImagePres] = useState(false);
  const [fontFam, setFontFam] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [redraw, setRedraw] = useState(false);
  const [modal, setModal] = useState(<div></div>);
  const { liveSmallAccount } = useLiveTopicCount(databaseState.dexUser);
  const [labelColor, setLabelColor] = useState("black");

  const [starFlag, setStarFlag] = useState();
  // use the following in case of loading topics in a recycler view (rather than loading them all into memory)
  // const livePreview = useLiveQuery(() => {
  //   if (databaseState.dexUser) {
  //     return databaseState.dexUser.message
  //       .where(["mtopic", "recipient"])
  //       .equals([props.sub?.mtopic, props.sub?.mpersona])
  //       .toArray((array) => {
  //         let a = array.map((d) => {
  //           return {
  //             ...d,
  //             sort: `${d.parameters?.pin || 0}_${d.ts_origin_server}`
  //           };
  //         });
  //         a.sort((a, b) => (a.sort > b.sort ? 1 : -1));
  //         return a.slice(-1);
  //       });
  //   } else {
  //     return undefined;
  //   }
  // }, [databaseState.dexUser, props.sub?.mtopic, props.sub?.mpersona]);

  // useEffect(() => {
  //   livePreview && livePreview.length > 0 && setPreviewMsg(livePreview[0]);
  // }, [livePreview]);

  const toggleStarFlag = () => {
    //what we know is the current mtopic and the current mpersona
    //our list of topics with stars are described by [{mtopic:mtopic,mpersona:mpersona}]
    let clientStarList = [];
    let updatedList;
    if (globalState.prefs?.clientStar) {
      clientStarList = [...globalState.prefs.clientStar];
      const index = clientStarList.findIndex(
        (item) =>
          item.mtopic === props.sub?.mtopic &&
          item.mpersona === props.sub?.mpersona
      );
      // Check if the index is found
      if (index !== -1) {
        // Remove the item from the list
        updatedList = clientStarList.filter((item, i) => i !== index);
      } else {
        // Add the item to the list
        updatedList = [
          ...clientStarList,
          { mtopic: props.sub?.mtopic, mpersona: props.sub?.mpersona }
        ];
      }
    } else {
      // Add the item to the list
      updatedList = [
        ...clientStarList,
        { mtopic: props.sub?.mtopic, mpersona: props.sub?.mpersona }
      ];
    }
    globalDispatch({
      type: "SET_PREFS",
      values: {
        prefs: {
          ...globalState.prefs,
          clientStar: [...updatedList] //list of topics with stars
        }
      }
    });

    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "user",
        doc: {
          key: "prefs",
          value: {
            ...globalState.prefs,
            clientStar: updatedList //list of topics with stars
          }
        }
      }
    });
    // setStarFlag(!starFlag)//force render
  };

  const liveDescriptor = useLiveQuery(() => {
    return databaseState.dexUser.topic_descriptor.get({
      mtopic: props.sub?.mtopic
    });
  }, [databaseState.dexUser]);

  useEffect(() => {
    let mounted = true;

    let f = async (topic, descriptor) => {
      let topic_logo = await databaseState.dexUser.topic_logo.get({
        mtopic: topic.mtopic
      });
      if (
        topic_logo?.digest !== descriptor?.logo?.imgdigest &&
        topic_logo?.digest !== descriptor?.logo?.thumbdigest
      ) {
        globalState.logging &&
          console.log("about to applyTopicDescriptors", topic, descriptor);
        mounted &&
          descriptor &&
          applyTopicDescriptors(databaseState.dexUser, [descriptor]);
      }
    };
    let pf = async (topic) => {
      //get the latest descriptor that matches the bparty
      let s = topic.mtopic.split("_");
      let bmpersona = s[1];
      if (bmpersona === topic.mpersona) bmpersona = s[2];
      let latest_descriptors = await databaseState.dexUser.descriptor
        .filter((d) => d.mpersona === bmpersona)
        .toArray();
      let latest_descriptor = latest_descriptors.reduce(
        (acc, d) => ((d.storedate || "1970") > (acc.storedate || "") ? d : acc),
        {}
      );
      let topic_logo =
        mounted &&
        (await databaseState.dexUser.topic_logo.get({
          mtopic: `${topic.mtopic}_${topic.mpersona}`
        }));
      if (
        latest_descriptor.digest &&
        topic_logo?.digest !== latest_descriptor.digest &&
        mounted
      ) {
        globalState.logging &&
          console.log(
            "about to mpersona applyTopicDescriptors",
            topic,
            latest_descriptor,
            latest_descriptors
          );
        if (latest_descriptor?.thumbdigest || latest_descriptor?.imgdigest) {
          let b64 = await getImage64(
            databaseState.dexUser,
            latest_descriptor.thumbdigest || latest_descriptor?.imgdigest
          );
          if (!b64 && mounted) {
            let i = await s3ToImage(
              bmpersona,
              latest_descriptor.thumbdigest || latest_descriptor?.imgdigest
            );
            if (i?.b64 || i?.i64) {
              b64 = i?.b64 || i?.i64;
              storeImage(databaseState.dexUser, i);
            }
          }
          if (b64 && mounted) {
            let topic_logo_final = {
              i64: b64,
              digest: latest_descriptor.digest,
              mtopic: `${topic.mtopic}_${topic.mpersona}`,
              storedate: latest_descriptor.storedate
            };
            globalState.logging &&
              console.log(
                "storing logo",
                topic_logo,
                topic,
                latest_descriptor,
                latest_descriptors
              );
            databaseState.dexUser.topic_logo.put(topic_logo_final);
          }
        }
      }
    };

    // do we have the assets in the descriptor? if not, then fetch them
    let s = props.sub?.mtopic.split("_");
    if (liveSmallAccount && s.length < 3) {
      liveDescriptor &&
        f(
          JSON.parse(JSON.stringify(props.sub)),
          JSON.parse(JSON.stringify(liveDescriptor))
        );
    } else if (liveSmallAccount && s.length >= 3) {
      // create a logo with the user's thumbnail
      pf(JSON.parse(JSON.stringify(props.sub)));
    }
    return () => {
      mounted = false;
    };
  }, [
    liveSmallAccount,
    databaseState.dexUser,
    globalState.logging,
    liveDescriptor,
    props.sub
  ]);

  const livePreview = useLiveQuery(() => {
    if (databaseState.dexUser) {
      return databaseState.dexUser.latest_message
        .where("[mtopic+recipient]")
        .equals([props.sub?.mtopic, props.sub?.mpersona])
        .first(); // To get the first matching record
    } else {
      return undefined;
    }
  }, [databaseState.dexUser, props.sub?.mtopic, props.sub?.mpersona]);

  // const livePreview = useLiveQuery(() => {
  //   // if (liveSmallAccount && databaseState.dexUser) {
  //   if (databaseState.dexUser) {
  //     return databaseState.dexUser.latest_message.get({
  //       mtopic: props.sub?.mtopic,
  //       recipient: props.sub?.mpersona
  //     });
  //   } else {
  //     return undefined;
  //   }
  // }, [
  //   // liveSmallAccount,
  //   databaseState.dexUser,
  //   props.sub?.mtopic,
  //   props.sub?.mpersona
  // ]);

  const getMessageData = async () => {
    if (databaseState.dexUser) {
      let msgData = await databaseState.dexUser.message
        .where("mtopic")
        .equals(props.sub?.mtopic)
        .toArray();
      return msgData;
    } else {
      return undefined;
    }
  };

  const messageData = useLiveQuery(getMessageData, [
    databaseState.dexUser,
    props.sub?.mtopic
  ]);

  useEffect(() => {
    let check = false;
    if (messageData)
      for (let i = 0; i < messageData?.length; i++) {
        if (messageData[i]?.read === false) {
          let chkStr = "@";
          chkStr = chkStr.concat(globalState.persona.persona);
          if (messageData[i]?.content?.body?.includes(chkStr)) {
            check = true;
            // setAtPersona(true)
            break;
          }
        }
      }
    setAtPersona(check);
  }, [globalState.persona.persona, messageData]);

  const liveMeta = useLiveQuery(() => {
    if (databaseState.dexUser) {
      let metadata = databaseState.dexUser.topic_metadata_buffer.get({
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona
      });
      return metadata || undefined;
    } else {
      return undefined;
    }
  }, [databaseState.dexUser, props.sub?.mtopic, props.sub?.mpersona]);

  useEffect(() => {
    setUnreadCount(liveMeta?.unreadCount || 0);
  }, [liveMeta?.unreadCount]);

  useEffect(() => {
    setPreviewMsg((p) => (isEqual(p, livePreview) ? p : livePreview));
  }, [livePreview?.msg_idx, props]);

  const liveLogo = useLiveQuery(() => {
    return (
      liveSmallAccount &&
      databaseState.dexUser &&
      databaseState.dexUser.topic_logo.get({
        mtopic:
          props?.sub?.mtopic?.split("_").length < 3
            ? props.sub?.mtopic
            : `${props.sub?.mtopic}_${props.sub?.mpersona}`
      })
    );
  }, [
    liveSmallAccount,
    databaseState.dexUser,
    props.sub?.mtopic,
    props.sub?.mpersona
  ]);

  let shareLink = () => {
    let link = props.sub?.mtopic?.startsWith("t_")
      ? window.location.origin + "/" + props.sub?.mtopic
      : window.location.origin + "/topic/" + props.sub?.mtopic;

    props.share(
      props.sub?.topic_display_name ||
        props.sub?.props?.topic_display_name ||
        props.sub?.topic,
      link
    );
  };

  const isDialog = () => props.sub?.props?.topictype === "dialog";
  const isOwner = () => props.sub?.roles?.includes("owner");
  const visibility =
    props.sub?.visibility?.toLowerCase() ||
    props.sub?.props?.visibility?.toLowerCase();

  process.env?.REACT_APP_DEBUG_THIS?.includes("UITopic") &&
    console.log(
      "[UITopic] topic: ",
      props.sub?.topic_display_name || props.sub?.topic,
      "\n\tisDialog: ",
      isDialog(),
      "\n\tisOwner: ",
      isOwner(),
      "\n\tvisibility",
      visibility
    );

  const toggleCollectionState = (topiccollection, mpersona) => {
    let collectionState = { ...liveCacheState?.collectionState } || {};
    collectionState[topiccollection] = collectionState[topiccollection] || {};
    collectionState[topiccollection][mpersona] = {
      open: !collectionState[topiccollection][mpersona]?.open
    };

    liveCacheDispatch({
      type: "SET",
      values: { cache: { collectionState: collectionState } }
    });
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: { key: "collectionstate", value: collectionState }
        }
      });
  };

  const deleteT = () => {
    if (
      window.confirm(
        'Are you sure you want to destroy "' +
          (props.sub?.topic_display_name ||
            props.sub?.props?.topic_display_name ||
            props.sub?.topic) +
          '"?\nIt will be deleted for all users\n'
      )
    ) {
      let j = {
        type: "w.t.delete",
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona,
        version: globalState.version,
        ts_sender: timestamp(),
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.sub?.mtopic &&
                  t.mpersona === props.sub?.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
    } else {
    }
  };

  // Calling parent's toggle here seems to calm the re-render loop
  // when the parent unpins a topic. Minimum renders:
  // 1 for the new pin
  // 2 for the unpinned
  // 3 for the re-ordering according to pinned date
  const togglePin = () => {
    props.togglePin(props.sub);
  };

  const archiveToggle = () => {
    if (true) {
      let archivedNewVal =
        props.sub?.subprops?.archived === "true" ? "false" : "true";
      let j = {
        type: "w.t.subprops.set",
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona,
        new_props: { archived: archivedNewVal },
        version: globalState.version,
        smid: randomString(8),
        ts_sender: timestampZero(), // !!! TODO: this needs to be converted to UTC+0
        status: "1",
        origin_sender: "registered"
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value?.forEach(
            (t) =>
              t.mpersona === props.sub?.mpersona &&
              t.mtopic === props.sub?.mtopic &&
              (t.subprops.archived = archivedNewVal)
          );
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
      if (!isMobileTablet()) {
        topicsDispatch({
          type: "SET_SHOWTOPIC",
          values: { showTopic: { subscription: undefined } }
        });
      }
    } else {
    }
  };

  const leaveT = () => {
    if (
      window.confirm(
        'Are you sure you want to leave "' +
          (props.sub?.topic_display_name ||
            props.sub?.props?.topic_display_name ||
            props.sub?.topic) +
          '"?\nIt will only be deleted for you.\nOthers will still be able to use it.\n'
      )
    ) {
      let j = {
        type: "w.t.leave",
        mtopic: props.sub?.mtopic,
        mpersona: props.sub?.mpersona,
        version: globalState.version,
        smid: randomString(8)
      };
      if (databaseState.dexUser) {
        let transform = (topicsRec) => {
          let newRec = JSON.parse(JSON.stringify(topicsRec));
          newRec.value =
            topicsRec.value?.filter(
              (t) =>
                !(
                  t.mtopic === props.sub?.mtopic &&
                  t.mpersona === props.sub?.mpersona
                )
            ) || [];
          return newRec;
        };
        dex_action({
          type: "DEX_MODIFY_TRANS",
          values: {
            db: databaseState.dexUser,
            table: "account",
            filter: (i) => i.type === "topics",
            transform: transform
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "send",
            doc: j
          }
        });
      }
      if (!isMobileTablet()) {
        topicsDispatch({
          type: "SET_SHOWTOPIC",
          values: { showTopic: { subscription: undefined } }
        });
      }
      // else {
      //   history.push("/");
      // }
    } else {
    }
  };

  const getIcon = (groupType) => {
    if (groupType !== undefined) {
      switch (groupType.toLowerCase()) {
        case "private_dialog":
          return "dialog";
        case "public":
          return "public";
        case "private":
          return "private";
        case "whisper":
          return "whisper";
        case "hidden":
          return "hidden";
        default:
          return groupType.toLowerCase();
      }
    }
    return undefined;
  };

  const menuShare = {
    icon: <ImShare2 />,
    callback: shareLink,
    title: "share"
  };

  const menuExit = {
    icon: <IoExitOutline size={20} />,
    callback: props.sub?.mtopic?.startsWith("t_") && leaveT,
    title: "leave/delete"
  };

  const menuArchive = {
    icon:
      props.sub?.subprops?.archived === "true" ? (
        <RiInboxUnarchiveLine size={20} />
      ) : (
        <RiInboxArchiveLine size={20} />
      ),
    callback: archiveToggle,
    title: props.sub?.subprops?.archived === "true" ? "retrieve" : "archive"
  };

  const menuDelete = {
    icon: <ImBin />,
    callback: props.sub?.mtopic?.startsWith("t_") ? deleteT : {},
    title: "delete"
  };

  // const getMuteIcon = () => {
  //   if (
  //     (liveCacheState?.mute &&
  //       liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute &&
  //       liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute) >
  //     secondsSinceEpoch() + 60
  //   )
  //     return true;
  //   else return false;
  // };

  let muteFCM = (Seconds, Mtopic) => {
    let j = {
      type: "w.user.fcmtime", // for new/old topics and dialogs
      cid: globalState.cid,
      seconds: Seconds, // how long to mute this topic
      mtopic: Mtopic,
      version: globalState.version,
      smid: randomString(8)
    };
    // console.log("MUTE w.user.fcmtime", j);
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
  };

  const muteTopic = () => {
    if (
      liveCacheState?.mute &&
      liveCacheState?.mute[props.sub?.mtopic]?.mute &&
      liveCacheState?.mute[props.sub?.mtopic]?.mute > secondsSinceEpoch() + 60
    ) {
      muteFCM(0, props.sub?.mtopic);
    } else muteFCM(60 * 60 * 24 * 365 * 10, props.sub?.mtopic); // mute for 10 years
  };

  const menuMute = {
    icon:
      (liveCacheState?.mute &&
        liveCacheState?.mute[props.sub?.mtopic]?.mute &&
        liveCacheState?.mute[props.sub?.mtopic]?.mute) >
      secondsSinceEpoch() + 60 ? (
        <IoVolumeHigh />
      ) : (
        <IoVolumeMute />
      ),
    callback: muteTopic,
    title: "mute"
  };

  const menuStar = {
    icon:
      globalState.prefs?.clientStar?.findIndex(
        (item) =>
          item.mtopic === props.sub?.mtopic &&
          item.mpersona === props.sub?.mpersona
      ) > -1 ? (
        <AiOutlineStar />
      ) : (
        <AiFillStar />
      ),
    callback: toggleStarFlag,
    title:
      globalState.prefs?.clientStar?.findIndex(
        (item) =>
          item.mtopic === props.sub?.mtopic &&
          item.mpersona === props.sub?.mpersona
      ) > -1
        ? "unstar"
        : "star"
  };

  const menuPin = {
    icon:
      props.sub?.subprops?.pinned === "true" ? (
        <AiOutlinePushpin />
      ) : (
        <AiFillPushpin />
      ),
    callback: togglePin,
    title: props.sub?.subprops?.pinned === "true" ? "unpin" : "pin"
  };

  // const showMuteLayout = () => {
  //   return (
  //     <span className="mute-icon">
  //       <GoMute />
  //     </span>
  //   );
  // };

  const isMuted = () => {
    if (
      (liveCacheState?.mute &&
        liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute &&
        liveCacheState?.mute[props?.sub?.mtopic || props?.sub?.mdialog]?.mute) >
      secondsSinceEpoch() + 60
    )
      return true;
    else return false;
  };

  const isFlagged = () => {
    if (
      globalState.prefs?.clientStar?.findIndex(
        (item) =>
          item.mtopic === props.sub?.mtopic &&
          item.mpersona === props.sub?.mpersona
      ) > -1
    )
      return true;
    else return false;
  };

  const isLabelled = useMemo(() => {
    const foundLabel = globalState.prefs?.labelNamesList?.find((i) => {
      if (
        i.mtopic === props.sub?.mtopic &&
        i.mpersona === globalState.persona?.mpersona &&
        i.labels?.length > 0
      ) {
        return true;
      }
      return false;
    });

    if (foundLabel) {
      if (
        typeof foundLabel.labels[0] === "object" &&
        foundLabel.labels[0]?.color
      ) {
        setLabelColor(foundLabel.labels[0]?.color);
        return true;
      } else {
        if (foundLabel.labels) return true;
        else return false;
      }
    } else {
      return false;
    }
  }, [globalState.prefs, props.sub?.topic, globalState.persona]);

  // const showPinnedLayout = () => {
  //   return (
  //     <span className="mute-icon">
  //       <AiFillPushpin />
  //     </span>
  //   );
  // };

  const showCollectionTag = () => {
    return (
      <>
        <label
          className="UI-topics-icon"
          type={getVisibility()}
          onClick={(e) => {
            e.preventDefault();
            toggleCollectionState(getIdentity(), props.sub?.mpersona);
          }}
        >
          {getIcon(props.sub?.props?.tag) ||
            getIcon(props.sub?.visibility) ||
            getIcon(props.sub?.props?.visibility)}
        </label>
      </>
    );
  };

  const showDumbCollectionTag = () => {
    // remove or add type for specific background color effect
    return (
      <label
        className="UI-topics-icon"
        type={getVisibility()}
        style={{
          borderStyle: "dashed",
          cursor: "default"
        }}
      >
        {getIcon(props.sub?.props?.tag) ||
          getIcon(props.sub?.visibility) ||
          getIcon(props.sub?.props?.visibility)}
      </label>
    );
  };

  const isPinned = () => {
    if (props?.sub?.subprops?.pinned === "true") {
      return true;
    }
    return false;
  };

  const getVisibility = () => {
    return (
      (props.sub?.props?.tag && getIcon(props.sub?.props.tag)) ||
      getIcon(props.sub?.props?.tag && "user") ||
      getIcon(props.sub?.visibility) ||
      getIcon(props.sub?.props?.visibility)
    );
  };

  // const strAvatar = (
  //   props.sub?.topic_display_name ||
  //   props.sub?.props?.topic_display_name ||
  //   props.sub?.topic
  // )
  //   .substring(0, 2)
  //   .toUpperCase();

  useEffect(() => {
    let mounted = true;
    const storeLogo = (mtopic, digest, image64, storedate) => {
      databaseState.dexUser &&
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "cache",
            doc: {
              scope: "logo",
              mtopic: mtopic,
              digest: digest,
              b64: image64,
              storedate: storedate
            }
          }
        });
    };
    // split the topic
    let p = props.sub?.mtopic?.split("_");
    setImagePres(liveLogo?.i64 || liveLogo?.b64);

    // if (p && p.length > 2 && p[0] === "t") {
    //   // This is a new topic dialog
    //   let peer = p.filter((t) => t !== props.sub?.mpersona && t !== "t")[0];
    //   // console.log("!_!_!_!top peer", peer);
    //   let descriptors = topicsState?.descriptorsB?.filter(
    //     (d) => d.mpersona === peer
    //   );
    //   // !!! TODO: we need to add a timestamp to the descriptor (from s3?)
    //   // otherwise the list cannot be ordered
    //   if (descriptors?.length > 0) {
    //     let d = descriptors.sort((a, b) => {
    //       let c = b.storedate || "1970";
    //       let d = a.storedate || "1970";
    //       return c.localeCompare(d);
    //     })[0];
    //     globalState.logging &&
    //       console.log(
    //         "[UITopic]descriptors",
    //         descriptors,
    //         d,
    //         props?.sub?.topic_display_name ||
    //           props?.sub?.props?.topic_display_name ||
    //           props?.sub?.topic
    //       );
    //     let thumbdigest = d.thumbdigest || d.imgdigest;
    //     if (thumbdigest) {
    //       async function fetchThumb() {
    //         let image64 = await getImage64(databaseState.dexUser, thumbdigest);
    //         globalState.logging &&
    //           console.log("[UITopic] fetchThumb image64", thumbdigest, image64);
    //         if (!image64) {
    //           let img = await s3ToImage(peer, thumbdigest);
    //           if (mounted && img?.b64) {
    //             topicsDispatch({
    //               type: "SET_LOGO",
    //               values: { mtopic: props.sub?.mtopic, logo: img.b64 }
    //             });
    //             setImagePres(img.b64);
    //             storeImage(databaseState.dexUser, img);
    //             storeLogo(props.sub?.mtopic, thumbdigest, img.b64, d.storedate);
    //           }
    //         } else if (mounted) {
    //           topicsDispatch({
    //             type: "SET_LOGO",
    //             values: { mtopic: props.sub?.mtopic, logo: image64 }
    //           });
    //           setImagePres(image64);
    //           // storeLogo(props.sub?.mtopic, thumbdigest, image64, d.storedate);
    //         }
    //       }
    //       fetchThumb();
    //     }
    //   }
    // }
    return () => {
      mounted = false;
    };
  }, [
    props.sub?.mtopic,
    // topicsState.logos,
    liveLogo,
    databaseState.dexUser
  ]);

  useEffect(
    () => {
      if (
        liveCacheState?.collectionState[getIdentity()] &&
        !liveCacheState?.collectionState[getIdentity()][props.sub?.mpersona]
          ?.open
      )
        setColArr(
          colArr.concat(
            props.sub?.topic ||
              props.sub?.topic_display_name ||
              props.sub?.props?.topic_display_name
          )
        );
      return () => {};
    },
    [liveCacheState.collectionState],
    props
  );

  useEffect(() => {
    let fontName = styleState?.theme?.fonts?.topic_name_main_screen_font;
    if (fontName) {
      setFontFam(fontName.substring(0, fontName.length - 3));
      setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
    }
  }, [styleState?.theme, fontFam, fontWeight]);

  const getIdentity = () => {
    let theIdentity =
      props.sub?.props?.topiccollection ||
      props.sub?.props?.tag ||
      props.sub?.visibility ||
      props.sub?.props?.visibility;
    theIdentity = theIdentity?.toString();
    theIdentity = theIdentity?.toLowerCase();
    return theIdentity;
  };

  useEffect(() => {
    setRedraw(!redraw);
  }, [styleState.theme?.opacities?.logo_background_transparency]);

  // let lp =
  //   liveCacheState?.latestPinned?.[`${props.sub?.mtopic}_${props.sub?.mpersona}`];
  // let lm =
  //   liveCacheState?.latest?.[`${props.sub?.mtopic}_${props.sub?.mpersona}`];

  // useEffect(() => {
  //   globalState.logging && console.log("!!!! liveCacheState", lp, lm);
  //   setPreviewMsg(lp || lm);
  // }, [props.sub?.mtopic, props.sub?.mpersona, lp, lm]);

  // const handleSetCodeExecuted = (val) => {
  //   setCodeExecuted(val)
  // }

  useEffect(() => {
    let pv = "";
    let pvType = "";
    let counter = 0;
    let inProcess = true;
    if (previewMsg)
      while (
        inProcess &&
        counter < previewMsg?.parameters?.service_msg?.items?.length
      ) {
        if (
          previewMsg?.parameters?.service_msg?.items[counter]?.type === "text"
        ) {
          if (previewMsg?.parameters?.service_msg?.items[counter]?.value)
            pv = previewMsg?.parameters?.service_msg?.items[counter]?.value;
          else if (previewMsg?.parameters?.service_msg?.items[counter]?.title)
            pv = previewMsg?.parameters?.service_msg?.items[counter]?.title;
          else pv = "";
          pvType = previewMsg?.parameters?.service_msg?.items[counter]?.type;
          inProcess = false;
        } else if (
          previewMsg?.parameters?.service_msg?.items[counter]?.type ===
            "hidden" ||
          !previewMsg?.parameters?.service_msg?.items[counter]?.type
        ) {
          counter++;
        } else if (
          previewMsg?.parameters?.service_msg?.items[counter]?.type ===
          "button_group"
        ) {
          pv = previewMsg?.parameters?.service_msg?.items[counter]?.buttons.map(
            (item) => item.title
          );
          pvType = previewMsg?.parameters?.service_msg?.items[counter]?.type;
          inProcess = false;
        }
      }
    setServData(pv);
    setServType(pvType);
  }, [
    databaseState.dexUser,
    props.sub?.mtopic,
    props.sub?.mpersona,
    // livePreview,
    livePreview?.msg_idx,
    previewMsg
  ]);

  const getPviewdata = () => {
    let pv = "";
    let pvType = "";
    let counter = 0;
    let inProcess = true;
    while (inProcess) {
      if (
        previewMsg?.parameters?.service_msg?.items[counter]?.type === "text"
      ) {
        if (previewMsg?.parameters?.service_msg?.items[counter]?.value)
          pv = previewMsg?.parameters?.service_msg?.items[counter]?.value;
        else if (previewMsg?.parameters?.service_msg?.items[counter]?.title)
          pv = previewMsg?.parameters?.service_msg?.items[counter]?.title;
        else pv = "";
        pvType = previewMsg?.parameters?.service_msg?.items[counter]?.type;
        inProcess = false;
      } else if (
        previewMsg?.parameters?.service_msg?.items[counter]?.type ===
          "hidden" ||
        !previewMsg?.parameters?.service_msg?.items[counter]?.type
      ) {
        counter++;
      } else if (
        previewMsg?.parameters?.service_msg?.items[counter]?.type ===
        "button_group"
      ) {
        pv = previewMsg?.parameters?.service_msg?.items[counter]?.buttons.map(
          (item) => item.title
        );
        pvType = previewMsg?.parameters?.service_msg?.items[counter]?.type;
        inProcess = false;
      }
    }
    let pvObj = {
      value: pv,
      type: pvType
    };
    return pvObj;
  };

  const getMenuArray = () => {
    let filteredMenuItems = [];

    if (isDialog()) {
      filteredMenuItems = [
        menuExit,
        ...(props.sub?.mtopic?.startsWith("t_") ? [menuPin] : []),
        menuMute,
        ...(props.sub?.mtopic?.startsWith("t_") ? [menuArchive] : []),
        menuStar
      ];
    } else if (visibility === "hidden" || visibility === "public") {
      filteredMenuItems = [
        ...(isOwner() ? [menuDelete] : []),
        menuExit,
        ...(props.sub?.mtopic?.startsWith("t_") ? [menuPin] : []),
        menuShare,
        menuMute,
        ...(props.sub?.mtopic?.startsWith("t_") ? [menuArchive] : []),
        menuStar
      ];
    } else {
      filteredMenuItems = [
        ...(isOwner() ? [menuDelete] : []),
        menuExit,
        ...(props.sub?.mtopic?.startsWith("t_") ? [menuPin] : []),
        menuMute,
        ...(props.sub?.mtopic?.startsWith("t_") ? [menuArchive] : []),
        menuStar
      ];
    }
    filteredMenuItems = filteredMenuItems.filter(
      (item) => Object.keys(item).length !== 0
    );
    return filteredMenuItems;
  };

  const handleAdjPan = () => {
    topicsDispatch({
      type: "SET_SHOWTOPIC",
      values: { showTopic: { subscription: props.sub, changeTopic: true } }
    });
    topicDispatch({
      type: "SET_CURRENT_TOPIC",
      values: { currentTopic: props.sub?.mtopic }
    });
    // if (topicsState.unreadDisplay)
    //   topicsDispatch({
    //     type: "SET_UNREAD_DISPLAY",
    //     values: { unreadDisplay: !topicsState.unreadDisplay }
    //   });
    // if (topicsState.labelDisplay?.length > 0)
    //   topicsDispatch({
    //     type: "SET_LABEL_DISPLAY",
    //     values: { labelDisplay: undefined }
    //   });
    // if (topicsState.flagDisplay)
    //   topicsDispatch({
    //     type: "SET_FLAG_DISPLAY",
    //     values: { flagDisplay: false }
    //   });
  };

  const pinnedStyle = () => {
    if (isPinned()) return 1.2;
    else return 0;
  };
  const muteStyle = () => {
    if (isMuted()) return 1.4;
    else return 0;
  };
  const starStyle = () => {
    if (isFlagged()) return 1.4;
    else return 0;
  };
  const labelStyle = isLabelled ? 1.4 : 0;

  let content = (
    <div>
      {props.sub?.mtopic?.startsWith("t_") && modal}
      <div
        // className="UI-topic_forwithpreview"
        className="UI-topics-forWithPreviewGrid"
        style={
          !isMobileTablet() &&
          props.sub?.mtopic === topicsState.showTopic?.subscription?.mtopic &&
          props.sub?.mpersona === topicsState.showTopic?.subscription?.mpersona
            ? {
                gridTemplateColumns: `4rem minmax(0, 1fr) ${muteStyle()}rem ${pinnedStyle()}rem ${labelStyle}rem ${starStyle()}rem 4rem 1.5rem 1.1rem`,
                border: "2px solid var(--topic_border_color)",
                borderRadius: "var(--border_radius_xtra_large"
              }
            : {
                gridTemplateColumns: `4rem minmax(0, 1fr) ${muteStyle()}rem ${pinnedStyle()}rem ${labelStyle}rem ${starStyle()}rem 4rem 1.5rem 1.1rem`
              }
        }
        id={props.sub?.mtopic}
      >
        {
          <div
            className="avatar"
            style={{
              width: "4rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {imagePres ? (
              <img
                style={
                  (topicsState?.logos &&
                    topicsState?.logos[
                      props.sub?.mtopic?.split("_").length < 3
                        ? props.sub?.mtopic
                        : `${props.sub?.mtopic}_${props.sub?.mpersona}`
                    ]) ||
                  liveLogo?.i64 ||
                  liveLogo?.b64
                    ? {
                        width: "3.5rem",
                        height: "3.5rem",
                        borderRadius: "50%"
                      }
                    : { display: "none" }
                }
                src={imagePres}
                alt="Logo"
                onClick={() => {
                  const dataToStore = {
                    scrollTop: props.scrollTop,
                    mpersona: globalState.persona?.mpersona
                  };
                  sessionStorage.setItem(
                    "listScrollTop",
                    JSON.stringify(dataToStore)
                  );
                  // sessionStorage.setItem("listScrollTop", props.scrollTop);
                  if (liveDescriptor) {
                    history.push("/imageDisplay", {
                      origin: "UITopic",
                      avatar: true,
                      picAttach: false,
                      thumb: imagePres,
                      descriptor: liveDescriptor.logo,
                      scope: liveDescriptor.logo?.imgpath?.split("/")[1],
                      imgdigest: liveDescriptor.logo?.imgdigest
                    });
                  }
                  if (props.sub?.mtopic.split("_") > 2) {
                  }
                }}
              />
            ) : (props.sub?.props?.tag &&
                props.sub?.props?.tag.toLowerCase().includes("job")) ||
              //tag id for "job"  is deprecated note end feb to check status
              (props.sub?.props?.topictype &&
                props.sub?.props?.topictype.toLowerCase() === "job") ? (
              <AiOutlineTool
                className="avatar"
                style={{
                  height: "3.5rem",
                  width: "3.5rem",
                  backgroundColor: "#008080",
                  color: "white"
                }}
              />
            ) : (
              <GetInitials
                type="topic"
                str={
                  props?.sub?.topic_display_name ||
                  props?.sub?.props?.topic_display_name ||
                  props?.sub?.topic
                }
              ></GetInitials>
            )}
          </div>
        }
        {isMobileTablet() ? (
          <Link
            key={props.sub?.mtopic}
            className={
              props.sub?.mtopic?.startsWith("t_")
                ? "UI-topic-link-with-t"
                : "UI-topic-link"
            }
            to={{
              pathname: "/UIMessageList",
              state: {
                subscription: props.sub,
                size: "small"
              },
              size: "small"
            }}
          >
            <li
            // className="UI-topic-link"
            >
              <div className="UI-topic-inner">
                <div
                  className="UI-topic-tdn hide-scrollbar"
                  style={{
                    fontFamily: fontFam,
                    fontWeight: fontWeight,
                    width: "100%"
                  }}
                >
                  <span>
                    {props.sub?.topic_display_name?.replace(
                      ".",
                      "." + "\u200B"
                    ) ||
                      props.sub?.props?.topic_display_name?.replace(
                        ".",
                        "." + "\u200B"
                      ) ||
                      props.sub?.topic?.replace(".", "." + "\u200B")}
                  </span>
                  {/*
                  Latest pinned message preview (only if a pinned message exists)
                  Note: we could use a different UI component if we want to do something smarter
                */}
                  {true && previewMsg?.parameters?.service_msg && (
                    <ServMsgPview
                      id={previewMsg?.parameters?.service_msg?.id}
                      pv={servData}
                      type={servType}
                      message={previewMsg}
                    ></ServMsgPview>
                  )}
                  {/* Latest unpinned message preview (only if a pinned message doesn't exist) */}
                  {true &&
                    previewMsg &&
                    !previewMsg?.parameters?.service_msg && (
                      <UIMessagePreview
                        message={previewMsg}
                        muid={globalState.muid}
                        version={globalState.version}
                        you={globalState.persona?.mpersona}
                        persona={globalState.persona?.persona}
                      />
                    )}
                </div>
              </div>
            </li>
          </Link>
        ) : (
          <div
            key={props.sub?.mtopic}
            className={
              props.sub?.mtopic?.startsWith("t_")
                ? "UI-topic-link-with-t"
                : "UI-topic-link"
            }
            style={{ cursor: "pointer" }}
            onClick={handleAdjPan}
          >
            {/* <li
          // className="UI-topic-link"
          > */}
            <div className="UI-topic-inner">
              <div
                className="UI-topic-tdn hide-scrollbar"
                style={{
                  fontFamily: fontFam,
                  fontWeight: fontWeight
                }}
              >
                <span>
                  {props.sub?.topic_display_name?.replace(
                    ".",
                    "." + "\u200B"
                  ) ||
                    props.sub?.props?.topic_display_name?.replace(
                      ".",
                      "." + "\u200B"
                    ) ||
                    props.sub?.topic?.replace(".", "." + "\u200B")}
                </span>

                {/*
                  Latest pinned message preview (only if a pinned message exists)
                  Note: we could use a different UI component if we want to do something smarter
                */}
                {true && previewMsg?.parameters?.service_msg && (
                  <ServMsgPview
                    id={previewMsg?.parameters?.service_msg?.id}
                    pv={servData}
                    type={servType}
                    message={previewMsg}
                  ></ServMsgPview>
                )}

                {/* Latest unpinned message preview (only if a pinned message doesn't exist) */}
                {true && previewMsg && !previewMsg?.parameters?.service_msg && (
                  <UIMessagePreview
                    message={previewMsg}
                    muid={globalState.muid}
                    version={globalState.version}
                    you={globalState.persona?.mpersona}
                    persona={globalState.persona?.persona}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {isMuted() ? (
          <span className="mute-icon">
            <IoVolumeMute size={18} />
          </span>
        ) : (
          <span className="mute-icon"></span>
        )}
        {isPinned() ? (
          <span className="mute-icon">
            <AiFillPushpin />
          </span>
        ) : (
          <span style={{ width: "0px" }}></span>
        )}
        {isLabelled ? (
          <span className="mute-icon">
            <LabelTag
              strokeColor="black"
              // strokeColor={labelColor ? labelColor : "black"}
              fillColor={labelColor ? labelColor : "black"}
              holeFillColor="white"
              tagHeight="18"
              tagWidth="18"
            />
            {/* <BiSolidPurchaseTagAlt size={16} /> */}
          </span>
        ) : (
          <span style={{ width: "0px" }}></span>
        )}
        {isFlagged() ? (
          <span className="mute-icon">
            <AiFillStar color={"red"} size={16} />
          </span>
        ) : (
          <span style={{ width: "0px" }}></span>
        )}
        {isPinned() ? showDumbCollectionTag() : showCollectionTag()}

        {unreadCount > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div className="UI-topic-count">{unreadCount}</div>
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                color: "var(--topic_at_symbol_color)"
              }}
            >
              {atPersona ? "@" : ""}
            </div>
          </div>
        ) : (
          <div>
            <span className="UI-topic-count"></span>
          </div>
        )}
        <IconDropDown className=".UI-icondropdown" menuItems={getMenuArray()} />
        {/* {unreadCount > 0 ? (
            <div className="UI-topic-count">
              {unreadCount}
            </div>
          ) : (
            <span className="UI-topic-count"></span>
          )} */}
        {/* </div> */}
        {/* <IconDropDown
          className=".UI-icondropdown"
          menuItems={getMenuArray()}
        /> */}
      </div>
    </div>
  );
  return content;
};;

export default React.memo(UITopic, (prevProps, nextProps) => {
  process.env?.REACT_APP_DEBUG_THIS?.includes("UITopic") &&
    console.log("[UITopic] prevProps, nextProps: ", prevProps, nextProps);
  return isEqual(prevProps, nextProps);
});
