import { s3PresignedURL, s3ToImage } from "connectivity/s3";
import { getImage64, storeImage } from "data/descriptors";
import React from "react";
import { FaFileDownload } from "react-icons/fa";
import { timeFormat, dateFormat, timestamp } from "utils/time";
import removeMarkdown from "remove-markdown";

const UIQuote = (props) => {
  const downloadAndShow = async (image) => {
    let mounted = true;
    let defaultImage = image.thumbnail &&
      image.large &&
      image.name && {
        image: image.thumbnail,
        large: image.large,
        thumbnail: image.thumbnail,
        name: image.name,
        width: image.width,
        height: image.height
      };
    let imageToShow = await getImage64(props.dexUser, image.medium.digest)
      .then(async (img) => {
        if (img) {
          return {
            image: img,
            large: image.large,
            thumbnail: image.thumbnail,
            name: image.name,
            width: image.width || null,
            height: image.height || null
          };
        } else {
          return await s3ToImage(image.medium.scope, image.medium.digest)
            .then((i) => {
              if (i?.b64) {
                storeImage(props.dexUser, i);
                return {
                  image: i?.b64,
                  large: image.large,
                  thumbnail: image.thumbnail,
                  name: image.name,
                  width: image.width || null,
                  height: image.height || null
                };
              }
            })
            .catch((err) => {
              return false;
            });
        }
      })
      .catch(
        async (err) =>
          await s3ToImage(image.medium.scope, image.medium.digest)
            .then((i) => {
              if (i?.b64) {
                storeImage(props.dexUser, i);
                return {
                  image: i?.b64,
                  large: image.large,
                  thumbnail: image.thumbnail,
                  name: image.name,
                  width: image.width || null,
                  height: image.height || null
                };
              }
            })
            .catch((err) => {
              return false;
            })
      );

    mounted && props.setShowImg(imageToShow || defaultImage);
  };

  const downloadAttachment = async (attachment) => {
    let link = await s3PresignedURL({
      collection: attachment.collection,
      row: attachment.row,
      table: attachment.table,
      method: "getObject"
    });
    if (link.url && window.confirm("Open " + attachment.name + "?")) {
      //downloadFile(link.url);
      window.open(link.url, "_blank");
    }
    !link.url && alert("Something went wrong, please try again.");
  };

  const dstamp = (message) =>
    dateFormat(
      timestamp(message.ts_server, message.ts_origin_server, message.ts_sender)
    );

  const tstamp = (message) =>
    timeFormat(
      timestamp(message.ts_server, message.ts_origin_server, message.ts_sender)
    );

  return (
    <div
      className="UI-message-content-body"
      style={{
        whiteSpace: "pre-wrap",
        fontStyle: "italic",
        fontSize: "0.9rem",
        opacity: 0.9,
        backgroundColor: "var(--quoted_message_background_color)",
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem",
        borderRadius: "var(--border_radius_medium)",
        padding: "0.4rem 0.5rem 0.3rem 0.5rem"
      }}
    >
      {`Quote: ${props.message.persona}\n${dstamp(props.message)} at ${tstamp(
        props.message
      )}\n`}
      {props.message.thumbnail && (
        <img
          className="thumbnail"
          style={{
            paddingLeft: "8px",
            width: "auto",
            height: "50px",
            objectFit: "cover"
          }}
          src={"data:image/png;base64," + props.message.thumbnail}
          alt={props.message.name}
          onClick={() => {
            props.message?.parameters?.images &&
              downloadAndShow(props.message?.parameters?.images[0]);
          }}
        />
      )}
      {((props.message.parameters.attachments &&
        props.message.parameters.attachments[0]) ||
        props.message.parameters.attachment) && (
        <div
          className="attachment-incl"
          onClick={() => {
            downloadAttachment(
              (props.message.parameters.attachments &&
                props.message.parameters.attachments[0]) ||
                props.message.parameters.attachment
            );
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <FaFileDownload
              style={{
                fontSize: "1rem"
              }}
            />
            {/* {" "} */}
            <span
              style={{
                fontSize: "0.8rem",
                marginLeft: "0.5rem"
              }}
            >
              {(props.message.parameters.attachments &&
                props.message.parameters.attachments[0].name) ||
                props.message.parameters.attachment.name}
            </span>
          </p>
        </div>
      )}
      {`${removeMarkdown(props.message?.content.body)}`}
    </div>
  );
};

export default React.memo(UIQuote, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
