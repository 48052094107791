import React, { useContext, useEffect, useState, useRef } from "react";
import DatabaseContext from "data/contextDatabase";
import { randomString } from "hooks/helper";
import { workerPost } from "workers/interfaceRest";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { sanitizeUserId } from "utils/textUtils";
import {
  WorldTextInput,
  CheckPhone,
  CheckSAIdentityNumber,
  removeLeadTrailSpace
} from "utils/UtilsUniversal";
import { shouldLog } from "utils/shouldLog";
import { dex_action } from "data/dexUtils";
import { isMobileTablet } from "hooks/helper";

const UINewUserLongModal = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
  const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [selectedPersona] = useState(false);
  let history = useHistory();

  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [uid, setUid] = useState("");
  const [uidEdit, setUidEdit] = useState(false);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [id, setId] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [pFocus, setPFocus] = useState(false);
  const userNameRef = useRef();
  const passwordRef = useRef();
  const [focusVal, setFocusVal] = useState(true);
  const [submitOK, setSubmitOK] = useState(false);

  useEffect(() => {
    if (
      givenName !== "" &&
      givenName !== undefined &&
      familyName !== "" &&
      familyName !== undefined &&
      !uidEdit
    ) {
      let trimGivenName = givenName.trim();
      let trimFamilyName = familyName.trim();
      setUid(trimGivenName + "." + trimFamilyName);
    }
  }, [givenName, familyName]);

  useEffect(() => {
    if (pFocus) passwordRef.current.focus();
  }, [passwordShown]);

  const createPersona = async (uid, pwd, accessToken, persona, tag) => {
    const response = await workerPost(urlPersona, keyPersona, {
      type: "addpersona",
      uid: uid,
      pwd: pwd,
      token: accessToken,
      persona: persona,
      tag: tag,
      case_sensitive: "true",
      msgid: randomString(8)
    });
    shouldLog() && console.log("createPersona: ", response);
    return response;
  };

  const onCreateUser = async (username, password, accessToken) => {
    const data = {
      type: "register",
      uid: username,
      pwd: password,
      token: accessToken,
      msgid: randomString(8)
    };
    const createUserResult = await workerPost(urlPersona, keyPersona, data);
    shouldLog() && console.log("[createUserResult]", createUserResult);
    if (createUserResult.result === "failed" || !createUserResult.result) {
      switch (createUserResult.reason) {
        case "uidexists":
          alert("That user already exists, please try again");
          break;
        default: {
          alert("User creation error: " + createUserResult.reason);
          break;
        }
      }
    } else if (createUserResult.result === "registered") {
      props.onClose();

      const persona = await createPersona(
        username,
        password,
        accessToken,
        username,
        "no tag"
      );
      shouldLog() && console.log("oncreatePersona: ", persona); // post the user properties here
      // workerPut {setuidattribute, Uid, Pwd, Attribute, Instance}
      givenName &&
        (await workerPost(urlPersona, keyPersona, {
          type: "addproperty",
          uid: sanitizeUserId(username),
          pwd: password,
          token: accessToken,
          persona: username,
          case_sensitive: "true",
          descriptor: "registration",
          expiry: "undefined",
          property: "given_name",
          value: givenName,
          msgid: randomString(8)
        })
          .then((r) =>
            console.log("[UIRegister] givenName workerPut result", r)
          )
          .catch((err) => {
            console.log("[UIRegister] givenName err", err);
          }));
      familyName &&
        (await workerPost(urlPersona, keyPersona, {
          type: "addproperty",
          uid: sanitizeUserId(username),
          pwd: password,
          token: accessToken,
          persona: username,
          case_sensitive: "true",
          descriptor: "registration",
          expiry: "undefined",
          property: "family_name",
          value: familyName,
          msgid: randomString(8)
        })
          .then((r) =>
            console.log("[UIRegister] familyName workerPut result", r)
          )
          .catch((err) => {
            console.log("[UIRegister] familyName err", err);
          }));
      email &&
        (await workerPost(urlPersona, keyPersona, {
          type: "addproperty",
          uid: sanitizeUserId(username),
          pwd: password,
          token: accessToken,
          persona: username,
          case_sensitive: "true",
          descriptor: "registration",
          expiry: "undefined",
          property: "email",
          value: email,
          msgid: randomString(8)
        })
          .then(
            (r) => shouldLog() && ("[UINewUserLongModal] workerPut result", r)
          )
          .catch((err) => {
            console.log("[UINewUserLongModal] err", err);
          }));

      phone &&
        (await workerPost(urlPersona, keyPersona, {
          type: "addproperty",
          uid: sanitizeUserId(username),
          pwd: password,
          token: accessToken,
          persona: username,
          case_sensitive: "true",
          descriptor: "registration",
          expiry: "undefined",
          property: "phone_number",
          value: phone,
          msgid: randomString(8)
        })
          .then(
            (r) =>
              shouldLog() &&
              console.log("[UINewUserLongModal] workerPut result", r)
          )
          .catch((err) => {
            console.log("[UINewUserLongModal] err", err);
          }));

      id &&
        (await workerPost(urlPersona, keyPersona, {
          type: "addproperty",
          uid: sanitizeUserId(username),
          pwd: password,
          token: accessToken,
          persona: username,
          case_sensitive: "true",
          descriptor: "registration",
          expiry: "undefined",
          property: "id_number",
          value: id,
          msgid: randomString(8)
        })
          .then(
            (r) =>
              shouldLog() &&
              console.log("[UINewUserLongModal] workerPut result", r)
          )
          .catch((err) => {
            console.log("[UINewUserLongModal] err", err);
          }));

      dex_action({
        type: "DEX_UPSERT_MATCH",
        values: {
          db: databaseState.dexAdmin,
          table: "store",
          match: { key: "client" },
          function: (val) => {
            return {
              cid: randomString(10),
              ...val // this will keep the old cid if it already exists
            };
          }
        }
      });

      dex_action({
        type: "DEX_UPSERT_MATCH",
        values: {
          db: databaseState.dexAdmin,
          table: "store",
          match: { key: "user" },
          function: (val) => {
            let v = {
              // ...val, // removing this because logging in should remove any jwt, muid etc
              key: "user",
              uid: sanitizeUserId(username),
              password: password
            };
            shouldLog() && console.log("update_user", v);
            return v;
          }
        }
      });

      if (history.length >= 1) history.goBack();
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick, selectedPersona]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGivenName = (e) => {
    setGivenName(e);
    setUidEdit(false);
  };

  const handleFamilyName = (e) => {
    setFamilyName(e);
    setUidEdit(false);
  };

  const handleUser = (e) => {
    setUidEdit(true);
    setUid(e);
  };

  const handlepassword = (e) => {
    setPassword(e);
  };

  const handleEmail = (e) => {
    setEmail(e);
  };

  const handlePhone = (e) => {
    setPhone(e);
  };

  const handleID = (e) => {
    setId(e);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let bool = true;
    if (submitOK) {
      setGivenName(givenName.trim());
      setFamilyName(familyName.trim());
      setUid(uid.trim());
      setPassword(password.trim());
      if (uid === undefined || uid === "") {
        alert("UserName cannot be blank");
        userNameRef.current?.focus();
        bool = false;
      }
      if (bool && (password === undefined || password === "")) {
        alert("Password cannot be blank");
        passwordRef.current?.focus();
        bool = false;
      }
      if (bool && email && email?.length > 0) {
        let regex = /^[^~,]+@[^~,]+\.[^~,]+$/;
        if (!regex.test(email)) {
          alert("email is not valid");
          bool = false;
        }
      }
      phone.replace(/\s/g, "");
      let cleanPhone = phone.replace(/\s/g, "");
      if (cleanPhone !== phone) setPhone(cleanPhone);
      if (bool && phone && phone?.length > 0) {
        let errMsg = CheckPhone(cleanPhone, 10, 13);
        // if (bool && phone && phone?.length > 0 && !CheckPhone(phone, 10, 13)) {
        if (errMsg.toLowerCase() !== "ok") {
          alert("Phone number is not valid - Error: " + errMsg);
          bool = false;
        }
      }
      if (
        bool &&
        id &&
        id?.length > 0 &&
        id?.length < 5
        //&& !CheckSAIdentityNumber(id, 6, 13, new Date('1900-01-01'), new Date('2999-12-31'))
      ) {
        alert("Identity number is not valid");
        bool = false;
      }
      let trimUid = uid.trim();
      let trimPassword = password.trim();
      if (bool) onCreateUser(trimUid, trimPassword, undefined);
      else alert("Something is wrong");
    }
  };

  const tagTooltip =
    "no leading or trailing spaces\n" +
    "maximum 12 characters\n" +
    "A..Z a..z 0-9\n" +
    "uppercase will be converted to lowercase";

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  useEffect(() => {
    if (
      givenName?.length > 0 &&
      familyName?.length > 0 &&
      uid?.length > 0 &&
      password?.length > 0
    )
      setSubmitOK(true);
    else setSubmitOK(false);
  }, [givenName, familyName, uid, password]);

  let modalCreateNewUserLong = (
    <div id="auto_user_modal">
      <Modals
        loginModal={true}
        title="Register"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        footer={
          <button
            disabled={!submitOK}
            form="newuser_form"
            type="submit"
            className="UI-button-service"
          >
            Register
          </button>
        }
      >
        <form id="newuser_form" onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <p
              style={{
                textAlign: "left",
                marginBottom: "0",
                marginTop: "0",
                fontWeight: "bold"
              }}
            >
              Privacy information
            </p>
            {process.env.REACT_APP_MODE === "dev" && (
              <div
                style={{
                  textAlign: "left",
                  maxHeight: "180px",
                  overflow: "auto",
                  padding: "4px",
                  marginBottom: "0",
                  marginTop: "0",
                  fontSize: "0.82rem",
                  maxWidth: "75vw",
                  width: "300px"
                }}
              >
                WORLD will not share your personal information with third
                parties without your express consent, unless:
                <br />
                <ol className="modal-list-new">
                  <li>
                    we believe it to be in the interests of your health and
                    safety;
                  </li>
                  <li>required to do so by law.</li>
                </ol>
                <br />
                By using the WORLD app you consent to this Privacy Policy, or
                future versions of it. You may opt-out at any time by deleting
                your account and uninstalling the app.
                <br /> <br />
                WORLD will retain User Provided data and Automatically Collected
                information for as long as you use the application and for a
                period of time thereafter as may be required by law.
                <br /> <br />
                WORLD will not actively solicit data from, or market to,
                children.
                <br /> <br />
                WORLD will not send you promotional material without your
                consent.
                <br /> <br />
                <p>
                  The detailed Privacy Policy can be viewed on our website using
                  the button below. WORLD will endeavour to notify you of any
                  changes to this policy.
                </p>
                <button
                  className="UI-button-confined"
                  style={{
                    marginLeft: "auto",
                    marginBottom: "0.75rem"
                  }}
                  type="button"
                  onClick={() =>
                    openInNewTab("https://www.enabled.world/privacy-policy/")
                  }
                >
                  Privacy policy
                </button>
              </div>
            )}
            {process.env.REACT_APP_MODE === "prod" && (
              <div
                style={{
                  textAlign: "left",
                  maxHeight: "180px",
                  overflow: "auto",
                  padding: "4px",
                  marginBottom: "0",
                  marginTop: "0",
                  fontSize: "0.82rem"
                }}
              >
                EZist will not share your personal information with third
                parties without your express consent, unless:
                <br />
                <ol className="modal-list-new">
                  <li>
                    we believe it to be in the interests of your health and
                    safety;
                  </li>
                  <li>required to do so by law.</li>
                </ol>
                <br />
                By using the EZist app you consent to this Privacy Policy, or
                future versions of it. You may opt-out at any time by deleting
                your account and uninstalling the app.
                <br /> <br />
                EZist will retain User Provided data and Automatically Collected
                information for as long as you use the application and for a
                period of time thereafter as may be required by law.
                <br /> <br />
                EZist will not actively solicit data from, or market to,
                children.
                <br /> <br />
                EZist will not send you promotional material without your
                consent.
                <br /> <br />
                <p>
                  The detailed Privacy Policy can be viewed on our website using
                  the button below. EZist will endeavour to notify you of any
                  changes to this policy.
                </p>
                <button
                  className="UI-button-confined"
                  style={{
                    marginLeft: "auto",
                    marginBottom: "0.75rem"
                  }}
                  type="button"
                  onClick={() =>
                    openInNewTab(
                      "https://ezist.online/wp-content/uploads/2022/05/EZist-Privacy-Policy-22020505.pdf"
                    )
                  }
                >
                  Privacy policy
                </button>
              </div>
            )}
            <p
              style={{
                display: "flex",
                textAlign: "left",
                margin: "0",
                alignItems: "center"
              }}
            >
              If you already have an account go to
            </p>
            <button
              className="UI-button-confined"
              style={{ marginLeft: "auto" }}
              type="button"
              onClick={() => {
                props.onClose();
                props.callback();
              }}
            >
              Sign in
            </button>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <WorldTextInput //first name
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="givenname"
              focus={focusVal}
              removeFocus={(val) => handleFocus(val)}
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleGivenName(e)}
              label={"First name"}
              required={true}
              placeholder={"Given name"}
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={80}
              value={givenName}
              regex={/[a-z'-._/, ]$/i}
            />
            <WorldTextInput //surname
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="surname"
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleFamilyName(e)}
              label={"Surname"}
              required={true}
              placeholder={"Family name"}
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={80}
              value={familyName}
              regex={/[a-z'-._/, ]$/i}
            />
            <WorldTextInput //email
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="email"
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleEmail(e)}
              label={"email"}
              required={false}
              placeholder={"email"}
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={56}
              value={email}
            />
            <WorldTextInput //phone
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="phone"
              labelStyle={{
                fontWeight: "bold"
              }}
              type="phone"
              title={"min 10 chars max 13"}
              callback={(e) => handlePhone(e)}
              label={"Mobile number (intl format)"}
              required={false}
              placeholder={"27 phone number"}
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              minStrLen={10}
              maxStrLen={13}
              value={phone}
              regex={/[0-9]$/}
            />
            <WorldTextInput //id
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="id"
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleID(e)}
              label={"National identity number"}
              required={false}
              placeholder={"ID"}
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              minStrLen={6}
              maxStrLen={56}
              value={id}
              regex={/^[\w-./]+$/i}
            />
            <WorldTextInput //uid ie username
              universalStyle={{ display: "flex", flexDirection: "column" }}
              identity="user"
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              // title={tagTooltip}
              callback={(e) => handleUser(e)}
              label="Username"
              required={true}
              placeholder="Username"
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={56}
              value={uid}
              active="true"
              // autoComplete="off"
              // autoCapitalize="none"
              regex={/[a-z'-._/, ]$/i}
              inpStyle={{
                maxWidth: "100%",
                width: isMobileTablet() ? "70vw" : "100%",
                padding: "0.5rem",
                boxSizing:
                  "border-box" /* Include the border within the width */
              }}
            />
            <WorldTextInput //password
              identity="password"
              labelStyle={{
                fontWeight: "bold"
              }}
              type="password"
              // title={tagTooltip}
              callback={(e) => handlepassword(e)}
              label="Password"
              required={true}
              placeholder="Password"
              // description={"generic World text input field testing phase"}
              minRows={1}
              maxRows={1}
              // minStrLen={3}
              maxStrLen={26}
              value={password}
              active="true"
              // regex={/^[\w]+$/i}
            />
          </div>
        </form>
      </Modals>
    </div>
  );

  let content = <div>{modalCreateNewUserLong}</div>;

  return content;
};

export default React.memo(UINewUserLongModal, (prevProps, nextProps) => {
    return prevProps === nextProps;
})
