import React, { useEffect, useContext, useMemo } from "react";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import { randomString } from "hooks/helper";
import { workerGet } from "workers/interfaceRest";
import { encrypt, decrypt } from "utils/simpleCrypt";
import UserContext from "contexts/contextUser";
import { useAccountDex } from "hooks/dexHooks";
import isEqual from "react-fast-compare";
import { dex_action } from "./dexUtils";

const Database = (props) => {
  const { databaseState, databaseDispatch } = useContext(DatabaseContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { userState } = useContext(UserContext);
  const { liveUser } = useAccountDex(databaseState.dexAdmin);

  const setReady = useMemo(
    () => (error, key) => {
      !error &&
        key &&
        databaseDispatch({
          type: "MASTER_DB_READY",
          values: { ready: true }
        });
    },
    [databaseDispatch]
  );

  useEffect(() => {
    databaseState.dexAdmin &&
      dex_action({
        type: "DEX_UPSERT_MATCH",
        values: {
          db: databaseState.dexAdmin,
          table: "store",
          match: { key: "client" },
          function: (val) => {
            return {
              cid: randomString(10),
              ...val // this will keep the old cid if it already exists
            };
          }
        }
      });

    databaseState.dexAdmin &&
      (userState?.parsedToken?.worldid ||
        (globalState.uid && globalState.password)) &&
      dex_action({
        type: "DEX_UPSERT_MATCH",
        values: {
          db: databaseState.dexAdmin,
          table: "store",
          match: { key: "user" },
          function: (val) => {
            let v = {
              ...val,
              uid: userState?.parsedToken?.worldid || globalState.uid,
              password: globalState.password,
              dbKey: globalState.key
            };
            if (
              (userState?.parsedToken?.worldid || globalState.uid) !== val.uid
            )
              v = val;
            globalState.logging && console.log("update_user", v);
            return v;
          }
        }
      });
    return () => {};
  }, [
    databaseDispatch,
    databaseState.dexAdmin,
    globalState.key,
    globalState.password,
    globalState.uid,
    userState?.parsedToken?.worldid
  ]);

  useEffect(() => {
    databaseState.dexAdmin && databaseState.dexUser && setReady(false, true);
  }, [databaseState.dexAdmin, databaseState.dexUser]);
  useEffect(() => {
    // console.log("CREATE_USER_DEX", globalState, databaseState, userState);
    globalState.uid &&
      databaseState.dexAdmin &&
      databaseDispatch({
        type: "CREATE_USER_DEX",
        values: {
          dbName: globalState.uid || userState?.parsedToken?.worldid
        }
      });
    return () => {};
  }, [
    databaseDispatch,
    databaseState.dexAdmin,
    globalState.uid,
    globalState.key,
    setReady,
    userState?.parsedToken?.worldid
  ]);

  useEffect(() => {
    globalState?.muid && localStorage.setItem("used", "true");
    return () => {};
  }, [globalState.muid]);

  useEffect(() => {
    databaseDispatch({
      type: "CREATE_DB_ADMIN_DEX",
      values: { dbName: "dexAdmin" }
    });
    return () => {};
  }, [databaseDispatch]);

  useEffect(() => {
    let mounted = true;
    // console.log(
    //   "KEY",
    //   userState.accessToken,
    //   globalState.password,
    //   globalState.uid
    // );
    let getKey = () => {
      const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
      const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
      let jKey = {
        type: "getKey",
        uid:
          userState?.parsedToken?.worldid || globalState?.uid || liveUser?.uid,
        pwd: globalState.password,
        msgid: randomString(8),
        version: globalState.version
      };
      userState?.accessToken && (jKey.token = userState?.accessToken);
      (userState?.parsedToken?.worldid || globalState?.uid || liveUser?.uid) &&
        workerGet(urlPersona, keyPersona, jKey)
          .then((keyResponse) => {
            // console.log("keyResponse", keyResponse);
            if (keyResponse?.key) {
              encrypt(keyResponse.key, globalState.password || "1234").then(
                (r) => {
                  // console.log("Cipher", r);
                  localStorage.setItem(
                    "key_" +
                      (userState?.parsedToken?.worldid ||
                        globalState?.uid ||
                        liveUser?.uid),
                    r
                  );
                }
              );
              globalState.key !== keyResponse.key &&
                globalDispatch({
                  type: "SET_KEY",
                  values: { key: keyResponse.key }
                });
              (globalState.muid !== keyResponse.key) && keyResponse.key &&
                globalDispatch({
                  type: "SET_MUID",
                  values: { muid: keyResponse.key }
                });
            } else {
              let enckey = localStorage.getItem(
                "key_" +
                  (userState?.parsedToken?.worldid ||
                    globalState.uid ||
                    liveUser.uid)
              );
              // console.log("Set enckey from localStorage", enckey);
              enckey &&
                decrypt(enckey, globalState.password || "1234")
                  .then((r) => {
                    // console.log("Cipher open", r);
                    if (r === "") {
                    } else {
                      mounted &&
                        globalDispatch({
                          type: "SET_KEY",
                          values: { key: r }
                        });
                      mounted &&
                        (globalState.muid !== r) && r &&
                        globalDispatch({
                          type: "SET_MUID",
                          values: { muid: r }
                        });
                    }
                  })
                  .catch((error) => {
                    console.log("KEY error", error);
                  });
            }
          })
          .catch((err) => {
            console.log("CATCH", err);
          });
    };

    if (globalState.uid && globalState.password) {
      let encText = localStorage.getItem(
        "key_" +
          (userState?.parsedToken?.worldid || globalState.uid || liveUser.uid)
      ); // + globalState.uid);
      if (encText) {
        decrypt(encText, globalState.password || "1234")
          .then((r) => {
            // console.log("Cipher open", r);
            if (r === "") {
              localStorage.removeItem(
                "key_" +
                  (userState?.parsedToken?.worldid ||
                    globalState.uid ||
                    liveUser.uid)
              ); // + globalState.uid);
              mounted && getKey();
            } else {
              mounted &&
                globalState.key !== r &&
                globalDispatch({
                  type: "SET_KEY",
                  values: { key: r }
                });
              mounted &&
                globalState.muid !== r &&
                r &&
                globalDispatch({
                  type: "SET_MUID",
                  values: { muid: r }
                });
            }
          })
          .catch((error) => {
            localStorage.removeItem(
              "key_" +
                (userState?.parsedToken?.worldid ||
                  globalState.uid ||
                  liveUser.uid)
            ); // + globalState.uid);
            mounted && getKey();
          });
      } else {
        mounted && getKey();
      }
    } else if (userState?.accessToken) {
      mounted && getKey();
    }
    return () => {
      mounted = false;
    };
  }, [
    userState?.accessToken,
    globalDispatch,
    globalState.version,
    globalState.password,
    globalState.uid,
    globalState.muid,
    liveUser?.uid,
    userState?.parsedToken?.worldid
  ]);

  let content = <div></div>;
  return content;
};

export default React.memo(Database, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
