import React from 'react'

const GrWorkshopWorld = (props) => {
  let strokeColor = "black"
  props.strokecolor
    ? strokeColor = props.strokecolor
    : strokeColor = "black"
  let fillColor = "black"
  props.fillcolor
    ? fillColor = props.fillcolor
    : fillColor = "black"
  return (
    <svg
      stroke={strokeColor}
      fill={fillColor}
      // strokeWidth={props.strokewidth}
      stroke-width="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      transform="
        translate(0 0)
        scale(0.75 0.75)">
      <path fill="none" stroke-width="2" d="M19,7 C19,7 14,14 6.5,14 C4.5,14 1,15 1,19 L1,23 L12,23 L12,19 C12,16.5 15,18 19,11 L17.5,9.5 M3,5 L3,2 L23,2 L23,16 L20,16 M11,1 L15,1 L15,3 L11,3 L11,1 Z M6.5,14 C8.43299662,14 10,12.4329966 10,10.5 C10,8.56700338 8.43299662,7 6.5,7 C4.56700338,7 3,8.56700338 3,10.5 C3,12.4329966 4.56700338,14 6.5,14 Z"></path>
      <path fill="none" stroke-width="2" d="M19,7 C19,7 14,14 6.5,14 C4.5,14 1,15 1,19 L1,23 L12,23 L12,19 C12,16.5 15,18 19,11 L17.5,9.5 M3,5 L3,2 L23,2 L23,16 L20,16 M11,1 L15,1 L15,3 L11,3 L11,1 Z M6.5,14 C8.43299662,14 10,12.4329966 10,10.5 C10,8.56700338 8.43299662,7 6.5,7 C4.56700338,7 3,8.56700338 3,10.5 C3,12.4329966 4.56700338,14 6.5,14 Z"></path>
    </svg>
  )
}

export default GrWorkshopWorld