import React, { useState, useEffect } from "react";
import UIPersona from "ui/UIPersona";
import "./modals.css";
import "ui/UI.css"

const UIPersonaList = (props) => {
  const [minvitees, setMinvitees] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);
  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => setErrorMsg(false), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [errorMsg]);

  const roles = {
    owner: [
      { label: "default", role: "default" },
      { label: "owner", role: "owner" },
      { label: "admin", role: "admin" },
      { label: "designer", role: "designer" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" },
      { label: "receive", role: "rx" }
    ],
    admin: [
      { label: "default", role: "default" },
      { label: "admin", role: "admin" },
      { label: "designer", role: "designer" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" },
      { label: "receive", role: "rx" }
    ],
    designer: [
      { label: "default", role: "default" },
      { label: "designer", role: "designer" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" },
      { label: "receive", role: "rx" }
    ],
    tx: [
      { label: "default", role: "default" },
      { label: "send", role: "tx" },
      { label: "whisper", role: "whisper" },
      { label: "receive", role: "rx" }
    ],
    whisper: [
      { label: "default", role: "default" },
      { label: "whisper", role: "whisper" },
      { label: "receive", role: "rx" }
    ],
    rx: [
      { label: "default", role: "default" },
      { label: "receive", role: "rx" }
    ]
  };

  const invRoles = props.topic?.roles?.includes("owner")
    ? roles["owner"]
    : props.topic?.roles?.includes("admin")
    ? roles["admin"]
    : props.topic?.roles?.includes("designer")
    ? roles["designer"]
    : props.topic?.roles?.includes("tx")
    ? roles["tx"]
    : props.topic?.roles?.includes("whisper")
    ? roles["whisper"]
    : props.topic?.roles?.includes("rx")
    ? roles["rx"]
    : [];

  const addDefaults = (persona, mpersona, currentRoles) => {
    let defaultRoles = props.topic.props?.defaultroles || [];
    let newRoles = [
      ...defaultRoles,
      ...currentRoles.filter(
        (r) =>
          !defaultRoles.includes(r) &&
          !(r === "tx" && defaultRoles.includes("whisper")) &&
          !(r === "whisper" && defaultRoles.includes("tx"))
      )
    ];
    let ThisMpersona = {
      persona: persona,
      mpersona: mpersona,
      roles: newRoles
    };
    let OtherMpersonas = minvitees.filter((m) => m.mpersona !== mpersona);
    setMinvitees(
      newRoles.length === 0
        ? [...OtherMpersonas]
        : [ThisMpersona, ...OtherMpersonas]
    );
  };

  const editRoles = (persona, mustAdd, mpersona, role) => {
    let OtherMpersonas = minvitees?.filter((m) => m.mpersona !== mpersona);
    let ThisMpersona = minvitees?.filter((m) => m.mpersona === mpersona)[0];
    let currentRoles = ThisMpersona ? ThisMpersona.roles : [];
    if (role === "default") {
      if (mustAdd) {
        addDefaults(persona, mpersona, currentRoles);
      } else {
        // document.getElementById(mpersona + "|" + role).checked = false;
        // setOptionsCount(optionsCount - 1)
      }
    } else {
      // document.getElementById(mpersona + "|" + "default").checked = false;
      let newRoles;
      if (mustAdd) {
        newRoles = [role, ...currentRoles].filter(
          (r) =>
            !(
              (r === "whisper" && role === "tx") ||
              (r === "tx" && role === "whisper")
            )
        );
        if (role === "tx") toggle(persona, mpersona, "whisper");
        if (role === "whisper") toggle(persona, mpersona, "tx");
        // setOptionsCount(optionsCount + newRoles.length - currentRoles.length);
      }
      ThisMpersona = { persona: persona, mpersona: mpersona, roles: newRoles };
      setMinvitees(
        newRoles.length === 0
          ? [...OtherMpersonas]
          : [ThisMpersona, ...OtherMpersonas]
      );
    }
  };

  useEffect(() => {
    if (props.onInvited) props.onInvited(minvitees);
  }, [minvitees]);

  const toggle = (persona, Bmpersona, role) => {
    editRoles(persona, false, Bmpersona, role);
  };

  useEffect(() => {
    if (props.clearStatus)
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));
    setMinvitees([]);
    return () => {};
  }, [props.clearStatus]);

  const checkChecked = (B, r) => {
    for (let rc = 0; rc < props.newInvites?.length; rc++) {
      if (props.newInvites.length > 0 && B === props.newInvites[rc].mpersona)
        for (let rl = 0; rl < props.newInvites[rc].roles?.length; rl++)
          if (props.newInvites[rc].roles[rl] === r.role) {
            return true;
          }
    }
    return false;
  };

  const checkDefaultChecked = (B, r) => {
    for (let rc = 0; rc < minvitees?.length; rc++) {
      if (minvitees.length > 0 && B === minvitees[rc].mpersona)
        for (let rl = 0; rl < minvitees[rc].roles?.length; rl++)
          if (minvitees[rc].roles[rl] === r.role) {
            return true;
          }
    }
    return false;
  };

  const boxes = (persona, Bmpersona) =>
    invRoles.map((r) => (
      <div
        key={Bmpersona + "_" + r.role}
        id={Bmpersona + "_" + r.role}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <input
          className="checkbox"
          style={{ alignContent: "center" }}
          type="checkbox"
          key={Bmpersona + "|" + r.role}
          id={Bmpersona + "|" + r.role}
          defaultChecked={checkChecked(Bmpersona, r)}
          checked={checkDefaultChecked(Bmpersona, r)}
          onClick={() => {
            if (document.getElementById(Bmpersona + "|" + r.role).checked) {
              editRoles(persona, true, Bmpersona, r.role);
            } else {
              editRoles(persona, false, Bmpersona, r.role);
            }
          }}
        ></input>
        <label style={{ fontSize: "0.9rem" }}>{r.label}</label>
      </div>
    ));

  let content = (
    <div
      style={{
        flex: "1",
        overflowY: "auto"
      }}
      // className="UI-topic-list-container-search"
    >
      <div
      // className="grid-container"
      // className="UI-topic-list"
      >
        {/* className="UI-topic-list hide-scrollbar"> */}
        {props.personaList === undefined || props.personaList.length === 0 ? (
          <p>No Results</p>
        ) : (
          props.personaList.map((p) => (
            <div
              key={"d_" + p.mpersona}
              id={"d_" + p.mpersona}
              style={{
                // height: "2rem",
                padding: "0.25rem 0rem 0.25rem 0rem",
                borderBottom:
                  "1px solid var(--main_screen_topic_separator_line_color)"
              }}
            >
              <UIPersona
                rpersona={p}
                pmpersona={p.mpersona}
                key={p.mpersona}
                mpersona={props.topic?.mpersona || props.mpersona}
                persona={props.topic?.persona || props.persona}
                mtopic={props.topic?.mtopic}
                muid={props.muid}
                version={props.version}
                roles={invRoles}
                onInvited={props.onInvited}
                onClose={props.onClose}
                noBorder={true}
              ></UIPersona>
              <div className="UI-options">
                {invRoles.length > 0 && boxes(p, p.mpersona)}
              </div>
            </div>
          ))
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginLeft: "auto"
        }}
      >
        {errorMsg && (
          <div
            className="confirm-cancel"
            type="noBorder"
            style={{
              color: "red"
            }}
          >
            Select Personas and Roles first
          </div>
        )}
      </div>
    </div>
  );

  return content;
};

export default React.memo(UIPersonaList, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
