import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "contexts/context";

/**
 * TODO:
 * Check that browser supports "onLine"
 * Make use of the ‘ononline’ and ‘onoffline’ events
 * Update globalState accordingly
 */

const Connectivity = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const [pollInterval, setPollInterval] = useState(props.poll_interval);

  useEffect(() => {
    const isConnected = (isConn) => {
      globalState.logging && console.log("CONNECTED: ", isConn);
      !isConn && setPollInterval(props.short_poll_interval);
    };
    const setConn = () => isConnected(true);
    const setDisconn = () => isConnected(false);
    window.addEventListener("offline", setDisconn);
    window.addEventListener("online", setConn);
    return () => {
      window.removeEventListener("offline", setDisconn);
      window.removeEventListener("online", setConn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.short_poll_interval]);

  useEffect(() => {
    // This runs after component is rendered ... first time. The [] indicates "no dependencies"
    // so this is like componentDidMount

    globalState.logging &&
      console.log("[Connectivity] pollInterval", pollInterval);

    const checkConnectivity = (url) => {
      var ifConnected = window.navigator.onLine;
      if (ifConnected) {
        fetch(url, {
          // Check for internet connectivity
          method: "HEAD",
          mode: "no-cors"
        })
          .then(() => {
            // set global state connection good
            globalDispatch({
              type: "SET_CONNECTION",
              values: { connected: true }
            });
            setPollInterval(props.poll_interval);
            globalState.logging && console.log("Connectivity all good");
          })
          .catch(() => {
            // set global state connection 50-50
            globalDispatch({
              type: "SET_CONNECTION",
              values: { connected: false }
            });
            setPollInterval(props.short_poll_interval);
            globalState.logging &&
              console.log("Connectivity cannot reach site");
          });
      } else {
        // set global state connection lost
        globalDispatch({
          type: "SET_CONNECTION",
          values: { connected: false }
        });
        globalState.logging && console.log("Connectivity not connected");
      }
    };

    checkConnectivity(props.poll_address);

    let poll = setInterval(() => {
      checkConnectivity(props.poll_address);
    }, pollInterval);

    return () => {
      clearInterval(poll);
    };
  }, [
    globalDispatch,
    props.poll_address,
    pollInterval,
    props.short_poll_interval
  ]);

  let content = <div></div>;
  return content;
};

export default React.memo(Connectivity, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
