import React, { useState, useMemo, useLayoutEffect } from "react";
import "ui/UI.css";
import "ui/UIArrows.css";
import "ui/UIButtons.css";

const isEqual = require("react-fast-compare");

// TODO:
// onScrollChange -> use to slow down scroll.
// Add button to pinned msgs

let lastMsg = null;
let firstUnread = null;

const UIGoToButton = (props) => {
  const { refs, lastMsgInView, firstUnreadKey, lastMsgKey } = props;
  //console.log("[UIGoToButton]:props:", props);

  const [toReadStillCnt, setToReadCnt] = useState(0);
  const [btnClass, setBtnClass] = useState("UI-new-msg-count-off");

  // turn button on/off
  // ==================

  useLayoutEffect(() => {
    if (!lastMsgKey?.current) {
      setBtnClass("UI-new-msg-count-off");
    } else {
      lastMsgInView
        ? setBtnClass("UI-new-msg-count-off")
        : setBtnClass("UI-new-msg-count-on");
    }

    //console.log("[UIGoToButton] button on/off", btnClass);

    return () => {};
  }, [lastMsgInView, lastMsgKey, btnClass]);

  // get important refs and counters
  // ===============================

  useLayoutEffect(() => {
    //console.log('[UIGoToButton] sort msg references: ');
    let unreadCount = 0;
    let refIdx = new Array(Object.keys[refs]?.length);

    Object.keys(refs).forEach((refkey) => {
      refs[refkey].current &&
        refs[refkey].current?.getAttribute("isread") === "false" &&
        unreadCount++;

      let idx = refs[refkey].current?.getAttribute("idx");
      refIdx[refs[refkey].current?.getAttribute("idx")] = {
        "idx": idx,
        "key": refs[refkey]
        //'isread': refs[refkey].current?.getAttribute('isread')
      };
    });

    lastMsgKey?.current && refs[lastMsgKey?.current]
      ? (lastMsg = refs[lastMsgKey.current])
      : (lastMsg = refIdx[refIdx.length - 1]?.key);
    //console.log('[UIGoToButton] get lastMsg: ', lastMsg);

    setToReadCnt(unreadCount);
    //console.log('[UIGoToButton] get unreadCount: ', unreadCount);

    firstUnread = refs[firstUnreadKey.current?.ref];
    //console.log('[UIGoToButton] get firstUnread: ', firstUnread);

    return () => {};
  }, [refs, lastMsgInView, firstUnreadKey, lastMsgKey]);

  // onClick
  // =======

  const scrollToMsg = useMemo(
    () => (msgRef) => {
      //console.log('[UIGoToButton] scrollToMsg: msgRef: ', msgRef);
      msgRef?.current?.scrollIntoView();
    },
    []
  );

  const moveToUnread = useMemo(
    () => () => {
      //console.log('[UIGoToButton] moveToFirstUnread: ', firstUnread);
      firstUnread && toReadStillCnt > 0 && scrollToMsg(firstUnread);
      setToReadCnt(toReadStillCnt - 1);
      firstUnread = null;
    },
    [scrollToMsg, toReadStillCnt]
  );

  const moveToLastRead = useMemo(
    () => () => {
      //console.log('[UIGoToButton] moveToLastRead: ', lastMsg);
      scrollToMsg(lastMsg);
    },
    [scrollToMsg]
  );

  // display elements
  // ================

  const counterButton = (
    <button
      id="counterButton"
      className={lastMsgInView ? "UI-new-msg-count-off" : `${btnClass}`}
      onClick={
        firstUnread?.current && toReadStillCnt > 0
          ? moveToUnread
          : moveToLastRead
      }
    >
      {toReadStillCnt > 0 && (
        <i className="UI-new-msg-text" type="text">
          {toReadStillCnt}
        </i>
      )}
      <i className="chevron-arrow down"></i>
    </button>
  );

  //console.log("[UIGoToButton] BUTTON: ", counterButton);

  return counterButton;
};

export default React.memo(UIGoToButton, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
