import React from "react";
import useUserState from "contexts/useUserState";
import UserContext from "contexts/contextUser";
import Auth from "auth/Auth";

const UserStateProvider = ({ children }) => {
  const stateUser = {
    idToken: localStorage.getItem("idToken"),
    accessToken: localStorage.getItem("accessToken"),
    refreshToken: localStorage.getItem("refreshToken"),
  };

  let wrapped =
    localStorage.getItem("use_keycloak") === "true" ? (
      <Auth>{children}</Auth>
    ) : (
      children
    );

  return (
    <UserContext.Provider value={useUserState(stateUser)}>
      {wrapped}
    </UserContext.Provider>
  );
};

export default UserStateProvider;
