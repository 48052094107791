export const ratioOverlap = (container, item) => {
  let containerRect = container.getBoundingClientRect();
  let itemRect = item.getBoundingClientRect();

  let top = Math.min(
    containerRect.bottom,
    Math.max(itemRect.top, containerRect.top)
  );
  let bottom = Math.max(
    containerRect.top,
    Math.min(itemRect.bottom, containerRect.bottom)
  );
  let heightRatio = Math.max(0, (bottom - top) / itemRect.height);
  let heightRatioOfPossible = Math.max(
    0,
    (bottom - top) / Math.min(itemRect.height, containerRect.height)
  );
  let topIsVisible =
    itemRect.top >= containerRect.top && itemRect.top <= containerRect.bottom;
  let bottomIsVisible =
    itemRect.bottom >= containerRect.top &&
    itemRect.bottom <= containerRect.bottom;

  let left = Math.min(
    containerRect.right,
    Math.max(itemRect.left, containerRect.left)
  );
  let right = Math.max(
    containerRect.left,
    Math.min(itemRect.right, containerRect.right)
  );
  let widthRatio = Math.max(0, (right - left) / itemRect.width);
  let widthRatioOfPossible = Math.max(
    0,
    (right - left) / Math.min(itemRect.width, containerRect.width)
  );

  return {
    heightRatio: heightRatio,
    widthRatio: widthRatio,
    heightRatioOfPossible: heightRatioOfPossible,
    widthRatioOfPossible: widthRatioOfPossible,
    topIsVisible: topIsVisible,
    bottomIsVisible: bottomIsVisible,
  };
};
