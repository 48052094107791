import { useReducer } from "react";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  shouldLog() && console.log("SET topic", state, action);
  switch (action.type) {
    case "SET_STICKYIDX":
      if (isEqual(state.stickyidx, action.values.stickyidx)) return state;
      else
        return {
          ...state,
          stickyidx: action.values.stickyidx
        };
    case "SET_PERSONAS_RX":
      if (isEqual(state.personas_rx, action.values.personas_rx)) return state;
      else
        return {
          ...state,
          personas_rx: action.values.personas_rx
        };
    case "SET_REPLY_TO":
      // console.log("!!!SET_REPLY_TO", action);
      if (isEqual(state.reply_to, action.values.reply_to)) return state;
      else
        return {
          ...state,
          reply_to: action.values.reply_to
        };

    case "SET_PARTICIPANTS":
      if (isEqual(state.participants, action.values.participants)) return state;
      else
        return {
          ...state,
          participants: action.values.participants
        };
    case "SET_RECEIPTS":
      if (isEqual(state.receipts, action.values.receipts)) return state;
      else
        return {
          ...state,
          receipts: action.values.receipts
        };
    case "SET_QUOTE":
      if (isEqual(state.quote, action.values.quote)) return state;
      else
        return {
          ...state,
          quote: action.values.quote
        };
    case "SET_CURRENT_TOPIC":
      if (isEqual(state.currentTopic, action.values.currentTopic)) return state;
      else
        return {
          ...state,
          currentTopic: action.values.currentTopic
        };
    case "SET_MESSAGE":
      if (isEqual(state.message, action.values.message)) return state;
      else
        return {
          ...state,
          message: action.values.message
        };
    case "SET_MESSAGES":
      if (isEqual(state.messages, action.values.messages)) return state;
      else
        return {
          ...state,
          messages: action.values.messages
        };
    // case "SET_COORDS":
    //   if (isEqual(state.ycoord, action.values.ycoord)) return state;
    //   else
    //     return {
    //       ...state,
    //       ycoord: action.values.ycoord
    //     };
    case "SET_LOGO":
      if (isEqual(state.logo, action.values.logo)) return state;
      else
        return {
          ...state,
          logo: action.values.logo
        };
    case "SET_LOGO_IMAGE":
      if (isEqual(state.logo_image, action.values.logo_image)) return state;
      else
        return {
          ...state,
          logo_image: action.values.logo_image
        };
    default: {
      return state;
    }
  }
};

const useTopicState = (state) => {
  const [topicState, topicDispatch] = useReducer(reducer, state);
  return { topicState, topicDispatch };
};

export default useTopicState;
