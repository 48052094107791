import React from "react";
import useLiveCacheState from "data/useLiveCacheState";
import LiveCacheContext from "data/contextLiveCache";

const LiveCacheStateProvider = ({ children }) => {
  const stateLiveCache = {};

  return (
    <LiveCacheContext.Provider value={useLiveCacheState(stateLiveCache)}>
      {children}
    </LiveCacheContext.Provider>
  );
};

export default LiveCacheStateProvider;
