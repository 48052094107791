import React, { useEffect, useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "ui/react-datepicker-override.css";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { createTheme, ThemeProvider } from '@material-ui/core';
import "ui/UI.css";
import StyleContext from "contexts/contextStyle";

const DateSelector = (props) => {

  const { styleState } = useContext(StyleContext);
  const [startDate, setStartDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [highlighted, setHighlighted] = useState(false);

  const theme = createTheme({
    overrides: {
      MuiDialog: {
        paper: {
          border: styleState?.theme?.colors?.menu_border_color,
          borderWidth: "1px",
          borderStyle: "solid",
          borderRadius: "var(--border_radius_large)"
        }
      },
      MuiPickersToolbar: {
        root: {},
        toolbar: {
          backgroundColor: styleState?.theme?.colors?.button_border_color
        }
      },
      MuiPickersToolbarText: {
        toolbarBtnSelected: {
          color: styleState?.theme?.colors?.button_text_color,
          opacity: "1"
        },
        toolbarTxt: {
          color: styleState?.theme?.colors?.button_text_color,
          opacity: "0.5"
        }
      },
      MuiPickerDTTabs: {
        tabs: {
          backgroundColor: styleState?.theme?.colors?.button_border_color
        }
      },
      MuiTab: {
        fullWidth: {
          backgroundColor: styleState?.theme?.colors?.button_border_color
        },
        textColorInherit: {
          // transition: "backgroundColor 4s ease-out, opacity 4s ease",
          // transitionDelay: "2s",
          transition: "opacity 1s ease",
          opacity: "0.5"
        },
        root: {
          backgroundColor: styleState?.theme?.colors?.button_border_color,
          opacity: "1"
        }
      },
      MuiSvgIcon: {
        root: {
          fontSize: "2.5rem"
        }
      },
      PrivateTabIndicator: {
        colorSecondary: {
          backgroundColor: "none"
        }
      },
      MuiPickersClock: {
        pin: {
          backgroundColor: styleState?.theme?.colors?.button_background_color
        }
      },
      MuiPickersClockPointer: {
        pointer: {
          backgroundColor: styleState?.theme?.colors?.button_background_color
        },
        thumb: {
          border: styleState?.theme?.colors?.button_background_color,
          borderWidth: "14px",
          borderStyle: "solid",
          backgroundColor: styleState?.theme?.colors?.button_background_color
        }
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "transparent"
        }
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor:
            styleState?.theme?.colors?.menu_background_selected_color,
          border: styleState?.theme?.colors?.button_background_color,
          borderWidth: "1px",
          borderStyle: "solid",
          color: "black",
          "&:hover": {
            backgroundColor:
              styleState?.theme?.colors?.menu_background_selected_color
          }
        }
      },
      MuiButton: {
        textPrimary: {
          backgroundColor: "white",
          background: `linear-gradient(to top, ${styleState?.theme?.colors?.button_background_color}, ${styleState?.theme?.colors?.button_background_gradient_color})`,
          color: styleState?.theme?.colors?.button_text_color,
          border: styleState?.theme?.colors?.button_border_color,
          borderWidth: "0.06rem",
          borderStyle: "solid",
          minWidth: "6rem",
          maxWidth: "6rem",
          height: "1.5rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          borderRadius: "var(--border_radius_small)",
          boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.25)",
          "&:hover": {
            color: "black",
            background: `linear-gradient(to top, ${styleState?.theme?.colors?.button_background_color}, white)`
          }
        }
      },
      MuiInputLabel: {
        root: {
          "&$focused": {
            color: styleState?.theme?.colors?.menu_border_color // Change this to the color you want
          }
        }
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottomColor: styleState?.theme?.colors?.menu_border_color // Change this to the color you want
          },
          "&:hover:not($disabled):before": {
            borderBottomColor: styleState?.theme?.colors?.menu_border_color // Change this to the hover color you want
          },
          "&:after": {
            borderBottomColor: styleState?.theme?.colors?.menu_border_color // Change this to the color you want
          }
        }
      },
      MuiTypography: {
        subtitle1: {
          color: "black"
        }
      },
      MuiPickersBasePicker: {
        pickerView: {
          minHeight: "325px"
        }
      },
      MuiDialogActions: {
        root: {
          backgroundColor: "transparent"
        }
      }
    },
    typography: {
      fontFamily: "Segoe UI"
    },
    components: {
      // MuiButton: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#202020',
      //       color: '#fff',
      //     }
      //   },
      // },
      // MuiDialogActions: {
      //   root: {
      //     backgroundColor: "lightbrown"
      //   },
      //   MuiButton: {
      //     root: {
      //       backgroundColor: "green"
      //     }
      //   },
      // },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Segoe UI';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
          }
        `
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: "#6A148E",
          textTransform: "uppercase"
        },
        dayLabel: {
          textTransform: "uppercase"
        }
      }
    }
  });

  const handleChange = (e) => {
    setStartDate(e);
    props.onChange(e);
  };

  useEffect(() => {
    if (props.selected && props.selected !== "" && props.selected !== undefined)
      setStartDate(props.selected);
    else {
      //if anything is set here the field in the modal will become populated
      //current thinking is that the modal field should start with no date
      //the commented code can set default dates and times
      //and can round minutes to closest 5 minute mark

      // const now = new Date();
      // const minutes = now.getMinutes();
      // const remainder = minutes % 5;
      // const initialDate = new Date(now.getTime() + (5 - remainder) * 60000);
      // setStartDate(initialDate);
      setStartDate(null);
      // const defaultTime = new Date(); // Get the current date and time
      // defaultTime.setHours(8, 0, 0, 0); // Set the time to 08:00
      // setDefaultStartDate(defaultTime)
    }
  }, [props.selected]);

  return (
    <div
      onClick={() => {
        setIsOpen(true);
      }}
      style={{
        direction: "ltr",
        display: isOpen ? true : false,
        marginLeft: "0.75rem"
      }}
    >
      {
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={theme}>
            <DateTimePicker
              variant="dialog"
              placeholder="Enter date and time"
              sdisableToolbar
              format={props.dateFormat}
              ampm={false}
              label={props.label}
              cancelLabel={"CANCEL"}
              okLabel={"OK"}
              minutesStep={5}
              value={startDate}
              disablePast={props.usePast === true ? false : true}
              disableFuture={props.usePast === true ? true : false}
              onChange={handleChange}
              onOpen={() =>
                props.setClickOutsideActive &&
                props.setClickOutsideActive(false)
              }
              onClose={() =>
                props.setClickOutsideActive && props.setClickOutsideActive(true)
              }
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      }
    </div>
  );
};

export default DateSelector;
