import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import "ui/UI.css";
import GlobalContext from "contexts/context";
import FocusContext from "contexts/contextFocus";
import TopicsContext from "contexts/contextTopics";
import TopicContext from "contexts/contextTopic";
import DatabaseContext from "data/contextDatabase";

import UIMessageSend from "ui/UIMessageSend";

import "ui/Dropdown/dropdownTopicMenu.css";
import UIReportModal from "ui/UIReportModal";
import UITopicHeader from "ui/UITopicHeader";
import UIMsgList from "ui/UIMsgList";
import { timestamp } from "hooks/helper";

import {
  existsImage64,
  getDescriptor,
  getImage64,
  storeImage
} from "data/descriptors";
import {
  useLiveMessages,
  useLiveParticipants,
  useLiveTopicCount
} from "hooks/dexHooks";
import { useHistory } from "react-router-dom";
import { s3ToImage } from "connectivity/s3";
import { dex_action } from "data/dexUtils";

const isEqual = require("react-fast-compare");

const UITopicContainer = (props) => {
  const selected = props.selected;
  const metadata = props.metadata || props.location?.state;
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { focusState } = useContext(FocusContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { topicDispatch } = useContext(TopicContext);
  const { databaseState } = useContext(DatabaseContext);
  const [reporting] = useState(false);
  const [headerSize, setHeaderSize] = useState(undefined);
  // const [description, setDescription] = useState(
  //   props.metadata?.topicDescription
  // );
  // const [origHeaderSize, setOrigHeaderSize] = useState("");
  // const [origDescription, setOrigDescription] = useState("");
  const sendbarContainer = useRef(null);
  const hgtRef = useRef(null);
  const history = useHistory();
  // "are there pinned messages?" is initially answered by looking at the topic from w.t.list
  // Thereafter, kept up to date by checking liveMessages_ in UIMsgList
  const [pinnedLiveMessages, setPinnedLiveMessages] = useState(
    metadata?.subscription?.pinned && metadata.subscription.pinned.length > 0
  );
  const[hdrHgt,setHdrHgt]=useState()
  const [msgPinnedDiv, setMsgPinnedDiv] = useState(undefined);
  const [msgDiv, setMsgDiv] = useState(undefined);
  const t =
    metadata?.subscription?.mtopic ||
    metadata?.topic?.mtopic ||
    metadata?.dialog?.mdialog;
  const [topicContainerHgt, setTopicContainerHgt] = useState(0);
  const [currentDigests, setCurrentDigests] = useState([]);

  const { liveSmallAccount } = useLiveTopicCount(databaseState.dexUser);

  // *** Code to set current conversation (used for determining notifications)
  useEffect(() => {
    globalDispatch({
      type: "SET_CURRENT_TOPIC",
      values: {
        displaying_topic:
          metadata?.subscription?.mtopic ?? metadata?.dialog?.mdialog
      }
    });
    return () => {
      // Reset current dialog to undefined
      globalDispatch({
        type: "SET_CURRENT_TOPIC",
        values: { displaying_topic: undefined }
      });
    };
  }, [
    globalDispatch,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(metadata?.subscription),
    JSON.stringify(metadata?.dialog)
  ]);

  useEffect(() => {
    setMsgPinnedDiv(
      pinnedLiveMessages ? (
        <UIMsgList
          selectTopic={props.selectTopic}
          topic={metadata?.subscription || metadata?.topic || metadata?.dialog}
          pin={1} // this "1" should ALWAYS match the "1" in pinnedLiveMessages at the top of this component
          sense={"ge"}
          requestHistory={false}
          topicContainerHgt={topicContainerHgt}
          hdrHgt={hdrHgt}
        />
      ) : undefined
    );
    setMsgDiv(
      <UIMsgList
        selectTopic={props.selectTopic}
        topic={metadata?.subscription || metadata?.topic || metadata?.dialog}
        pin={0}
        sense={"le"}
        requestHistory={true}
        topicContainerHgt={topicContainerHgt}
        hdrHgt={hdrHgt}
      />
    );
  }, [
    metadata?.dialog,
    metadata?.subscription,
    metadata?.topic,
    pinnedLiveMessages,
    props?.selectTopic,
    topicContainerHgt
  ]);

  useEffect(() => {
    let pollParticipants = setInterval(() => {
      if (focusState.hasFocus) {
        let j = {
          type: "w.t.participants",
          mtopic: t,
          version: globalState.version,
          smid: `${"participants"}|${t}`,
          ts_sender: timestamp()
        };

        liveSmallAccount &&
          databaseState.dexUser &&
          t &&
          t.startsWith("t_") &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "send",
              doc: { ...j }
            }
          });
      }
    }, 10000);
    return () => {
      clearInterval(pollParticipants);
    };
  }, [
    liveSmallAccount,
    databaseState.dexUser,
    globalState.version,
    focusState.hasFocus,
    t
  ]);

  var liveParticipants = useLiveParticipants(databaseState.dexUser, t);

  var { liveMessages, liveItems } = useLiveMessages(
    databaseState.dexUser,
    {
      pin: 0,
      sense: "ge",
      topic: metadata?.subscription || metadata?.topic || metadata?.dialog,
      mpersona: metadata?.subscription?.mpersona
    },
    t,
    1000
  );

  // useEffect(() => {
  //   return () => {
  //     liveMessages = undefined;
  //     liveItems = undefined;
  //     topicDispatch({
  //       type: "SET_MESSAGES",
  //       values: {
  //         messages: {
  //           unpinned: [],
  //           pinned: [],
  //           items: []
  //         }
  //       }
  //     });
  //   };
  // }, []);

  useEffect(() => {
    let j = {
      type: "w.t.participants",
      mtopic: t,
      version: globalState.version,
      smid: `${"participants"}|${t}`,
      ts_sender: timestamp()
    };

    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: { ...j }
        }
      });
  }, [databaseState.dexUser, globalState.version, t]);

  useEffect(() => {
    let mounted = true;
    if (liveMessages) {
      let messagesPinned = liveMessages.filter((m) => m.parameters?.pin > 0);
      setPinnedLiveMessages(messagesPinned.length);

      topicDispatch({
        type: "SET_MESSAGES",
        values: {
          messages: {
            unpinned: liveMessages.filter(
              (m) => !m.parameters?.pin && !m.hide && !m.parameters?.display
            ),
            pinned: liveMessages.filter(
              (m) => m.parameters?.pin > 0 && !m.hide && !m.parameters?.display
            ),
            items: [...liveItems]
            // from: "UITopicContainer"
          }
        }
      });

      let listDigest = liveMessages.reduce((acc, msg) => {
        if (msg.mpersona && msg.parameters?.descriptor) {
          return [
            ...acc,
            { mpersona: msg.mpersona, descriptor: msg.parameters?.descriptor }
          ];
        }
        return acc;
      }, []);

      let uniqueDigests = [...new Set(listDigest.map(JSON.stringify))].map(
        JSON.parse
      );
      if (!isEqual(uniqueDigests, currentDigests)) {
        setCurrentDigests(uniqueDigests);
      }
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveMessages, liveItems]);

  useEffect(() => {
    let mounted = true;
    if (liveParticipants) {
      topicDispatch({
        type: "SET_PARTICIPANTS",
        values: {
          participants: liveParticipants?.personas
        }
      });
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveParticipants?.personas]);

  useEffect(() => {
    // preload the image from each unique descriptor in the topic list
    let mounted = true;
    currentDigests.forEach((uDigest) => {
      mounted &&
        uDigest.descriptor &&
        getDescriptor(
          databaseState.dexUser,
          "descriptor",
          uDigest.mpersona,
          uDigest.descriptor
        ).then((d) => {
          if (d) {
            d?.thumbdigest &&
              mounted &&
              existsImage64(databaseState.dexUser, d.thumbdigest)
                .then((result) => {
                  // console.log("[UITopicContainer] existsImage64", result);
                  if (result) {
                    // console.log("[UITopicContainer] result", result);
                  } else {
                    mounted &&
                      s3ToImage(uDigest.mpersona, d.thumbdigest).then(
                        (image) => {
                          // console.log("[UITopicContainer] inage", image);
                          if (image?.b64) {
                            mounted && storeImage(databaseState.dexUser, image);
                          }
                        }
                      );
                  }
                })
                .catch((err) => {
                  console.log("CATCH", err);
                });
          }
        });
    });
    return () => {
      mounted = false;
    };
  }, [currentDigests, databaseState.dexUser]);

  useEffect(() => {
    const setDefaults = () => {
      topicDispatch({
        type: "SET_LOGO_IMAGE",
        values: { logo_image: undefined }
      });
      topicDispatch({
        type: "SET_LOGO",
        values: { mtopic: t, logo: undefined }
      });
    };
    // CONSUMING THE TOPIC DESCRIPTOR
    let isMounted = true;
    if (topicsState.topics && t && t.startsWith("t_")) {
      let topicList = topicsState.topics.filter((top) => top.mtopic === t);
      if (topicList[0]?.props?.descriptor?.digest) {
        // do we have the descriptor? if not, then fetch
        getDescriptor(
          databaseState.dexUser,
          "topic_descriptor",
          t,
          topicList[0]?.props?.descriptor.digest
        ).then(async (d) => {
          if (d.mtopic) {
            globalState.logging &&
              console.log("CONSUMING_THE_TOPIC_DESCRIPTOR", d);
            // then apply
            if (isMounted) {
              // set the header size
              // if (d.headerSize) {
              // setHeaderSize(d.headerSize);
              // setOrigHeaderSize(d.headerSize);
              // }
              // else {
              //   setHeaderSize("medium");
              //   setOrigHeaderSize("medium");
              // }

              //set the description
              // d.description ? setDescription(d.description)
              //   : setDescription(undefined)
              // setOrigDescription(d.description);
              //set the logo
              if (d.logo?.thumbdigest) {
                try {
                  let image64 = await getImage64(
                    databaseState.dexUser,
                    d.logo?.thumbdigest
                  );

                  if (!image64 && d.logo?.thumbdigest) {
                    let scope = d.logo.thumbpath.split("/")[1];
                    let i = await s3ToImage(scope, d.logo.thumbdigest);
                    if (i?.b64) {
                      // cacheMpersonaThumb(drec.mpersona, i?.b64);
                      topicDispatch({
                        type: "SET_LOGO_IMAGE",
                        values: { logo_image: i?.b64 }
                      });
                      topicsDispatch({
                        type: "SET_LOGO",
                        values: { mtopic: t, logo: i?.b64 }
                      });
                      storeImage(databaseState.dexUser, i);
                    }
                  } else if (image64) {
                    topicDispatch({
                      type: "SET_LOGO_IMAGE",
                      values: { logo_image: image64 }
                    });
                    //cacheMpersonaThumb(drec.mpersona, image64);
                  }
                } catch (err) {
                  console.log(err);
                }
              } else {
                setDefaults();
              }
            } else {
              setDefaults();
            }
          }
        });
      } else {
        setDefaults();
      }
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseState.dexUser, topicsState.topics, t]);

  useEffect(() => {
    function handleResize() {
      if (hgtRef.current) {
        setTopicContainerHgt(hgtRef.current.offsetHeight);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the height

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [hgtRef]);

  // useEffect(() => {
  //   if (hgtRef.current) {
  //     setTopicContainerHgt(hgtRef.current.offsetHeight);
  //   }
  // }, [hgtRef?.current?.offsetHeight]);

  // *** End of code to set current conversation (used for determining notifications)

  // if metadata is undefined, then go to topic selection page
  if (metadata === undefined) {
    (props.history || history).push("/");
    return null;
  }

  const softkbdStyle = {
    position: "absolute",
    marginBottom: "0.25rem"
  };

  let messageSend;
  if (metadata.dialog === undefined) {
    messageSend =
      !globalState?.prevent_sending &&
      (metadata.subscription?.got_voice === "true" ||
        ((metadata.subscription?.roles?.includes("tx") ||
          metadata.subscription?.roles?.includes("whisper")) &&
          metadata.subscription?.props?.topictype !== "service"))
      ? (
        <div className="sendbar" key="msgSend">
          <UIMessageSend
            mtopic={metadata.subscription.mtopic}
            topic={metadata.subscription.topic}
            persona={metadata.subscription.persona}
            mpersona={metadata.subscription.mpersona}
            name={metadata.subscription.persona}
            topic_display_name={
              metadata.subscription.topic_display_name ||
              metadata.subscription.props?.topic_display_name ||
              ""
            }
          />
        </div>
      ) : (
        <div key="msgSend" />
      );
  } else {
    let marray = metadata.dialog.mdialog.split("/");
    let tag = marray.length === 2 ? marray[1] : "";
    let topic_display_name =
      metadata.dialog.dialog !== metadata.dialog.persona &&
      metadata.dialog.dialog !== metadata.dialog.peerpersona
        ? metadata.dialog.dialog
        : "";
    messageSend =
      !globalState?.prevent_sending && metadata.dialog?.got_voice ? (
        <div
          ref={sendbarContainer}
          className="sendbar"
          key="msgSend"
          style={softkbdStyle}
          // style={changeHgt ? softkbdStyle : {}}
        >
          <UIMessageSend
            mdialog={metadata.dialog.mdialog}
            dialog={metadata.dialog.dialog}
            persona={metadata.dialog.persona}
            mpersona={metadata.dialog.mpersona}
            name={metadata.dialog.persona}
            peer={metadata.dialog.peer}
            tag={tag}
            topic_display_name={topic_display_name}
          />
        </div>
      ) : (
        <div key="msgSend" />
      );
  }

  let content = (
    <div
      className="UI-topic-container"
      ref={hgtRef}
      key={
        metadata.subscription?.mtopic ||
        metadata.topic?.mtopic ||
        metadata.dialog?.mdialog
      }
    >
      {reporting && <UIReportModal></UIReportModal>}
      <UITopicHeader
        mtopic={t}
        hdrHgt={(val)=>setHdrHgt(val)}
        preview={false}
        topic={metadata.subscription || metadata.topic || metadata.dialog}
        selectTopic={props.selectTopic}
        showback={props.showback}
      />
      {msgPinnedDiv}
      {msgDiv}
      {(selected || !props.selectTopic) && messageSend}
    </div>
  );

  return content;
};;

export default React.memo(UITopicContainer, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
