import { useReducer } from "react";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  shouldLog() && console.log("SET thumbs", state, action);
  switch (action.type) {
    case "SET_THUMBS":
      if (isEqual(state.thumbs, action.values.thumbs)) return state;
      else
        return {
          ...state,
          thumbs: action.values.thumbs
        };
    default: {
      return state;
    }
  }
};

const useThumbsState = (state) => {
  const [thumbsState, thumbsDispatch] = useReducer(reducer, state);
  return { thumbsState, thumbsDispatch };
};

export default useThumbsState;
