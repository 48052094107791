export const isVisible = (msg) => {
  if (msg.hide) {
    return false;
  } // hidden, so not for normal display
  if (["w.deleted"].includes(msg.content?.msgtype)) {
    return false;
  }
  if (["false", false].includes(msg.parameters?.display)) {
    return false;
  }
  return true;
};

export const isUnread = (msg) =>
  msg.msg_idx &&
  !["t", "true", true].includes(msg.read) &&
  !["t", "true", true].includes(msg.hide) &&
  // !msg.parameters?.control_payload &&
  !["w.deleted"].includes(msg.content?.msgtype) &&
  !["false", false].includes(msg.parameters?.display) &&
  !["t", "true", true].includes(msg.parameters?.system_msg);

export const requestRead = (msg) =>
  ["t", "true", true].includes(msg.request_read);
// ||
// msg.parameters?.control_payload;

export const isRead = (msg, mpersonas, lastRead) => {
  if (msg.recipient === msg.mpersona) {
    return true;
  } // own message
  if (mpersonas?.includes(msg.mpersona)) {
    return true;
  } // sent by one of your other mpersonas
  if (["t", "true", true].includes(msg.read)) {
    return true;
  } // multiple ways of marking as true
  if (msg.msg_idx <= lastRead) {
    return true;
  } // earlier than your last read message
  if (msg.parameters?.control_payload) {
    return true;
  } // contains a control payload, so not for normal display
  if (msg.hide) {
    return true;
  } // hidden, so not for normal display
  if (["w.deleted"].includes(msg.content?.msgtype)) {
    return true;
  }
  if (msg.parameters?.system_msg) {
    return true;
  } // eg: someone joined or left
  if (["false", false].includes(msg.parameters?.display)) {
    return true;
  }
  return false;
};

export const isHide = (msg) => {
  if (
    msg.parameters?.display &&
    ["false", false].includes(msg.parameters?.display)
  ) {
    return true;
  }
  if (["w.deleted"].includes(msg.content?.msgtype)) {
    return true;
  }
  if (msg.parameters?.control_payload) {
    return true;
  } // contains a control payload, so not for normal display
  if (msg.hide) {
    return true;
  } // hidden, so not for normal display
  return false;
};

// export const lastReadMeta = async (dexUser, mtopic, mpersona) => {
//   return await dexUser.topic_metadata
//     .get({ mtopic: mtopic, mpersona: mpersona })
//     .then((topicMetadata) => {
//       return topicMetadata?.lastRead || 0;
//     })
//     .catch((e) => {
//       console.error(e);
//       return 0;
//     });
// };

export const lastReadMessage = async (dexUser, mtopic, mpersona) => {
  return await dexUser.message
    .where(["mtopic", "recipient"])
    .equals([mtopic, mpersona])
    .filter(
      (doc) =>
        ["t", "true", true].includes(doc.read) &&
        !doc.hide &&
        !doc.parameters?.control_payload &&
        !!parseInt(doc.msg_idx, 10) &&
        !["w.deleted"].includes(doc.content?.msgtype) &&
        !(
          doc.parameters?.display &&
          ["false", false].includes(doc.parameters?.display)
        ) &&
        ["t", "true", true].includes(doc.parameters?.system_msg)
    )
    .toArray((doc) =>
      doc.reduce(
        (acc, d) =>
          parseInt(d.msg_idx, 10) > acc ? parseInt(d.msg_idx, 10) : acc,
        0
      )
    )
    .then((lastReadIdx) => {
      return lastReadIdx;
    })
    .catch((e) => {
      console.log(e);
      return 0;
    });
};
