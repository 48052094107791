import React from "react";
import isEqual from "react-fast-compare";

const UIReceipt = (props) => {
  const { msg_idx, receipts } = props;
  let summary = receipts
    ? Object.keys(receipts)?.reduce(
        (acc, key) => {
          let r = {
            read:
              receipts[key]?.read === msg_idx &&
                key !== props.sender
                // &&
              // key !== props.recipient
                ? [...acc.read, receipts[key]?.persona]
                : acc.read,

            delivered:
              receipts[key]?.delivered === msg_idx &&
                // receipts[key]?.read !== msg_idx &&
                //above commented - delivered to include read
                key !== props.sender
                // &&
              // key !== props.recipient
                ? [...acc.delivered, receipts[key]?.persona]
                : acc.delivered
          };
          return r;
        },
        { read: [], delivered: [] }
      )
    : undefined;
  let receipt = <span></span>;

  const lim = 3;
  // visualisation idea: pie chart showing empty circle if not delivered/read
  if (summary?.read.length || summary?.delivered.length) {
    receipt = (
      <span
        style={{ marginRight: "1rem" }}>
        {/* <pre
          style={{
            margin: "0px",
            textAlign: "left"
          }}
        >{`  ${summary?.read.length ? "read by: " + summary.read.slice(0, lim) : ""}
        ${summary.read.length > lim ? "..." : ""}
        ${summary?.delivered.length
            ? "delivered to: " + summary.delivered.slice(0, lim)
              : ""
            }${summary.delivered.length > lim ? "..." : ""}`}</pre> */}
        {<span>Delivered: {summary.delivered?.length}</span>}
        {<span style={{ marginLeft: "1rem" }}>Read: {summary.read?.length} </span>}

        {/* 
          {`Not delivered: ${props.participants - summary?.delivered.length - summary.read.length}`}
         */}
        {/* {<span title="Number of participants">`({props.participants})`</span>} */}
      </span>
    );
  }

  return receipt;
};

export default React.memo(UIReceipt, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
