import { useReducer } from "react";
import isEqual from "react-fast-compare";

const reducer = (state, action) => {
  //console.log("SET cache", state, action);
  switch (action.type) {
    case "SET":
      if (isEqual(state.cache, action.values.cache)) return state;
      else
        return {
          ...state,
          ...action.values.cache
        };
    case "DELETE":
      let newState = { ...state };
      delete newState[action.values.key];
      if (isEqual(state, newState)) return state;
      else
        return {
          newState
        };
    default: {
      // console.log("Location default...");
      return state;
    }
  }
};

const useLiveCacheState = (state) => {
  const [liveCacheState, liveCacheDispatch] = useReducer(reducer, state);
  return { liveCacheState, liveCacheDispatch };
};

export default useLiveCacheState;
