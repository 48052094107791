import React, { useEffect, useContext, useMemo } from "react";
import UserContext from "contexts/contextUser";
import Keycloak from "keycloak-js";

const _kc = new Keycloak("./keycloak.json");

const Auth = ({ children }) => {
  const { userState, userDispatch } = useContext(UserContext);

  const refresh = useMemo(
    () => () =>
      setTimeout(() => {
        _kc
          .updateToken(20)
          .success((refreshed) => {
            if (refreshed) {
              console.debug("Token refreshed" + refreshed);
              localStorage.setItem("accessToken", _kc.token);
              localStorage.setItem("idToken", _kc.idToken);
              localStorage.setItem("refreshToken", _kc.refreshToken);
              refresh();
            } else {
              console.warn(
                "Token not refreshed, valid for " +
                  Math.round(
                    _kc.tokenParsed.exp +
                      _kc.timeSkew -
                      new Date().getTime() / 1000
                  ) +
                  " seconds"
              );
              refresh();
            }
          })
          .error(() => {
            console.error("Failed to refresh token");
          });
      }, 200000),
    []
  );

  const initKeycloak = useMemo(
    () => (onAuthenticatedCallback) => {
      console.log(
        "Token initKeycloak",
        localStorage.getItem("idToken"),
        localStorage.getItem("accessToken"),
        localStorage.getItem("refreshToken")
      );
      let token = localStorage.getItem("accessToken");
      let refreshToken = localStorage.getItem("refreshToken");
      let idToken = localStorage.getItem("idToken");
      let jInit = {
        onLoad: "check-sso",
        pkceMethod: "S256",
        token: token,
        refreshToken: refreshToken,
        idToken: idToken,
        silentCheckSsoFallback: true,
        checkLoginIframe: false,
        //   checkLoginIframeInterval: 1,
      };
      if (false && window.location.origin !== "http://localhost:3000")
        jInit.silentCheckSsoRedirectUri =
          window.location.origin + "/silent-check-sso.html";
      _kc
        .init(jInit)
        .then((authenticated) => {
          console.log("Token initialised", authenticated, _kc);
          if (authenticated) {
            console.log("Token auth", authenticated, _kc);
            localStorage.setItem("idToken", _kc.idToken);
            localStorage.setItem("accessToken", _kc.token);
            localStorage.setItem("refreshToken", _kc.refreshToken);
            // set an updateToken process
            refresh();
            onAuthenticatedCallback();
          } else {
            console.log("Token not initialised", _kc);
            _kc.logout();
            _kc.login();
          }
        })
        .catch((err) => console.log("Authentication error", err));
    },
    [refresh]
  );

  const checkConnectivity = async (url) => {
    var ifConnected = window.navigator.onLine;
    if (ifConnected) {
      await fetch(url, {
        // Check for internet connectivity
        mode: "no-cors",
      })
        .then(() => {
          console.log("Connected");
          return true;
        })
        .catch(() => {
          console.log("not connected");
          return false;
        });
    } else {
      console.log("not connected");
      return false;
    }
  };

  // If userState.accessToken is null, go to Keycloak login
  useEffect(() => {
    // initKeycloak initialises with tokens stored in localStorage
    // checkConnectivity(
    //   "https://auth.beta.enabled.world/auth/realms/world/.well-known/openid-configuration"
    // ) &&
    // false &&

    // !_kc.token &&
    checkConnectivity(
      "https://auth.beta.enabled.world/auth/realms/world/.well-known/openid-configuration"
    ).then(
      (r) =>
        (r || !userState.accessToken) &&
        initKeycloak((r) => {
          userDispatch({
            type: "SET_TOKEN",
            values: {
              accessToken: _kc.token,
              parsedToken: _kc.tokenParsed,
              idToken: _kc.idToken,
            },
          });
        })
    );
    return () => {};
  }, [initKeycloak, userDispatch, userState.accessToken]);

  // Only show children if userState.token is available
  return <div>{userState?.accessToken && children}</div>;
};

export default React.memo(Auth, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
