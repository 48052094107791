import React, { useContext, useMemo, useEffect, useState } from "react";
import GlobalContext from "contexts/context";
import TopicsContext from "contexts/contextTopics";
import DatabaseContext from "data/contextDatabase";
import { randomString, timestamp } from "hooks/helper";
import { useHistory } from "react-router-dom";
import { dex_action } from "data/dexUtils";
import LiveCacheContext from "data/contextLiveCache";
import { isMobileTablet } from "hooks/helper";
import StyleContext from "contexts/contextStyle";

const UITopicResult = (props) => {
  const { databaseState } = useContext(DatabaseContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  const { styleState } = useContext(StyleContext);
  const [fontFam, setFontFam] = useState("");
  const [fontWeight, setFontWeight] = useState();

  let history = useHistory();

  const sortJoin = (A, B, Delim) => {
    return A < B ? A + Delim + B : B + Delim + A;
  };

  useEffect(() => {
    let fontName = styleState?.theme?.fonts?.topic_name_main_screen_font;
    if (fontName) {
      setFontFam(fontName.substring(0, fontName.length - 3));
      setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
    }
  }, [styleState]);

  const subscribeTopic = () => {
    let j = {
      version: globalState.version,
      type: "w.t.join",
      source: "link",
      mtopic: props.topic.mtopic,
      smid: randomString(8),
      ts_sender: timestamp(),
      origin_sender: "registered"
    };
    props.persona && (j.persona = props.persona);
    props.mpersona && (j.mpersona = props.mpersona);
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "send",
          doc: j
        }
      });
    props.onClose();
    if (window.location?.href?.includes("modal")) {
      history.goBack();
    }
  };

  const handleAdjPan = (obj) => {
    topicsDispatch({
      type: "SET_SHOWTOPIC",
      values: { showTopic: obj }
    });
    props.onClose();
  };

  const goToTopic = useMemo(
    () => (t) => {
      // we need this info to action the rest...
      let persona = {
        mpersona: t.mpersona,
        persona: t.persona,
        tag: (
          t.tag ||
          t.props?.tag ||
          t.props?.topiccollection ||
          t.props?.visibility ||
          t.props?.topictype ||
          "undefined"
        ).toLowerCase()
      };
      let collectionState = liveCacheState?.collectionState || {};
      process.env.REACT_APP_DEBUG_THIS &&
        console.log(
          "[UISearchMyTopicsModal] topic",
          t,
          "\n\tcollection",
          collectionState,
          "\n\tpersona:",
          persona
        );
      // make sure this topic's collection will be expanded
      if (
        collectionState &&
        collectionState[persona?.tag] &&
        collectionState[persona.tag][persona.mpersona]?.open &&
        collectionState[persona.tag][persona.mpersona].open !== false
      ) {
        collectionState[persona.tag][persona.mpersona].open = false;
        liveCacheDispatch({
          type: "SET",
          values: { cache: { collectionState: collectionState } }
        });
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "user",
              doc: { key: "collectionstate", value: collectionState }
            }
          });
      }
      // and open the topic
      if (t.mpersona !== globalState.persona?.mpersona) {
        globalDispatch({
          type: "SET_PERSONA",
          values: {
            persona: persona
          }
        });
      }
      if (isMobileTablet()) {
        let serializedObj = JSON.stringify({
          mtopic: t?.mtopic,
          mpersona: t?.mpersona
        });
        history.push("/UIMessageList", { subscription: t.topic });
      } else {
        handleAdjPan({ subscription: t });
      }
    },
    [
      liveCacheState?.collectionState,
      globalState.persona?.mpersona,
      databaseState.dexUser
    ]
  );

  const handleClick = () => {
    //check exist topics for selected topic, check for mtopic
    let found = false;
    if (topicsState?.topics) {
      for (const t of Object.keys(topicsState.topics)) {
        if (topicsState.topics[t]?.mtopic === props.topic?.mtopic) {
          found = true;
          goToTopic(topicsState.topics[t]);
          break;
        }
      }
    }
    if (!found) subscribeTopic();
  };

  let content = (
    <div>
      <div
        // className="grid-row"
        // className="flex-row"
        // style={props.noBorder ? noBorderStyle : {}}
        style={{
          height: "2rem",
          padding: "0.25rem 0rem 0.25rem 0rem",
          borderBottom:
            "1px solid var(--main_screen_topic_separator_line_color)"
        }}
        onClick={() => handleClick()}
      >
        <div className="flex-row" style={{ flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <div
              className="flex-cell"
              style={{
                fontSize: isMobileTablet() ? "1rem" : "1.1rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginRight: isMobileTablet() ? "0.2rem" : "1rem",
                maxWidth: isMobileTablet()
                  ? `${window.innerWidth * 0.65}px`
                  : props.topic?.topic_display_name?.length > 40 ||
                    props.topic?.topic?.length > 40 ||
                    props.topic.description?.length > 40
                  ? "300px"
                  : "fit-content"
              }}
            >
              {props.topic.topic_display_name || props.topic.topic}
            </div>
            <div
              style={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: "0.9rem",
                marginRight: isMobileTablet() ? "0.2rem" : "1rem",
                maxWidth: isMobileTablet()
                  ? `${window.innerWidth * 0.65}px`
                  : props.topic?.topic_display_name?.length > 40 ||
                    props.topic?.topic?.length > 40 ||
                    props.topic.description?.length > 40
                  ? "300px"
                  : "fit-content"
              }}
            >
              {props.topic.description}
            </div>
          </div>
          <div
            className="flex-cell"
            style={{
              fontSize: "0.9rem",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textAlign: "right",
              minWidth: "0rem",
              maxWidth: isMobileTablet()
                ? "20vw"
                : props.topic?.topic_display_name?.length > 40 ||
                  props.topic?.topic?.length > 40 ||
                  props.topic.description?.length > 40
                ? "300px"
                : "fit-content"
            }}
          >
            {props.topic.countsubs}
          </div>
        </div>
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicResult, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
