import React, { useState, useContext, useEffect, useRef } from "react";
// import StyleContext from "contexts/contextStyle";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import { randomString } from "hooks/helper";
import { updateTheme } from "theme/updateTheme";
import "ui/UI.css";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import StyleContext from "contexts/contextStyle";
import Slider from "../utils/Slider"
import { WorldTextInput } from "utils/UtilsUniversal";

const UICreateTheme = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { styleState } = useContext(StyleContext);
  // const newThemeName = useRef();
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [clickOutsideActive, setClickOutsideActive] = useState(true);
  let history = useHistory();
  const [bckgrndOpacValue, setBckgrndOpacValue] = useState(90);
  const [rangeValue, setRangeValue] = useState(90);
  const [focusVal, setFocusVal] = useState(true);
  const [themeName, setThemeName] = useState("");
  const [themeID, setThemeID] = useState("");
  const [screenWidth, setScreenWidth] = useState();

  let newTheme = useRef(
    globalState.themeEdit
      ? { ...globalState.themeEdit.theme }
      : { ...styleState?.theme }
  );

  const [test, setTest] = useState(newTheme);
  const [itemChanged, setItemChanged] = useState(false);

  const handleNew = () => {
    setItemChanged(false);
  };

  useEffect(() => {
    if (test.current?.opacities?.logo_background_transparency >= 0)
      setBckgrndOpacValue(
        test.current?.opacities?.logo_background_transparency * 100
      );
    else setBckgrndOpacValue(90);
  }, [test]);

  useEffect(() => {
    if (test.current?.transparency?.whisper_background_transparency >= 0)
      setRangeValue(
        test.current?.transparency?.whisper_background_transparency * 100
      );
    else setRangeValue(90);
  }, [test]);

  useEffect(() => {
    if (globalState.themeEdit) {
      newTheme.current = { ...globalState.themeEdit.theme };
      if (globalState.themeEdit.editType === "clone") {
        newTheme.current.name = globalState.themeEdit.theme.name + "_Clone";
        setThemeName(globalState.themeEdit.theme.name + "_Clone");
        // document.getElementById("theme_name").value =
        //   globalState.themeEdit.theme.name + "_Clone";
        let id = randomString(8);
        newTheme.current.id = id;
        setThemeID(id);
        // document.getElementById("theme_id").value = id;
        newTheme.current.owners = globalState.themeEdit.theme.owners
          ? [
              globalState.persona.mpersona,
              ...globalState.themeEdit.theme.owners.filter(
                (m) => m !== globalState.persona.mpersona
              )
            ]
          : [globalState.persona.mpersona];
        newTheme.current.creator = [globalState.persona.persona];
      } else {
        newTheme.current.name = globalState.themeEdit.theme.name;
        newTheme.current.id = globalState.themeEdit.theme.id;
        setThemeName(globalState.themeEdit.theme.name);
        // document.getElementById("theme_name").value =
        //   globalState.themeEdit.theme.name;
        setThemeID(globalState.themeEdit.theme.id);
        // document.getElementById("theme_id").value =
        //   globalState.themeEdit.theme.id;
      }
      document.getElementById("createtheme_modal");
      globalDispatch({ type: "CLEAR_THEME_EDIT" });
    } else {
    }
    return () => {};
  }, [globalDispatch, globalState.persona, globalState.themeEdit]);

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    props.onClose();
    // if (history.length >= 1) history.goBack();
  };

  const availFonts = [
    { fontName: "Roboto400" },
    { fontName: "Roboto700" },
    { fontName: "Nunito400" },
    { fontName: "Nunito600" },
    { fontName: "Open Sans500" },
    { fontName: "Open Sans700" }
  ];
  useEffect(() => {
    setThemeName(newTheme.current?.name);
  }, []);

  const handleThemeName = (e) => {
    // let themeName = document.getElementById("theme_name").value;
    setThemeName(e);
    let editTheme = { ...newTheme.current };
    // let id = randomString(8);
    // document.getElementById("theme_id").value = id;
    // editTheme.id = id;
    editTheme.name = e;
    newTheme.current = { ...editTheme };
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  // Use useEffect to update columnWidth based on screen width
  useEffect(() => {
    function handleResize() {
      // setScrnHgt(window.innerHeight);
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let modal = (
    <div id="createtheme_modal">
      <Modals
        title={props.titleName}
        onClose={() => handleClose()}
        noHistoryBack={true}
        onClickOutside={() => handleClickOutside()}
        style={{ top: "0", maxWidth: "100%" }}
        clickOutsideActive={false}
        switchScrollOff={true}
        footer={
          <div>
            <button
              className="UI-button-service"
              // style={{ marginBottom: "0.5rem" }}
              disabled={themeName?.length <= 0}
              type="login"
              onClick={() => {
                // Check values
                // Set as current theme
                updateTheme(databaseState.dexUser, newTheme.current);
                document.documentElement.style.setProperty(
                  "--basefont",
                  newTheme?.current?.fonts?.body_typeface_font.substring(
                    0,
                    newTheme?.current?.fonts?.body_typeface_font.length - 3
                  )
                );
                document.documentElement.style.setProperty(
                  "--baseweight",
                  newTheme?.current?.fonts?.body_typeface_font.substr(
                    newTheme?.current?.fonts?.body_typeface_font.length - 5
                  )
                );
                // Close modal
                document.getElementById("createtheme_modal").style.display =
                  "none";
                handleClose();
                // document.getElementById("settings_modal").style.display =
                //   "none";
                globalDispatch({ type: "CLEAR_THEME_EDIT" });
                // props.cloneTheme(false)
              }}
            >
              OK
            </button>
          </div>
        }
      >
        {/* <div id="createtheme_modal" */}
        {/* className="UI-login-modal show" */}
        {/* > */}
        {/* <div */}
        {/* className="UI-login-modal-content-theme" */}
        {/* > */}
        <div className="UI-theme-select-container">
          {/* <label className="modal-label-new">Theme Editor</label> */}
          <WorldTextInput
            universalStyle={{ display: "flex", flexDirection: "column" }}
            identity="createtheme"
            focus={focusVal}
            removeFocus={(val) => handleFocus(val)}
            labelStyle={{
              fontWeight: "bold"
            }}
            type="text"
            // title={tagTooltip}
            callback={(e) => handleThemeName(e)}
            label={"Theme Editor"}
            // required={true}
            placeholder="New theme name"
            // description={"generic World text input field testing phase"}
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            maxStrLen={56}
            // active={worldTestTA?.length > 0 ? "true" : "false"}
            value={themeName}
            // regex={/^[\w\.\- ]+$/i}
          />
          {/* <input
            className="UI-input"
            type="uid"
            placeholder="New theme name"
            // ref={newThemeName}
            required
            id={"theme_name"}
            defaultValue={newTheme.current?.name}
            onChange={() => {
              let themeName = document.getElementById("theme_name").value;
              let editTheme = { ...newTheme.current };
              // let id = randomString(8);
              // document.getElementById("theme_id").value = id;
              // editTheme.id = id;
              editTheme.name = themeName;
              newTheme.current = { ...editTheme };
            }}
          /> */}
          <WorldTextInput
            identity="createnewtheme"
            readOnly="readOnly"
            // focus={focusVal}
            // removeFocus={(val) => handleFocus(val)}
            // labelStyle={{
            //   fontWeight: "bold",
            // }}
            type="text"
            // title={tagTooltip}
            // callback={(e) => handleThemeName(e)}
            // label={"Theme Editor"}
            // required={true}
            placeholder="New theme id"
            // description={"generic World text input field testing phase"}
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            maxStrLen={56}
            active={"false"}
            value={themeID}
            // regex={/^[\w\.\- ]+$/i}
          />
          {/* <input
            readOnly="readOnly"
            className="UI-input"
            type="uid"
            placeholder="New theme id"
            required
            id={"theme_id"}
            defaultValue={newTheme.current?.id}
            onChange={() => {
              let themeId = document.getElementById("theme_id").value;
              let editTheme = { ...newTheme.current };
              editTheme.id = themeId;
              newTheme.current = { ...editTheme };
            }}
          /> */}

          {/* <label className="modal-label-new"
            style={{
              overflowWrap: "anywhere",
              textAlign: "left"
            }}>
            {"Add theme # theme/public/" + newTheme.current?.id}
          </label> */}
          <span
            className="modal-label-new"
            style={{
              textAlign: "left"
            }}
          >
            Fonts
          </span>
          {newTheme.current?.fonts &&
            Object.keys(newTheme.current.fonts).map((k) => (
              <div
                key={"theme_" + k}
                id={"theme_" + k}
                className="UI-theme-item-fonts"
              >
                <div className="UI-theme-item-text">{k}</div>
                <select
                  value={test.current.fonts && test.current.fonts[k]}
                  onChange={(e) => {
                    let editTheme = { ...newTheme.current };
                    editTheme.fonts[k] = e.target.value;
                    newTheme.current = { ...editTheme };
                    setItemChanged(true);
                  }}
                  style={{ marginLeft: "1rem" }}
                >
                  {/* <option value="" disabled selected hidden>Select action</option> */}
                  {availFonts.map((item, i) => (
                    <option
                      // value={item.fontName}
                      className="UI-dropdown-icon"
                      key={i}
                    >
                      {item.fontName}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          <span
            className="modal-label-new"
            style={{
              textAlign: "left"
            }}
          >
            Opacities
          </span>
          {newTheme.current?.opacities &&
            Object.keys(newTheme.current.opacities).map((k) => (
              <div
                key={"theme_" + k}
                id={"theme_" + k}
                className="UI-theme-item-fonts"
              >
                <div className="UI-theme-item-text">{k}</div>
                <div>
                  <Slider
                    className="slider-style"
                    min={0}
                    max={100}
                    step={10}
                    defaultLength={bckgrndOpacValue}
                    value={bckgrndOpacValue}
                    onChangeValue={(e) => {
                      setBckgrndOpacValue(parseInt(e.target.value, 10));
                      let editTheme = { ...newTheme.current };
                      editTheme.opacities[k] =
                        Math.round(parseInt(e.target.value, 10)) / 100;
                      newTheme.current = { ...editTheme };
                      setItemChanged(true);
                    }}
                  />
                  <span style={{ fontWeight: "bold" }}>
                    {bckgrndOpacValue >= 0 ? bckgrndOpacValue : 90} %
                  </span>
                </div>
              </div>
            ))}
          {newTheme.current?.transparency &&
            Object.keys(newTheme.current.transparency).map((k) => (
              <div
                key={"theme_" + k}
                id={"theme_" + k}
                className="UI-theme-item-fonts"
              >
                <div className="UI-theme-item-text">{k}</div>
                <div>
                  <Slider
                    className="slider-style"
                    min={0}
                    max={100}
                    step={10}
                    defaultLength={rangeValue}
                    value={rangeValue}
                    onChangeValue={(e) => {
                      setRangeValue(parseInt(e.target.value, 10));
                      let editTheme = { ...newTheme.current };
                      editTheme.transparency[k] =
                        Math.round(parseInt(e.target.value, 10)) / 100;
                      newTheme.current = { ...editTheme };
                      setItemChanged(true);
                    }}
                  />
                  <span style={{ fontWeight: "bold" }}>
                    {rangeValue >= 0 ? rangeValue : 90} %
                  </span>
                </div>
              </div>
            ))}

          <span
            className="modal-label-new"
            style={{
              textAlign: "left"
            }}
          >
            Colours
          </span>
          <div
            className="UI-theme-content"
            style={{
              width: "400px",
              maxWidth: `${screenWidth * 0.8}px`
            }}
          >
            {newTheme.current?.colors &&
              Object.keys(newTheme.current.colors).map((k) => (
                <div
                  key={"theme_" + k}
                  id={"theme_" + k}
                  className="UI-theme-item"
                  style={{
                    gridTemplateColumns: "2fr 50px"
                    // "repeat(auto-fit, minmax(100px, max-content) 50px"

                    // gridTemplateColumns: `${columnWidth} 3.5rem`
                  }}
                >
                  <div className="UI-theme-item-text">{k}</div>
                  <input
                    className="UI-input"
                    type="color"
                    placeholder="hex colour"
                    required
                    id={k}
                    value={
                      test.current?.colors && test.current?.colors[k]
                      // newTheme.current?.colors && newTheme.current?.colors[k]
                    }
                    onChange={(e) => {
                      let editTheme = { ...newTheme.current };
                      editTheme.colors[k] = document.getElementById(k).value;
                      newTheme.current = { ...editTheme };
                      setItemChanged(true);
                    }}
                  />
                </div>
              ))}
          </div>
          {itemChanged && handleNew()}
          <div></div>
        </div>
      </Modals>
    </div>
  );

  return modal;
};

// memo acts like shouldComponentUpdate ... can use without the function, or with a function that returns false if it SHOULD rerender
export default React.memo(UICreateTheme, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
