import { useReducer } from "react";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  shouldLog() && console.log("SET focus", state, action);
  switch (action.type) {
    case "SET_HASFOCUS":
      // console.log("SET_HASFOCUS", action, Date.now());
      if (state.hasFocus === action.values.hasFocus) {
        return state;
      } else {
        // console.log("CHANGE!!!", state, action);
        return {
          ...state,
          hasFocus: action.values.hasFocus
        };
      }
    default: {
      return state;
    }
  }
};

const useFocusState = (state) => {
  const [focusState, focusDispatch] = useReducer(reducer, state);
  return { focusState, focusDispatch };
};

export default useFocusState;
