import React from 'react'

const GrDocTimeWorld = (props) => {
  return (
    <div>
      <svg
        stroke={props.strokecolor}
        fill={props.fillcolor}
        // strokeWidth={props.strokewidth}
        stroke-width="0"
        viewBox="0 0 24 24"
        classname="icon"
        height="40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
        transform="
        translate(-2 3)
        scale(0.9 0.9)">
        <path fill="none" stroke-width="2" d="M4.99787498,6.99999999 L4.99787498,0.999999992 L19.4999998,0.999999992 L22.9999998,4.50000005 L23,23 L17,23 M18,1 L18,6 L23,6 M10,23 C13.8659932,23 17,19.8659932 17,16 C17,12.1340068 13.8659932,9 10,9 C6.13400675,9 3,12.1340068 3,16 C3,19.8659932 6.13400675,23 10,23 Z M10,12 L10,16 L13,19"></path>
        <path fill="none" stroke-width="2" d="M4.99787498,6.99999999 L4.99787498,0.999999992 L19.4999998,0.999999992 L22.9999998,4.50000005 L23,23 L17,23 M18,1 L18,6 L23,6 M10,23 C13.8659932,23 17,19.8659932 17,16 C17,12.1340068 13.8659932,9 10,9 C6.13400675,9 3,12.1340068 3,16 C3,19.8659932 6.13400675,23 10,23 Z M10,12 L10,16 L13,19"></path>

      </svg>

    </div>
  )
}

export default GrDocTimeWorld