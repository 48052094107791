import React, { useState, useEffect, useRef, useContext } from "react";
// import { timeoutGet } from "hooks/http";
import { workerGet } from "workers/interfaceRest";
// import { randomString } from "hooks/helper";
import UITopicResults from "ui/UITopicResults";
import useDebounce from "hooks/debounce";
import GlobalContext from "contexts/context";
import PersonasContext from "contexts/contextPersonas";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { WorldTextInput } from "utils/UtilsUniversal";

const UISearchTopic = (props) => {
  const [useSearch, setSearch] = useState(undefined);
  const [useList, setList] = useState([]);
  const debouncedSearchTerm = useDebounce(useSearch, 500);
  const { globalState } = useContext(GlobalContext);
  const { personasState } = useContext(PersonasContext);
  const searchFor = useRef();
  const [timer, setTimer] = useState(0);
  const increment = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const tick = useRef();
  const [modalClose, setModalClose] = useState(false)
  const [outClick, setOutClick] = useState(false)
  const [selectedPersona, setSelectedPersona] = useState(false)
  let history = useHistory()
  const [focusVal, setFocusVal] = useState(true)
  const [searchName, setSearchName] = useState("")
  const [calculatedMaxHeight, setCalculatedMaxHeight] = useState();

  useEffect(() => {
    if (increment.current) {
      increment.current = !increment.current;
      return;
    }

    if (isActive) {
      tick.current = setInterval(() => {
        // <-- set tick ref current value
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current); // <-- access tick ref current value
    }

    return () => clearInterval(tick.current); // <-- clear on unmount!
  }, [isActive]);

  useEffect(() => {
    const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
    const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
    if (debouncedSearchTerm === "") {
      setIsActive(false);
      setList([]);
    } else {
      let isMounted = true;
      globalState?.jwt &&
        debouncedSearchTerm &&
        debouncedSearchTerm !== "" &&
        workerGet(urlPersona, keyPersona, {
          type: "r_topic_search",
          topic: debouncedSearchTerm,
          muid: globalState.muid,
          msgid: 1,
          token: globalState?.jwt,
          lat: 0,
          lon: 0,
          range: 100000,
          maxreturned: 10,
          version: globalState.version
        })
          .then((result) => {
            isMounted && setList(result.list || []);
            // clearInterval(increment.current);
            setIsActive(false);
            setTimer(0);
          })
          .catch((err) => {
            console.log("CATCH", err);
          });

      return () => {
        isMounted = false;
      };
    }
    //the empty string in the depnedencies below means that this useEffect
    //is executed (triggered) even when debouncedSearchTerm bcomes ""
  }, [debouncedSearchTerm, globalState.muid, globalState.version, ""]);

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (window.location?.href?.includes("menu")) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick, selectedPersona]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  const handleSelectedPersona = () => {
    setSelectedPersona(true);
    props.onClose();
    // if (history.length > 1) history.goBack()
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleSearchName = (e) => {
    setSearchName(e);
    setSearch(e);
    setIsActive(true);
  };

  let content = (
    <div id="search_topics_modal">
      <Modals
        title="Search for Topic"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        switchScrollOff={true}
        theMaxHgtBody={(val) => setCalculatedMaxHeight(val)}
        // footer={<p>footer</p>}
      >
        <div className="UI-login-container">
          <WorldTextInput
            identity="searchtopic"
            focus={focusVal}
            removeFocus={(val) => handleFocus(val)}
            labelStyle={{
              fontWeight: "bold"
            }}
            type="text"
            // title={tagTooltip}
            callback={(e) => handleSearchName(e)}
            label="Tap on topic to join"
            //  required={true}
            placeholder="Searching for..."
            // description={"generic World text input field testing phase"}
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            //  maxStrLen={56}
            // active={worldTestTA?.length > 0 ? "true" : "false"}
            value={searchName}
            active="true"
            autoComplete="off"
          />
          {isActive && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Loading...</span>
              {timer}
              <div className="spinner"></div>
            </div>
          )}
          <div
            className="flex-row"
            // className="grid-row"
            // className="ui-invite-grid"
          >
            <header>Topic</header>
            <header style={{ marginRight: "2rem" }}>Participants</header>
          </div>
          <div
            id="result_list"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              maxHeight: `${calculatedMaxHeight - 55}px`
            }}
          >
            <UITopicResults
              //the next line filters out blanks
              topicList={useList.filter((topic) => topic.mtopic !== "")}
              muid={globalState.muid}
              mpersona={
                globalState.persona?.mpersona
                  ? globalState.persona.mpersona
                  : personasState.personas
                  ? personasState.personas[0]?.mpersona
                  : undefined
              }
              persona={
                globalState.persona?.persona
                  ? globalState.persona.persona
                  : personasState.personas
                  ? personasState.personas[0]?.persona
                  : undefined
              }
              onClose={() => handleSelectedPersona()}
              // onClose={props.onClose}
            />
          </div>
        </div>
      </Modals>
    </div>
  );
  return content;
}

// memo acts like shouldComponentUpdate ... can use without the function, or with a function that returns false if it SHOULD rerender
export default React.memo(UISearchTopic, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
