// import { compareDocs } from "utils/dbUtils";
import { shouldLog } from "utils/shouldLog";
import { workerPost } from "workers/interfaceRest";

// const post = (data, onSuccess, onFailure) => {
//   let url = process.env.REACT_APP_PERSONA_API_URL;
//   let options = {
//     method: "POST",
//     headers: {
//       "content-type": "application/json",
//       "x-api-key": process.env.REACT_APP_PERSONA_API_KEY
//     },
//     body: JSON.stringify(data)
//   };
//   restFetch(url, options, onSuccess, onFailure);
// };

export const personaList = async (db) => {
  let personas = [];
  return await db
    .get("54account_data4personas4muid")
    .then((pList) => {
      personas = pList.list;
      return personas;
    })
    .catch((err) => {
      // console.log("[sw.js] CATCH personas", err);
      return personas;
    });
};

export const lastRead = async (db, mtopic) => {
  return await db
    .allDocs({
      include_docs: true,
      startkey: "54messages4" + mtopic,
      endkey: "54messages4" + mtopic + "5"
    })
    .then((msgDocs) => {
      let indices = msgDocs.rows
        .filter(
          (d) => d.doc.read && !d.doc.hide && !!parseInt(d.doc.msg_idx, 10)
        )
        .map((d) => parseInt(d.doc.msg_idx, 10));
      return indices.length > 0 ? Math.max(...indices) : 0;
    })
    .catch((e) => {
      // console.log(e);
      return 0;
    });
};

// export const upsert = (db, doc, callback) => {
//   return db
//     .upsert(doc._id, (existingDoc) =>
//       compareDocs(existingDoc, doc) ? false : doc
//     )
//     .then(() => {
//       console.log("[API] upsert", doc);
//       callback && callback();
//       // success
//     })
//     .catch((err) => {
//       if (err.name === "conflict") {
//         // conflict!
//       } else {
//         // other error
//       }
//     });
// };

export const rest_api = (action) => {
  switch (action.type) {
    case "r_t_list": // action.db  action.user.jwt
      return new Promise((resolve, reject) => {
        let data = {
          type: `r_t_list`,
          jwt: action.jwt,
          version: "",
          concise: "false",
          url: process.env.REACT_APP_URL
        };

        let onSuccess = (response) => {
          shouldLog() && console.log(`[API] restFetch r_t_list`, response);
          resolve(response);
        };
        let onFailure = (reason) => {
          shouldLog() && console.log(`[sw.js] restFetch failure`, reason);
          reject(reason);
        };

        workerPost(
          process.env.REACT_APP_PERSONA_API_URL,
          process.env.REACT_APP_PERSONA_API_KEY,
          data
        )
          .then((r) => onSuccess(r))
          .catch((e) => onFailure(e));
      });

    case "r_t_hist": // action.db  action.topicList  action.user.jwt
      return new Promise((resolve, reject) => {
        let data = {
          type: `r_t_hist`,
          topics: action.topics,
          jwt: action.jwt
        };

        let onSuccess = (response) => {
          shouldLog() && console.log(`[API] r_t_hist`, response);
          resolve(response);
        };
        let onFailure = (reason) => {
          console.log(`[API] r_t_hist failure`, reason);
          reject(reason);
        };

        workerPost(
          process.env.REACT_APP_PERSONA_API_URL,
          process.env.REACT_APP_PERSONA_API_KEY,
          data
        )
          .then((r) => onSuccess(r))
          .catch((e) => onFailure(e));
      });

    case "r_t_visible":
      return new Promise((resolve, reject) => {
        let data = action;

        let onSuccess = (response) => {
          shouldLog() && console.log(`[API] r_t_visible`, response);
          resolve(response);
        };
        let onFailure = (reason) => {
          console.log(`[API] r_t_visible`, reason);
          reject(reason);
        };

        workerPost(
          process.env.REACT_APP_PERSONA_API_URL,
          process.env.REACT_APP_PERSONA_API_KEY,
          data
        )
          .then((r) => onSuccess(r))
          .catch((e) => onFailure(e));
      });

    default:
      return false;
  }
};
