import React from "react";
import "ui/UICarousel.css";
import ScrollBox from "./ScrollBox";

const UICarousel = (props) => {
  let items = props.items;
  let carousel = (
    <div
      className="hs full no-scrollbar UI-persona-carousel"
      style={props.style}
    >
      {/* {items.map((item) => item)} */}
      <ScrollBox
        className="carousel-scroll-box"
        orig={props.orig}>
        {items.map((item) => item)}
      </ScrollBox>

      {/* <ScrollBox>{items.map((item) => item)}</ScrollBox> */}
    </div>
  );

  return carousel;
};

export default React.memo(UICarousel, (prevProps, nextProps) => {
  const isEqual = require("react-fast-compare");
  return isEqual(prevProps, nextProps);
});
