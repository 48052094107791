import React from "react";
import useTopicsState from "contexts/useTopicsState";
import TopicsContext from "contexts/contextTopics";

const TopicsStateProvider = ({ children }) => {
  return (
    <TopicsContext.Provider value={useTopicsState({})}>
      {children}
    </TopicsContext.Provider>
  );
};

export default TopicsStateProvider;
