import React, { useState, useEffect, useRef } from "react";
// import { RiRainyFill } from "react-icons/ri";
import {
  TextInputSingleLine,
  // ShowSingleLineSwitch,
  OneLineInfo,
  PersonaButton
  // OKButton,
  // DropdownMenu
} from "./TextInput";
import './textinput.css'


const IDInfo = () => { }
export default IDInfo;

const genStyle = {
    marginTop: "0px",
    marginLeft: "24px",
    marginRight: "0px",
    marginBottom: "0px",
    width: "100%",
    height: "40px",
    color: "black",
    paddingLeft: "8px",
    // paddingRight: "4px",
    // paddingTop: "4px",
    // paddingBottom: "4px",
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "black",
    borderStyle: "solid",
}



const generalWidth = "300px"
const generalPadding = "4px"
const generalHeight = "40px"
const genBorderRadius = "var(--border_radius_medium"
const genMarginTop = "0px"
const genMarginBottom = "8px"

export const NameForm = props => {

    const [open, setOpen] = useState(false)
    const dispnone = {
        display: "none"
    }
    const dispblk = {
        display: "block"
    }

    /*First Names*/
    const [firstNames, setFirstNames] = useState("")
    const handleChangeFirstNames = newValue => {
        setFirstNames(newValue)
    }
    const onChangeValueFirstNames = () => {
        console.log("changing value")
    }
    /*Last Names*/
    const [lastNames, setLastNames] = useState("")
    const handleChangeLastNames = newValue => {
        setLastNames(newValue)
    }
    const onChangeValueLastNames = () => {
        console.log("changing value")
    }
    /*Title*/
    const [title, setTitle] = useState("")
    const handleChangeTitle = newValue => {
        setTitle(newValue)
    }
    const onChangeValueTitle = () => {
        console.log("changing value")
    }
    /*Description*/
    const [description, setDescription] = useState("")
    const handleChangeDescription = newValue => {
        setDescription(newValue)
    }
    const onChangeValueDescription = () => {
        console.log("changing value")
    }
    // const handleOK = () => {
    //     props.onClose()
    // }

    return (
        <div>
            <div
                className="form-content">
                <OneLineInfo
                    width="90%"
                    height={generalHeight}
                    fontWeight="bold"
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="12px"
                    marginRight="16px"
                    marginTop="0px"
                    marginBottom="8px"
                    backgroundColor="#FFFFFF"
                    textColor="red"
                    fontStyle="italic"
                    value="Disclaimer goes here Disclaimer goes here Disclaimer goes here Disclaimer goes here"
                />

                {/* <DropdownMenu
                style={
                    open ? dispnone : dispblk
                }
                title="Name"
                menuItems={props.menuItems}
                open={open}
                onClick={() => setOpen(!open)}
                onClose={props.onClose}
                key="21"
            >
            </DropdownMenu> */}

                {/* <ShowSingleLineSwitch  
                    label="Attribute Type"
                    type="text"
                    value="Name"
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius="4px"
                    backgroundColor="#FFFFFF"
                    textColor="black"
                    addSwitch="false"
                /> */}
                <TextInputSingleLine
                    label="First Names"
                    mandatory={true}
                    // type={props.type}
                    type="input"
                    value={firstNames}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    // backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="First Names"
                    callback={handleChangeFirstNames}
                    onChange={onChangeValueFirstNames}
                />

                <TextInputSingleLine
                    label="Last Names"
                    mandatory={true}
                    // type={props.type}
                    type="input"
                    value={lastNames}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    // backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Family Name(s)"
                    callback={handleChangeLastNames}
                    onChange={onChangeValueLastNames}
                />

                <TextInputSingleLine
                    label="Title"
                    mandatory={false}
                    // type={props.type}
                    type="input"
                    value={title}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Mr. Mrs. Ms. Dr. Prof"
                    callback={handleChangeTitle}
                    onChange={onChangeValueTitle}
                />

                <TextInputSingleLine
                    label="Description"
                    mandatory={false}
                    // type={props.type}
                    type="input"
                    value={title}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Birth Name"
                    callback={handleChangeDescription}
                    onChange={onChangeValueDescription}
                />

                <OneLineInfo
                    width="90%"
                    height={generalHeight}
                    fontWeight="bold"
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="12px"
                    marginRight="16px"
                    marginTop="16px"
                    marginBottom="0px"
                    backgroundColor="#FFFFFF"
                    textColor="red"
                    fontStyle="italic"
                    value="To verify this information a suitable identity document needs to be uploaded. Press the button below to verify now"
                />

                <PersonaButton
                    marginLeft="0px"
                    marginRight="0px"
                    marginBottom="0px"
                    onClick={() => console.log("verify")}
                    label="Verify"
                // key="3"
                />
            </div>
            {/* <OKButton
                marginLeft="16px"
                marginBottom="0px"
                marginTop="16px"
                marginBottom="0px"
                height="40px"
                width="100px"
                onClick={handleOK}
                label="OK"
            /> */}

        </div>
    )
}

export const PhnNumForm = props => {
    /*Country*/
    const [country, setCountry] = useState("")
    const [cancPhn, setCancPhn] = useState(false)
    const handleChangeCountry = newValue => {
        setCountry(newValue)
    }
    const onChangeValueCountry = () => {
        console.log("changing value")
    }
    /*Phone number*/
    const [PhnNum, setPhnNum] = useState("")
    const handleChangePhnNum = newValue => {
        setPhnNum(newValue)
    }
    const onChangeValuePhnNum = () => {
        console.log("changing value")
    }
    /*Description*/
    const [description, setDescription] = useState("")
    const handleChangeDescription = newValue => {
        setDescription(newValue)
    }
    const onChangeValueDescription = () => {
        console.log("changing value")
    }

    const handleCancel = () => {
        setCancPhn(!cancPhn)
    }

    const [validNum, setValidNum] = useState(false)
    const [bckSpcNum, setBckSpcNum] = useState(false)

    const [otpArr, setOptArr] = useState([
        {
            id: 0,
            title: "",
            value: ""
        },
        {
            id: 1,
            title: "",
            value: ""
        },
        {
            id: 2,
            title: "",
            value: ""
        },
        {
            id: 3,
            title: "",
            value: ""
        },
        {
            id: 4,
            title: "",
            value: ""
        }
    ]);

    const [index, setIndex] = useState(0)
    const inputRefs = useRef([]);
    inputRefs.current = []

    const chkOtp = (event) => {
        let datas = otpArr
        if (validNum) {
            datas[index].value = event.target.value
            setOptArr(datas)
            if (!bckSpcNum) {
                if (index < 4) {
                    setIndex(index + 1)
                }
                else {
                    setIndex(0)
                }
            }
            else setBckSpcNum(false)
        }
    }

    const handleKeyPressOtp = (event) => {
        if (otpArr[index].value.length < 1) {
            if (event.charCode >= 48 && event.charCode <= 57) {
                setValidNum(true)
            }
            else setValidNum(false)
        }
        else {
            setValidNum(false)
        }
    }

    const handleKeyDownOtp = (event) => {
        if (event.key === "Backspace" || event.key === "Delete") {
            setValidNum(true)
            setBckSpcNum(true)
        }
    }

    const addToRefs = (el) => {
        if (el && !inputRefs.current.includes(el)) {
            inputRefs.current.push(el)
        }
    }

    useEffect(() => {
        if (index <= 4)
            inputRefs.current[index].focus();
        return () => {
        }
    }, [index])

    const setNewIndex = (newIdx) => {
        setIndex(newIdx)
    }

    const otpStyle = {
        height: "40px",
        width: "40px",
        border: "2px solid black",
        fontSize: "40px",
        textAlign: "center"
    }

    return (
        <div>
            <div
                className="form-content">
                <TextInputSingleLine
                    label="Country"
                    mandatory={true}
                    // type={props.type}
                    type="input"
                    value={country}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    // backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Country"
                    callback={handleChangeCountry}
                    onChange={onChangeValueCountry}
                />

                <TextInputSingleLine
                    label="Phone Number"
                    mandatory={true}
                    // type={props.type}
                    type="input"
                    value={PhnNum}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    // backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Mobile Phone Number"
                    callback={handleChangePhnNum}
                    onChange={onChangeValuePhnNum}
                />

                <TextInputSingleLine
                    label="Description"
                    mandatory={false}
                    // type={props.type}
                    type="input"
                    value={description}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Description"
                    callback={handleChangeDescription}
                    onChange={onChangeValueDescription}
                />

                <PersonaButton
                    marginLeft="0px"
                    marginRight="0px"
                    marginBottom="0px"
                    onClick={() => console.log("verify")}
                    label="Request OTP"
                />
                <PersonaButton
                    marginLeft="0px"
                    marginRight="0px"
                    marginBottom="0px"
                    onClick={() => console.log("verify")}
                    label="Submit OTP"
                />
                <div
                    style={{
                        marginTop: "24px",
                        marginLeft: "32px",
                        marginRight: "32px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <input
                        style={otpStyle}
                        title="0"
                        ref={addToRefs}
                        onClick={() => setNewIndex(0)}
                        onKeyPress={handleKeyPressOtp}
                        onKeyDown={handleKeyDownOtp}
                        value={otpArr[0].value}
                        onChange={chkOtp}
                    >
                    </input>
                    <input
                        style={otpStyle}
                        title="1"
                        ref={addToRefs}
                        onClick={() => setNewIndex(1)}
                        onKeyPress={handleKeyPressOtp}
                        onKeyDown={handleKeyDownOtp}
                        value={otpArr[1].value}
                        onChange={chkOtp}
                    >
                    </input>
                    <input
                        style={otpStyle}
                        title="2"
                        ref={addToRefs}
                        onClick={() => setNewIndex(2)}
                        onKeyPress={handleKeyPressOtp}
                        onKeyDown={handleKeyDownOtp}
                        value={otpArr[2].value}
                        onChange={chkOtp}
                    >
                    </input>
                    <input
                        style={otpStyle}
                        title="3"
                        ref={addToRefs}
                        onClick={() => setNewIndex(3)}
                        onKeyPress={handleKeyPressOtp}
                        onKeyDown={handleKeyDownOtp}
                        value={otpArr[3].value}
                        onChange={chkOtp}
                    >
                    </input>
                    <input
                        style={otpStyle}
                        title="4"
                        ref={addToRefs}
                        onClick={() => setNewIndex(4)}
                        onKeyPress={handleKeyPressOtp}
                        onKeyDown={handleKeyDownOtp}
                        value={otpArr[4].value}
                        onChange={chkOtp}
                    >
                    </input>
                </div>
            </div>
        </div >
    )
}

export const EmailForm = props => {
    /*Country*/
    const [emailAddr, setEmailAddr] = useState("")
    const handleChangeEmailAddr = newValue => {
        setEmailAddr(newValue)
    }
    const onChangeValueEmailAddr = () => {
        console.log("changing value")
    }
    // const handleOK = () => {
    //     props.onClose()
    // }

    return (
        <div>
            <div
                className="form-content">
                <TextInputSingleLine
                    label="Email Address"
                    mandatory={true}
                    // type={props.type}
                    type="input"
                    value={emailAddr}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    // backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Email Address"
                    callback={handleChangeEmailAddr}
                    onChange={onChangeValueEmailAddr}
                />
            </div>
        </div>
    )
}

export const IDNumForm = props => {
    /*Country*/
    const [country, setCountry] = useState("")
    const handleChangeCountry = newValue => {
        setCountry(newValue)
    }
    const onChangeValueCountry = () => {
        console.log("changing value")
    }
    /*Identity number*/
    const [IDNum, setIDNum] = useState("")
    const handleChangeIDNum = newValue => {
        setIDNum(newValue)
    }
    const onChangeValueIDNum = () => {
        console.log("changing value")
    }
    /*Description*/
    const [description, setDescription] = useState("")
    const handleChangeDescription = newValue => {
        setDescription(newValue)
    }
    const onChangeValueDescription = () => {
        console.log("changing value")
    }
    // const handleOK = () => {
    //     props.onClose()
    // }

    return (
        <div>
            <div
                className="form-content">
                <TextInputSingleLine
                    label="Country"
                    mandatory={true}
                    // type={props.type}
                    type="input"
                    value={country}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    // backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Country"
                    callback={handleChangeCountry}
                    onChange={onChangeValueCountry}
                />

                <TextInputSingleLine
                    label="Identity Number"
                    mandatory={true}
                    // type={props.type}
                    type="input"
                    value={IDNum}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    // backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="ID/Social Security Number"
                    callback={handleChangeIDNum}
                    onChange={onChangeValueIDNum}
                />

                <TextInputSingleLine
                    label="Description"
                    mandatory={false}
                    // type={props.type}
                    type="input"
                    value={description}
                    width={generalWidth}
                    height={generalHeight}
                    paddingLeft={generalPadding}
                    paddingRight={generalPadding}
                    marginLeft="24px"
                    marginRight="0px"
                    marginTop={genMarginTop}
                    marginBottom={genMarginBottom}
                    borderWidth="1px"
                    borderColor="#000000"
                    borderStyle="solid"
                    borderRadius={genBorderRadius}
                    backgroundColor="#FFFFFF"
                    textColor="black"
                    placeholder="Description"
                    callback={handleChangeDescription}
                    onChange={onChangeValueDescription}
                />
            </div>
            {/* <OKButton
                marginLeft="16px"
                marginBottom="0px"
                marginTop="16px"
                marginBottom="0px"
                height="40px"
                width="100px"
                onClick={handleOK}
                label="OK"
            /> */}

        </div>
    )
}