import React, { useState, useEffect, useContext } from 'react'
import EZist_logo from "ui/icons/EZist_ARI-10.png";
import world_logo from "ui/icons/world_light_black_outline.png";
import StyleContext from "contexts/contextStyle";

// import world_logo from "ui/icons/world_logo_4.png";


const UITopicTest = () => {
  const [show, setShow] = useState(false)
  const [environment, setEnvironment] = useState(
    process.env.REACT_APP_URL?.includes("ezist") ? "ezist" : "world"
  );
  const [fontWeight, setFontWeight] = useState("");
  const [fontFam, setFontFam] = useState("");
  const { styleState } = useContext(StyleContext);

  const handleOptions = () => {
    setShow(true)
  }
  const handleExitOptions = () => {
    setShow(false)
  }

  useEffect(() => {
    let fontName = styleState?.theme?.fonts?.topic_name_main_screen_font;
    if (fontName) {
      setFontFam(fontName.substring(0, fontName.length - 3));
      setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
    }
  }, [styleState]);

  useEffect(() => {
    let localEnvironment = localStorage.getItem("environment");
    setEnvironment((e) => localEnvironment || e);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "100px 25px 0px 25px"
      }}>
      {/* <div>UITopicTest</div> */}
      <img
        src={environment === "ezist" ? EZist_logo : world_logo}
        alt="Logo"
        style={{
          width: "200px",
          height: "200px"
        }}
      ></img>
      {/* <img
        src={world_logo}
        alt="logo"
        style={{

          width: "200px",
          height: "200px",
          marginTop: "50px"
        }}>
      </img> */}
      {/* {!show &&
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "25px",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <button
            onClick={() => (handleOptions())}
            style={{
              marginTop: "50px",
              width: "150px",
              height: "50px",
              backgroundColor: "violet",
              borderRadius: "5px",
              cursor: "pointer"
            }}>
            Options
          </button>
        </div>
      }
      {show &&
        <div>
          <p>enter content here</p>
          <button
            onClick={() => handleExitOptions()}
            style={{
              marginTop: "50px",
              width: "150px",
              height: "50px",
              backgroundColor: "lightgreen",
              borderRadius: "5px",
              cursor: "pointer"
            }}>
            Exit
          </button>
        </div>
      } */}
      <div
        style={{
          fontWeight: fontWeight,
          fontFamily: fontFam,
          textAlign: "center"
        }}
      >
        <p>
          {environment === "ezist"
            ? `Community living made easy`
            : `Connecting your world`}
        </p>
      </div>

    </div>
  )
}

export default UITopicTest