import { useReducer } from "react";
import { timestampZero } from "hooks/helper";
import { shouldLog } from "utils/shouldLog";

const send = (socket, jMessage) => {
  shouldLog() && console.log("WSS send", socket, jMessage);
  delete jMessage.read;
  jMessage.content && (jMessage.control = { ts_sender_out: timestampZero() });
  // console.log("!!!message", jMessage);
  var p = JSON.stringify(jMessage);
  socket !== undefined && socket.readyState === 1
    ? socket.send(p)
    : shouldLog() && console.log("socket not connected");
};

const ping = (socket, text) => {
  shouldLog() && console.log("called ping");
  socket !== undefined && socket.readyState === 1
    ? socket.send(text)
    : shouldLog() && console.log("socket not connected");
};

const connect = (url, onopen, onclose, onerror, onmessage) => {
  const socket = new WebSocket(url);
  socket.onopen = onopen;
  socket.onclose = onclose;
  socket.onerror = onerror;
  socket.onmessage = onmessage;
  return socket;
};

const reducer = (state, action) => {
  shouldLog() && console.log("SET ws", state, action);
  switch (action.type) {
    case "SENDLIST":
      const sendTypes = [
        "w.t.pindate",
        "w.t.pinned",
        "w.t.edit",
        "w.t.create",
        "w.t.msg",
        "w.t.join",
        "w.t.leave",
        "w.t.delete",
        "w.t.invite",
        "w.t.evict",
        "w.t.hist",
        "w.t.list",
        "w.t.property.set",
        "w.t.subprops.set",
        "w.t.participants",
        "w.t.read",
        "w.topic.hist",
        "w.mpersona.hist",
        "w.topic.message",
        "w.topic.subscribe.mtopic",
        "w.mpersona.message",
        "w.user.token.update",
        "w.user.token.set",
        "w.user.token.clear",
        "w.user.fcmtime",
        "w.user.fcmtime.get",
        "w.user.otp.request",
        "w.user.otp.response",
        "w.topic.destroy",
        "w.mpersona.stream.offer",
        "w.mpersona.stream.answer",
        "w.mpersona.stream.hang_up",
        "w.mpersona.stream.new_ice_candidate"
        // "w.user.subscription.getlist",
      ];
      if (state.socket !== undefined) {
        action.values.messageList.forEach((message) => {
          if (
            message.type !== undefined &&
            sendTypes.indexOf(message.type) > -1
          ) {
            send(state.socket, message);
          }
        });
      }
      return state;
    case "PING":
      ping(state.socket, "--heartbeat--");
      return state;
    case "SEND":
      send(state.socket, action.values.jmessage);
      return state;
    case "CLOSE":
      state.socket && (state.socket.onclose = () => {});
      state.socket && (state.socket.onmessage = (m) => {});
      send(state.socket, { type: "kill" });
      // state.socket && state.socket.close();
      return {
        ...state,
        socket: undefined
      };
    case "OPEN":
      shouldLog() && console.log("OPEN socket ", action);
      state.socket && (state.socket.onclose = () => {});
      state.socket && (state.socket.onmessage = (m) => {});
      send(state.socket, { type: "kill" });
      // state.socket && state.socket.close();
      // add a brief pause
      const socket = connect(
        action.values.url,
        action.values.onopen,
        action.values.onclose,
        action.values.onerror,
        action.values.onmessage
      );
      return {
        ...state,
        socket: socket
      };
    case "SET_ONMESSAGE":
      state.socket && (state.socket.onmessage = action.values.onmessage);
      return state;
    default: {
      shouldLog() && console.log("Websocket default...");
      return state;
    }
  }
};

const useWebsocketState = (state) => {
  const [websocketState, websocketDispatch] = useReducer(reducer, state);
  return { websocketState, websocketDispatch };
};

export default useWebsocketState;
