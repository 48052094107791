const arrayBufferToString = (buf) => {
  return String.fromCharCode.apply(null, new Uint16Array(buf));
};

const buff_to_base64 = (buff) => btoa(String.fromCharCode.apply(null, buff));

const algoKeyGen = {
  name: "AES-GCM",
  length: 256,
};

const keyUsages = ["encrypt", "decrypt"];

const getPasswordKey = (password) => {
  let enc = new TextEncoder();
  return window.crypto.subtle.importKey(
    "raw",
    enc.encode(password),
    "PBKDF2",
    false,
    ["deriveKey"]
  );
};

const deriveKey = (passwordKey, salt, keyUsage) =>
  window.crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt: salt,
      iterations: 250000,
      hash: "SHA-256",
    },
    passwordKey,
    algoKeyGen,
    false,
    keyUsages
  );

export const encrypt = async (secretData, password) => {
  try {
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const passwordKey = await getPasswordKey(password);
    const aesKey = await deriveKey(passwordKey, salt, ["encrypt"]);
    const encryptedContent = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      aesKey,
      new TextEncoder().encode(secretData)
    );

    const encryptedContentArr = new Uint8Array(encryptedContent);
    let encText = arrayBufferToString(encryptedContentArr);
    console.log("CipherText: " + encText);

    let buff = new Uint8Array(
      salt.byteLength + iv.byteLength + encryptedContentArr.byteLength
    );
    buff.set(salt, 0);
    buff.set(iv, salt.byteLength);
    buff.set(encryptedContentArr, salt.byteLength + iv.byteLength);
    const base64Buff = buff_to_base64(buff);
    return base64Buff;
  } catch (e) {
    console.log(`Error - ${e}`);
    return "";
  }
};

const base64_to_buf = (b64) =>
  Uint8Array.from(atob(b64), (c) => c.charCodeAt(null));

export const decrypt = async (encryptedData, password) => {
  try {
    const encryptedDataBuff = base64_to_buf(encryptedData);
    const salt = encryptedDataBuff.slice(0, 16);
    const iv = encryptedDataBuff.slice(16, 16 + 12);
    const data = encryptedDataBuff.slice(16 + 12);

    const passwordKey = await getPasswordKey(password);
    const aesKey = await deriveKey(passwordKey, salt, ["decrypt"]);

    const decryptedContent = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv
      },
      aesKey,
      data
    );
    let clearText = new TextDecoder().decode(decryptedContent);
    // console.log("CipherClearText: " + clearText);
    return clearText;
  } catch (e) {
    console.log(`Error - ${e}`);
    return "";
  }
};
