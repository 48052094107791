import { useReducer } from "react";

import { shouldLog } from "utils/shouldLog";
import { newDex } from "./dex";
import { migrateCredentials, setMeta, setMetaAll } from "./dexUtils";
// import Dexie from "dexie";

const isEqual = require("react-fast-compare");

// Note: React calls the reducer twice, so the console log repeats
// https://github.com/facebook/react/issues/16295#issuecomment-610098654

// In Dev mode, React will execute the reduce function twice, to highlight any side effects
// In our case, we are deliberately using the side effect to populate the database
// so when upserting a document, we break out when the existing  doc is identical to the new doc

const reducer = (state, action) => {
  //console.log("SET db", state, action);
  let db;
  let doc;
  // console.log("[useDatabaseState] reducer", state, action);
  switch (action.type) {
    case "CREATE_DB_ADMIN_DEX":
      let dexAdmin = newDex(action.values?.dbName || "dexAdmin");
      migrateCredentials(dexAdmin);
      return {
        ...state,
        dexAdmin: dexAdmin
      };
    case "CREATE_USER_DEX":
      shouldLog() && console.log("CREATE_USER_DEX 1");
      let dexUser =
        action.values.dbName && newDex(`dexUser_${action.values.dbName}`);
      shouldLog() && console.log("CREATE_USER_DEX 2", dexUser);
      // action.values.cb(false, action.values.dbName);
      return {
        ...state,
        dexUser: dexUser
      };

    case "DEX_UPDATE_TOPIC_ARCHIVE":
      action.values.db.transaction("rw", action.values.db.account, async () => {
        let filter = {
          type: "topics"
        };
        let doc = await action.values.db.account.get(filter);
        if (doc && doc.value) {
          let topics = doc.value.filter(
            (t) =>
              t.mtopic === action.values.mtopic &&
              t.mpersona === action.values.mpersona
          );
          if (
            topics?.length === 1 &&
            topics[0]?.subprops?.archived !== action.values.archived
          ) {
            let newTopic = { ...topics[0] };
            newTopic.subprops = newTopic.subprops || {};
            newTopic.subprops.archived = action.values.archived;
            let newVal = doc.value.filter(
              (t) =>
                !(
                  t.mtopic === action.values.mtopic &&
                  t.mpersona === action.values.mpersona
                )
            );
            action.values.db.account.put({
              type: "topics",
              value: [...newVal, newTopic],
              time: Date.now()
            });
          }
        }
      });
      return state;

    case "DEX_UNREAD_MOD":
      setMeta(
        state.dexUser,
        action.values.mtopic,
        action.values.recipient,
        "unread",
        (currentVal) => {
          let t = (currentVal || []).filter(
            (i) => i.smid !== action.values.smid
          );
          return [false, "false", "f"].includes(action.values.read)
            ? [
                ...t,
                { smid: action.values.smid, msg_idx: action.values.msg_idx }
              ]
            : t;
        }
      );
      return state;
    case "DEX_UNREAD_MOD_ALL":
      shouldLog() && console.log("DEX_UNREAD_MOD_ALL", action);
      setMetaAll(
        state.dexUser,
        action.values.mtopic,
        "unread",
        (currentVal) => {
          let t = (currentVal || []).filter(
            (i) => i.smid !== action.values.smid
          );
          return [false, "false", "f"].includes(action.values.read)
            ? [
                ...t,
                { smid: action.values.smid, msg_idx: action.values.msg_idx }
              ]
            : t;
        }
      );
      return state;

    case "DEX_UPSERT_KEYS": // beware of race conditions...
      shouldLog() && console.log("DEX_UPSERT_KEYS", action);
      if (action.values.db) {
        let keys = action.values.db.table(action.values.table).schema;
        let filter = [
          keys.primKey.name,
          ...keys.indexes.map((idx) => idx.name)
        ];
        let filterObj = Object.fromEntries(
          Object.entries(action.values.doc).filter(([key]) =>
            filter.includes(key)
          )
        );
        shouldLog() && console.log("DEX_UPSERT_KEYS filterObj ", filterObj);
        action.values.db
          .table(action.values.table)
          .get(filterObj)
          .then((doc) => {
            shouldLog() && console.log("DEX_UPSERT_KEYS got doc", doc);
            shouldLog() &&
              console.log("DEX_UPSERT_KEYS new doc", {
                ...doc,
                ...action.values.doc
              });
            action.values.db
              .table(action.values.table)
              .put({ ...doc, ...action.values.doc });
          })
          .catch(() => {
            action.values.db.table(action.values.table).put(action.values.doc);
          });
      }
      return state;
    case "DEX_UPSERT_KEYS_TRANS": // gets the doc with matching keys, and updates the new items in the doc, keeping the unchanged items
      shouldLog() && console.log("DEX_UPSERT_KEYS_TRANS", Date.now(), action);
      if (action.values.db) {
        let keysTrans = action.values.db.table(action.values.table).schema;
        let filterTrans = [
          keysTrans.primKey.name,
          ...keysTrans.indexes.map((idx) => idx.name)
        ];
        let filterObjTrans = Object.fromEntries(
          Object.entries(action.values.doc).filter(([key]) =>
            filterTrans.includes(key)
          )
        );
        shouldLog() &&
          console.log("DEX_UPSERT_KEYS_TRANS filterObj ", filterObjTrans);
        action.values.db
          .transaction(
            "rw",
            action.values.db.table(action.values.table),
            async () => {
              let doc = await action.values.db
                .table(action.values.table)
                .get(filterObjTrans);
              shouldLog() && console.log("DEX_UPSERT_KEYS_TRANS got doc", doc);
              shouldLog() &&
                console.log("DEX_UPSERT_KEYS_TRANS new doc", {
                  ...doc,
                  ...action.values.doc
                });
              await action.values.db
                .table(action.values.table)
                .put({ ...doc, ...action.values.doc });
            }
          )
          .then(() => {
            shouldLog() && console.log("DEX_UPSERT_KEYS_TRANS committed");
          })
          .catch((err) => {
            console.error("DEX_UPSERT_KEYS_TRANS error", err.stack);
            action.values.db.table(action.values.table).put(action.values.doc);
          });
      }
      return state;

    case "MASTER_DB_READY":
      if (state.dbReady === action.values.ready) return state;
      else
        return {
          ...state,
          dbReady: action.values.ready
        };
    default: {
      console.log("!!!missing", action);
      return state;
    }
  }
};

const useDatabaseState = (state) => {
  const [databaseState, databaseDispatch] = useReducer(reducer, state);
  return { databaseState, databaseDispatch };
};

export default useDatabaseState;
