import { s3PresignedURL } from "connectivity/s3";

export const left_or_right = (
  you,
  mpersona,
  reverse_side,
  side,
  mpersona_side
) => {
  const resultms = mpersona_side?.find((item) => item.mpersona === you);
  if (resultms && resultms?.side) {
    if (resultms?.side === "centre") return "center";
    else return resultms.side;
  } else {
    let result = mpersona === you ? "right" : "left";
    if (reverse_side === "true") result = mpersona === you ? "left" : "right";
    switch (side) {
      case "reverse":
        result = mpersona === you ? "left" : "right";
        break;
      case "left":
        result = "left";
        break;
      case "right":
        result = "right";
        break;
      case "center":
        result = "center";
        break;
      default: {
        break;
      }
    }
    return result;
  }
};

export const timeFormat = (s) => {
  let newstr = s.replace(" ", "T");
  const d = new Date(newstr + "Z");
  return d.toLocaleTimeString();
};

export const timestamp = (pref1, pref2, pref3) => {
  if (pref1 && pref1 !== "" && pref1 !== "undefined") {
    return pref1;
  } else if (pref2 && pref2 !== "" && pref2 !== "undefined") {
    return pref2;
  } else if (pref3 && pref3 !== "" && pref3 !== "undefined") {
    return pref3;
  }
  return "";
};

export const sortJoin = (A, B, Delim) => {
  return A < B ? A + Delim + B : B + Delim + A;
};

export const downloadAttachment = async (attachment) => {
  let link = await s3PresignedURL({
    collection: attachment.collection,
    row: attachment.row,
    table: attachment.table,
    method: "getObject"
  });
  if (link.url && window.confirm("Open " + attachment.name + "?")) {
    //downloadFile(link.url);
    window.open(link.url, "_blank");
  }
  !link.url && alert("Something went wrong, please try again.");
};

const isColour = (colour) =>
  colour && colour.startsWith("#") && colour.length < 8;
export const bubbleColour = (bubbcolor) =>
  isColour(bubbcolor) ? bubbcolor : "";
export const textColour = (bubbcolor) =>
  isColour(bubbcolor) ? "#" + contrastingColor(bubbcolor.replace("#", "")) : "";

const contrastingColor = (color) => {
  return luma(color) >= 135 ? "000" : "fff";
};
const luma = (
  color // color can be a hx string or an array of RGB values 0-255
) => {
  var rgb = typeof color === "string" ? hexToRGBArray(color) : color;
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]; // SMPTE C, Rec. 709 weightings
};
const hexToRGBArray = (color) => {
  if (color.length === 3)
    color =
      color.charAt(0) +
      color.charAt(0) +
      color.charAt(1) +
      color.charAt(1) +
      color.charAt(2) +
      color.charAt(2);
  else if (color.length !== 6) throw "Invalid hex color: " + color;
  var rgb = [];
  for (var i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16);
  return rgb;
};

const isAnyAlphaNumeric = (str) => {
  var code, i, len;
  if (str) {
    for (i = 0, len = str.length; i < len; i++) {
      code = str.charCodeAt(i);
      if (
        (code > 47 && code < 58) || // numeric (0-9)
        (code > 64 && code < 91) || // upper alpha (A-Z)
        (code > 96 && code < 123)
      ) {
        // lower alpha (a-z)
        return true;
      }
    }
  }
  return false;
};

const fancyCount = (str) => {
  return str ? Array.from(str.split(/[\ufe00-\ufe0f]/).join("")).length : 0;
};

export const isEmoji = (str) => {
  var ranges = [
    "(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])" // U+1F680 to U+1F6FF
  ];
  if (str && str.match(ranges.join("|"))) {
    return true;
  } else {
    return false;
  }
};

export const getFontSize = (str) => {
  if (isEmoji(str)) {
    if (isAnyAlphaNumeric(str)) {
      return "1.2rem";
    } else {
      let onlyEmojii = str.replace(
        /([\u270E]|[\u26D4]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g,
        ""
      );
      // let onlyEmojii = str.replace(/ ([\u2700-\u27bf]|(\ud83c[\udde6-\uddff])|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g, "");

      let totEmojis = fancyCount(str) - fancyCount(onlyEmojii);
      if (totEmojis >= 5) {
        return "1.5rem";
      } else {
        // let fsize = 6.5 - totEmojis;
        let fsize = Math.round(6.5 - totEmojis);
        return fsize.toString() + "rem";
      }
    }
  } else {
    return {};
  }
};
