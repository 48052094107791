import React, { useState, useEffect, useContext } from 'react'
import EZist_logo from "ui/icons/EZist_ARI-10.png";
// import world_logo from "ui/icons/world_logo_bw.png";
import world_logo from "ui/icons/world_light_black_outline.png";
import { WorldTextInput } from "utils/UtilsUniversal";
import StyleContext from "contexts/contextStyle";
import "./modals.css";
import "./UI.css";
import UIRegister from "./UIRegister";
import "utils/utilsUniversal.css";
import { workerPost } from "workers/interfaceRest";
import UILoginModal from "ui/UILoginModal";
import GlobalContext from "contexts/context";
import DatabaseContext from "data/contextDatabase";
import { useAccountDex } from "hooks/dexHooks";

const UIFlash = (props) => {
  const [focusVal, setFocusVal] = useState(true);
  const [emailInp, setEmailInp] = useState("");
  const { styleState } = useContext(StyleContext);
  const [fontFam, setFontFam] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [parentHeight, setParentHeight] = useState(window.innerHeight);
  const [validEmail, setValidEmail] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [emailFound, setEmailFound] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [environment, setEnvironment] = useState(
    process.env.REACT_APP_URL?.includes("ezist") ? "ezist" : "world"
  );
  const { databaseState } = useContext(DatabaseContext);
  const { globalState } = useContext(GlobalContext);
  const { liveUser } = useAccountDex(databaseState.dexAdmin);

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleEmailInp = (e) => {
    setEmailInp(e.trim());
  };

  const CheckEmail = (emailStr) => {
    // Regular expression to validate email address
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/; this causes delay with long addresses
    const isValid = regex.test(emailStr);
    return isValid;
  };

  useEffect(() => {
    let localEnvironment = localStorage.getItem("environment");
    setEnvironment((e) => localEnvironment || e);
  }, []);

  useEffect(() => {
    setValidEmail(CheckEmail(emailInp));
  }, [emailInp]);

  useEffect(() => {
    let fontName = styleState?.theme?.fonts?.topic_name_main_screen_font;
    if (fontName) {
      setFontFam(fontName.substring(0, fontName.length - 3));
      setFontWeight(fontName.substring(fontName.length - 3, fontName.length));
    }
  }, [styleState]);

  useEffect(() => {
    function handleResize() {
      setParentHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // const handleSignIn = () => {
  //   setSignedIn(true);
  // };

  const requestEmail = (email) => {
    let url = process.env.REACT_APP_JWT_PWD_API_URL;
    let key = process.env.REACT_APP_JWT_PWD_API_KEY;
    let topicList = JSON.parse(
      sessionStorage.getItem("topiclist") || JSON.stringify([])
    );
    let append = topicList
      ? topicList.reduce((acc, t) => `${acc}/${t}`, "")
      : "";
    let reqEmail = {
      "type": "otpInit",
      "url": window.location.host,
      "email": email,
      "append": append
    };
    workerPost(url, key, reqEmail).then((urlResult) => {
      console.log("EmailLogin result ", urlResult);
      urlResult.result === "Email sent" &&
        sessionStorage.removeItem("topiclist");
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // localStorage.setItem("loginproc", Date.now())
    setSignedIn(true);
    requestEmail(emailInp);
    // setEmailFound(false)
  };

  const handleSignInUser = () => {
    setShowModal(true);
  };

  return (
    <div
      style={{
        overflow: "auto"
      }}
    >
      {emailFound && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: parentHeight,
            marginTop: `${parentHeight * 0.15}px`
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "80%"
            }}
          >
            <img
              src={environment === "ezist" ? EZist_logo : world_logo}
              alt="Logo"
              style={{
                width: "200px",
                height: "200px"
              }}
            ></img>
            <div
              style={{
                fontWeight: fontWeight,
                fontFamily: fontFam,
                textAlign: "center"
              }}
            >
              <p>
                {environment === "ezist"
                  ? `Community living made easy`
                  : `Connecting your world`}
              </p>
            </div>
            {showModal && (
              <UILoginModal onClose={() => setShowModal(false)}></UILoginModal>
            )}
            {!signedIn &&
              !showModal &&
              !window.location.pathname.includes("jwt_") &&
              (!liveUser ||
                (!liveUser?.user?.jwt && !liveUser?.user?.pwd && (
                  <div className="sign-in-email-buttons">
                    <form id="newuser_form" onSubmit={handleSubmit}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          width: "100%",
                          maxWidth: "300px"
                        }}
                      >
                        <div
                          style={{
                            marginTop: "2rem",
                            width: "100%"
                          }}
                        >
                          <WorldTextInput
                            universalStyle={{
                              display: "flex",
                              flexDirection: "column"
                            }}
                            identity="loginEmail"
                            focus={focusVal}
                            removeFocus={(val) => handleFocus(val)}
                            labelStyle={{
                              fontWeight: "bold"
                            }}
                            inpStyle={{
                              height: "2rem",
                              width: "100%",
                              margin: "0px",
                              disabled: signedIn
                            }}
                            type="text"
                            // title={tagTooltip}
                            callback={(e) => handleEmailInp(e)}
                            label="email"
                            // required={true}
                            placeholder="Enter your email address"
                            // description={"generic World text input field testing phase"}
                            minRows={1}
                            maxRows={1}
                            // minStrLen={3}
                            maxStrLen={255}
                            // active={worldTestTA?.length > 0 ? "true" : "false"}
                            value={emailInp}
                            active="true"
                            autoComplete="off"
                            // autoCapitalize="none"
                          />
                        </div>
                        <button
                          className="UI-button-service"
                          style={{ alignSelf: "center" }}
                          disabled={!validEmail}
                          form="newuser_form"
                          type="submit"
                        >
                          Sign in
                        </button>
                      </div>
                    </form>
                    <button
                      onClick={handleSignInUser}
                      style={{
                        position: "fixed",
                        left: "50%",
                        bottom: "10px",
                        transform: "translate(-50%, -50%)",
                        margin: "0 auto",
                        backgroundColor: "white",
                        border: "0.06rem solid var(--modal_border_color)",
                        borderRadius: "var(--border_radius_small)"
                      }}
                      // className="UI-button-confined"
                    >
                      {`Or sign in with your`}
                      <br />
                      {`username and password`}
                    </button>
                  </div>
                )))}
            {signedIn && (
              <div
                className="fade-in-text"
                style={{
                  textAlign: "center",
                  width: "80%"
                }}
              >
                <p
                  style={{
                    fontSize: "1.5rem",
                    wordBreak: "break-all"
                  }}
                >
                  {emailInp}
                </p>
                An email has been sent to the above address with a link to
                access the app.
              </div>
            )}
          </div>
        </div>
      )}
      {!emailFound && <UIRegister />}
    </div>
  );
};

export default UIFlash