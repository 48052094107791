import React, { useState, useContext, useEffect } from "react";
import DatabaseContext from "data/contextDatabase";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { WorldTextInput } from "utils/UtilsUniversal";

const UINumTopicsModal = (props) => {
  let history = useHistory();
  const [changeHgt, setChangeHgt] = useState(false);
  const [scrnHgt, setScrnHgt] = useState(0);
  const [focusVal, setFocusVal] = useState(true);
  const [numTopicsInp, setNumTopicsInp] = useState("");
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const { databaseState } = useContext(DatabaseContext);

  useEffect(() => {
    function handleResize() {
      setScrnHgt(window.innerHeight * 0.9);
      setChangeHgt(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onSubmitNumTopics = (numTopics) => {
    let numT = parseInt(numTopics);
    if (numT && numT > 0) {
      localStorage.setItem("numTopics", numT);
      databaseState.dexUser &&
        databaseState.dexUser.account.put({
          type: "numTopics",
          value: numT,
          time: Date.now()
        });
      setModalClose(true);
      history.goBack();
      props.onClose();
    } else {
      alert("That is not a positive integer.");
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (window.location?.href?.includes("menu")) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      window.history.pushState({ id: 2 }, null, "");
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    history.goBack();
    props.onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (numTopicsInp === undefined || numTopicsInp === "") {
      alert("Number cannot be blank");
    } else {
      onSubmitNumTopics(numTopicsInp);
    }
  };

  const handleNumTopicsInp = (e) => {
    setNumTopicsInp(e);
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  let content = (
    <div id="numTopics_modal">
      <form onSubmit={handleSubmit}>
        <Modals
          loginModal={true}
          title="Limit the number of topics"
          onClose={() => handleClose()}
          onClickOutside={() => handleClickOutside()}
          clickOutsideActive={true}
          style={
            changeHgt && window.innerWidth <= 1024 ? { maxHeight: scrnHgt } : {}
          }
          styleBody={
            changeHgt && window.innerWidth <= 1024
              ? { maxHeight: scrnHgt * 0.5 }
              : {}
          }
          footer={
            <button
              className="UI-button-service"
              type="submit"
              onClick={handleSubmit}
              disabled={numTopicsInp.length <= 0}
            >
              Set limit
            </button>
          }
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%"
            }}
          >
            <WorldTextInput
              identity="num"
              focus={focusVal}
              removeFocus={(val) => handleFocus(val)}
              labelStyle={{
                fontWeight: "bold"
              }}
              type="text"
              callback={(e) => handleNumTopicsInp(e)}
              label="Number of topcs"
              required={true}
              placeholder="Enter number of topics"
              minRows={1}
              maxRows={1}
              maxStrLen={10}
              value={numTopicsInp}
              active="true"
              autoComplete="off"
              // autoCapitalize="none"
            />
          </div>
        </Modals>
      </form>
    </div>
  );
  return content;
};

export default React.memo(UINumTopicsModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
