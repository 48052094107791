export const onScrollChange = (element, delay, onStart, onStop) => {
  let timer;
  let scrolling;
  element.onscroll = () => {
    !scrolling && onStart();
    scrolling = true;
    clearTimeout(timer);
    timer = setTimeout(() => {
      scrolling = false;
      onStop();
    }, delay);
  };
};
