import React, { useState, useEffect, useContext, useRef } from "react";
import GlobalContext from "contexts/context";
import Modals from "./Modals";
import "./modals.css";
import "ui/UI.css";
import { useHistory } from "react-router-dom";
import { WorldTextInput, ColorOptions } from "utils/UtilsUniversal";
import { GrLock, GrUnlock } from 'react-icons/gr'
import { RiDeleteBinFill } from 'react-icons/ri'
import { dex_action } from "data/dexUtils";
import DatabaseContext from "data/contextDatabase";
import TopicsContext from "contexts/contextTopics";
import LabelTag from "./icons/LabelTag";
import ClickOutsideDetector from "utils/ClickOutsideDetector";

const UILabels = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const [modalClose, setModalClose] = useState(false);
  let history = useHistory();
  const [newLabel, setNewLabel] = useState("");
  const [oldLabel, setOldLabel] = useState("");
  const [editedLabel, setEditedLabel] = useState(undefined)
  const [labelListArr, setLabelListArr] = useState([])
  const [focusVal, setFocusVal] = useState(true);
  const [selectedList, setSelectedList] = useState([])
  const [changed, setChanged] = useState(false)
  const [selectChanged, setSelectChanged] = useState(false)
  const bottomRef = useRef(null)
  const [added, setAdded] = useState(false)
  const { topicsState,topicsDispatch } = useContext(TopicsContext);
  const [temporaryText, setTemporaryText] = useState([])
  const [selectLabelColor, setSelectLabelColor] = useState(-1)
  const [selectedColor, setSelectedColor] = useState("black");
  const [mnuX, setMnuX] = useState(0)
  const [mnuY, setMnuY] = useState(0)
  const labelColors = [
    "#0000FF",// blue 
    "#ffde0b",// yellow 
    "#64dd17",// light green
    "#ff9907",// orange
    "#a636d0",// purple
    "#008800",// dark green
    "#c45542",// brown
    "#000000"// black
  ]
  const inputRefs = useRef([])
  inputRefs.current = []

  const addToRefs = (el) => {
    if (el && !inputRefs.current.includes(el)) {
      inputRefs.current.push(el)
    }
  }

  useEffect(() => {
    const updatedLabelList = globalState.prefs?.labelList?.map((label) => {
      if (!label.hasOwnProperty("color")) {
        return { ...label, color: "black" };
      }
      return label;
    });

    const updatedPrefs = globalState.prefs.labelNamesList?.map((pref) => {
      if (pref.labels) {
        let newArr = [];
        for (let i = 0; i < pref.labels.length; i++) {
          if (typeof pref.labels[i] !== "object") {
            newArr.push({ color: "black", text: pref.labels[i] });
          } else newArr.push(pref.labels[i]);
        }
        return {
          ...pref,
          labels: newArr
        };
      }
    });

    if (globalState.prefs?.labelNamesList && globalState.prefs?.labelList) {
      globalDispatch({
        type: "SET_PREFS",
        values: {
          prefs: {
            ...globalState.prefs,
            labelList: updatedLabelList,
            labelNamesList: updatedPrefs
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // event.preventDefault()
      if (
        event.ctrlKey &&
        event.altKey &&
        (event.key === "C" || event.key === "c")
      ) {
        event.preventDefault();
        handleClearThisTopic();
      } else if (
        event.ctrlKey &&
        event.altKey &&
        (event.key === "A" || event.key === "a")
      ) {
        event.preventDefault();
        handleClearAll();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (globalState.prefs?.labelList) {
      setLabelListArr(globalState.prefs?.labelList);
    }
    if (globalState.prefs?.labelNamesList) {
      let found = false;
      for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
        if (
          globalState.prefs?.labelNamesList[i]?.mtopic ===
            globalState.displaying_topic &&
          globalState.prefs?.labelNamesList[i]?.mpersona ===
            globalState.persona?.mpersona
        ) {
          setSelectedList([...globalState.prefs?.labelNamesList[i].labels]);
          found = true;
          break;
        }
      }
      if (!found) setSelectedList([]);
    }
  }, []);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    if (history.length > 1) history.goBack();
  };

  const handleOnChange = (e) => {
    setNewLabel(e);
  };

  const addItem = (event) => {
    event.preventDefault();
    let finLabel = newLabel.trim();
    if (finLabel !== "") {
      let found = false;
      for (let i = 0; i < labelListArr.length; i++) {
        if (labelListArr[i].text === finLabel) {
          found = true;
          window.alert(`${finLabel} already exists`);
          break;
        }
      }
      if (!found) {
        setLabelListArr([
          ...labelListArr,
          {
            id: labelListArr.length,
            text: finLabel,
            lock: false,
            color: "black"
          }
        ]);
        setNewLabel("");
        handleFocus(false);
        let element = document.getElementById("labelTopic");
        element.blur();
        setChanged(true);
        databaseState.dexUser &&
          databaseState.dexUser.account.put({
            type: "labels",
            value: [
              ...labelListArr,
              {
                id: labelListArr.length,
                text: finLabel,
                lock: false,
                color: "black"
              }
            ],
            time: Date.now()
          });
      }
    }

    setAdded(true);
  };

  useEffect(() => {
    if (added) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
      setAdded(false);
    }
  }, [added]);

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const deleteItem = (position) => {
    // position is index in labelList
    // topicsDispatch({
    //   type: "SET_LABEL_DISPLAY",
    //   values: { labelDisplay: undefined }
    // });
    let itemToBeDeleted = labelListArr[position].text;
    if (
      window.confirm(
        `${itemToBeDeleted} will be deleted from all topics it is associated with.`
      ) === true
    ) {
      const newArray = labelListArr.filter(
        (item) => item.text !== itemToBeDeleted
      );
      setLabelListArr(newArray);
      setChanged(true);
    } // need to change selected!
    for (let i = 0; i < selectedList.length; i++) {
      if (selectedList[i].text === itemToBeDeleted) {
        const newSelArray = selectedList.filter(
          (item) => item.text !== itemToBeDeleted
        );
        setSelectedList(newSelArray);
        break;
      }
    } //need to remove the label from all topics containg the label
    const updatedLabelNamesList = (globalState.prefs?.labelNamesList || [])
      .map((item) => {
        if (!item.labels || item.labels.length === 0) {
          return null; // Skip items with empty labels
        }
        const tmpArr = item.labels.filter(
          (itemLabel) => itemLabel.text !== itemToBeDeleted
        );
        if (tmpArr.length > 0) {
          return {
            labels: tmpArr,
            mpersona: item.mpersona,
            mtopic: item.mtopic
          };
        }
        return null; // Skip items where all labels were removed
      })
      .filter((item) => item !== null); // Remove items that were skipped

    globalDispatch({
      type: "SET_PREFS",
      values: {
        prefs: {
          ...globalState.prefs,
          labelNamesList: [...updatedLabelNamesList]
        }
      }
    });
    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "user",
        doc: {
          key: "prefs",
          value: {
            ...globalState.prefs,
            labelNamesList: updatedLabelNamesList
          }
        }
      }
    });
  };

  const toggleLockItem = (position) => {
    // position is index in labelList
    // topicsDispatch({
    //   type: "SET_LABEL_DISPLAY",
    //   values: { labelDisplay: undefined }
    // });
    if (labelListArr[position].lock === true) {
      setLabelListArr((prevLabels) =>
        prevLabels.map((label) =>
          label.id === position
            ? {
                id: position,
                text: label.text,
                lock: false,
                color: label.color
              }
            : label
        )
      );
    } else {
      setLabelListArr((prevLabels) =>
        prevLabels.map((label) =>
          label.id === position
            ? { id: position, text: label.text, lock: true, color: label.color }
            : label
        )
      );
    }
    setChanged(true);
  };

  const handleClearThisTopic = () => {
    let topicName = "";
    for (let i = 0; i < topicsState.topics.length; i++) {
      if (
        topicsState.topics[i].hasOwnProperty("mtopic") &&
        topicsState.topics[i].mtopic === globalState.displaying_topic
      ) {
        if (topicsState.topics[i].props?.topic_display_name)
          topicName = topicsState.topics[i].props?.topic_display_name;
        else if (topicsState.topics[i].topic)
          topicName = topicsState.topics[i].topic;
        break; // Exit the loop once the matching key-value pair is found
      }
    }
    if (
      window.confirm(
        `Please confirm:\nClear all labels on ${topicName}.\nThis excludes locked labels`
      ) === true
    ) {
      //globalState.prefs.localNamesList is the array containing mpersona and mtopic links/descriptions and
      //an array containing selected lables on the topic which is called labels
      //the current topic is globalState.displaying_topic
      //traverse globalState.prefs.labelNamesList -
      //if an entry exists with globalState.prefs.localNamesList[i].mtopic = globalState.displaying_topic
      //and globalState.prefs.localNamesList[i].mpersona =globalState.persona?.mpersona then
      //setSelectedList to contain only the locked labels - ie remove unlocked labels
      if (globalState.prefs?.labelNamesList) {
        for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
          if (
            globalState.prefs?.labelNamesList[i]?.mtopic ===
              globalState.displaying_topic &&
            globalState.prefs?.labelNamesList[i]?.mpersona ===
              globalState.persona?.mpersona
          ) {
            let filteredList = [];
            selectedList.forEach((item) => {
              const foundItem = labelListArr.find(
                (element) => element.text === item.text && element.lock === true
              );
              if (foundItem) {
                filteredList.push(item);
              }
            });
            setSelectedList(filteredList);
            setSelectChanged(true);
          }
        }
      }
    }
  };

  const handleClearAll = () => {
    //search globalState.prefs.labelList (an array) for any labels
    //then we set that objects .labels array to an empty array
    //looking at globalState.labelNamesList we get objects with an array of labels
    //attached to mpersona and mtopic
    //remove all lables from the array that are not locked
    //we get the locked lables from labelListArr
    if (
      window.confirm(
        `Please confirm:\nAll checked labels will be removed from all topics.\nThis excludes locked labels`
      ) === true
    ) {
      const lockedLabels = labelListArr
        .filter((item) => item.lock === true)
        .map((item) => item.text);
      //then we check the array labelNamesList- if labelNamesList[i].labels exists
      // we remove all of the labels unless they are found in the lockedLabels array
      let lnl = [];
      for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
        let tmpObj = {};
        if (
          globalState.prefs?.labelNamesList[i]?.labels &&
          globalState.prefs?.labelNamesList[i]?.labels?.length > 0
        ) {
          let newArr = [];
          for (
            let j = 0;
            j < globalState.prefs?.labelNamesList[i]?.labels?.length;
            j++
          ) {
            const found = lockedLabels.includes(
              globalState.prefs?.labelNamesList[i]?.labels[j].text
            );
            if (found) {
              newArr = [
                ...newArr,
                globalState.prefs?.labelNamesList[i]?.labels[j]
              ];
            }
          }
          tmpObj = {
            labels: newArr,
            mpersona: globalState.prefs.labelNamesList[i].mpersona,
            mtopic: globalState.prefs.labelNamesList[i].mtopic
          };
          if (newArr) lnl = [...lnl, tmpObj];
        }
      }
      globalDispatch({
        type: "SET_PREFS",
        values: {
          prefs: {
            ...globalState.prefs,
            labelNamesList: lnl
          }
        }
      });
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: {
            key: "prefs",
            value: {
              ...globalState.prefs,
              labelNamesList: lnl
            }
          }
        }
      });
      const filteredList = [];
      selectedList.forEach((item) => {
        const foundItem = labelListArr.find(
          (element) => element.text === item.text && element.lock === true
        );
        if (foundItem) {
          filteredList.push(item);
        }
      });
      setSelectedList(filteredList);
    }
  };

  const handleClearAllLabels = () => {
    //need to keep locked items??
    if (
      window.confirm(
        `Please confirm:\nAll labels will be removed from all topics and this label list will be deleted.\nAre you Sure?\nThis includes locked labels\nThis cannot be undone?`
      ) === true
    ) {
      globalDispatch({
        type: "SET_PREFS",
        values: {
          prefs: {
            ...globalState.prefs,
            labelNamesList: []
          }
        }
      });
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: {
            key: "prefs",
            value: {
              ...globalState.prefs,
              labelNamesList: []
            }
          }
        }
      });
      setLabelListArr([]);
      globalDispatch({
        type: "SET_PREFS",
        values: {
          prefs: {
            ...globalState.prefs,
            labelList: [...labelListArr] //available labels object
          }
        }
      });
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: {
            key: "prefs",
            value: {
              ...globalState.prefs,
              labelList: [...labelListArr]
            }
          }
        }
      });
      setSelectedList([]);
    }
  };

  const toggleChangeCheck = (position) => {
    //position is the index in the labelList array
    //cannot have 2 labels with same text for label
    let found = false;
    for (let i = 0; i < selectedList?.length; i++) {
      if (typeof selectedList[i] === "object") {
        if (selectedList[i].text === labelListArr[position].text) {
          found = true;
          setSelectedList((selectedList) =>
            selectedList.filter((oa) => oa.text !== labelListArr[position].text)
          );
        }
      } else {
        if (selectedList[i] === labelListArr[position].text) {
          found = true;

          setSelectedList((selectedList) =>
            selectedList.filter((oa) => oa !== labelListArr[position].text)
          );
        }
      }
    }
    setSelectChanged(true);
    if (!found) {
      setSelectedList([
        ...selectedList,
        {
          text: labelListArr[position].text,
          color: labelListArr[position].color
        }
      ]);
    }
  };

  useEffect(() => {
    if (changed) {
      globalDispatch({
        type: "SET_PREFS",
        values: {
          prefs: {
            ...globalState.prefs,
            labelList: [...labelListArr] //available labels object
          }
        }
      });
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: {
            key: "prefs",
            value: {
              ...globalState.prefs,
              labelList: [...labelListArr]
            }
          }
        }
      });
      setChanged(false);
    }
  }, [labelListArr, changed]);

  useEffect(() => {
    //globalState.prefs can hold a labelsNamesList
    //labelsNamesList will be an array of objects with {mtopic:mtopic, mpersona:mpersona, labelsArr:labelList
    //we replace or insert new labelNamesList with associated mtopic and mpersona to prefs
    if (selectChanged) {
      //check if globalState.prefs.labelNamesList exists
      if (globalState.prefs?.labelNamesList?.length > 0) {
        //if this does not exist a label must have been checked
        if (editedLabel) {
          const updatedLabelNamesList = globalState.prefs.labelNamesList.map(
            (item) => {
              const updatedLabels = item.labels.map((label) => {
                return label.text === oldLabel.text ? editedLabel : label;
              });
              return { ...item, labels: updatedLabels };
            }
          );

          globalDispatch({
            type: "SET_PREFS",
            values: {
              prefs: {
                ...globalState.prefs,
                labelList: labelListArr,
                labelNamesList: updatedLabelNamesList
              }
            }
          });
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "user",
              doc: {
                key: "prefs",
                value: {
                  ...globalState.prefs,
                  labelList: labelListArr,
                  labelNamesList: updatedLabelNamesList
                }
              }
            }
          });
          setEditedLabel(undefined);
        } else {
          let found = false;
          for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
            //check if there is an item with mpersona=current and mtopic=current
            if (
              globalState.prefs?.labelNamesList[i].mtopic ===
                globalState.displaying_topic &&
              globalState.prefs?.labelNamesList[i].mpersona ===
                globalState.persona?.mpersona
            ) {
              found = true;
              let lnl = globalState.prefs.labelNamesList.map((item, index) =>
                index === i ? { ...item, labels: selectedList } : item
              );
              globalDispatch({
                type: "SET_PREFS",
                values: {
                  prefs: {
                    ...globalState.prefs,
                    labelList: labelListArr,
                    labelNamesList: lnl
                    // [
                    //   ...globalState.prefs.labelNamesList.map((item, index) =>
                    //     index === i ? { ...item, labels: selectedList } : item
                    //   )
                    // ]
                  }
                }
              });
              dex_action({
                type: "DEX_PUT",
                values: {
                  db: databaseState.dexUser,
                  table: "user",
                  doc: {
                    key: "prefs",
                    value: {
                      ...globalState.prefs,
                      labelList: labelListArr,
                      labelNamesList: lnl
                      // [
                      //   ...globalState.prefs.labelNamesList.map((item, index) =>
                      //     index === i ? { ...item, labels: selectedList } : item
                      //   )
                      // ]
                    }
                  }
                }
              });
            }
          }
          if (!found) {
            let labelNamesLista = {
              mtopic: props?.mtopic,
              mpersona: props?.mpersona,
              labels: selectedList
            };
            globalDispatch({
              type: "SET_PREFS",
              values: {
                prefs: {
                  ...globalState.prefs,
                  labelList: labelListArr,
                  labelNamesList: [
                    ...globalState.prefs.labelNamesList,
                    labelNamesLista
                  ]
                }
              }
            });
            dex_action({
              type: "DEX_PUT",
              values: {
                db: databaseState.dexUser,
                table: "user",
                doc: {
                  key: "prefs",
                  value: {
                    ...globalState.prefs,
                    labelList: labelListArr,
                    labelNamesList: [
                      ...globalState.prefs.labelNamesList,
                      labelNamesLista
                    ]
                  }
                }
              }
            });
          }
        }
      } else {
        //what to do when ctl-c clear all checked labels happens
        let labelNamesList = [
          {
            mtopic: props?.mtopic,
            mpersona: props?.mpersona,
            labels: selectedList
          }
        ];
        globalDispatch({
          type: "SET_PREFS",
          values: {
            prefs: {
              ...globalState.prefs,
              labelList: labelListArr,
              labelNamesList: [...labelNamesList] //new labels object
            }
          }
        });
        dex_action({
          type: "DEX_PUT",
          values: {
            db: databaseState.dexUser,
            table: "user",
            doc: {
              key: "prefs",
              value: {
                ...globalState.prefs,
                labelList: labelListArr,
                labelNamesList: [...labelNamesList]
              }
            }
          }
        });
      }
      setSelectChanged(false);
    }
    setOldLabel("");
  }, [selectedList, selectChanged]);

  const handleLabelChange = (id, newValue, event, index) => {
    //editing label
    // topicsDispatch({
    //   type: "SET_LABEL_DISPLAY",
    //   values: { labelDisplay: undefined }
    // });
    setOldLabel(labelListArr[index]);
    setEditedLabel({
      text: newValue,
      color: labelListArr[index].color || "black"
    });
    let tmpArr = [...temporaryText];
    tmpArr[index] = newValue;
    setTemporaryText(tmpArr);
    // setSelectChanged(true)
  };

  const handleInputFocus = (id, text, index) => {
    let newArr = labelListArr.map((item) => item.text);
    setTemporaryText([...newArr]);
  };

  const handleKeyDown = (event, newValue, id, index) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const alphanumericPattern =
        /^[a-zA-Z0-9!\s\"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]+$/;
      let finValue = newValue.trim();
      if (finValue !== newValue) {
        let tmpArr = [...temporaryText];
        tmpArr[index] = finValue;
        setTemporaryText(tmpArr);
      }
      if (alphanumericPattern.test(finValue)) {
        let oldValue = labelListArr.find((obj) => {
          return obj.id === id;
        }).text;
        let tmpLabelListArr = labelListArr.map((label) => {
          return label.id === id
            ? { id: id, text: finValue, lock: label.lock, color: label.color }
            : label;
        });
        setLabelListArr(tmpLabelListArr);

        setChanged(true);
        // if label is in selected list update selected list
        let found = false;
        for (let i = 0; i < selectedList.length; i++) {
          // if (typeof selectedList[i] === 'object') {
          if (selectedList[i].text === oldValue) {
            found = true;
            const newSelArray = selectedList.filter((item) => {
              return item.text !== oldValue;
            });
            newSelArray.push({ text: finValue, color: "black" });
            setSelectedList(newSelArray);
            setSelectChanged(true); //these will effect a change to labelNamesList
            break;
          }
          //the name is in selectedlist but it must still be updated in globalstate
          //look at globalstate- find oldvalue and change it to newvalue
          let tmpLab = [];

          for (let k = 0; k < globalState.prefs?.labelNamesList?.length; k++) {
            if (
              typeof globalState.prefs?.labelNamesList[k]?.labels === "object"
            ) {
              globalState.Prefs?.labelNamesList[k]?.labels.map((item) => {
                if (item.text === oldValue) {
                  tmpLab.push({ "text": newValue, "color": item.color });
                } else tmpLab.push(item);
              });
            } else {
              globalState.prefs?.labelNamesList[k]?.labels.map((item) => {
                if (item === oldValue) {
                  tmpLab.push({ "text": newValue, "color": "black" });
                } else tmpLab.push({ "text": item, "color": "black" });
              });
            }
          }
          globalDispatch({
            type: "SET_PREFS",
            values: {
              prefs: {
                ...globalState.prefs,
                labelList: tmpLabelListArr,
                labelNamesList: [...tmpLab] //new labels object
              }
            }
          });
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "user",
              doc: {
                key: "prefs",
                value: {
                  ...globalState.prefs,
                  labelList: tmpLabelListArr,
                  labelNamesList: [...tmpLab]
                }
              }
            }
          });
        }
        if (!found) {
          //not in selectedList
          let lnl = [];
          for (let i = 0; i < globalState.prefs?.labelNamesList?.length; i++) {
            let tmpObj = {};
            //here we need to check for object and react accordingly
            //globalState.prefs?.labelNamesList.labels is an array
            //so we iterate through the array checking if one of the objects in the array has a key with a value === oldValue
            //then we remove that item from the array storing the result in newArr
            // Check if labels is an array or an array of objects
            // Now, update the text in the array of objects
            const indexToUpdate = globalState.prefs.labelNamesList[
              i
            ].labels.findIndex((label) => label.text === oldValue);

            if (indexToUpdate !== -1) {
              globalState.prefs.labelNamesList[i].labels[indexToUpdate].text =
                finValue;
            }
          }
          if (
            lnl?.length <= 0 &&
            globalState.prefs?.labelNamesList?.length > 0
          ) {
            lnl = [...globalState.prefs?.labelNamesList];
          }

          globalDispatch({
            type: "SET_PREFS",
            values: {
              prefs: {
                ...globalState.prefs,
                labelList: tmpLabelListArr,
                labelNamesList: [...lnl] //new labels object
              }
            }
          });
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "user",
              doc: {
                key: "prefs",
                value: {
                  ...globalState.prefs,
                  labelList: tmpLabelListArr,
                  labelNamesList: [...lnl]
                }
              }
            }
          });
        }
        //if the text is anywhere in globalState.prefs.labelNamesList it muts also be changed there
      }
      inputRefs?.current[index]?.blur();
    }
  };

  const handleInputBlur = () => {
    setTemporaryText([]);
  };

  const changeLabelColor = (index, event) => {
    const modalContent = document.getElementById("list-container");
    const x = event.clientX - modalContent.getBoundingClientRect().left;
    const y = event.clientY - modalContent.getBoundingClientRect().top;
    setMnuX(x);
    setMnuY(y);
    setSelectLabelColor(index);
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    const updatedLabelListArr = [...labelListArr];
    updatedLabelListArr[selectLabelColor].color = color;
    setLabelListArr(updatedLabelListArr);
    //if the label is selected we need to make changes to gloablState.prefs.labelNamesList
    //check if the label is included in globalState.prefs.labelNamesList
    //if so, change the label description in globalState.prefs.labelNamesList to include the color
    //a couple of scenarios exist- the labelNamesList may be old format
    //ie no colors are present labelNamesList only has the text of the labels
    //labelNamesList may be new format - an object where we have text and color
    let transformedLabels;
    let lnl = [];
    for (let i = 0; i < globalState.prefs.labelNamesList?.length; i++) {
      let tmpObj = {};
      //iterate through the labels find out if this is new format
      if (typeof globalState.prefs.labelNamesList[i]?.labels[0] === "object") {
        transformedLabels = globalState.prefs.labelNamesList[i].labels?.map(
          (item) => {
            if (item.text === labelListArr[selectLabelColor]?.text)
              return { text: item.text, color: color };
            else
              return {
                text: item.text,
                color: item.color ? item.color : "black"
              };
          }
        );
      }
      //see if globalState.prefs.labelNamesList[i].labels contains the text of the label
      //     //now change globalState.prefs.labelNamesList[i].labels.color
      else {
        //old format
        transformedLabels = globalState.prefs.labelNamesList[i].labels?.map(
          (item) => {
            if (item === labelListArr[selectLabelColor].text)
              return { text: item, color: color };
            else return { text: item, color: "black" };
          }
        );
        //see if globalState.prefs.labelNamesList[i].labels contains the text of the label
        //change globalState.prefs.labelNamesList[i]?.labels to the new format with new values
      }
      tmpObj = {
        labels: transformedLabels,
        mpersona: globalState.prefs.labelNamesList[i].mpersona,
        mtopic: globalState.prefs.labelNamesList[i].mtopic
      };
      lnl = [...lnl, tmpObj];
    }
    globalDispatch({
      type: "SET_PREFS",
      values: {
        prefs: {
          ...globalState.prefs,
          labelNamesList: lnl
        }
      }
    });
    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "user",
        doc: {
          key: "prefs",
          value: {
            ...globalState.prefs,
            labelNamesList: lnl
          }
        }
      }
    });

    setSelectLabelColor(-1);
  };

  const handleClickOutsideColorMenu = () => {
    setSelectLabelColor(-1)
  };

  let content =
    <div id={"labels"}>
      <Modals
        title="Label"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={selectLabelColor >= 0 ? false : true}
        switchScrollOff={true}
      >
        <div
          id="list-container"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxHeight: "62vh",
          }}>
          <div
            style={{
              flexGrow: "1",
              overflowY: "auto"
            }}>
            {labelListArr?.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="checkbox"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "2rem 1fr 7.5rem",
                      marginBottom: "0.5rem"
                    }}
                  >
                    <input
                      className="checkbox"
                      type="checkbox"
                      key={item.id}
                      checked={selectedList.some((i) => {
                        if (typeof i === "object") {
                          return i.text === item.text;
                        } else {
                          return i === item.text;
                        }
                      })}
                      onChange={() => {
                        toggleChangeCheck(index); //selecting label
                      }}
                    ></input>
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        // width: "70%",
                        fontSize: "1.2rem",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {item.text}
                      {/* <input //editing label
                      type="text"
                        key={item.id}
                        ref={addToRefs}
                        value={temporaryText[index] !== undefined ? temporaryText[index] : item.text}
                        onChange={e => handleLabelChange(item.id, e.target.value, e, index)}
                        onFocus={() => handleInputFocus(item.id, item.text, index)}
                        onKeyDown={e => handleKeyDown(e, e.target.value, item.id, index)}
                        title="press enter to add label"
                        onBlur={handleInputBlur}
                      style={{
                        border: "none",
                        // width: "95%",
                        fontSize: "1.1rem",
                      }}
                    /> */}
                    </label>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "2.5rem 2.5rem 2.5rem",
                        alignItems: "center"
                      }}
                    >
                      <span
                        style={{
                          cursor: "pointer"
                        }}
                        title="Delete item"
                        onClick={() => deleteItem(index)}
                      >
                        <RiDeleteBinFill size={20} />
                      </span>
                      <span
                        style={{
                          cursor: "pointer"
                        }}
                        title={item.lock ? "Unlock item" : "Lock item"}
                        onClick={() => toggleLockItem(index)}
                      >
                        {item.lock ? (
                          <GrLock size={16} />
                        ) : (
                          <GrUnlock size={16} />
                        )}
                      </span>
                      <span
                        style={{
                          cursor: "pointer"
                        }}
                        title="color labels"
                        onClick={(event) => changeLabelColor(index, event)}
                      >
                        <LabelTag
                          strokeColor="black"
                          // strokeColor={item.color}
                          fillColor={item.color}
                          holeFillColor="white"
                          tagHeight="22"
                          tagWidth="22"
                        />
                      </span>
                    </div>
                  </div>
                  <div ref={bottomRef} />
                </div>
              );
            })
            }
          </div>
          <div>
          <form
            id="labelForm"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "80%",
              fontSize: "1.1rem",
              marginTop: "0.5rem"
            }}
            onSubmit={addItem}>
            <WorldTextInput
                identity="labelTopic"
              type="text"
              callback={(e) => handleOnChange(e)}
                placeholder={"Add label and press enter"}
              minRows={1}
              maxRows={1}
              maxStrLen={40}
              value={newLabel}
                // regex={/^[a-zA-Z0-9]+$/}
                regex={/^[a-zA-Z0-9!\"#$%&'()*+,\-./:;<=>?@\[\\\]^_`{|}~]+$/}
                title={"Press enter key to add label"}
              />
          </form>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "right",
                marginTop: "0.5rem",
                fontWeight: "bold"
              }}>
              Bulk clear all labels from:
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(0, 1fr))",
                gap: "0.5rem",
                marginRight: "3rem",
                width: `calc( 2 * 6.5rem)`//no buttons * width of one btn+gap
              }}>
              <button
                className="UI-button-service"
                title="clear all labels on this topic only"
                onClick={handleClearThisTopic}>
                This topic
              </button>
              <button
                className="UI-button-service"
                title="clears all checked labels on all topics"
                onClick={handleClearAll}>
                All topics
              </button>
            </div>
          </div>
          {selectLabelColor >= 0 &&
            <ClickOutsideDetector
              caller="selectLabelColor"
              listen
              onClickOutside={(e) => {
                if (e)
                  e.stopPropagation()
                handleClickOutsideColorMenu()
              }}
              onClick={(e) => { if (e) e.stopPropagation() }}
            >
              <div
                className=""
                style={{
                  top: selectLabelColor === 0 ? `${mnuY + 30}px` : `${mnuY}px`,
                  position: "absolute",
                  left: `${mnuX - 180}px`,
                  textAlign: "left",
                  fontSize: "1rem",
                  color: "var(--menu_text_color)",
                  height: "120px",
                  width: "165px",
                  border: "solid 1px var(--menu_border_color)",
                  borderRadius: "10px",
                  padding: "5px",
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  boxShadow: "var(--box_shadow)"
                }}
              >
                <span>Select label color</span>
                <br></br>
                <div
                  style={{ marginBottom: "0.5rem" }}>
                  {labelListArr[selectLabelColor]?.text}
                </div>
                <ColorOptions
                  colors={labelColors}
                  selectedColor={selectedColor}
                  onColorSelect={(color) => handleColorSelect(color)}
                />
              </div>
            </ClickOutsideDetector>
          }
        </div >
      </Modals >
    </div >

  return content;
};

export default React.memo(UILabels, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
