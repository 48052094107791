import React, { useContext, useState, useEffect, useRef } from "react";
import "utils/utilsUniversal.css";
import { InputBase, makeStyles } from "@material-ui/core"
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import GlobalContext from "contexts/context";
import { ImBin } from "react-icons/im";

// in this file:
// convHrsMins converts a timestamp to format HH:mm or HH:mm:ss
// WorldTextInput input of types of text- text, textWithClear, textArea, phone, password
// checkPhone
// checkEmail
// CheckSAIdentityNumber
// DispTopicHdr used to display topic details in invite persona
// getIcon returns text related to group type
// hexToRGBArray converts hex color (#123456) to rgb array
// ColorOptions used in UILabels for displaying colors for labels

export const convHrsMins = (time, timeFormatHMS) => {
  if (time === undefined || time?.length < 1) return "-";
  if (timeFormatHMS === "HH:mm:ss") return time;
  let secStr = time?.split(":");
  if (secStr?.length === 3) return secStr[0] + ":" + secStr[1];
  else return time;
};

// export const removeLeadTrailSpace = (str) => {
//   return (
//     str.trim()
//   )
// }

function useClearableInput(initialValue = "") {
  const inputRef = useRef(null);

  const clearInput = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  };

  return [inputRef, clearInput, initialValue];
}

export const WorldTextInput = (props) => {
  //this generic text input can handle
  //type=text (single line input), type=textarea (multi-line input),z
  //type=textWithClear - has button to reset content to empty string
  //it can check input for a regex string
  //i.e numbers only or alpha-numeric only or whatever
  //it can check for email, telephone (landline or mobile)
  //it takes on the styling provided but if that is not described
  //default styling {modal-label-new etc) is used}
  const [inputRef, clearInput, initialValue] = useClearableInput(props.value);

  const focusRef = useRef(null);

  const interimStyle =
    props.active === "false"
      ? {
          opacity: "0.5"
        }
      : {};

  const finalStyle = { ...props.inpStyle, ...interimStyle };

  const passwordRef = useRef(null);
  useEffect(() => {
    if (props?.focus && focusRef?.current) focusRef?.current?.focus();
    if (props?.focus && passwordRef?.current) passwordRef?.current?.focus();
  }, [props?.focus]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const useStyles = makeStyles((theme) => ({
    inputBase: {
      minWidth: props.minWidth,
      padding: "0.25rem",
      marginRight: "0.5rem",
      marginBottom: props.marginBottom,
      minHeight: props.minHeight,
      fontSize: props.fontSize,
      border: props.border,
      borderRadius: props.border_radius,
      backgroundColor: props.background_color,
      color: props.text_color,
      "& ::placeholder": {
        color: props.hint_color
      }
    }
  }));

  const classes = useStyles();
  const [validKeyCode, setValidKeyCode] = useState(false);
  const [handled, setHandled] = useState(false);
  const [cursor, setCursor] = useState();
  const [passwordShown, setPasswordShown] = useState(false);

  const handleKeyUp = (event, str) => {
    var RegEx = props.regex;
    if (!handled) {
      if (
        (props.maxStrLen && str?.length < props.maxStrLen) ||
        !props.maxStrLen
      ) {
        if (RegEx && str.length > 0) {
          setValidKeyCode(RegEx.test(str[str.length - 1]));
          if (!RegEx.test(str[str.length - 1])) {
            props.callback(str.substring(0, str.length - 1));
          }
        }
      } else {
        setValidKeyCode(false);
        alert(`too long - max ${props.maxStrLen} characters`); /*modify*/
      }
    }
  };

  const handleKeyDown = (event, str) => {
    //problem with Android- does not return same char codes as on Laptop
    //if keycode=229 (Android) let the value go through, then check on UP event
    if (props.shiftEnter && event.shiftKey && event.keyCode === 13) {
      props.shiftEnter();
      setHandled(true);
      setValidKeyCode(false);
    } else {
      if (
        event.altKey ||
        event.ctrlKey ||
        event.keyCode === 17 ||
        event.keyCode === 18
      ) {
        setValidKeyCode(false);
        setHandled(true);
      } else if (event.keyCode !== 229) {
        //alt and ctrl disabled 17,18
        if (event.keyCode >= 8 && event.keyCode <= 46) {
          //bckspc pgup, etc.
          setValidKeyCode(true);
        } else {
          var RegEx = props.regex;
          if (
            (props.maxStrLen && str?.length < props.maxStrLen) ||
            !props.maxStrLen
          ) {
            if (RegEx) setValidKeyCode(RegEx.test(event.key));
            else setValidKeyCode(true);
          } else {
            setValidKeyCode(false);
            alert(`String - max ${props.maxStrLen} characters`); /*modify*/
          }
        }
        setHandled(true);
      } else {
        setValidKeyCode(true);
      }
    }
  };

  const handleNewVal = (e) => {
    if (validKeyCode) {
      setCursor(e.target.selectionEnd);
      props.callback(e.target.value);
    }
  };

  const handleBlur = () => {
    props.callback2();
  };

  useEffect(() => {
    if (props.removeFocus) props.removeFocus(false);
  }, []);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleClearInp = () => {
    clearInput();
    props.callback("");
  };

  const pasteHandler = (event, str) => {
    let cursorPos = 0;
    if (props.identity)
      cursorPos = document.getElementById(props.identity)?.selectionStart;
    if (!cursorPos) cursorPos = 0;
    let tempStr = event.clipboardData.getData("text");
    if (props.regex) {
      let RegEx = props.regex;
      for (let count = 0; count < tempStr.length; count++) {
        if (!RegEx.test(tempStr[count])) {
          tempStr =
            tempStr.slice(0, count) + tempStr.slice(count + 1, tempStr.length);
          count--;
        }
      }
    }
    if (tempStr.length + str?.length > props.maxStrLen) {
      alert(`String will be truncated - max ${props.maxStrLen} characters`);
    }
    if (tempStr.length + str?.length > props.maxStrLen)
      tempStr = tempStr.slice(0, props.maxStrLen - str?.length);
    if (str)
      tempStr =
        str?.slice(0, cursorPos) + tempStr + str?.slice(cursorPos, str?.length);
    props.callback(tempStr);
    event.preventDefault();
  };

  switch (props.type) {
    case "text":
      return (
        <div style={props.universalStyle}>
          <label
            className={props.required ? "utils-label required" : "utils-label"}
            style={props.labelStyle}
            type={props.active}
            title={props.title}
          >
            {props.label}
          </label>
          <div
            style={
              props.lineStyle
                ? props.lineStyle
                : {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }
            }
          >
            <input
              id={props.identity}
              readOnly={props.readOnly}
              ref={focusRef}
              className={
                props.description ? "utils-input-noMrgn" : "utils-input"
              }
              style={finalStyle}
              type="text"
              disabled={props.active === "false"}
              onKeyUp={(e) => handleKeyUp(e, props.value)}
              onKeyDown={(e) => handleKeyDown(e, props.value)}
              onPaste={(e) => pasteHandler(e, props.value)}
              onFocus={(e) => {
                e.target.selectionStart = cursor;
                e.target.selectionEnd = cursor;
              }}
              onChange={(e) => {
                handleNewVal(e);
              }}
              onBlur={props.callback2 ? handleBlur : null}
              value={props.value}
              placeholder={props.placeholder}
              required={props.required}
              autoComplete={props.autoComplete}
              // autoCapitalize={props.autoCapitalize}
              title={props.title}
            />
            {props.bin && (
              <div onClick={props.callbackBin}>
                <ImBin style={props.binStyle} size={16} />
              </div>
            )}
          </div>
          <div className="description" style={props.descStyle}>
            {props.description}
          </div>
        </div>
      );
    case "textWithClear":
      return (
        <>
          <label
            className={props.required ? "utils-label required" : "utils-label"}
            style={props.labelStyle}
            type={props.active}
            title={props.title}
          >
            {props.label}
          </label>
          <div
            style={{
              border: "2px black solid",
              borderRadius: "var(--border_radius_medium)",
              marginRight: "0.5rem",
              display: "flex",
              alignItems: "center",
              height: "1.9em",
              padding: "0 1rem 0 0.2rem"
            }}
          >
            <input
              id={props.identity}
              readOnly={props.readOnly}
              ref={inputRef}
              className={
                props.description
                  ? "utils-input-noMrgn"
                  : "utils-input-with-clear"
              }
              style={{ outline: "none", border: "none", ...finalStyle }}
              type="text"
              disabled={props.active === "false"}
              onKeyUp={(e) => handleKeyUp(e, props.value)}
              onKeyDown={(e) => handleKeyDown(e, props.value)}
              onPaste={(e) => pasteHandler(e, props.value)}
              onFocus={(e) => {
                e.target.selectionStart = cursor;
                e.target.selectionEnd = cursor;
              }}
              onBlur={props.callback2 ? handleBlur : null}
              onChange={(e) => {
                handleNewVal(e);
              }}
              value={props.value}
              placeholder={props.placeholder}
              required={props.required}
              autoComplete={props.autoComplete}
              // autoCapitalize={props.autoCapitalize}
            />
            <button
              type="button"
              className="clearContent"
              aria-label="Close"
              onClick={handleClearInp}
              title="Clear contents"
            >
              <span aria-hidden="true">&times; </span>
            </button>
          </div>
          <div className="description" style={props.descStyle}>
            {props.description}
          </div>
        </>
      );
    case "textarea":
      return (
        <>
          {props.label && (
            <label
              className={
                props.required ? "utils-label required" : "utils-label"
              }
              style={props.labelStyle}
              type={props.active}
              title={props.title}
            >
              {props.label}
            </label>
          )}
          <InputBase
            id={props.identity}
            className={classes.inputBase}
            variant={props.variant}
            // margin="dense"
            disabled={props.active === "false"}
            onKeyUp={(e) => handleKeyUp(e, props.value)}
            onKeyDown={(e) => handleKeyDown(e, props.value)}
            onPaste={(e) => pasteHandler(e, props.value)}
            onFocus={(e) => {
              e.target.selectionStart = cursor;
              e.target.selectionEnd = cursor;
            }}
            onBlur={props.callback2 ? handleBlur : null}
            onChange={(e) => {
              handleNewVal(e);
            }}
            value={props.value}
            placeholder={props.placeholder}
            required={props.required}
            multiline
            minRows={props.minRows}
            maxRows={props.maxRows}
          />
          {props.description && (
            <span className="description" style={props.descStyle}>
              {props.description}
            </span>
          )}
        </>
      );
    case "password":
      return (
        <>
          <label
            className={props.required ? "utils-label required" : "utils-label"}
            style={props.labelStyle}
            type={props.active}
            title={props.title}
          >
            {props.label}
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            <input
              ref={passwordRef} //?
              className={
                props.description ? "utils-input-noMrgn" : "utils-input"
              }
              style={finalStyle}
              type={passwordShown ? "text" : "password"}
              disabled={props.active === "false"}
              onKeyUp={(e) => handleKeyUp(e, props.value)}
              onKeyDown={(e) => handleKeyDown(e, props.value)}
              onFocus={(e) => {
                e.target.selectionStart = cursor;
                e.target.selectionEnd = cursor;
              }}
              onBlur={props.callback2 ? handleBlur : null}
              onChange={(e) => {
                handleNewVal(e);
              }}
              value={props.value}
              placeholder={props.placeholder}
              required={props.required}
            />
            <button
              type="button"
              onClick={togglePassword}
              style={{
                border: "none",
                backgroundColor: "transparent",
                padding: "0",
                cursor: "pointer"
              }}
            >
              {passwordShown ? (
                <div style={{ width: "3rem" }}>
                  <AiOutlineEyeInvisible size={20} />
                </div>
              ) : (
                <div style={{ width: "3rem" }}>
                  <AiOutlineEye size={20} />
                </div>
              )}
            </button>
          </div>
        </>
      );
    case "phone":
      const mergedStyle = {
        ...props.inpStyle, // Merge with inpStyle prop
        padding: '0', // Additional padding style
      };
    
      return (
        <div style={props.universalStyle}>
          <label
            className={props.required ? "utils-label required" : "utils-label"}
            style={props.labelStyle}
            type={props.active}
            title={props.title}
          >
            {props.label}
          </label>
          <div
            style={
              props.lineStyle
                ? props.lineStyle
                : {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                  }
            }
          >
            <span className="textbox" style={props.inpStyle}>
              +
              <input
                ref={focusRef}
                className="section-readonly-input"
                type="text"
                style={mergedStyle}
                disabled={props.active === "false"}
                onKeyUp={(e) => handleKeyUp(e, props.value)}
                onKeyDown={(e) => handleKeyDown(e, props.value)}
                onFocus={(e) => {
                  e.target.selectionStart = cursor;
                  e.target.selectionEnd = cursor;
                }}
                onBlur={props.callback2 ? handleBlur : null}
                onChange={(e) => handleNewVal(e)}
                value={props.value}
                placeholder={props.placeholder}
                required={props.required}
              ></input>
            </span>
            {props.bin && (
              <div onClick={props.callbackBin}>
                <ImBin style={props.binStyle} size={16} />
              </div>
            )}
          </div>
        </div>
      );
    // case "email": hmmm is this needed can use text input with regex
    //   return (

    //   )
    // case "mobile": hmmm is this needed can use text input with regex
    //   return (

    //   )
    // case "numberonly": hmmm is this needed can use text input with regex
    //   return (

    //   )
    default:
      break;
  }
};

export const CheckPhone = (phnStr, minLen, maxLen) => {
  //checks phnStr - it must contain only numbers, it must be a max digits long
  //and min length long.
  const RegEx = /^[0-9]+$/;
  let valid = RegEx.test(phnStr);
  let errMsg = "";
  if (valid) errMsg = "ok";
  else errMsg = "Numeric";
  if (valid) {
    valid = phnStr.length >= minLen && phnStr.length <= maxLen;
    if (valid) errMsg = "ok";
    else errMsg = "length";
  }
  return errMsg;
};

export const CheckEmail = (emailStr) => {
  // Regular expression to validate email address
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const isValid = regex.test(emailStr);
  // setValid(isValid);
  return isValid;
};

export const CheckSAIdentityNumber = (
  IDStr,
  minLen,
  maxLen,
  dateStart,
  dateEnd
) => {
  //checks Identity No. - it must contain only numbers, it must be a max digits long
  //and min length long. The first 6 digits should be a date (YYMMDD) within dateRange
  //and it checks the number using the Luhn algorithm or Luhn formula,
  //datestart and dateend to be YYYY-MM-DD

  const checkLuhn = (purportedCC) => {
    let nDigits = purportedCC.length;
    let sum = parseInt(purportedCC[nDigits - 1]);
    let parity = (nDigits - 2) % 2;
    for (let i = 0; i < nDigits - 1; i++) {
      let digit = parseInt(purportedCC[i]);
      if (i % 2 === parity) digit = digit * 2;
      if (digit > 9) digit = digit - 9;
      sum = sum + digit;
    }
    return sum % 10 === 0;
  };

  let RegEx = /^[0-9]+$/;
  let valid = RegEx.test(IDStr);
  if (valid) {
    valid = IDStr.length >= minLen && IDStr.length <= maxLen;
    if (valid) {
      //check date
      let birthDate = IDStr?.toString().substring(0, 6);
      RegEx = /^[0-9]?[0-9]?[0-1]?[0-9]?[0-3]?[0-9]$/;
      if (RegEx.test(birthDate)) {
        let YY = birthDate.substring(0, 2);
        let currYY = new Date().getFullYear();
        let strYY = currYY.toString().substring(2, 4);
        let strCC = currYY.toString().substring(0, 2);
        let strMM = birthDate.substring(2, 4);
        let MM = parseInt(strMM) - 1;
        if (parseInt(YY) < parseInt(strYY)) {
          YY = strCC.toString() + YY;
        } else {
          let tmpYY =
            (parseInt(strCC) - 1).toString() + parseInt(YY).toString();
          YY = tmpYY.toString();
        }
        let bDay = new Date(YY, MM, birthDate.substring(4, 6));
        if (dateStart && dateEnd) {
          valid = bDay >= dateStart && bDay <= dateEnd;
        }
      }
      if (valid) {
        valid = checkLuhn(IDStr);
      }
    }
  }
  return valid;
};

export const DispTopicHdr = (props) => {
  const { globalState } = useContext(GlobalContext);
  return (
    <div
      style={{
        fontStyle: "italic",
        fontSize: "0.85rem"
      }}
    >
      {globalState.devMode === "true" || globalState.devMode === true ? (
        <span
          style={{
            overflowWrap: "break-word",
            // wordBreak: "break-all",
            marginRight: "0.5rem"
          }}
        >
          Topic : {props.topicName}
        </span>
      ) : (
        <span
          style={{
            wordBreak: "break-all",
            marginRight: "0.5rem"
          }}
        >
          Topic : {props.displayName || props.topicName}
        </span>
      )}
      <br />
      {(globalState.devMode === "true" || globalState.devMode === true) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <span>Display name: {props.displayName}</span>
          {/* <br /> */}
          <span
            style={{
              overflowWrap: "break-word",
              // wordBreak: "break-all",
              marginRight: "0.5rem"
            }}
          >
            ID: {props.topicID}
          </span>
          {/* <br /> */}
          <span
            style={{
              overflowWrap: "break-word",
              // wordBreak: "break-all",
              marginRight: "0.5rem"
            }}
          >
            Creation date: {props.time}
          </span>
          {props.creator && (
            <div>
              <span
                style={{
                  overflowWrap: "break-word",
                  // wordBreak: "break-all",
                  marginRight: "0.5rem"
                }}
              >
                Creator: {props.creator}
              </span>
            </div>
          )}
          {/* {props.creatorpersona &&
            <div>
              <span style={{
                wordBreak: "break-all",
                marginRight: "0.5rem"
              }}>
                {props.creatorpersona}
              </span>
            </div>
          } */}
        </div>
      )}
    </div>
  );
};

export const getIcon = (groupType) => {
  if (groupType !== undefined) {
    switch (groupType.toLowerCase()) {
      case "private_dialog":
        return "dialog";
      case "public":
        return "public";
      case "private":
        return "private";
      case "whisper":
        return "whisper";
      case "hidden":
        return "hidden";
      default:
        return groupType.toLowerCase();
    }
  }
  return undefined;
};

// export const ThreeDotsMenu = (size,callback,style) => {
//   return (
//     <div
//       style={style}
//     onClick={()=>callback}>
//     <BsThreeDots
//     size={size}
//       />
//       </div>
//     )
// }

// <ThreeDotsMenu
//   size={20}
//   style="backgroundColor:pink"
//   callback={handlecallback()}

// />

export const hexToRGBArray = (color) => {
  if (color[0] === "#") color = color.substring(1)
  if (color.length === 3)
    color =
      color.charAt(0) +
      color.charAt(0) +
      color.charAt(1) +
      color.charAt(1) +
      color.charAt(2) +
      color.charAt(2);
  else if (color.length !== 6) throw "Invalid hex color: " + color;
  var rgb = [];
  for (var i = 0; i <= 2; i++) rgb[i] = parseInt(color.substr(i * 2, 2), 16);
  return rgb;
};

export const ColorOptions = ({ colors, selectedColor, onColorSelect, ...props }) => {
  return (
    <div
      className="colors_display"
    >
      {colors.map((color, index) => (
        <div
          key={index}
          className={`color-option ${selectedColor === color ? 'selected' : ''}`}
          style={{ backgroundColor: color }}
          onClick={() => onColorSelect(color)}
        >
        </div>
      ))}
    </div>
  );
};

