import React from "react";
import useThumbsState from "contexts/useThumbsState";
import ThumbsContext from "contexts/contextThumbs";

const ThumbsStateProvider = ({ children }) => {
  return (
    <ThumbsContext.Provider value={useThumbsState({})}>
      {children}
    </ThumbsContext.Provider>
  );
};

export default ThumbsStateProvider;
