import React, { useEffect, useState, useRef, useContext } from "react";
// import UIPersonaSelectList from "ui/UIPersonaSelectList";
import useDebounce from "hooks/debounce";
import { workerGet } from "workers/interfaceRest";
import GlobalContext from "contexts/context";
import { WorldTextInput } from "utils/UtilsUniversal";
import { isMobileTablet } from "hooks/helper";
import "ui/UI.css";

const UISelectPersonas = (props) => {
  const [useSearch, setSearch] = useState(undefined);
  const [useList, setList] = useState([]);
  const debouncedSearchTerm = useDebounce(useSearch, 500);
  const { globalState } = useContext(GlobalContext);
  const [selected, setSelected] = useState([]);
  const themeShareInput = useRef();
  const [focusVal, setFocusVal] = useState(true);
  const [pShareName, setPShareName] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const increment = useRef(null);
  const tick = useRef();

  useEffect(() => {
    if (increment.current) {
      increment.current = !increment.current;
      return;
    }

    if (isActive) {
      tick.current = setInterval(() => {
        // <-- set tick ref current value
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current); // <-- access tick ref current value
    }

    return () => clearInterval(tick.current); // <-- clear on unmount!
  }, [isActive]);
  useEffect(() => {
    props.updateSelected(selected);
    return () => {};
  }, [selected]);

  useEffect(() => {
    if (props.clearSelectedArr) setSelected([]);
    return () => {};
  }, [props.clearSelectedArr]);

  const UIPersonaSelectListMark2 = (props) => {
    const clear = () => {
      setSelected([]);
    };

    const setOwner = (mpersona, isOwner) => {
      setSelected([
        ...selected.map((s) => {
          s.mpersona === mpersona && (s.owner = isOwner);
          return s;
        })
      ]);
    };

    let content = (
      <div
        id="select_result_list"
        className="flex-container"
        style={{
          overflow: "auto",
          fontWeight: "normal",
          paddingRight: "1rem",
          maxHeight: `${
            props.theMaxHgtBody - 250 - Math.min(selected?.length * 20, 5 * 20)
          }px`
        }}
      >
        {props.personaList === undefined ? (
          <p></p>
        ) : (
          props.personaList.map(
            (p) =>
              !selected.map((s) => s.mpersona).includes(p.mpersona) && (
                <div
                  key={"d_" + p.mpersona}
                  id={"d_" + p.mpersona}
                  className="flex-row"
                  style={{}}
                >
                  <div
                    className="flex-cell"
                    style={{
                      display: "flex",
                      fontSize: isMobileTablet() ? "1rem" : "1.1rem",
                      marginRight: "1rem",
                      maxWidth: isMobileTablet()
                        ? "50vw"
                        : p.persona?.length > 40
                        ? "300px"
                        : "fit-content",
                      cursor: "pointer"
                    }}
                  >
                    <input
                      className="checkbox"
                      onClick={() => {
                        document.getElementById(p.mpersona + "|" + "chk")
                          .checked
                          ? setSelected([
                              p,
                              ...selected.filter(
                                (i) => i.mpersona !== p.mpersona
                              )
                            ])
                          : setSelected([
                              ...selected.filter(
                                (i) => i.mpersona !== p.mpersona
                              )
                            ]);
                      }}
                      type="checkbox"
                      id={p.mpersona + "|" + "chk"}
                    ></input>
                    {p.persona}
                  </div>
                </div>
              )
          )
        )}
      </div>
    );

    return content;
  };

  useEffect(() => {
    const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
    const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;

    let isMounted = true;
    globalState?.jwt &&
      debouncedSearchTerm &&
      debouncedSearchTerm !== "" &&
      workerGet(urlPersona, keyPersona, {
        type: "searchpersona",
        value: debouncedSearchTerm,
        token: globalState?.jwt,
        version: "v1.6.0"
      })
        .then((result) => {
          isMounted && setList(result.list || []);
          setIsActive(false);
        })
        .catch((err) => {
          console.log("CATCH", err);
        });

    return () => {
      isMounted = false;
    };
  }, [debouncedSearchTerm, globalState.version]);

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleNames = (e) => {
    setPShareName(e); //??
    setSearch(e);
    setIsActive(true);
  };

  const modalSelect = (
    <div className="UI-login-container">
      {selected?.length > 0 && (
        <div>
          <div className="modal-label-new" style={{ marginBottom: "0.5rem" }}>
            Selected personas:
          </div>
          <div
            className="flex-container"
            style={{
              overflow: "auto",
              maxHeight: `${
                props.theMaxHgtBody -
                250 -
                Math.min(useList?.length * 20, 5 * 20)
              }px`,
              fontWeight: "normal",
              paddingRight: "1rem"
            }}
          >
            {selected.map((s) => (
              <div
                className="flex-row"
                key={"selected_" + s.mpersona}
                id={"selected_" + s.mpersona}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: "1rem"
                  }}
                >
                  <div
                    className="flex-cell"
                    style={{
                      fontSize: isMobileTablet() ? "1rem" : "1.1rem",
                      marginRight: "1rem",
                      maxWidth: isMobileTablet()
                        ? "50vw"
                        : s.persona?.length > 40
                        ? "300px"
                        : "fit-content"
                    }}
                  >
                    <label>
                      <input
                        className="UI-checkbox-large"
                        onChange={() => {
                          document.getElementById(s.mpersona + "|" + "selected")
                            .checked
                            ? setSelected([
                                s,
                                ...selected.filter(
                                  (i) => i.mpersona !== s.mpersona
                                )
                              ])
                            : setSelected([
                                ...selected.filter(
                                  (i) => i.mpersona !== s.mpersona
                                )
                              ]);
                        }}
                        type="checkbox"
                        id={s.mpersona + "|" + "selected"}
                        checked
                        style={{ height: "12px" }}
                      ></input>
                      {s.persona}
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <WorldTextInput
        identity="searchpersona"
        focus={focusVal}
        removeFocus={(val) => handleFocus(val)}
        labelStyle={{
          fontWeight: "bold"
        }}
        type="text"
        callback={(e) => handleNames(e)}
        label={`Share theme, ${props.themeName}, with...`}
        placeholder="Start typing a name..."
        description={useList?.length > 0 ? "Select from list" : ""}
        minRows={1}
        maxRows={1}
        maxStrLen={56}
        value={pShareName}
      />
      {isActive && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>Loading...</span>
          {timer}
          <div className="spinner"></div>
        </div>
      )}
      <UIPersonaSelectListMark2
        personaList={[...useList]}
        muid={props.muid}
        mpersona={props.mpersona}
        persona={props.persona}
        theMaxHgtBody={props.theMaxHgtBody}
        isOwner={props.isOwner}
      />
    </div>
  );
  return modalSelect;
};

export default React.memo(UISelectPersonas, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
