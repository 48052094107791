import React from "react";
import DropDownIconsCard from "./dropDownIconsCard";
// import { CgMore } from "react-icons/cg";
import { BsThreeDotsVertical } from "react-icons/bs"
import ClickOutsideDetector from "utils/ClickOutsideDetector";

const IconWithDropDownCmp = (props) => {
  const [open, setOpen] = React.useState(false);
  const drop = React.useRef(null);

  function handleClick(e) {
    if (!e.target.closest(`.${drop.current?.className}`) && open) {
      setOpen(false);
    }
  }

  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  return (
    <ClickOutsideDetector
      caller="iconDropDown"
      listen
      onClickOutside={() => {
        setOpen((open) => false)
      }}
      onClick={() => {
      }}
    >
      <div
        className="UI-icondropdown"
        ref={drop}>
      {open && (
        <DropDownIconsCard
          menuItems={props.menuItems}
          setOpen={setOpen} />
      )}
      <BsThreeDotsVertical
        // <CgMore
        // className="UI-dropdown-icon"
        onClick={() => setOpen((open) => !open)}
      />
      </div>
    </ClickOutsideDetector>
  );
};

export default IconWithDropDownCmp;
