import { dex_action } from "data/dexUtils";

export const updateTheme = (db, theme) => {
  dex_action({
    type: "DEX_PUT",
    values: {
      db: db,
      table: "user",
      doc: { key: "current_theme", value: theme }
    }
  });
  // Save to upload_theme
  dex_action({
    type: "DEX_PUT",
    values: {
      db: db,
      table: "themes",
      doc: theme
    }
  });
  dex_action({
    type: "DEX_PUT",
    values: {
      db: db,
      table: "upload_theme",
      doc: theme
    }
  });
};
