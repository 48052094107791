import React, { useContext, useEffect } from "react";

import GlobalContext from "contexts/context";
import PersonasContext from "contexts/contextPersonas";
import DatabaseContext from "data/contextDatabase";
import { usePersonasDex } from "hooks/dexHooks";

const timer = new Date().getTime();

const PersonasSetter = (props) => {
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { personasDispatch } = useContext(PersonasContext);

  const { livePersonas } = usePersonasDex(databaseState.dexUser);

  useEffect(() => {
    globalState.logging && console.log("PersonasSetter 18");
    globalState.logging &&
      console.log("[PersonasSetter] livePersonas", livePersonas);
    personasDispatch({
      type: "SET_PERSONAS",
      values: { personas: livePersonas }
    });
    return () => {};
  }, [personasDispatch, livePersonas]);

  globalState.logging &&
    console.log("PersonasSetter timer", new Date().getTime() - timer);
  return null;
};

export default React.memo(PersonasSetter, (prevProps, nextProps) => {
  return true;
});
