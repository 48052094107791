import React, { useContext, useEffect } from "react";

import GlobalContext from "contexts/context";
import PersonasContext from "contexts/contextPersonas";
import DatabaseContext from "data/contextDatabase";

import { useTopicMetaDex } from "hooks/dexHooks";

import { rest_api } from "connectivity/API";
import {
  isHide,
  isRead,
  isUnread,
  lastReadMessage,
  requestRead
} from "utils/isRead";
import { dex_action } from "./dexUtils";
import { useLiveQuery } from "dexie-react-hooks";

const timer = new Date().getTime();

const MissingFetcher = (props) => {
  const { globalState } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { personasState } = useContext(PersonasContext);

  const { liveMissing } = useTopicMetaDex(databaseState.dexUser);

  const jwt = useLiveQuery(
    () =>
      databaseState.dexAdmin &&
      databaseState.dexAdmin.store.get({ key: "user" }).then((u) => u?.jwt),
    [databaseState.dexAdmin]
  );

  useEffect(() => {
    globalState.logging && console.log("MissingFetcher 1");
    let mounted = true;
    globalState.logging &&
      console.log("[MissingFetcher] useffect", [
        globalState.connected,
        globalState.authenticated,
        // globalState.version,
        liveMissing,
        jwt,
        // globalState.displaying_topic,
        // globalState?.prefs?.clientMute,
        databaseState.dexAdmin,
        databaseState.dexUser,
        databaseState.dbReady,
        personasState.personas
      ]);

    const getMessagesRecursive = (dexUser, mpersonas, topicsMissing) => {
      globalState.logging &&
        console.log(
          "[MissingFetcher] topicsMissing",
          topicsMissing,
          liveMissing
        );
      let top = topicsMissing.splice(0, 1);
      let action = {
        type: "r_t_hist",
        topics: top,
        jwt: jwt
      };
      rest_api(action)
        .then(async (r) => {
          globalState.logging &&
            console.log(
              "[MissingFetcher] rest_api getMessagesRecursive",
              r,
              action
            );
          if (r?.messages[0]?.mtopic) {
            let lastR = await lastReadMessage(
              dexUser,
              r.messages[0].mtopic,
              r.messages[0].recipient
            );
            let latest = r?.messages[0] ? lastR : 0;
            r.messages.forEach((m) => {
              m.read = isRead(m, mpersonas, latest);
              m.hide = isHide(m);
              let cb = dex_action({
                type: "DEX_UPDATE_TOPIC_METADATA",
                values: {
                  db: databaseState.dexUser,
                  jMessage: m
                }
              });
              dex_action({
                type: "DEX_UPSERT_KEYS_LATEST_TRANS",
                values: {
                  db: databaseState.dexUser,
                  table: "message",
                  latest_key: "msg_idx",
                  match_keys: ["mtopic", "recipient", "smid"],
                  doc: m,
                  notify:
                    isUnread(m) &&
                    !requestRead(m) &&
                    !globalState.displaying_topic &&
                    !globalState?.prefs?.clientMute,
                  cb: cb
                }
              });
            });
          }
          setTimeout(
            () =>
              topicsMissing?.length > 0 &&
              // mounted &&
              getMessagesRecursive(dexUser, mpersonas, topicsMissing),
            100
          );
        })
        .catch((e) => {
          console.log("[MissingFetcher] rest_api error", e);
          setTimeout(
            () =>
              topicsMissing?.length > 0 &&
              // mounted &&
              getMessagesRecursive(dexUser, mpersonas, topicsMissing),
            100
          );
        });
    };

    if (
      globalState.connected &&
      globalState.authenticated &&
      databaseState.dexAdmin &&
      databaseState.dbReady &&
      liveMissing &&
      databaseState.dexUser &&
      Array.isArray(liveMissing) &&
      liveMissing?.length > 0
    ) {
      // R_T_HIST
      if (true && databaseState.dexUser && jwt) {
        globalState.logging &&
          globalState.logging &&
          console.log("[MissingFetcher] liveMissing", liveMissing);
        mounted &&
          getMessagesRecursive(
            databaseState.dexUser,
            personasState.personas.map((p) => p?.mpersona),
            [...liveMissing]
          );
      } else if (true) {
        // W.T.HIST
        let j = {
          type: "w.t.hist",
          topics: liveMissing,
          smid: "w.t.hist" // randomString(8),
        };
        globalState.logging && console.log("[MissingFetcher] w.t.hist", j);
        // W.T.HIST
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "send",
              doc: j
            }
          });
      }
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    globalState.connected,
    globalState.authenticated,
    // globalState.version,
    liveMissing,
    jwt,
    // globalState.displaying_topic,
    // globalState?.prefs?.clientMute,
    databaseState.dexAdmin,
    databaseState.dexUser,
    databaseState.dbReady,
    personasState.personas
  ]);

  globalState.logging &&
    console.log("MissingFetcher timer", new Date().getTime() - timer);
  return null;
};

export default React.memo(MissingFetcher, (prevProps, nextProps) => {
  return true;
});
