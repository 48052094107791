import React, { useEffect, useContext, useState, useRef, useMemo, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import TopicsContext from "contexts/contextTopics";
import GlobalContext from "contexts/context";
import SubscriptionsContext from "contexts/contextSubscriptions";
import DatabaseContext from "data/contextDatabase";
import LiveCacheContext from "data/contextLiveCache";
import { isMobileTablet } from "hooks/helper";
import { WorldTextInput } from "utils/UtilsUniversal";
import Modals from "./Modals";
import "./modals.css";
import { dex_action } from "data/dexUtils";

const UISearchMyTopicsModal = (props) => {
  const { topicsState, topicsDispatch } = useContext(TopicsContext);
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { subscriptionsState } = useContext(SubscriptionsContext);
  const { liveCacheState, liveCacheDispatch } = useContext(LiveCacheContext);
  const { databaseState } = useContext(DatabaseContext);
  const [modalClose, setModalClose] = useState(true);
  const [topicsList, setTopicsList] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const displayList = useRef();
  const [focusVal, setFocusVal] = useState(true);
  const [displayListNew, setDisplayListNew] = useState([])
  let history = useHistory();

  const handleAdjPan = (obj) => {
    topicsDispatch({
      type: "SET_SHOWTOPIC",
      values: { showTopic: obj }
    });
    setModalClose(true)
    handleClose()
  }

  useEffect(() => {
    if (sessionStorage.getItem("searchTopicString") &&
      sessionStorage.getItem("searchTopicString") !== undefined)
      setSearchStr(sessionStorage.getItem("searchTopicString"))
    else setSearchStr("")
  }, [])

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  useEffect(() => {
    let shortList = [];
    if (topicsState?.topics) {
      Object.keys(topicsState.topics).forEach((t) => {
        let topicName = topicsState.topics[t].props.topic_display_name
          ? topicsState.topics[t].props.topic_display_name
          : topicsState.topics[t].topic;
        topicsState.topics[t].persona !== "unknown" &&
          shortList.push({
            topicName: topicName,
            persona: topicsState.topics[t].persona,
            tag: topicsState.topics[t].props?.tag,
            topic: topicsState.topics[t]
          });
      });
    }

    if (subscriptionsState?.subscriptions) {
      Object.keys(subscriptionsState.subscriptions).forEach((s) => {
        let topicName = subscriptionsState.subscriptions[s].topic_display_name
          ? subscriptionsState.subscriptions[s].topic_display_name
          : subscriptionsState.subscriptions[s].topic;

        subscriptionsState.subscriptions[s].persona !== "unknown" &&
          shortList.push({
            topicName: topicName,
            persona: subscriptionsState.subscriptions[s].persona,
            tag: subscriptionsState.subscriptions[s].visibility,
            topic: subscriptionsState.subscriptions[s]
          });
      });
    }
    let sortedList = [];
    if (shortList.length > 0) {
      sortedList = shortList.sort((a, b) => {
        return a.topicName?.toLowerCase() > b.topicName?.toLowerCase() ? 1 : -1;
      });
    }
    setTopicsList(sortedList);
  }, [])

  const toggleCollectionState = (topiccollection, mpersona) => {
    let collectionState = { ...liveCacheState?.collectionState } || {};
    collectionState[topiccollection] = collectionState[topiccollection] || {};
    collectionState[topiccollection][mpersona] = {
      open: !collectionState[topiccollection][mpersona]?.open
    };
    liveCacheDispatch({
      type: "SET",
      values: { cache: { collectionState: collectionState } }
    });
    databaseState.dexUser &&
      dex_action({
        type: "DEX_PUT",
        values: {
          db: databaseState.dexUser,
          table: "user",
          doc: { key: "collectionstate", value: collectionState }
        }
      });
  };

  const goToTopic = useMemo(
    () => (t) => {
      toggleCollectionState(t.tag ||
        t.topic.props.tag ||
        t.topic.props.topiccollection ||
        t.topic.props.visibility ||
        t.topic.props.topictype ||
        "undefined", t.topic.mpersona)
      // we need this info to action the rest...
      let persona = {
        mpersona: t.topic.mpersona,
        persona: t.topic.persona,
        tag: (
          t.tag ||
          t.topic.props.tag ||
          t.topic.props.topiccollection ||
          t.topic.props.visibility ||
          t.topic.props.topictype ||
          "undefined"
        ).toLowerCase()
      };
      let collectionState = liveCacheState?.collectionState || {};
      process.env.REACT_APP_DEBUG_THIS &&
        console.log(
          "[UISearchMyTopicsModal] topic",
          t,
          "\n\tcollection",
          collectionState,
          "\n\tpersona:",
          persona
        );
      // make sure this topic's collection will be expanded
      if (
        collectionState &&
        collectionState[persona?.tag] &&
        collectionState[persona.tag][persona.mpersona]?.open &&
        collectionState[persona.tag][persona.mpersona].open !== false
      ) {
        collectionState[persona.tag][persona.mpersona].open = false;
        liveCacheDispatch({
          type: "SET",
          values: { cache: { collectionState: collectionState } }
        });
        databaseState.dexUser &&
          dex_action({
            type: "DEX_PUT",
            values: {
              db: databaseState.dexUser,
              table: "user",
              doc: { key: "collectionstate", value: collectionState }
            }
          });
      }
      // and open the topic
      if (t.mpersona !== globalState.persona?.mpersona) {
        globalDispatch({
          type: "SET_PERSONA",
          values: {
            persona: persona
          }
        });
      }
      if (isMobileTablet()) {
        let serializedObj = JSON.stringify({
          mtopic: t?.topic?.mtopic,
          mpersona: t?.topic?.mpersona,
        });
        // sessionStorage.setItem("scrollMtopic", serializedObj);
        history.push("/UIMessageList", { subscription: t.topic });
      }
      else {
        setModalClose(true)
        handleAdjPan({ subscription: t.topic })
      }
      setModalClose(true)
      displayList.current = undefined;
    },
    [
      liveCacheState?.collectionState,
      globalState.persona?.mpersona,
      databaseState.dexUser
    ]
  );

  useEffect(() => {
    let resultsList = [];
    if (searchStr === undefined || searchStr === "" || searchStr?.length === 0) {
      topicsList && topicsList?.length > 0 &&
        Object.keys(topicsList).forEach((t) => {
          resultsList.push({
            topic: topicsList[t]?.topicName,
            persona: topicsList[t]?.persona,
            description: topicsList[t]?.topic?.props?.description,
            onClick: () => goToTopic(topicsList[t]),
          });
        });
      setDisplayListNew(resultsList);
    } else {
      topicsList &&
        topicsList?.length > 0 &&
        Object.keys(topicsList).forEach((t) => {
          if (
            topicsList[t].topicName
              ?.toLowerCase()
              .includes(searchStr?.toLowerCase())
          ) {
            resultsList.push({
              topic: topicsList[t].topicName,
              persona: topicsList[t].persona,
              description: topicsList[t]?.topic?.props?.description,
              onClick: () => goToTopic(topicsList[t]),
            });
          }
        });
      setDisplayListNew(resultsList);
    }
    return () => {
    };
  }, [searchStr, topicsList]);

  useEffect(() => {
    window.addEventListener("popstate", handleClose);
    if (window.location?.href?.includes("search")) {
      window.history.replaceState({ id: 2 }, null, "/");
    } else {
      if (!modalClose) {
        window.history.pushState({ id: 2 }, null, "search");
      }
    }
    return () => {
      window.removeEventListener("popstate", handleClose);
    };
  }, [modalClose]);

  const handleClickOutside = () => {
    setModalClose(true);
    // setSearchStr("");
    displayList.current = undefined;
  };

  const handleClose = () => {
    setModalClose(true);
    props.onClose()
    // setSearchStr("");
    displayList.current = undefined;
  };

  const handleSearchStr = (e) => {
    setSearchStr(e)
  }

  const handleClearContent = () => {
    setSearchStr("")
  }

  const chkCtrlF = useCallback((event) => {
    if (event.keyCode === 114 || (event.ctrlKey && event.keyCode === 70)) {
      event.preventDefault();
      setModalClose(false)
    }
  });

  useEffect(() => {
    // Add listeners
    window.addEventListener("keydown", chkCtrlF);
    return () => {
      // Remove listeners
      window.removeEventListener("keydown", chkCtrlF);
    };
  });

  useEffect(() => {
    sessionStorage.setItem("searchTopicString", searchStr);
  }, [searchStr]);

  let content = (
    <div>
      <Modals
        title="Search for Topic"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        switchScrollOff={true}
      >
        <div className="UI-login-container"
          style={{ overflow: "auto" }}>
          <WorldTextInput
            // ref={ref}
            identity="searchtopic"
            // focus={focusVal}
            // removeFocus={(val) => handleFocus(val)}
            labelStyle={{
              fontWeight: "bold",
            }}
            type="textWithClear"
            callback={(e) => handleSearchStr(e)}
            clearContent={() => handleClearContent()}
            label="Tap on topic to go there"
            placeholder="Searching for..."
            minRows={1}
            maxRows={1}
            value={searchStr}
            active="true"
            autoComplete="off"
          />
          {displayListNew.map((item, index) => (
            <div
              className="UI-topic"
              style={{ cursor: "pointer" }}
              key={index}
              onClick={item.onClick}
            >
              <div className="UI-topic-search-list">
                <div
                  className="UI-topic-search-list-item"
                  style={{ fontWeight: "bold" }}>
                  {item.topic}
                </div>
                <div
                  className="UI-topic-search-list-item">
                  {item.persona}
                </div>
                <div
                  className="UI-topic-search-list-description">
                  {item.description}
                </div>
                {/* </div> */}
              </div>
            </div>
          ))}
        </div>
      </Modals>
    </div>
  );

  return content;
};

export default React.memo(UISearchMyTopicsModal, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
