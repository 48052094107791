import { useReducer } from "react";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  shouldLog() && console.log("SET count", state, action);
  switch (action.type) {
    case "SET_COUNT":
      shouldLog() && console.log("SET_COUNT", action);
      if (!action.values.count || isEqual(state.count, action.values.count))
        return state;
      else
        return {
          ...state,
          count: { ...state.count, ...action.values.count }
        };
    case "SET_LAST_VISIBLE_DATE":
      shouldLog() && console.log("SET_LAST_VISIBLE_DATE", action);
      if (
        !action.values.last_visible_date ||
        isEqual(state.last_visible_date, action.values.last_visible_date)
      )
        return state;
      else
        return {
          ...state,
          last_visible_date: {
            ...state.last_visible_date,
            ...action.values.last_visible_date
          }
        };
    default: {
      return state;
    }
  }
};

const useCountState = (state) => {
  const [countState, countDispatch] = useReducer(reducer, state);
  return { countState, countDispatch };
};

export default useCountState;
