import React, { useState, useContext, useEffect, useRef } from "react";
// import StyleContext from "contexts/contextStyle";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import { updateTheme } from "theme/updateTheme";
import UISelectPersonas from "ui/UISelectPersonas";
import { sortJoin } from "utils/sortJoin";
import { mpersonaDescriptor } from "data/descriptors";
import { timestamp, randomString } from "hooks/helper";
import "ui/UI.css";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import StyleContext from "contexts/contextStyle";

const UIShareTheme = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { databaseState, databaseDispatch } = useContext(DatabaseContext);
  const { styleState } = useContext(StyleContext);

  const [selected, setSelected] = useState([]);
  const [clear, setClear] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [clickOutsideActive, setClickOutsideActive] = useState(true);
  const [calculatedMaxHeight, setCalculatedMaxHeight] = useState();
  let history = useHistory();
  // const newThemeName = useRef();
  // useEffect(() => {
  //     newThemeName.current.focus()
  // });

  let newTheme = useRef(
    globalState.themeEdit
      ? { ...globalState.themeEdit.theme }
      : { ...styleState?.theme }
  );

  // const [test, setTest] = useState(newTheme)

  const [colorChanged, setColorChanged] = useState(false);

  const handleNew = () => {
    setColorChanged(false);
  };
  useEffect(() => {
    if (globalState.themeEdit) {
      newTheme.current = { ...globalState.themeEdit.theme };
      if (globalState.themeEdit.editType === "clone") {
        newTheme.current.name = globalState.themeEdit.theme.name + "_Clone";
        document.getElementById("theme_name").value =
          globalState.themeEdit.theme.name + "_Clone";
        let id = randomString(8);
        newTheme.current.id = id;
        document.getElementById("theme_id").value = id;
        newTheme.current.owners = globalState.themeEdit.theme.owners
          ? [
              globalState.persona.mpersona,
              ...globalState.themeEdit.theme.owners.filter(
                (m) => m !== globalState.persona.mpersona
              )
            ]
          : [globalState.persona.mpersona];
      } else {
        newTheme.current.name = globalState.themeEdit.theme.name;
        newTheme.current.id = globalState.themeEdit.theme.id;
        document.getElementById("theme_name").value =
          globalState.themeEdit.theme.name;
        document.getElementById("theme_id").value =
          globalState.themeEdit.theme.id;
      }
      document.getElementById("createtheme_modal");
      globalDispatch({ type: "CLEAR_THEME_EDIT" });
    } else {
    }
    return () => {};
  }, [globalDispatch, globalState.persona, globalState.themeEdit]);

  const storeAndUpdateTheme = (theme) => {
    updateTheme(databaseState.dexUser, theme);
  };

  let sendTheme = async (theme, sender, recipient) => {
    console.log("THEME", theme);
    let d = {
      dialog: recipient.persona,
      got_voice: true,
      mdialog: sortJoin(sender.mpersona, recipient.mpersona, "_"),
      mpersona: sender.mpersona,
      persona: sender.persona,
      peer: recipient.mpersona,
      peerpersona: recipient.persona,
      visibility: "PRIVATE_DIALOG"
    };
    console.log("THEME", d);
    let body = "Add theme # theme/public/" + theme.id;
    let descriptor = await mpersonaDescriptor(
      databaseState.dbMaster,
      sender.mpersona
    );
    let m = {
      type: "w.mpersona.message",
      content: { body: body, msgtype: "w.text" },
      mpersona: sender.mpersona,
      peer: recipient.mpersona,
      visibility: "PRIVATE_DIALOG",
      name: sender.name,
      ts_sender: timestamp(),
      smid: randomString(8),
      status: "1",
      origin_sender: "registered",
      thumbnail: "",
      lat: "",
      lon: "",
      persona_picture: "",
      version: globalState.version,
      parameters: { descriptor: descriptor }
    };
    console.log("THEME", m);
    databaseDispatch({
      type: "UPSERT_REC",
      values: {
        db: databaseState.dbMaster,
        collection: "dialog",
        table: globalState.muid,
        rowid: sortJoin(sender.mpersona, recipient.mpersona, "_"),
        doc: d
      }
    });
    databaseDispatch({
      type: "UPSERT_REC",
      values: {
        jmessage: m,
        db: databaseState.dbMessages,
        collection: "send",
        table: "confirm_smid",
        rowid: m.smid,
        doc: m
      }
    });
  };

  const shareTheTheme = (listSelected) => {
    console.log("SHARE WITH PERSONAS", listSelected);
    //Update the theme with the new owners
    if (globalState.persona) {
      let newOwners = listSelected
        .filter((recipient) => recipient.owner)
        .map((recipient) => recipient.mpersona);
      console.log("newOwners", newOwners);
      props.theme.owners = props.theme.owners
        ? [
            ...newOwners,
            ...props.theme.owners.filter((m) => !newOwners.includes(m))
          ]
        : [globalState.persona.mpersona];
      storeAndUpdateTheme(props.theme);
      console.log("newOwners theme", props.theme);
      //Send the theme to each new owner
      listSelected.forEach((recipient) => {
        sendTheme(props.theme, globalState.persona, recipient)
          .then(() => {})
          .catch((e) => {});
      });
    }
    props.clickOutsideActive(true);
    // props.onClose(false);
  };
  // return (
  //     <div id="modalShare" style={{ display: "block" }}>

  //         <UISelectPersonas
  //             isOwner={
  //                 props.theme.owners && props.theme.owners.includes(globalState.persona?.mpersona)
  //             }
  //             callback={callback}
  //             muid={globalState.muid}
  //             mpersona={globalState.persona?.mpersona}
  //             persona={globalState.persona?.persona}
  //         />
  //         {/* {props.theme.name} */}

  //     </div>
  // );

  const clearArr = () => {
    setClear(true);
  };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    props.onClose();
    // if (history.length >= 1) history.goBack();
  };

  let modal = (
    <div id="sharetheme_modal">
      <Modals
        // loginModal={true}
        title="Share"
        onClose={() => handleClose()}
        noHistoryBack={true}
        clickOutsideActive={false}
        onClickOutside={() => handleClickOutside()}
        switchScrollOff={true}
        theMaxHgtBody={(val) => setCalculatedMaxHeight(val)}
        footer={
          <div>
            {true && (
              <button
                className="UI-button-service"
                type="login"
                onClick={() => {
                  clearArr();
                }}
              >
                Clear
              </button>
            )}
            <button
              className="UI-button-service"
              type="login"
              onClick={() => {
                shareTheTheme([...selected]);
              }}
            >
              Submit
            </button>
          </div>
        }
      >
        <div style={{ width: "100%" }}>
          <UISelectPersonas
            isOwner={
              props.theme.owners &&
              props.theme.owners.includes(globalState.persona?.mpersona)
            }
            theMaxHgtBody={calculatedMaxHeight}
            // callback={shareTheTheme()}
            clearSelectedArr={clear}
            updateSelected={(val) => setSelected(val)}
            muid={globalState.muid}
            mpersona={globalState.persona?.mpersona}
            persona={globalState.persona?.persona}
            themeName={props.theme.name}
          />
        </div>
      </Modals>
    </div>
  );

  return modal;
};

// memo acts like shouldComponentUpdate ... can use without the function, or with a function that returns false if it SHOULD rerender
export default React.memo(UIShareTheme, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
