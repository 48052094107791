// request short jwt (short jwt should carry a server generated id so that the server can mark it as used)

// fetch public key from trusted web page keys.enabled.world (is it in the env?) along with an identifier (so the server knows which priv key to use)
export const test = () => {
  requestPublicKey().then((r) => {
    let id = getDeviceIdentifiers();
    console.log("Public Key", r, id);
    importKey(r.publicKey)
      .then((key) => {
        console.log(key);
        encrypt(key, "This is a test").then((e) => {
          console.log(e);
          send_encrypted(e);
        });
      })
      .catch((e) => console.log(e));
  });
};

export const send_encrypted = (encData) => {
  let headers = {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_JWT_PWD_API_KEY
  };
  let data = { type: "encryptedRequest", data: encData };
  let options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  };
  return fetch(process.env.REACT_APP_JWT_PWD_API_URL, options)
    .then((response) => {
      return response.ok ? response.json() : { ok: false };
    })
    .catch((err) => {
      console.log(err);
      return { ok: undefined };
    });
};

export const requestPublicKey = async () => {
  let headers = {
    "Content-Type": "application/json",
    "x-api-key": process.env.REACT_APP_JWT_PWD_API_KEY
  };
  let data = { type: "requestPublicKey" };
  let options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data)
  };
  return fetch(process.env.REACT_APP_JWT_PWD_API_URL, options)
    .then((response) => {
      return response.ok ? response.json() : { ok: false };
    })
    .catch(function (err) {
      console.log(err);
      return { ok: undefined };
    });
};
// create device signature
export const getDeviceIdentifiers = () => {
  const { ClientJS } = require("clientjs");
  var client = new ClientJS(); // Create A New Client Object
  var fingerprint = client.getFingerprint(); // Get Client's Fingerprint
  return fingerprint;
};

export const signDeviceIdentifiers = (keyPublic, deviceIdentifier) => {
  let payload = JSON.stringify({
    date: new Date().getTime(),
    deviceIdentifier: deviceIdentifier
  });
  let encrypted = encrypt(keyPublic, payload);
  return encrypted;
};
export const createTokenRequest = (keyPublic, jwtShort, signature) => {
  // combine short jwt with device signature
  // salt and encrypt with public key
};
// request a long jwt
export const postRequestLongJWT = async () => {
  /*
  {
    let encRequest = getTokenRequest(keyPublic, jwtShort, signature)
    post.then((r) => callback)
  }
  */
};

export const payloadJWT = (jwt) => {
  const jwtPayload = jwt.split(".")[1];
  const payload = JSON.parse(Buffer.from(jwtPayload, "base64").toString());
  return payload;
};
// long jwt includes the encrypted device signature
// check long jwt with public key to ensure it came from the server
export const validateJWT = (publicKey, jwt) => {
  return {};
  // return { valid: signatureIsValid, expired: expired, payload: payload };
};
// when using the long jwt, send the device signature (dated and salted, then encrypted)
export const useLongJWT = () => {
  return {
    longJWT: "", // includes muid and pub encrypted signature from oringinal request
    datedSig: "" // pub encrypted {date: "", signature: "", salt: ""}, server compares the signature to the one in the longJWT
  };
};
// server can decrypt the 2 signatures (provided and embedded in jwt) and evaluate similarity

// Supporting functions

export const stringToArrayBuffer = (string) => {
  var buf = new ArrayBuffer(string.length); // Uint8Array ... Uint16Array needs 2 bytes for each char
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = string.length; i < strLen; i++) {
    bufView[i] = string.charCodeAt(i);
  }
  return buf;
};

export const encrypt_strVec = (key, strVec, strData) => {
  let init_vec = stringToArrayBuffer(strVec);
  return encrypt_vec(key, init_vec, strData);
};

export const encrypt = (key, strData) => {
  let init_vec = crypto.getRandomValues(new Uint8Array(16));
  return encrypt_vec(key, init_vec, strData);
};

export const importKey = (pemEncodedKey) => {
  // helper function
  // from https://developers.google.com/web/updates/2012/06/How-to-convert-ArrayBuffer-to-and-from-String
  const str2ab = (str) => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  };

  // main function to load the public key
  // and convert it to crypto key
  // for encryption in browser
  const importRsaKey = (pem) => {
    // fetch the part of the PEM string between header and footer
    // replace this

    const pemContents = pem
      .replace("-----BEGIN PUBLIC KEY-----", "")
      .replace("-----END PUBLIC KEY-----", "")
      .replace("/\r?\n|\r/g", "");

    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pemContents);

    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);

    return window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256"
      },
      true,
      ["encrypt"]
    );
  };

  return importRsaKey(pemEncodedKey);
};

export const encrypt_vec = (key, init_vec, strData) => {
  let data = stringToArrayBuffer(strData);
  console.log("key: ", key);
  console.log("init_vec: ", init_vec);
  console.log("Data", data);
  const p = new Promise((resolve) => {
    key === null
      ? resolve(undefined)
      : resolve(
          crypto.subtle
            .encrypt(
              {
                name: "RSA-OAEP",
                // iv: init_vec,
                tagLength: 128
              },
              key,
              data
            )
            .then((encrypted) => {
              console.log("Data enc", encrypted);
              let uenc = new Uint8Array(encrypted);
              return {
                init_vec: Array.from(init_vec),
                encrypted: Array.from(uenc)
              };
            })
            .catch((err) => {
              console.log("CATCH", err);
            })
        );
  });
  return p;
};
