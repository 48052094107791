import React from 'react'

const LabelTag = (props) => {
  let tagHeight = "20"
  props.tagHeight
    ? tagHeight = props.tagHeight
    : tagHeight = "20"
  let tagWidth = "20"
  props.tagWidth
    ? tagWidth = props.tagWidth
    : tagWidth = "20"
  let strokeColor = "black"
  props.strokeColor
    ? strokeColor = props.strokeColor
    : strokeColor = "black"
  let fillColor = "black"
  props.fillColor
    ? fillColor = props.fillColor
    : fillColor = "black"
  let holeFillColor = "white"
  props.holeFillColor
    ? holeFillColor = props.holeFillColor
    : holeFillColor = "white"
  return (
    <svg
      stroke={strokeColor}
      fill={fillColor}
      stroke-width="0"
      viewBox="0 0 24 24"
      height={tagHeight}
      width={tagWidth}
      xmlns="http://www.w3.org/2000/svg"
    // transform="
    //   translate(0 0)
    //   scale(0.75 0.75)"
    >
      <path
        fill={fillColor} /* Fill color for the tag */
        stroke-width="1"
        d="M 11.707 2.293 A 0.996 0.996 0 0 0 11 2 H 6 a 0.996 0.996 0 0 0 -0.707 0.293 l -3 3 A 0.996 0.996 0 0 0 2 6 v 5 c 0 0.266 0.105 0.52 0.293 0.707 l 10 10 a 0.997 0.997 0 0 0 1.414 0 l 8 -8 a 0.999 0.999 0 0 0 0 -1.414 l -10 -10 Z"
      ></path>

      {/* Circle */}
      <circle
        cx="8.5"
        cy="8.4"
        r={holeFillColor === "white"
          ? "2"
          : "2"}
        fill={holeFillColor} /* Fill color for the circle */
      /* You can add more circle-specific attributes here */
      ></circle>
      {/* <path fill={fillColor} stroke-width="2" d="M 11.707 2.293 A 0.996 0.996 0 0 0 11 2 H 6 a 0.996 0.996 0 0 0 -0.707 0.293 l -3 3 A 0.996 0.996 0 0 0 2 6 v 5 c 0 0.266 0.105 0.52 0.293 0.707 l 10 10 a 0.997 0.997 0 0 0 1.414 0 l 8 -8 a 0.999 0.999 0 0 0 0 -1.414 l -10 -10 Z M 8.353 10 a 1.647 1.647 0 1 1 -0.001 -3.293 A 1.647 1.647 0 0 1 8.353 10 Z"></path>
      <path fill={fillColor} stroke-width="2" d="M 11.707 2.293 A 0.996 0.996 0 0 0 11 2 H 6 a 0.996 0.996 0 0 0 -0.707 0.293 l -3 3 A 0.996 0.996 0 0 0 2 6 v 5 c 0 0.266 0.105 0.52 0.293 0.707 l 10 10 a 0.997 0.997 0 0 0 1.414 0 l 8 -8 a 0.999 0.999 0 0 0 0 -1.414 l -10 -10 Z M 8.353 10 a 1.647 1.647 0 1 1 -0.001 -3.293 A 1.647 1.647 0 0 1 8.353 10 Z"></path> */}
    </svg>
  )
}

export default LabelTag
