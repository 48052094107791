import Dexie from "dexie";
import {
  versionAdmin,
  versionUser,
  adminStore,
  userStore
} from "data/dexSchema";

export const newDex = (dbName) => {
  const dex = new Dexie(dbName);

  let schema = dbName === "dexAdmin" ? adminStore : userStore;
  let version = dbName === "dexAdmin" ? versionAdmin : versionUser;
  dex.version(version).stores(schema);

  dex.open().catch(function (e) {
    console.error("Open failed: " + e.stack);
  });
  return dex;
};
