export const urlify = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}">${url}</a>`;
  });
};

export const linkProps = {
  format: {
    url: (value) => {
      if (
        value
          .replace("youtu.be", "www.youtube.com/embed")
          .startsWith("https://www.youtube.com/embed/")
      ) {
        value = (
          <iframe
            sandbox
            width="300"
            height="200"
            src={value.replace("youtu.be", "www.youtube.com/embed")}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        );
      }
      else value = value.length > 30 ? value.slice(0, 30) + "…" : value;
      return value;
    },
  },
  ignoreTags: ["script", "style"],
  className: { url: "linkify" },
  defaultProtocol: "https",
  target: { url: "_blank" },
};
