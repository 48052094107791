import { useReducer } from "react";
import isEqual from "react-fast-compare";

const reducer = (state, action) => {
  console.log("SET dialogs", state, action);
  switch (action.type) {
    case "SET_DIALOGS":
      if (isEqual(state.dialogs, action.values.dialogs)) return state;
      else
        return {
          ...state,
          dialogs: action.values.dialogs,
        };
    default: {
      return state;
    }
  }
};

const useDialogsState = (state) => {
  const [dialogsState, dialogsDispatch] = useReducer(reducer, state);
  return { dialogsState, dialogsDispatch };
};

export default useDialogsState;
