import React, { useEffect, useContext, useState } from "react";
import DatabaseContext from "data/contextDatabase";
import GlobalContext from "contexts/context";
import StyleContext from "contexts/contextStyle";
import themes from "theme/schema.json";
import UICreateTheme from "./UICreateTheme";
import UIShareTheme from "./UIShareTheme";
import { ImBin, ImShare2 } from "react-icons/im";
import { MdRefresh } from "react-icons/md";
import { RiEditLine, RiFileCopyLine } from "react-icons/ri";
import { sortJoin } from "utils/sortJoin";
import { timestamp, randomString } from "hooks/helper";
import { mpersonaDescriptor, cacheS3Theme } from "data/descriptors";
import { updateTheme } from "theme/updateTheme";
import "./UI.css"
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { dex_action } from "data/dexUtils";
import { isMobileTablet } from "hooks/helper";

const UISettings = (props) => {
  const { globalState, globalDispatch } = useContext(GlobalContext);
  const { databaseState } = useContext(DatabaseContext);
  const { styleState } = useContext(StyleContext);
  const [mainThemeState, setMainThemeState] = useState(true);
  const [editThemeModalState, setEditThemeModalState] = useState(false);
  const [cloneThemeModalState, setCloneThemeModalState] = useState(false);
  const [shareModalState, setShareModalState] = useState(false);
  const { styleDispatch } = useContext(StyleContext);
  const [clickOutsideActive, setClickOutsideActive] = useState(true);
  const showEditThemeModal = () => {
    setEditThemeModalState(!editThemeModalState);
    setMainThemeState(false);
  };
  const showCloneThemeModal = () => {
    setCloneThemeModalState(!cloneThemeModalState);
    setMainThemeState(false);
  };
  const [selectedTheme, setSelectedTheme] = useState(null);
  const showShareModal = () => {
    setShareModalState(!shareModalState);
    setMainThemeState(false);
  };
  const [modalClose, setModalClose] = useState(false);
  const [outClick, setOutClick] = useState(false);
  const [selectedPersona, setSelectedPersona] = useState(false);
  let history = useHistory();
  const [contentModal, setContentModal] = useState();
  const [allThemes, setAllThemes] = useState({
    ...themes,
    ...styleState.themes
  });
  const [screenWidth, setScreenWidth] = useState();

  useEffect(() => {
    setAllThemes({ ...themes, ...styleState.themes });
    return () => {};
  }, [styleState.themes]);

  let sendTheme = async (theme, sender, recipient) => {
    let d = {
      dialog: recipient.persona,
      got_voice: true,
      mdialog: sortJoin(sender.mpersona, recipient.mpersona, "_"),
      mpersona: sender.mpersona,
      persona: sender.persona,
      peer: recipient.mpersona,
      peerpersona: recipient.persona,
      visibility: "PRIVATE_DIALOG"
    };
    let body = "Add theme # theme/public/" + theme.id;
    let descriptor = await mpersonaDescriptor(
      databaseState.dbMaster,
      sender.mpersona
    );
    let m = {
      type: "w.mpersona.message",
      content: { body: body, msgtype: "w.text" },
      mpersona: sender.mpersona,
      peer: recipient.mpersona,
      visibility: "PRIVATE_DIALOG",
      name: sender.name,
      ts_sender: timestamp(),
      smid: randomString(8),
      status: "1",
      origin_sender: "registered",
      thumbnail: "",
      lat: "",
      lon: "",
      persona_picture: "",
      version: globalState.version,
      parameters: { descriptor: descriptor }
    };
  };

  const storeAndUpdateTheme = (theme) => {
    updateTheme(databaseState.dexUser, theme);
  };

  // let modalShare = (val) => {
  //   const callback = (listSelected) => {
  //     console.log("SHARE WITH PERSONAS", listSelected);
  //     //Update the theme with the new owners
  //     if (globalState.persona) {
  //       let newOwners = listSelected
  //         .filter((recipient) => recipient.owner)
  //         .map((recipient) => recipient.mpersona);
  //       console.log("newOwners", newOwners);
  //       val.owners = val.owners
  //         ? [...newOwners, ...val.owners.filter((m) => !newOwners.includes(m))]
  //         : [globalState.persona.mpersona];
  //       storeAndUpdateTheme(val);
  //       console.log("newOwners theme", val);
  //       //Send the theme to each new owner
  //       listSelected.forEach((recipient) => {
  //         sendTheme(val, globalState.persona, recipient)
  //           .then(() => {})
  //           .catch((e) => {});
  //       });
  //     }
  //     setContentModal(undefined);
  //   };
  //   return (
  //     <div id="modalShare">
  //       <UISelectPersonas
  //         isOwner={
  //           val.owners && val.owners.includes(globalState.persona?.mpersona)
  //         }
  //         callback={callback}
  //         muid={globalState.muid}
  //         mpersona={globalState.persona?.mpersona}
  //         persona={globalState.persona?.persona}
  //       />
  //       {/* {val.name} */}
  //     </div>
  //   );
  // };

  const menuItems = (theme) => {
    let owner =
      theme.owners && theme.owners.includes(globalState.persona?.mpersona);
    return [
      {
        icon: (
          <RiEditLine
            style={
              owner
                ? { color: "var(--main_screen_text_color)" }
                : {
                    color: "var(--main_screen_text_color)",
                    opacity: "0.5"
                  }
            }
            title={owner ? "Edit" : "Edit unavailable"}
          />
        ),
        callback: (val) => {
          if (owner) {
            showEditThemeModal();
            setClickOutsideActive(false);
            let editType = "edit";
            owner &&
              globalDispatch({
                type: "SET_THEME_EDIT",
                values: {
                  theme: { ...val },
                  editType: editType
                }
              });
          }
        }
      },
      {
        icon: <RiFileCopyLine title="Clone" />,
        callback: (val) => {
          showCloneThemeModal();
          setClickOutsideActive(false);
          let editType = "clone";
          globalDispatch({
            type: "SET_THEME_EDIT",
            values: {
              theme: { ...val },
              editType: editType,
              creator: globalState.persona
            }
          });
        }
      },
      {
        icon: (
          <MdRefresh
            style={{ color: "var(--main_screen_text_color)" }}
            title="Refresh"
          />
        ),
        callback: (val) => {
          cacheS3Theme(databaseState.dexUser, "public", val.id);
        }
      },
      {
        icon: (
          <ImShare2
            style={{ color: "var(--main_screen_text_color)" }}
            title="Share"
          />
        ),
        callback: (val) => {
          showShareModal();
          setClickOutsideActive(false);
          setSelectedTheme(val);
        }
      },
      {
        icon: (
          <ImBin
            style={
              theme.id === styleState?.theme?.id
                ? {
                    color: "var(--main_screen_text_color)",
                    opacity: "0.5"
                  }
                : owner
                ? { color: "var(--main_screen_text_color)" }
                : {
                    color: "var(--main_screen_text_color)",
                    opacity: "0.5"
                  }
            }
            title={
              theme.id === styleState?.theme?.id
                ? "cannot delete current theme"
                : owner
                ? "Delete"
                : "Delete unavailable"
            }
          />
        ),
        callback: (val) => {
          if (owner && theme.id !== styleState?.theme?.id) {
            if (
              window.confirm(
                "Are you sure you want to delete " + val.name + "?"
              )
            ) {
              dex_action({
                type: "DEX_DELETE_RECORD",
                values: {
                  db: databaseState.dexUser,
                  table: "themes",
                  primaryKey: theme.id
                }
              });
            }
            let count = Object.keys(styleState.themes).length;
            if (count === 1) {
              styleDispatch({
                type: "SET_THEMES",
                values: { themes: {} },
                creator: globalState.persona
              });
            }
          }
        }
      }
    ];
  };

  const onRadioChange = (e) => {
    dex_action({
      type: "DEX_PUT",
      values: {
        db: databaseState.dexUser,
        table: "user",
        doc: { key: "current_theme", value: allThemes[e.target.value] }
      }
    });
  };

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick, selectedPersona]);

  const handleClose = () => {
    if (clickOutsideActive) {
      props.onClose();
      setModalClose(true);
    }
    // props.onClose()
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  const contnrStyle = { height: "90vh", maxWidth: "100%" };

  const handleCloseEdit = () => {
    setEditThemeModalState(false);
    setMainThemeState(true);
  };

  const handleCloseClone = () => {
    setCloneThemeModalState(false);
    setMainThemeState(true);
  };

  const handleCloseShare = () => {
    setShareModalState(false);
    setMainThemeState(true);
  };

  useEffect(() => {
    if (!screenWidth || screenWidth === undefined)
      setScreenWidth(window.innerWidth);
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  let content = (
    <div>
      {mainThemeState && (
        <Modals
          title="Settings"
          onClose={() => handleClose()}
          onClickOutside={() => handleClickOutside()}
          style={
            cloneThemeModalState || editThemeModalState || shareModalState
              ? contnrStyle
              : {}
          }
          footer={<div>{/* <p>footer</p>  */}</div>}
        >
          <div style={{ width: "100%" }}>
            <div className="modal-label-new" style={{ fontSize: "larger" }}>
              Themes
            </div>
            <div className="grid-container">
              {Object.keys(allThemes).map((k) => (
                <div
                  className="grid-row"
                  style={
                    isMobileTablet()
                      ? {
                          gridTemplateColumns:
                            "minmax(42vw, 1fr) minmax(min-content, max-content)",
                          gap: "0.5rem"
                        }
                      : allThemes[k].name?.length > (0.4 * screenWidth) / 10
                      ? {
                          gridTemplateColumns:
                            "minmax(40vw, 1fr) minmax(min-content, max-content)"
                        }
                      : {
                          gridTemplateColumns:
                            "minmax(max-content, 1fr) minmax(min-content, max-content)"
                        }
                  }
                  // className="UI-theme-item-themes"
                  id={"theme_" + allThemes[k].id}
                  key={"theme_" + allThemes[k].id}
                >
                  <div
                    className="grid-cell"
                    style={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      accentColor:
                        "var(--button_background_color_selected,#007bff)"
                    }}
                    // className="UI-theme-item-radio"
                  >
                    <label style={{ paddingLeft: "1rem" }}>
                      <input
                        type="radio"
                        value={k}
                        name="theme"
                        id={"radio_set_" + allThemes[k].id}
                        checked={
                          styleState?.theme?.id === undefined
                            ? allThemes[k].id === "TE"
                            : allThemes[k].id === styleState?.theme?.id
                        }
                        onChange={onRadioChange}
                      />
                      <span> {allThemes[k].name}</span>
                    </label>
                    <div
                      style={
                        allThemes[k].creator
                          ? {
                              fontSize: "0.9rem",
                              marginLeft: "1.5rem",
                              textAlign: "left"
                            }
                          : { display: "none" }
                      }
                    >
                      ({allThemes[k].creator})
                    </div>
                  </div>
                  <div className="grid-cell">
                    <ul className="UI-dropdown-theme-icons">
                      {menuItems(allThemes[k]).map((item, i) => (
                        <li
                          className="UI-dropdown-icon"
                          style={
                            isMobileTablet()
                              ? { marginLeft: "0.3rem", marginRight: "0.3rem" }
                              : {}
                          }
                          key={i}
                          onClick={() => item.callback(allThemes[k])}
                        >
                          {item.icon}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modals>
      )}
      {cloneThemeModalState && (
        <UICreateTheme
          titleName="Clone"
          onClose={() => handleCloseClone()}
          // setCloneThemeModalState(false)}
        />
      )}
      {shareModalState && (
        <UIShareTheme
          theme={selectedTheme}
          onClose={() => handleCloseShare()}
          // setShareModalState(false)}
          // clickOutsideActive={(val) => setClickOutsideActive(val)}
          // show={shareModalState}
        />
      )}
      {editThemeModalState && (
        <UICreateTheme
          titleName="Edit"
          onClose={() => handleCloseEdit()}
          // setEditThemeModalState(false)}
          show={editThemeModalState}
        />
      )}
    </div>
  );
  return content;
};

// memo acts like shouldComponentUpdate ... can use without the function,
//  or with a function that returns false if it SHOULD rerender
export default React.memo(UISettings, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
