import React from "react";
import useStyleState from "contexts/useStyleState";
import StyleContext from "contexts/contextStyle";
import { shouldLog } from "utils/shouldLog";

const StyleStateProvider = ({ children }) => {
  shouldLog() && console.log("[StyleStateProvider]props", children);
  return (
    <StyleContext.Provider value={useStyleState({})}>
      {children}
    </StyleContext.Provider>
  );
};

export default React.memo(StyleStateProvider, (prevProps, nextProps) => {
  return true;
});
