// import { timeoutGet, timeoutPost, timeoutDelete } from "hooks/http";
import { workerGet } from "workers/interfaceRest";

import { randomString } from "hooks/helper";
import { timestamp } from "hooks/helper";
import { shouldLog } from "./shouldLog";
import { dex_action } from "data/dexUtils";

const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;

export const updatePersonaList = async (
  uid,
  password,
  version,
  accessToken,
  db,
  databaseDispatch
) => {
  workerGet(urlPersona, keyPersona, {
    type: "personalist",
    uid: uid,
    pwd: password,
    token: accessToken,
    // !!! TODO token
    msgid: randomString(8),
    version: version
  })
    .then((personaList) => {
      shouldLog() && console.log("!!!personaList", personaList);
      personaList?.list === undefined
        ? console.log()
        : dex_action({
            type: "DEX_PUT",
            values: {
              db: db,
              table: "account",
              doc: { type: "personas", value: personaList.list }
            }
          });
    })
    .catch((err) => {
      console.log("CATCH", err);
    });
};

export const personaDelete = async (
  uid,
  password,
  version,
  accessToken,
  mpersona,
  db,
  databaseDispatch,
  globalDispatch
) => {
  const response = await workerGet(urlPersona, keyPersona, {
    type: "delmpersona",
    uid: uid,
    pwd: password,
    token: accessToken,
    mpersona: mpersona,
    msgid: randomString(8),
    version: version
  });
  shouldLog() && console.log("deletePersona: ", response);

  // If successfully deleted, remove associated dialogs from database
  if (response.result === "mpersonadeleted") {
    // Should we also delete all messages?
    updatePersonaList(
      uid,
      password,
      version,
      accessToken,
      db,
      databaseDispatch
    );
    globalDispatch({
      type: "SET_PERSONA",
      values: { persona: undefined }
    });
  }

  // Now refresh the Persona list

  return response;
};

export const personaRename = async (
  uid,
  password,
  version,
  accessToken,
  mpersona,
  persona,
  db,
  databaseDispatch,
  globalDispatch
) => {
  const response = await workerGet(urlPersona, keyPersona, {
    type: "renamempersona",
    uid: uid,
    pwd: password,
    token: accessToken,
    mpersona: mpersona,
    persona: persona,
    newtag: timestamp(),
    msgid: randomString(8),
    version: version
  });
  shouldLog() && console.log("renamempersona: ", response);

  // If successfully updated
  if (response.result === "personarenamed") {
    // What about Descriptors?
    await updatePersonaList(
      uid,
      password,
      version,
      accessToken,
      db,
      databaseDispatch
    );
    globalDispatch({
      type: "SET_PERSONA",
      values: { persona: { mpersona: mpersona, persona: persona } }
    });
  }

  // Now refresh the Persona list

  return response;
};
