import GlobalContext from "contexts/context";
import React, { useMemo, useContext, useState, useEffect, useRef } from "react";
import { workerPost } from "workers/interfaceRest";
const isEqual = require("react-fast-compare");

//"finance:payment:supplier"

const ServiceDropDown = (props) => {
  const { item, svcMsgState, onChange, message } = props;
  const { globalState } = useContext(GlobalContext);

  const convtoUpper = (text) => {
    return text.toUpperCase();
  };

  const [select, setSelect] = useState(<div></div>);

  // useEffect(() => {
  //   setSelect(props, item, parent, svcMsgState, filterResult, svcMsgDispatch);
  // }, [props, item, parent, svcMsgState, filterResult, svcMsgDispatch]);

  useEffect(() => {
    // setSelect(sel(props, item));
    item.dynamicOptions &&
      globalState?.jwt &&
      workerPost(item.dynamicOptions.url, item.dynamicOptions.key, {
        "action": item.dynamicOptions.action,
        "mtopicOrig": message.mtopic,
        "topicOrig": message.topic,
        "mpersonaOrig": message.mpersona,
        "personaOrig": message.persona,
        "start_position": item.dynamicOptions.start_position,
        "jwt": globalState?.jwt
      }).then((urlResult) => {
        console.log("!!!urlResult ", urlResult, item);
        // TODO: Consider adding a "persist" flag to allow replacement of the "options" item in the message stored on the PWA's database
        // TODO: if REST fails, then do not update the initial list
        item.options = (item.staticOptions || []).concat(urlResult);
        // setItems(urlResult);
        // setSelect(sel(props, item));
        // setTimeout(
        //   () =>
        //     setSelect(sel(props, item, parent, filterResult, svcMsgDispatch)),
        //   5000
        // );
      });

    return () => {};
  }, [item?.dynamicOptions, globalState?.jwt]);

  useEffect(() => {
    let sel = (props, item) => (
      <select
        className={"lines-inner"}
        disabled={item.locked === "true"}
        id={props.message.smid + "_" + item.id}
        style={{
          fontSize: "var(--service_message_fontsize)",
          minWidth: "10rem",
          border: "1px solid var(--modal_border_color)",
          borderRadius: "var(--border_radius_small)"
        }}
        onChange={(event) => onChange(event)}
      >
        <option value={item.title} id="none" key="title">
          {convtoUpper(item.title)}
        </option>

        {Array.isArray(item?.options) &&
          item.options.map((opt, index) => {
            let v = (
              <option
                key={opt.id}
                value={opt.title}
                id={opt.id}
                position={item.position}
                items={opt.items}
                selected={
                  item.selected
                    ? item.selected === opt.id
                    : svcMsgState.response.filter(
                        (r) =>
                          r.id === props.message.smid + "_" + item.id &&
                          r.value === opt.id
                      ).length > 0
                }
              >
                {opt.title}
              </option>
            );
            return v;
          })}
      </select>
    );
    setSelect(sel(props, item));
    return () => {};
  }, [item, item.options, onChange, props, svcMsgState.response]);

  return select;
};

export default React.memo(ServiceDropDown, (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
});
