import React, { useState } from "react";
import ClickOutsideDetector from "utils/ClickOutsideDetector";
import "../ui/UI.css";

const LoadMenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <ClickOutsideDetector
      caller="dropDownCard"
      listen
      onClickOutside={() => {
        props.close();
      }}
      onClick={() => {}}
    >
      <div style={{ position: "relative" }}>
        {props.type === "normal" && (
          <ul
            // ref={menuRef}
            className="UI-dropdown-list"
            style={{
              // maxHeight: `${maxHgtMnu}px`,
              top: `${props.yPos}px`,
              left: `${props.xPos}px`,
              // isMobileTablet() ? `${props.xPos - 60}px` : `${props.xPos}px`,
              transition: "transform 0.2s ease-in-out"
            }}
          >
            <li className="UI-dropdown-header">{props.title}</li>
            {props.menuList.map((item, i) => {
              return (
                <li
                  className="UI-dropdown-item-filters"
                  key={i}
                  onClick={() => {
                    // setOpen(false);
                    item.callback();
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100px"
                    }}
                  >
                    {item.name}
                  </span>
                  <span
                    style={{
                      display: "inline-block",
                      width: "1rem",
                      height: "1rem",
                      backgroundColor: item.color,
                      marginLeft: "0.5rem"
                    }}
                  ></span>
                </li>
              );
            })}
          </ul>
        )}
        {props.type === "dropdown" ? (
          <div
            className="UI-dropdown-list"
            style={{
              top: `${props.yPos}px`,
              left: `${props.xPos}px`,
              transition: "transform 0.2s ease-in-out"
            }}
          >
            <button
              onClick={toggleDropdown}
              style={{
                border: "none",
                background: "transparent"
              }}
            >
              {props.title} {isOpen ? "▲" : "▼"}
            </button>
            {isOpen && (
              <ul>
                {props.menuList.map((option, index) => (
                  <li
                    key={index}
                    style={{ cursor: "pointer" }}
                    onClick={option.callback}
                  >
                    {option.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <div
            className="UI-dropdown-list"
            style={{
              top: `${props.yPos}px`,
              left: `${props.xPos}px`,
              zIndex: "999",
              transition: "transform 0.2s ease-in-out"
            }}
          >
            <ul>
              {props.menuList.map((option, index) => (
                <li
                  key={index}
                  style={{ cursor: "pointer", textAlign: "left" }}
                  onClick={option.callback}
                >
                  {option.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ClickOutsideDetector>
  );
};

export default LoadMenu;
