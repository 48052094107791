export const strTob64 = (str) => {
  return Buffer.from(str).toString("base64");
};

export const b64ToStr = (b64) => {
  return Buffer.from(b64, "base64").toString("utf-8");
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      let dataUrl = reader.result;
      resolve(dataUrl.split(",")[1]);
    };
    reader.readAsDataURL(blob);
  });
};

export const addAttachments = async (dbMessages, doc) => {
  if (doc._attachments) {
    try {
      let contentBlob = await dbMessages.getAttachment(doc._id, "content");
      let content64 = await blobToBase64(contentBlob);
      let content = JSON.parse(b64ToStr(content64));
      doc.content = content;
      // console.log("!!!attachment content", content);
    } catch (e) {}

    try {
      let imagesBlob = await dbMessages.getAttachment(doc._id, "images");
      let images64 = await blobToBase64(imagesBlob);
      let images = JSON.parse(b64ToStr(images64));
      doc.parameters.images = images;
      // console.log("!!!attachment images", images);
    } catch (e) {}

    try {
      let thumbBlob = await dbMessages.getAttachment(doc._id, "thumbnail");
      let thumb64 = await blobToBase64(thumbBlob);
      let thumbnail = JSON.parse(b64ToStr(thumb64));
      doc.thumbnail = thumbnail;
      // console.log("!!!attachment thumbnail", thumbnail);
    } catch (e) {}

    try {
      let serviceBlob = await dbMessages.getAttachment(doc._id, "service_msg");
      let service64 = await blobToBase64(serviceBlob);
      let service_msg = JSON.parse(b64ToStr(service64));
      doc.parameters.service_msg = service_msg;
      // console.log("!!!attachment service_msg", service_msg);
    } catch (e) {}

    try {
      let quoteBlob = await dbMessages.getAttachment(doc._id, "quote");
      let quote64 = await blobToBase64(quoteBlob);
      let quote = JSON.parse(b64ToStr(quote64));
      doc.parameters.quote = quote;
      // console.log("!!!attachment quote", quote);
    } catch (e) {}
  }
  return doc;
};

export const moveAttachments = (jMessage, separateAttachments) => {
  if (separateAttachments) {
    let attachments = {};
    jMessage.content &&
      (attachments.content = {
        content_type: "text/plain",
        data: strTob64(JSON.stringify(jMessage.content))
      });
    jMessage.parameters.images &&
      (attachments.images = {
        content_type: "text/plain",
        data: strTob64(JSON.stringify(jMessage.parameters.images))
      });
    jMessage.parameters.service_msg &&
      (attachments.service_msg = {
        content_type: "text/plain",
        data: strTob64(JSON.stringify(jMessage.parameters.service_msg))
      });
    jMessage.parameters.quote &&
      (attachments.quote = {
        content_type: "text/plain",
        data: strTob64(JSON.stringify(jMessage.parameters.quote))
      });
    jMessage.thumbnail &&
      (attachments.thumbnail = {
        content_type: "text/plain",
        data: jMessage.thumbnail
      });
    delete jMessage.content.body;
    delete jMessage.parameters.images;
    delete jMessage.parameters.service_msg;
    delete jMessage.parameters.quote;
    delete jMessage.thumbnail;
    jMessage._attachments = attachments;
  }
};
