import { useReducer, useState, useEffect } from "react";
import themes from "theme/schema.json";
import { shouldLog } from "utils/shouldLog";

const useStyleState = (state) => {
  const defaultThemeID = "ezist";
  const [selectedStyle, setSelectedStyle] = useState(
    { ...themes, ...state.liveThemes }[defaultThemeID]
  );

  const setFilter = (filter) => {
    let root = document.getElementById("root");
    root && (root.style.filter = filter);
  };

  const applyStyle = () => {
    shouldLog() && console.log("applyStyle", selectedStyle);
    let root = document.getElementById("root");
    let keys = Object.keys(selectedStyle.colors);
    keys.forEach((k) => {
      shouldLog() && console.log("applyStyle colors", selectedStyle.colors[k]);
      root && root.style.setProperty("--" + k, selectedStyle.colors[k]);
    });
    if (selectedStyle.fonts) {
      let fontKeys = Object.keys(selectedStyle.fonts);
      fontKeys.forEach((k) => {
        root && root.style.setProperty("--" + k, selectedStyle.fonts[k]);
      });
    }
    if (selectedStyle.opacities) {
      let opacitiesKeys = Object.keys(selectedStyle.opacities);
      opacitiesKeys.forEach((k) => {
        root && root.style.setProperty("--" + k, selectedStyle.opacities[k]);
      });
    }
    if (selectedStyle.transparency) {
      let transparencyKeys = Object.keys(selectedStyle.transparency);
      transparencyKeys.forEach((k) => {
        root && root.style.setProperty("--" + k, selectedStyle.transparency[k]);
      });
    }
  };

  useEffect(() => {
    selectedStyle?.colors && applyStyle();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStyle?.colors]);

  useEffect(() => {
    selectedStyle?.fonts && applyStyle();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStyle?.fonts]);

  useEffect(() => {
    selectedStyle?.opacities && applyStyle();
    return () => {};
  }, [selectedStyle?.opacities]);

  useEffect(() => {
    selectedStyle?.transparency && applyStyle();
    return () => {};
  }, [selectedStyle?.transparency]);

  useEffect(() => {
    let allThemes = { ...themes, ...state.liveThemes };
    selectedStyle &&
      Object.keys(allThemes).forEach((k) => {
        if (allThemes[k].name === selectedStyle?.name) {
          // localStorage.setItem("lastThemeID", selectedStyle?.id);
          // localStorage.setItem("lastTheme", JSON.stringify(selectedStyle));
        }
      });
    return () => {};
  }, [selectedStyle, selectedStyle?.id, state.liveThemes]);

  const reducer = (state, action) => {
    let allThemes = { ...themes, ...state.liveThemes };
    switch (action.type) {
      case "SET_DEFAULT":
        // setSelectedStyle(themes[defaultThemeID])
        // setFilter(filterDefault);
        return {
          ...state,
          style: allThemes[defaultThemeID]
        };
      case "TOGGLE_DARKMODE":
        let filter = state.dark
          ? "invert(0) brightness(1)"
          : "invert(1) brightness(1)";
        setFilter(filter);
        return {
          ...state,
          filter: filter,
          dark: !state.dark
        };
      case "SET_STYLE":
        setSelectedStyle(
          allThemes[action.values.style] || allThemes[defaultThemeID]
        );
        return {
          ...state,
          style: allThemes[action.values.style] || allThemes[defaultThemeID]
        };
      case "SET_THEME":
        setSelectedStyle(action.values.theme);
        return {
          ...state,
          theme: action.values.theme
        };
      case "SET_THEMES":
        return {
          ...state,
          themes: action.values.themes
        };
      case "RESTORE_STYLE":
        let style = action.values.style;
        let desiredTheme = Object.keys(themes).reduce((result, k) => {
          return themes[k].id === style.id ? themes[k] : result;
        }, undefined);
        if (desiredTheme) {
          style.colors = desiredTheme.colors;
          style.fonts = desiredTheme.fonts;
          style.opacities = desiredTheme.opacities;
          style.transparency = desiredTheme.transparency;
        } else {
          style.colors = {
            ...allThemes[defaultThemeID].colors,
            ...action.values.style.colors
          };

          style.fonts = {
            ...allThemes[defaultThemeID].fonts,
            ...action.values.style.fonts
          };
          style.opacities = {
            ...allThemes[defaultThemeID].opacities,
            ...action.values.style.opacities
          };
          style.transparency = {
            ...allThemes[defaultThemeID].transparency,
            ...action.values.style.transparency
          };
        }
        setSelectedStyle(style);
        return {
          ...state,
          style: style
        };
      default: {
        return state;
      }
    }
  };

  const [styleState, styleDispatch] = useReducer(reducer, state);
  return { styleState, styleDispatch };
};

export default useStyleState;
