import React, { useState, useEffect, useRef, useContext } from "react";
// import { timeoutGet } from "hooks/http";
// import { randomString } from "hooks/helper";
import UIPersonaList from "ui/UIPersonaList";
import useDebounce from "hooks/debounce";
import { workerGet } from "workers/interfaceRest";
import GlobalContext from "contexts/context";
import PersonasContext from "contexts/contextPersonas";
import Modals from "./Modals";
import "./modals.css";
import { useHistory } from "react-router-dom";
import { WorldTextInput } from "utils/UtilsUniversal";

const UISearch = (props) => {
  const { globalState } = useContext(GlobalContext);
  const { personasState } = useContext(PersonasContext);
  const [useSearch, setSearch] = useState(undefined);
  const [useList, setList] = useState([]);
  const debouncedSearchTerm = useDebounce(useSearch, 500);
  const searchForPersona = useRef();
  const [timer, setTimer] = useState(0);
  const increment = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const tick = useRef();
  const [modalClose, setModalClose] = useState(false)
  const [outClick, setOutClick] = useState(false)
  const [selectedPersona, setSelectedPersona] = useState(false)
  let history = useHistory()
  const [focusVal, setFocusVal] = useState(true)
  const [searchName, setSearchName] = useState("")
  const [calculatedMaxHeight, setCalculatedMaxHeight] = useState();

  useEffect(() => {
    if (increment.current) {
      increment.current = !increment.current;
      return;
    }

    if (isActive) {
      tick.current = setInterval(() => {
        // <-- set tick ref current value
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current); // <-- access tick ref current value
    }

    return () => clearInterval(tick.current); // <-- clear on unmount!
  }, [isActive]);

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleSearchName = (e) => {
    setSearchName(e);
    setSearch(e);
    setIsActive(true);
  };

  useEffect(() => {
    const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
    const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
    if (debouncedSearchTerm === "") {
      setIsActive(false);
      setList([]);
    }
    let isMounted = true;
    globalState?.jwt &&
      debouncedSearchTerm &&
      debouncedSearchTerm !== "" &&
      workerGet(urlPersona, keyPersona, {
        type: "searchpersona",
        value: debouncedSearchTerm,
        token: globalState?.jwt,
        version: "v1.6.0"
      })
        .then((result) => {
          isMounted && setList(result.list || []);
          clearInterval(increment.current);
          setIsActive(false);
          setTimer(0);
        })
        .catch((err) => {
          console.log("CATCH", err);
        });

    return () => {
      isMounted = false;
    };
    //the empty string in the depnedencies below means that this useEffect
    //is executed (triggered) even when debouncedSearchTerm bcomes ""
  }, [debouncedSearchTerm, globalState?.jwt, globalState.version, ""]);

  useEffect(() => {
    window.addEventListener("popstate", props.onClose);
    if (
      window.location?.href?.includes("menu") ||
      window.location?.href?.includes("modal")
    ) {
      window.history.replaceState({ id: 2 }, null, "modal");
    } else {
      if (!props.loginModal) {
        window.history.pushState({ id: 2 }, null, "modal");
      }
    }
    return () => {
      window.removeEventListener("popstate", props.onClose);
    };
  }, [modalClose, outClick, selectedPersona]);

  const handleClose = () => {
    setModalClose(true);
    props.onClose();
  };

  const handleClickOutside = () => {
    setOutClick(true);
    if (history.length >= 1) history.goBack();
  };

  const handleSelectedPersona = () => {
    setSelectedPersona(true);
    props.onClose();
  };

  let content = (
    <div id="search_for_persona_modal">
      <Modals
        title="Search for Persona"
        onClose={() => handleClose()}
        onClickOutside={() => handleClickOutside()}
        clickOutsideActive={true}
        switchScrollOff={true}
        theMaxHgtBody={(val) => setCalculatedMaxHeight(val)}
        // footer={<p></p>}
      >
        <div className="UI-login-container">
          <WorldTextInput
            identity="searchfor"
            focus={focusVal}
            removeFocus={(val) => handleFocus(val)}
            labelStyle={{
              fontWeight: "bold"
            }}
            type="text"
            // title={tagTooltip}
            callback={(e) => handleSearchName(e)}
            label="Tap on name to start a dialog"
            //  required={true}
            placeholder="Searching for..."
            // description={"generic World text input field testing phase"}
            minRows={1}
            maxRows={1}
            // minStrLen={3}
            //  maxStrLen={56}
            // active={worldTestTA?.length > 0 ? "true" : "false"}
            value={searchName}
            active="true"
            autoComplete="off"
          />
          {isActive && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Loading...</span>
              {timer}
              <div className="spinner"></div>
            </div>
          )}
          <div className="flex-container">
            <div
              className="flex-row"
              // className="grid-row"
              // className="ui-invite-grid"
            >
              <header>Persona</header>
              <header style={{ marginRight: "2rem" }}>Last online</header>
            </div>
            <div
              id="result_list"
              style={{
                overflowY: "auto",
                overflowX: "hidden",
                maxHeight: `${calculatedMaxHeight - 55}px`,
                paddingRight: "1rem"
              }}
            >
              <UIPersonaList
                personaList={[...useList]}
                muid={globalState.muid}
                mpersona={
                  globalState.persona?.mpersona
                    ? globalState.persona.mpersona
                    : personasState.personas
                    ? personasState.personas[0]?.mpersona
                    : undefined
                }
                persona={
                  globalState.persona?.persona
                    ? globalState.persona.persona
                    : personasState.personas
                    ? personasState.personas[0]?.persona
                    : undefined
                }
                onClose={() => handleSelectedPersona()}
                version={globalState.version}
              />
            </div>
          </div>
        </div>
      </Modals>
    </div>
  );

  return content;
};

// memo acts like shouldComponentUpdate ... can use without the function, or with a function that returns false if it SHOULD rerender
export default React.memo(UISearch, (prevProps, nextProps) => {
  return prevProps === nextProps;
});
