import { useReducer } from "react";
import isEqual from "react-fast-compare";
import { shouldLog } from "utils/shouldLog";

const reducer = (state, action) => {
  shouldLog() && console.log("SET personas", state, action);
  switch (action.type) {
    case "SET_DESCRIPTORS":
      if (isEqual(state.descriptors, action.values.descriptors)) return state;
      else
        return {
          ...state,
          descriptors: action.values.descriptors
        };
    case "SET_PERSONAS":
      if (isEqual(state.personas, action.values.personas)) return state;
      else
        return {
          ...state,
          personas: action.values.personas
        };
    default: {
      return state;
    }
  }
};

const usePersonasState = (state) => {
  const [personasState, personasDispatch] = useReducer(reducer, state);
  return { personasState, personasDispatch };
};

export default usePersonasState;
