export const timeFormat = (s, format) => {
  let newstr = s.replace(" ", "T");
  const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  const d = new Date(newstr + "Z");
  // console.log("dateandtime s=", s, " d=", d,
  //   " tolocale=", d.toLocaleString())
  return d.toLocaleTimeString(undefined, options);
};

export const dateFormat = (s, format) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  let newstr = s.replace(" ", "T");
  const d = new Date(newstr + "Z");
  // console.log("dateandtime s=", s, " d=", d,
  //   " tolocale=", d.toLocaleString())
  return d.toLocaleDateString(undefined, options);
};

export const timestamp = (pref1, pref2, pref3) => {
  if (pref1 && pref1 !== "" && pref1 !== "undefined") {
    return pref1;
  } else if (pref2 && pref2 !== "" && pref2 !== "undefined") {
    return pref2;
  } else if (pref3 && pref3 !== "" && pref3 !== "undefined") {
    return pref3;
  }
  return "";
};
