import React, { useState, useRef, useContext, useEffect } from 'react'
import { WorldTextInput, CheckPhone, CheckSAIdentityNumber, removeLeadTrailSpace } from "utils/UtilsUniversal"
import { randomString } from "hooks/helper";
import { useHistory } from "react-router-dom";
import { workerPost } from "workers/interfaceRest";
import { shouldLog } from "utils/shouldLog";
import { sanitizeUserId } from "utils/textUtils";
import { dex_action } from "data/dexUtils";
import DatabaseContext from "data/contextDatabase";
import "ui/UI.css"
import "ui/UISS.css";
import { isMobileTablet } from "hooks/helper";

const UIRegister = (props) => {
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(props.email);
  const [submitOK, setSubmitOK] = useState(false);
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [uid, setUid] = useState("");
  const [uidEdit, setUidEdit] = useState(false);
  const [id, setId] = useState("");
  const [pFocus, setPFocus] = useState(false);
  const userNameRef = useRef();
  const urlPersona = process.env.REACT_APP_PERSONA_API_URL;
  const keyPersona = process.env.REACT_APP_PERSONA_API_KEY;
  const { databaseState } = useContext(DatabaseContext);
  let history = useHistory();
  const [focusVal, setFocusVal] = useState(true);
  const [accepted, setAccepted] = useState(false);
  const [scrnHeight, setScrnHeight] = useState(0);
  const [changeHeight, setChangeHeight] = useState(false);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 1024) {
        setScrnHeight(window.innerHeight * 0.8);
        setChangeHeight(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createPersona = async (uid, pwd, accessToken, persona, tag) => {
    const response = await workerPost(urlPersona, keyPersona, {
      type: "addpersona",
      uid: uid,
      pwd: pwd,
      token: accessToken,
      persona: persona,
      tag: tag,
      case_sensitive: "true",
      msgid: randomString(8)
    });
    shouldLog() && console.log("createPersona: ", response);
    return response;
  };

  const onCreateUser = async (username, accessToken, personaName) => {
    const data = {
      type: "create_user", // use create_user instead of register as it will add the persona and email atomically
      uid: username,
      persona: personaName || username,
      token: accessToken,
      msgid: randomString(8)
    };
    const createUserResult = await workerPost(urlPersona, keyPersona, data);
    // shouldLog() &&
    console.log("[createUserResult]", createUserResult);
    if (createUserResult.result === "failed" || !createUserResult.result) {
      switch (createUserResult.reason) {
        case "uidexists":
          alert("That user already exists, please try again");
          break;
        default: {
          alert("User creation error: " + createUserResult.reason);
          break;
        }
      }
    } else if (createUserResult.result === "created_user") {
      let Muid = createUserResult.muid;
      let Uid = createUserResult.uid;
      let Token = createUserResult.token;
      Muid &&
        Uid &&
        Token &&
        dex_action({
          type: "DEX_UPSERT_MATCH",
          values: {
            db: databaseState.dexAdmin,
            table: "store",
            match: { key: "user" },
            function: (val) => {
              let newVal = { ...val };
              if (Token && Token !== val?.jwtLongExpiry) {
                newVal.jwtLongExpiry = Token;
              }
              if (Uid && Uid !== val?.uid) {
                newVal.uid = Uid;
              }
              if (Muid && Muid !== val?.muid) {
                newVal.muid = Muid;
              }
              return newVal;
            }
          }
        });
      // props.onClose && props.onClose();
      givenName &&
        (await workerPost(urlPersona, keyPersona, {
          type: "add_unique_property",
          jwt: Token,
          muid: Muid,
          property: "given_name",
          value: givenName
        })
          .then(
            (r) =>
              shouldLog() && console.log("[UIRegister] workerPut result", r)
          )
          .catch((err) => {
            console.log("[UIRegister] err", err);
          }));
      familyName &&
        (await workerPost(urlPersona, keyPersona, {
          type: "add_unique_property",
          jwt: Token,
          muid: Muid,
          property: "family_name",
          value: familyName
        })
          .then(
            (r) =>
              shouldLog() && console.log("[UIRegister] workerPut result", r)
          )
          .catch((err) => {
            console.log("[UIRegister] err", err);
          }));
      phone &&
        (await workerPost(urlPersona, keyPersona, {
          type: "add_unique_property",
          jwt: Token,
          muid: Muid,
          property: "phone_number",
          value: phone
        })
          .then(
            (r) =>
              shouldLog() && console.log("[UIRegister] workerPut result", r)
          )
          .catch((err) => {
            console.log("[UIRegister] err", err);
          }));
      id &&
        (await workerPost(urlPersona, keyPersona, {
          type: "add_unique_property",
          jwt: Token,
          muid: Muid,
          property: "id_number",
          value: id
        })
          .then(
            (r) =>
              shouldLog() && console.log("[UIRegister] workerPut result", r)
          )
          .catch((err) => {
            console.log("[UIRegister] err", err);
          }));
      dex_action({
        type: "DEX_UPSERT_MATCH",
        values: {
          db: databaseState.dexAdmin,
          table: "store",
          match: { key: "client" },
          function: (val) => {
            return {
              cid: randomString(10),
              ...val // this will keep the old cid if it already exists
            };
          }
        }
      });
      history.push("/");
      // if (history.length >= 1) history.goBack();
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleFocus = (val) => {
    setFocusVal(val);
  };

  const handleGivenName = (e) => {
    setGivenName(e);
    setUidEdit(false);
  };

  const handleBlurGivenName = () => {
    setGivenName(givenName.trim());
  };

  const handleFamilyName = (e) => {
    setFamilyName(e);
    setUidEdit(false);
  };

  const handleBlurFamilyName = () => {
    setFamilyName(familyName.trim());
  };

  const handleID = (e) => {
    setId(e);
  };

  const handleEmail = (e) => {
    setEmail(e);
  };

  const handlePhone = (e) => {
    setPhone(e);
  };

  const handleUser = (e) => {
    setUidEdit(true);
    setUid(e);
  };

  useEffect(() => {
    if (
      givenName?.length > 0 &&
      familyName?.length > 0 &&
      uid?.length > 0 &&
      phone?.length > 0
    )
      setSubmitOK(true);
    else setSubmitOK(false);
  }, [givenName, familyName, uid, phone]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let bool = true;
    if (submitOK) {
      setGivenName(givenName.trim());
      setFamilyName(familyName.trim());
      setUid(uid.trim());
      if (uid === undefined || uid === "" || uid.length < 9) {
        if (uid.length < 9) alert("UserName must be more than 8 characters");
        // else alert("UserName cannot be blank");
        userNameRef.current?.focus();
        bool = false;
      }
      phone.replace(/\s/g, "");
      let cleanPhone = phone.replace(/\s/g, "");
      if (cleanPhone !== phone) setPhone(cleanPhone);
      if (bool && phone && phone?.length > 0) {
        let errMsg = CheckPhone(cleanPhone, 10, 13);
        if (errMsg.toLowerCase() !== "ok") {
          alert("Phone number is not valid - Error: " + errMsg);
          bool = false;
        }
      }
      if (
        bool &&
        id &&
        id?.length > 0 &&
        id?.length < 5
        //&& !CheckSAIdentityNumber(id, 6, 13, new Date('1900-01-01'), new Date('2999-12-31'))
      ) {
        alert("Identity number is not valid");
        bool = false;
      }
      let trimUid = uid.trim();
      if (bool)
        onCreateUser(
          trimUid,
          props.jwtSignup,
          `${givenName.trim()} ${familyName.trim()}`
        );
    }
  };

  const [intFrameHeight, setIntFrameHeight] = useState(window.innerHeight);
  const [UIFrameHeight, setUIFrameHeight] = useState(window.innerHeight);

  const handleAccept = () => {
    setAccepted(true);
  };

  useEffect(() => {
    if (
      givenName !== "" &&
      givenName !== undefined &&
      familyName !== "" &&
      familyName !== undefined &&
      !uidEdit
    ) {
      let trimGivenName = givenName.trim();
      let trimFamilyName = familyName.trim();
      setUid(trimGivenName + "." + trimFamilyName);
    }
  }, [givenName, familyName]);

  return (
    <div
      className="UI"
      style={{
        height: intFrameHeight ? intFrameHeight : window.innerHeight,
        backgroundColor: "var(--main_screen_background_color)"
      }}
    >
      <div
        className={isMobileTablet() ? "UI-frame" : "col-1"}
        style={{
          height: "100vh"
        }}
      >
        {!accepted && (
          <div
            style={{
              margin: "10px 10px 10px 10px",
              overflow: "auto"
            }}
          >
            <h2
              style={{
                textAlign: "left",
                marginBottom: "0",
                marginTop: "0",
                fontWeight: "bold"
              }}
            >
              Privacy information
            </h2>
            {!process.env.REACT_APP_URL?.includes("ezist") && (
              <div
                style={{
                  textAlign: "left",
                  overflow: "auto",
                  padding: "4px",
                  marginBottom: "0",
                  marginTop: "0",
                  fontSize: "0.82rem"
                }}
              >
                WORLD will not share your personal information with third
                parties without your express consent, unless:
                <br />
                <ol className="modal-list-new">
                  <li>
                    we believe it to be in the interests of your health and
                    safety;
                  </li>
                  <li>required to do so by law.</li>
                </ol>
                <br />
                By using the WORLD app you consent to this Privacy Policy, or
                future versions of it. You may opt-out at any time by deleting
                your account and uninstalling the app.
                <br /> <br />
                WORLD will retain User Provided data and Automatically Collected
                information for as long as you use the application and for a
                period of time thereafter as may be required by law.
                <br /> <br />
                WORLD will not actively solicit data from, or market to,
                children.
                <br /> <br />
                WORLD will not send you promotional material without your
                consent.
                <br /> <br />
                <p>
                  The detailed Privacy Policy can be viewed on our website using
                  the button below. WORLD will endeavour to notify you of any
                  changes to this policy.
                </p>
                <button
                  className="UI-button-confined"
                  style={{
                    marginLeft: "auto",
                    // marginTop: "0.75rem",
                    marginBottom: "0.75rem"
                  }}
                  type="button"
                  onClick={() =>
                    openInNewTab("https://www.enabled.world/privacy-policy/")
                  }
                >
                  Privacy policy
                </button>
              </div>
            )}
            {process.env.REACT_APP_URL?.includes("ezist") && (
              <div
                style={{
                  textAlign: "left",
                  // maxHeight: "180px",
                  overflow: "auto",
                  padding: "4px",
                  marginBottom: "0",
                  marginTop: "0",
                  fontSize: "0.82rem"
                }}
              >
                EZist will not share your personal information with third
                parties without your express consent, unless:
                <br />
                <ol className="modal-list-new">
                  <li>
                    we believe it to be in the interests of your health and
                    safety;
                  </li>
                  <li>required to do so by law.</li>
                </ol>
                <br />
                By using the EZist app you consent to this Privacy Policy, or
                future versions of it. You may opt-out at any time by deleting
                your account and uninstalling the app.
                <br /> <br />
                EZist will retain User Provided data and Automatically Collected
                information for as long as you use the application and for a
                period of time thereafter as may be required by law.
                <br /> <br />
                EZist will not actively solicit data from, or market to,
                children.
                <br /> <br />
                EZist will not send you promotional material without your
                consent.
                <br /> <br />
                <p>
                  The detailed Privacy Policy can be viewed on our website using
                  the button below. EZist will endeavour to notify you of any
                  changes to this policy.
                </p>
                <button
                  className="UI-button-confined"
                  style={{
                    marginLeft: "auto",
                    // marginTop: "0.75rem",
                    marginBottom: "0.75rem"
                  }}
                  type="button"
                  onClick={() =>
                    openInNewTab(
                      "https://ezist.online/wp-content/uploads/2022/05/EZist-Privacy-Policy-22020505.pdf"
                    )
                  }
                >
                  Privacy policy
                </button>
              </div>
            )}
            {/* </div> */}
            <div>
              <button
                className="UI-button-service"
                style={{
                  height: "fit-content",
                  wordBreak: "break-word",
                  maxWidth: "90%"
                }}
                onClick={handleAccept}
              >
                I accept the terms and conditions
              </button>
            </div>
          </div>
        )}
        {accepted && (
          <div
            style={
              changeHeight && window.innerWidth <= 1024
                ? {
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    overflowY: "scroll",
                    height: "100%",
                    maxHeight: scrnHeight
                  }
                : {
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    flex: "1"
                  }
            }
          >
            <h2
              style={{
                marginTop: "1rem",
                textAlign: "left",
                marginLeft: "10%"
              }}
            >
              Register
            </h2>
            <form
              id="newuser_form"
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "10%",
                width: "80%",
                height: "100%"
              }}
            >
              <WorldTextInput //first name
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="givenname"
                // focus={focusVal}
                // removeFocus={(val) => handleFocus(val)}
                labelStyle={{
                  fontWeight: "bold"
                }}
                type="text"
                // title={tagTooltip}
                callback={(e) => handleGivenName(e)}
                callback2={() => handleBlurGivenName()}
                label={"First name"}
                required={true}
                placeholder={"Given name"}
                // description={"generic World text input field testing phase"}
                minRows={1}
                maxRows={1}
                // minStrLen={3}
                maxStrLen={80}
                // active={worldTestTA?.length > 0 ? "true" : "false"}
                value={givenName}
                regex={/[a-z'-._/, ]$/i}
              />
              <WorldTextInput //surname
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="csurname"
                labelStyle={{
                  fontWeight: "bold"
                }}
                type="text"
                // title={tagTooltip}
                callback={(e) => handleFamilyName(e)}
                callback2={() => handleBlurFamilyName()}
                label={"Surname"}
                required={true}
                placeholder={"Family name"}
                // description={"generic World text input field testing phase"}
                minRows={1}
                maxRows={1}
                // minStrLen={3}
                maxStrLen={80}
                // active={worldTestTA?.length > 0 ? "true" : "false"}
                value={familyName}
                regex={/[a-z'-._/, ]$/i}
              />
              <WorldTextInput //email
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="email"
                labelStyle={{
                  fontWeight: "bold"
                }}
                type="text"
                // title={tagTooltip}
                callback={(e) => handleEmail(e)}
                label={"Email"}
                required={false}
                placeholder={email || "eg email@server.com"}
                // description={"generic World text input field testing phase"}
                minRows={1}
                maxRows={1}
                minStrLen={9}
                maxStrLen={200}
                active={email ? "false" : "true"}
                value={email}
                regex={
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                }
              />
              <WorldTextInput //phone
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="phone"
                labelStyle={{
                  fontWeight: "bold"
                }}
                type="phone"
                // title={tagTooltip}
                callback={(e) => handlePhone(e)}
                label={"Mobile number (intl format)"}
                required={true}
                placeholder={"27 phone number"}
                // description={"generic World text input field testing phase"}
                minRows={1}
                maxRows={1}
                minStrLen={9}
                maxStrLen={13}
                // active={worldTestTA?.length > 0 ? "true" : "false"}
                value={phone}
                regex={/[0-9]$/}
              />
              <WorldTextInput //id
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="id"
                labelStyle={{
                  fontWeight: "bold"
                }}
                type="text"
                // title={tagTooltip}
                callback={(e) => handleID(e)}
                label={"National identity number"}
                required={false}
                placeholder={"ID"}
                // description={"generic World text input field testing phase"}
                minRows={1}
                maxRows={1}
                minStrLen={9}
                maxStrLen={56}
                // active={worldTestTA?.length > 0 ? "true" : "false"}
                value={id}
                regex={/^[\w-./]+$/i}
              />
              <WorldTextInput //username
                universalStyle={{ display: "flex", flexDirection: "column" }}
                identity="user"
                labelStyle={{
                  fontWeight: "bold"
                }}
                type="text"
                // title={tagTooltip}
                callback={(e) => handleUser(e)}
                label="Username"
                required={true}
                placeholder="Username"
                // description={"generic World text input field testing phase"}
                minRows={1}
                maxRows={1}
                // minStrLen={3}
                maxStrLen={56}
                // active={worldTestTA?.length > 0 ? "true" : "false"}
                value={uid}
                active="true"
                // autoComplete="off"
                // autoCapitalize="none"
                regex={/[a-z0-9-._/, ]$/i}
              />
              <button
                className="UI-button-service"
                style={{ alignSelf: "center" }}
                disabled={!submitOK}
                form="newuser_form"
                type="submit"
              >
                Register
              </button>
            </form>
          </div>
        )}
        {/* </div> */}
      </div>
    </div>
  );
};


export default UIRegister