import React from "react";
import UITopicResult from "ui/UITopicResult";

const UITopicResults = (props) => {

  const handleClose = () => {
    props.onClose();
  };

  let content = (
    <div
    // className="UI-topic-list-container-search"
    >
      <div
        // className="grid-container"
        // className="UI-topic-list"
        className="flex-container"
        style={{ paddingRight: "1rem" }}
      >
        {props.topicList === undefined || props.topicList.length === 0 ? (
          <p>No Results</p>
        ) : (
          props.topicList.map((t) => (
            <UITopicResult
              topic={t}
              key={t.mtopic}
              muid={props.muid}
              mpersona={props.mpersona}
              persona={props.persona}
              onClose={handleClose}
            ></UITopicResult>
          ))
        )}
      </div>
    </div>
  );

  return content;
};

export default React.memo(UITopicResults, (prevProps, nextProps) => {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
