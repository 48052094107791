import React, { useState, useRef } from 'react'
import "../utils/Slider.css"

const Slider = (props) => {
  const {
    step,
    min,
    max,
    value,
    defaultValue,
    defaultLength,
    onChangeValue,
    // linearGradientColoir,
    // rangeBackgroundColor
  } = props
  const rangeRef = useRef()
  const [range, setRange] = useState(defaultLength)

  // useEffect(() => {
  //   const rangeValue = parseInt(rangeRef.current.value, 10);
  // rangeLinearGradient(rangeValue, min, max);
  // }, [rangeRef, min, max]);

  const handleChange = max => e => {
    onChangeValue(e)
    setRange(e.target.value)
    // rangeLinearGradient(e.target.value, min, max);
  }

  // const calculatePercentage = (value, min, max) => {
  //   return ((value - min) / (max - min)) * 100 + '%';
  // }

  // const rangeLinearGradient = (value, min, max) => {  //this is for use with syles and imported library JSS
  //   const percentage = calculatePercentage(value, min, max);
  // const newBackgroundStyle =
  //   `linear-gradient(90deg, ${linearGradientColor} 0% ${percentage}, ${rangeBackgroundColor} ${percentage} 100%)`;
  // rangeRef.current.style.background = newBackgroundStyle;
  // }

  return (
    <>

      <div className='slider-container'>
        {/* <div > */}
          <span className="range-min-max-values" type="left">{min}</span>
          <span className="range-min-max-values" type="right">{max}</span>
        {/* </div> */}
        <input
          ref={rangeRef}
          className="range-slider"
          type="range"
          step={step}
          min={min}
          max={max}
          value={value}
          // defaultValue={defaultValue}
          onChange={handleChange(max)}
        />
      </div>
    </>
  )
}

export default Slider