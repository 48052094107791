import React from 'react'

const PrivacyPolicy = (props) => {
  return (
    <div>
      <svg
        // classname="icon"
        transform="translate(1 2) scale(1 1)"
        stroke={props.strokecolor}
        fill={props.fillcolor}
        strokeWidth={props.strokewidth}
        viewBox="0 0 24 24"
        height="22"
        width="22"
        xmlns="http://www.w3.org/2000/svg">
        {/* <path d="M 0 0 h 24 v 24 H 0 Z">
        </path> */}

        <path d="M 12 3.19 l 7 3.11 V 11 c 0 4.52 -2.98 8.69 -7 9.93 c -4.02 -1.24 -7 -5.41 -7 -9.93 V 6.3 l 7 -3.11 M 12 1 L 3 5 v 6 c 0 5.55 3.84 10.74 9 12 c 5.16 -1.26 9 -6.45 9 -12 V 5 l -9 -4 Z m -1 6 h 2 v 2 h -2 V 7 Z m 0 4 h 2 v 6 h -2 v -6 Z">
        </path>
      </svg>

    </div >
  )
}

export default PrivacyPolicy