import { useState, useEffect } from "react";

// https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci

export default function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    let isMounted = true;
    const handler = setTimeout(() => {
      isMounted && setDebouncedValue(value);
    }, delay);
    return () => {
      isMounted = false;
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
}
